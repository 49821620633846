export const StudentRateYourExperienceTranslations = {
  RATE_YOUR_EXPERIENCE_TITLE: {
    es: 'Valorar tu experiencia en Ucademy',
    en: 'Rate your experience in Ucademy',
    cat: 'Valorar la teva experiència en Ucademy',
    eus: 'Baloratu zure esperientzia Ucademy-n',
    pt: 'Avaliar sua experiência na Ucademy'
  },
  RATE_YOUR_EXPERIENCE_DESCRIPTION: {
    es: 'Una vez al mes, podrás darnos tu opinión y ayudarnos a seguir mejorando.',
    en: 'Once a month, you can give us your opinion and help us improve.',
    cat: 'Cada mes, podràs donar-nos la teva opinió i ajudar-nos a millorar.',
    eus: 'Hilean behin, zure iritzia eman dezakezu eta hobetzen jarraitzen lagunduko diguzu.',
    pt: 'Uma vez por mês, você pode nos dar sua opinião e nos ajudar a melhorar.'
  },
  RATE_YOUR_EXPERIENCE_TUTOR_TITLE: {
    es: 'Tu tutor',
    en: 'Your tutor',
    cat: 'Tu tutor',
    eus: 'Zure tutorea',
    pt: 'Seu tutor'
  },
  RATE_YOUR_EXPERIENCE_TUTOR_DESCRIPTION: {
    es: 'Cuéntanos qué te parece el acompañamiento de tu tutor.',
    en: 'Tell us what you think about your tutor.',
    cat: 'Cuéntanos qué te sembla el acompanyament del teu tutor.',
    eus: 'Esan iezaguzu zer iruditzen zaizun zure tutorearen laguntzaz.',
    pt: 'Diga-nos o que você pensa sobre seu tutor.'
  },
  RATE_YOUR_EXPERIENCE_TEACHERS_TITLE: {
    es: 'Los profesores',
    en: 'The teachers',
    cat: 'Els professors',
    eus: 'Irakasleak',
    pt: 'Professores'
  },
  RATE_YOUR_EXPERIENCE_TEACHERS_DESCRIPTION: {
    es: 'Evalúa las habilidades y la dedicación de tus profesores.',
    en: 'Evaluate the skills and dedication of your teachers.',
    cat: 'Evalúa les habilitats i la dedicació dels teus professors.',
    eus: 'Ebaluatu zure irakasleen gaitasunak eta dedikazioa.',
    pt: 'Avalie as habilidades e a dedicação dos seus professores.'
  },
  RATE_YOUR_EXPERIENCE_TEACHERS_EXTRA_one: {
    es: 'Hay 1 profesor sin valorar',
    en: 'There is 1 teacher to rate',
    cat: 'Hi ha 1 professor per valorar',
    eus: 'Irakasle 1 dago baloraziorik gabe',
    pt: 'Professor 1 sem avaliação'
  },
  RATE_YOUR_EXPERIENCE_TEACHERS_EXTRA_other: {
    es: 'Hay {{count}} profesores sin valorar',
    en: 'There are {{count}} teachers to rate',
    cat: 'Hi ha {{count}} professors per valorar',
    eus: 'Irakasle {{count}} dago baloraziorik gabe',
    pt: 'Professor {{count}} sem avaliação'
  },
  RATE_YOUR_EXPERIENCE_LIVE_CLASSES_TITLE: {
    es: 'Las clases en directo',
    en: 'The live classes',
    cat: 'Les classes en directe',
    eus: 'Zuzeneko klaseak',
    pt: 'Aulas ao vivo'
  },
  RATE_YOUR_EXPERIENCE_LIVE_CLASSES_DESCRIPTION: {
    es: 'Valora la calidad y el enfoque de las clases.',
    en: 'Rate the quality and focus of the classes.',
    cat: 'Valora la qualitat i l`enfoc de les classes.',
    eus: 'Baloratu klaseen kalitatea eta ikuspegia.',
    pt: 'Avalie a qualidade e o foco das aulas.'
  },
  RATE_YOUR_EXPERIENCE_LIVE_CLASSES_EXTRA_one: {
    es: 'Hay 1 clase sin valorar',
    en: 'There is 1 class to rate',
    cat: 'Hi ha 1 classe per valorar',
    eus: 'Baloratu gabeko klase bat dago',
    pt: 'Há 1 aula sem avaliação'
  },
  RATE_YOUR_EXPERIENCE_LIVE_CLASSES_EXTRA_other: {
    es: 'Hay {{count}} clases sin valorar',
    en: 'There are {{count}} classes to rate',
    cat: 'Hi ha {{count}} classes per valorar',
    eus: 'Baloratu gabeko klase {{count}} dago',
    pt: 'Há {{count}} aulas sem avaliação'
  },
  RATE_YOUR_EXPERIENCE_BUTTON: {
    es: 'Valorar',
    en: 'Rate',
    cat: 'Valorar',
    eus: 'Baloratu',
    pt: 'Avaliar'
  },
  RATE_YOUR_EXPERIENCE_EXTRA_DONE: {
    es: 'Valoración recibida. ¡Vota de nuevo el próximo mes!',
    en: 'Rate received. Vote again next month!',
    cat: 'Valoració rebut. ¡Vota de nou el próxim mes!',
    eus: 'Jasotako balorazioa. Datorren hilabetean berriro bozkatu!',
    pt: 'Avaliação recebida. Vote novamente no próximo mês!'
  }
};
