import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: absolute;
  left: 16px;
  bottom: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.purple7};
  animation: ${slideIn} 0.3s ease-out;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;
