export const StudentTutorVoteTranslations = {
  TUTOR_VOTE_TITLE: {
    es: 'Valora a tu tutor',
    en: 'Rate your tutor',
    cat: 'Valorar el teu tutor',
    eus: 'Baloratu zure tutor',
    pt: 'Avaliar seu tutor'
  },
  TUTOR_VOTE_COMMENTS_TITLE: {
    es: '¿Algo que destacar sobre tu tutor?',
    en: 'Something to highlight about your tutor?',
    cat: '¿Alguna cosa que destacar sobre el teu tutor?',
    eus: 'Zure ikastarok gaitzeko zer moduzko komentario batzuetan?',
    pt: 'Você deseja destacar algo sobre seu tutor?'
  },
  TUTOR_VOTE_COMMENTS_SUBTITLE: {
    es: 'Tu opinión es privada. {{tutorName}} no podrá leer lo que escribas.',
    en: 'Your opinion is private. {{tutorName}} will not be able to read what you write.',
    cat: 'La teva opinió és privada. {{tutorName}} no podrà llegir el que escriguis.',
    eus: 'Zure ikustarok pribatua da. {{tutorName}} ez duzu idatzitakoaren ikusten.',
    pt: 'Sua opinião é privada. {{tutorName}} não poderá ler o que você escrever.'
  },
  TUTOR_VOTE_COMMENTS_PLACEHOLDER: {
    es: 'Escribe aquí...',
    en: 'Write here...',
    cat: 'Escriu aquí...',
    eus: 'Idatzi hemen...',
    pt: 'Escreva aqui...'
  },
  SEND_VOTE_BUTTON: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  TUTOR_VOTE_RATING_QUALITY: {
    es: 'Calidad de las tutorías',
    en: 'Tutor quality',
    cat: 'Qualitat de les tutoríes',
    eus: 'Tutoretzaren kalitatea',
    pt: 'Qualidade do tutor'
  },
  TUTOR_VOTE_RATING_ATTENTION: {
    es: 'Atención personalizada y seguimiento de tu progreso',
    en: 'Personalized attention and progress tracking',
    cat: 'Atenció personalitzada i seguiment del teu progrés',
    eus: 'Arreta pertsonalizatua eta zure aurrerapenaren jarraipena.',
    pt: 'Atendimento personalizado e acompanhamento do seu progresso.'
  },
  TUTOR_VOTE_RATING_DIFFICULTIES: {
    es: 'Ayuda para superar dificultades y resolver dudas',
    en: 'Help to overcome difficulties and resolve doubts',
    cat: 'Ajuda per superar dificultats i resoldre dubtes',
    eus: 'Zailtasunak gainditzen eta zalantzak konpontzen laguntzea',
    pt: 'Ajuda para superar dificuldades e resolver dúvidas'
  },
  TUTOR_SINCE: {
    es: 'Es tu tutor/a desde {{month}} de {{year}}',
    en: 'He/she has been your tutor since {{month}} of {{year}}',
    cat: 'És el teu tutor/a des de {{month}} de {{year}}',
    eus: 'Zure tutorea izan da {{month}} {{year}} geroztik',
    pt: 'Ele é seu tutor desde {{month}} de {{year}}'
  }
};
