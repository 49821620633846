import { IUpdateLiveClassVote } from '@sdk/contracts';
import { create } from 'zustand';

type IPartialVote = Omit<Partial<IUpdateLiveClassVote>, 'comment'>;

interface IStore {
  liveClassVoteItem?: IPartialVote;
  setLiveClassVoteItem: (liveClassVoteItem: IPartialVote) => void;
  resetLiveClassVoteItem: () => void;
}

export const useLiveClassVoteItem = create<IStore>(set => ({
  setLiveClassVoteItem: (liveClassVoteItem: IPartialVote) => set({ liveClassVoteItem }),
  resetLiveClassVoteItem: () => set({ liveClassVoteItem: undefined })
}));
