import { IFile } from '@sdk/contracts';
import ReactQuill from 'react-quill';

import { EditorToolbar } from './EditorToolbar';
import { Wrapper } from './HtmlEditor.styles';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

type Props = {
  value?: string;
  onChange?: (content: string) => void;
  disabled?: boolean;
  placeholder?: string;
  canUpload?: boolean;
  onFileUpload?: (file: IFile) => void;
  isUploadDisabled?: boolean;
  theme?: 'snow' | 'bubble';
};

export const HtmlEditor: React.FC<Props> = ({
  value,
  onChange,
  disabled = false,
  placeholder,
  canUpload = false,
  onFileUpload,
  isUploadDisabled = false,
  theme = 'snow'
}) => {
  const handleChange = (content: string) => {
    const newContent = content === '<p><br></p>' ? '' : content;
    onChange?.(newContent);
  };

  return (
    <Wrapper>
      {canUpload && (
        <EditorToolbar isUploadDisabled={isUploadDisabled} onFileUpload={onFileUpload} />
      )}
      <ReactQuill
        value={value}
        onChange={handleChange}
        readOnly={disabled}
        placeholder={placeholder}
        theme={theme}
        modules={{
          toolbar: canUpload ? '#my-toolbar' : undefined
        }}
      />
    </Wrapper>
  );
};
