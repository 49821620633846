import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 0 8px 24px 8px;

  .ant-divider {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 16px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    padding: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SectionWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black6};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const ChangeActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`;
