import { PageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { create } from 'zustand';

interface IStore {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  viewerRef?: PageNavigationPlugin;
  setViewerRef: (viewerRef?: PageNavigationPlugin) => void;
}

const store = create<IStore>(set => ({
  currentPage: 1,
  setCurrentPage: page => set({ currentPage: page }),
  viewerRef: undefined,
  setViewerRef: viewerRef => set({ viewerRef })
}));

export const usePdfResource = () => {
  const { currentPage, setCurrentPage, viewerRef, setViewerRef } = store();

  const handleJumpToPage = (page: number) => {
    if (!viewerRef) return;
    viewerRef.jumpToPage(page);
  };

  return { currentPage, setCurrentPage, handleJumpToPage, viewerRef, setViewerRef };
};
