import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 16px 16px 8px;
  }

  .ant-collapse {
    width: 100%;
  }
`;
