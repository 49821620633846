export const RecoverTranslations = {
  RECOVER: {
    es: 'Recuperar',
    en: 'Recover',
    cat: 'Recuperar',
    eus: 'Berreskuratu',
    pt: 'Recuperar'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  READY: {
    es: '¡Listo!',
    en: 'Ready!',
    cat: '¡Listo!',
    eus: '¡Listo!',
    pt: 'Pronto!'
  },
  RESEND: {
    es: 'Reenviar',
    en: 'Resend',
    cat: 'Reenviar',
    eus: 'Birbidali',
    pt: 'Reenviar'
  },
  NEW_PASSWORD: {
    es: 'Nueva contraseña',
    en: 'New password',
    cat: 'Nova contrasenya',
    eus: 'Pasahitz berria',
    pt: 'Nova senha'
  },
  PASSWORDS_DO_NOT_MATCH: {
    es: 'Las contraseñas no coinciden',
    en: 'Passwords do not match',
    cat: 'Les contrasenyes no coincideixen',
    eus: 'Pasahitzak ez datoz bat',
    pt: 'As senhas não coincidem'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Tu contraseña ha sido cambiada con éxito',
    en: 'Your password has been changed successfully',
    cat: 'La teva contrasenya ha estat canviada amb èxit',
    eus: 'Zure pasahitza ongi aldatu da',
    pt: 'Sua senha foi alterada com sucesso'
  },
  REPEAT_PASSWORD: {
    es: 'Repetir contraseña',
    en: 'Repeat password',
    cat: 'Repetir contrasenya',
    eus: 'Errepikatu pasahitza',
    pt: 'Repetir senha'
  },
  EMAIL_RESENT: {
    es: 'Se ha reenviado el correo de recuperación',
    en: 'The recovery email has been resent',
    cat: 'S`ha reenviat el correu de recuperació',
    eus: 'Berreskurapenaren emaila birbidali da',
    pt: 'O email de recuperação foi reenviado'
  },
  CHECK_YOUR_EMAIL: {
    es: 'Revisa tu correo, si no lo has recibido haz click aquí',
    en: 'Check your email, if you have not received it click here',
    cat: 'Revisa el teu correu, si no l`has rebut fes click aquí',
    eus: 'Egiaztatu zure emaila, ez baduzu jaso klikatu hemen',
    pt: 'Verifique o seu email, se não recebeu, clique aqui'
  },
  INSERT_YOUR_EMAIL_AND_SEND_LINK_BACK: {
    es: 'Ingresa tu email y te enviaremos un enlace para restablecer la contraseña',
    en: 'Insert your email and we will send you a link to reset your password',
    cat: 'Ingresa el teu correu i t`enviarem un enllaç per restablir la contrasenya',
    eus: 'Sartu zure emaila eta bidaliko dizugu zure pasahitza berrezartzeko esteka',
    pt: 'Insira o seu email e enviaremos um link para redefinir a sua senha'
  }
};
