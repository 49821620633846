import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > :first-child {
    background-color: ${({ theme }) => theme.colors.black10};
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    video {
      border-radius: 10px;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal {
    min-height: 100%;
  }

  .ant-modal > :nth-child(1) {
    height: calc(100% - 10px) !important;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    background-color: ${({ theme }) => theme.colors.black10};

    & > :first-child {
      max-width: 600px;
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    & > :first-child {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 16 / 9;
    }

    .ant-modal-mask,
    .ant-modal-wrap {
      position: absolute;
    }
    .ant-modal {
      width: calc(100% - 10px) !important;
      height: calc(100% - 15px) !important;
      min-height: 100% !important;
      top: 5px;
      bottom: 10px;
      padding: 0;
      .ant-modal-content {
        min-height: 100% !important;
        display: flex;
        flex-direction: column;
      }
      .ant-modal-footer {
        margin-top: auto;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    & > :first-child {
      max-width: unset;
    }
    background-color: ${({ theme }) => theme.colors.black1};
  }
`;

export const TakeNoteWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1000;
`;
