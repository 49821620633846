export const IndexationLandingsTranslations = {
  INDEXATION_LANDINGS_TITLE: {
    es: 'Landings de indexación',
    en: 'Indexation landings',
    cat: "Landings d'indexació",
    eus: 'Indexazio landings',
    pt: 'Landings de indexação'
  },
  LANDINGS_TOTAL_COUNT: {
    es: '{{total}} landings',
    en: '{{total}} landings',
    cat: '{{total}} landings',
    eus: '{{total}} landings',
    pt: '{{total}} landings'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  URL: {
    es: 'URL',
    en: 'URL',
    cat: 'URL',
    eus: 'URL',
    pt: 'URL'
  }
};
