export const ChatTranslations = {
  CHAT: {
    es: 'Chat',
    en: 'Chat',
    cat: 'Xat',
    eus: 'Txat',
    pt: 'Chat'
  },
  DELIVERABLES: {
    es: 'Entregables',
    en: 'Deliverables',
    cat: 'Lliurables',
    eus: 'Entregagarriak',
    pt: 'Entregáveis'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  TUTORES: {
    es: 'Tutores',
    en: 'Tutors',
    cat: 'Tutors',
    eus: 'Tutoreak',
    pt: 'Tutores'
  },
  YOUR_TUTOR: {
    es: 'Tu tutor',
    en: 'Your tutor',
    cat: 'El teu tutor',
    eus: 'Zure tutorea',
    pt: 'Seu tutor'
  },
  STUDENT_FROM: {
    es: 'Alumno de:',
    en: 'Student from:',
    cat: 'Alumne de:',
    eus: 'Ikaslea:',
    pt: 'Aluno de:'
  },
  THERE_ARE_NO_MESSAGES: {
    es: 'No hay mensajes',
    en: 'There are no messages',
    cat: 'No hi ha missatges',
    eus: 'Ez dago mezurik',
    pt: 'Não há mensagens'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur',
    pt: 'Hoje'
  },
  NO_MESSAGES: {
    es: 'Todavía no le has escrito ningún mensaje a tu tutor',
    en: 'You have not written any messages to your tutor yet',
    cat: 'Encara no li has escrit cap missatge al teu tutor',
    eus: 'Oraindik ez duzu zure tutoreari mezu bat idatzi',
    pt: 'Você ainda não escreveu nenhuma mensagem para seu tutor'
  },
  CREATE_FIRST_NOTE: {
    es: 'Escribe observaciones sobre {{ name }} para mantener un registro actualizado de su progreso.',
    en: 'Write notes about {{ name }} to keep an updated record of their progress.',
    cat: 'Escriu observacions sobre {{ name }} per mantenir un registre actualitzat del seu progrés.',
    eus: 'Idatzi oharrak {{ name }} gurekin gure aurrerapenaren gordailua mantentzeko.',
    pt: 'Escreva observações sobre {{ name }} para manter um registro atualizado do seu progresso.'
  },
  CREATE_NOTE: {
    es: 'Crear nota',
    en: 'Create note',
    cat: 'Crear nota',
    eus: 'Erabili nota',
    pt: 'Criar nota'
  },
  CREATE_A_NOTE: {
    es: 'Crear una nota',
    en: 'Create a note',
    cat: 'Crear una nota',
    eus: 'Erabili nota',
    pt: 'Criar uma nota'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  CREATED_BY: {
    es: 'Creada por {{ name }}',
    en: 'Created by {{ name }}',
    cat: 'Creada per {{ name }}',
    eus: '{{ name }} sortua',
    pt: 'Criado por {{ name }}'
  },
  LAST_UPDATED_ON: {
    es: 'Modificada por última vez el {{ date }}',
    en: 'Last updated on {{ date }}',
    cat: 'Modificada per darrera vegada el {{ date }}',
    eus: 'Azken aldaketa {{ date }} eginda',
    pt: 'Última atualização em {{ date }}'
  },
  HIDE_TO_TEACHER: {
    es: 'Ocultar al profesor',
    en: 'Hide to teacher',
    cat: 'Ocultar al professor',
    eus: 'Ikastarietara erakutsi ez',
    pt: 'Ocultar ao professor'
  },
  SAVE_NOTE: {
    es: 'Guardar nota',
    en: 'Save note',
    cat: 'Guardar nota',
    eus: 'Gordatu nota',
    pt: 'Salvar nota'
  },
  WRITE_HERE_YOUR_NOTE: {
    es: 'Escribe aquí tu nota...',
    en: 'Write here your note...',
    cat: 'Escriu aquí la teva nota...',
    eus: 'Idatzi zure nota hemen...',
    pt: 'Escreva aqui sua nota...'
  },
  STUDENT_NOTE_COUNT_one: {
    es: '{{ count }} nota sobre {{ name }}',
    en: '{{ count }} note about {{ name }}',
    cat: '{{ count }} nota sobre {{ name }}',
    eus: '{{ count }} nota {{ name }} gurekin',
    pt: '{{ count }} nota sobre {{ name }}'
  },
  STUDENT_NOTE_COUNT_other: {
    es: '{{ count }} notas sobre {{ name }}',
    en: '{{ count }} notes about {{ name }}',
    cat: '{{ count }} notes about {{ name }}',
    eus: '{{ count }} nota {{ name }} gurekin',
    pt: '{{ count }} notas sobre {{ name }}'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Fitxategiak',
    pt: 'Filtros'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Buscar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  HIGHLIGHTED_NOTES: {
    es: 'Notas destacadas',
    en: 'Highlighted notes',
    cat: 'Notas destacades',
    eus: 'Nota(k) erakusten',
    pt: 'Notas destacadas'
  },
  HIGHLIGHTED_NOTES_COUNT_one: {
    es: '{{ count }} nota destacada',
    en: '{{ count }} highlighted note',
    cat: '{{ count }} nota destacada',
    eus: '{{ count }} nota erakusten',
    pt: '{{ count }} nota destacada'
  },
  HIGHLIGHTED_NOTES_COUNT_other: {
    es: '{{ count }} notas destacadas',
    en: '{{ count }} highlighted notes',
    cat: '{{ count }} nota(s) destacades',
    eus: '{{ count }} nota(k) erakusten',
    pt: '{{ count }} notas destacadas'
  },
  NOW: {
    es: 'Ahora',
    en: 'Now',
    cat: 'Ara',
    eus: 'Orain',
    pt: 'Agora'
  },
  MINUTES_AGO_one: {
    es: 'Hace {{count}} minuto',
    en: '{{count}} minute ago',
    cat: 'Fa {{count}} minut',
    eus: 'Duela {{count}} minutu',
    pt: 'Há {{count}} minuto'
  },
  MINUTES_AGO_other: {
    es: 'Hace {{count}} minutos',
    en: '{{count}} minutes ago',
    cat: 'Fa {{count}} minuts',
    eus: 'Duela {{count}} minutu',
    pt: 'Há {{count}} minutos'
  },
  HOURS_AGO_one: {
    es: 'Hace {{count}} hora',
    en: '{{count}} hour ago',
    cat: 'Fa {{count}} ora',
    eus: 'Duela {{count}} ordua',
    pt: 'Há {{count}} hora'
  },
  HOURS_AGO_other: {
    es: 'Hace {{count}} horas',
    en: '{{count}} hours ago',
    cat: 'Fa {{count}} hores',
    eus: 'Duela {{count}} ordu',
    pt: 'Há {{count}} horas'
  },
  ONLY_FOR_MANAGERS: {
    es: 'Nota oculta para el profesor',
    en: 'Note hidden for the teacher',
    cat: 'Nota oculta per al professor',
    eus: 'Nota erakutsi ez',
    pt: 'Nota oculta para o professor'
  },
  HIGHLIGHTED_BY: {
    es: 'Destacada por {{ name }}',
    en: 'Highlighted by {{ name }}',
    cat: 'Destacada per {{ name }}',
    eus: '{{ name }} erakusten',
    pt: 'Destacado por {{ name }}'
  },
  DELETE_INTERNAL_NOTE_CONFIRM: {
    es: '¿Estás seguro de querer eliminar esta nota?',
    en: 'Are you sure you want to delete this note?',
    cat: 'Estàs segur que vols esborrar aquesta nota?',
    eus: 'Zer moduz zer moduz ez duzu zure nota ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir esta nota?'
  },
  NOTES: {
    es: 'Notas',
    en: 'Notes',
    cat: 'Notas',
    eus: 'Oharrak',
    pt: 'Notas'
  },
  HIDDEN_FOR_TEACHERS: {
    es: 'Ocultas para el profesor',
    en: 'Hidden for the teacher',
    cat: 'Ocultes per al professor',
    eus: 'Ikastarietara erakutsi ez',
    pt: 'Oculto para o professor'
  },
  TAGS: {
    es: 'Etiquetas',
    en: 'Tags',
    cat: 'Etiquetes',
    eus: 'Etiketak',
    pt: 'Etiquetas'
  },
  CREATED_AT: {
    es: 'Fecha de creación',
    en: 'Created at',
    cat: 'Data de creació',
    eus: 'Sortua',
    pt: 'Data de criação'
  },
  UPDATED_AT: {
    es: 'Fecha de actualización',
    en: 'Updated at',
    cat: 'Data de darrera actualització',
    eus: 'Aldaketa',
    pt: 'Data de atualização'
  },
  CLEAR_FILTERS: {
    es: 'Borrar filtros',
    en: 'Clear filters',
    cat: 'Netejar filtres',
    eus: 'Ezabatu fitxategiak',
    pt: 'Limpar filtros'
  },
  WRITE_TITLE_TO_SAVE: {
    es: 'Escribe un título para guardar la nota',
    en: 'Write a title to save the note',
    cat: 'Escriu un títol per guardar la nota',
    eus: 'Izenburua idatzi gurentzat',
    pt: 'Escreva um título para salvar a nota'
  }
};
