import { create } from 'zustand';

import { ITutorRating } from '../interfaces';

interface IStore {
  tutorRating: ITutorRating;
  comments?: string;
  setTutorRating: (tutorRating: ITutorRating) => void;
  setComments: (comments?: string) => void;
  reset: () => void;
}

export const useTutorVoteItem = create<IStore>(set => ({
  tutorRating: {
    tutoringQuality: 0,
    personalizedAttention: 0,
    helpWithDifficulties: 0
  },
  setTutorRating: (tutorRating: ITutorRating) => set({ tutorRating }),
  setComments: (comments?: string) => set({ comments }),
  reset: () =>
    set({
      tutorRating: { tutoringQuality: 0, personalizedAttention: 0, helpWithDifficulties: 0 },
      comments: undefined
    })
}));
