import { EVoteReason, EPostStatus } from '@constants';

export const CommonDashboardTranslations = {
  HI: {
    es: 'Hola, {{name}}!',
    en: 'Hi, {{name}}!',
    cat: 'Hola, {{name}}!',
    eus: 'Kaixo, {{name}}!',
    pt: 'Olá, {{name}}!'
  },
  HI_STUDENT: {
    es: '¡Hola {{name}}! ¡A por todas con el estudio!',
    en: 'Hi {{name}}! Go for it with the study!',
    cat: 'Hola {{name}}! Endavant amb l’estudi!',
    eus: 'Kaixo {{name}}! Ikasketekin aurrera!',
    pt: 'Olá {{name}}! Vamos para frente com o estudo!'
  },
  NEWS: {
    es: 'Noticias',
    en: 'News',
    cat: 'Notícies',
    eus: 'Berriak',
    pt: 'Notícias'
  },
  COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak',
    pt: 'cursos'
  },
  FILES: {
    es: 'Archivos',
    en: 'Files',
    cat: 'Fitxers',
    eus: 'Fitxategiak',
    pt: 'Ficheiros'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  NEXT_EVENTS: {
    es: 'Próximos eventos',
    en: 'Next events',
    cat: 'Propers esdeveniments',
    eus: 'Hurrengo ekitaldiak',
    pt: 'Próximos eventos'
  },
  REPORTS: {
    es: 'Reportes',
    en: 'Reports',
    cat: 'Informes',
    eus: 'Txostenak',
    pt: 'Relatórios'
  },
  NO_EVENTS_IN_THIS_MONTH: {
    es: 'No hay eventos programados para {{month}}.',
    en: 'No events scheduled for {{month}}.',
    cat: 'No hi ha esdeveniments programats per {{month}}.',
    eus: '{{month}}rako ez dago ekitaldirik egiteko.',
    pt: 'Não há eventos programados para {{month}}.'
  },
  POST_TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  POST_CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia',
    pt: 'Conteúdo'
  },
  DOUBTS_TITLE: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  INFO_OPTION_STUDENTS: {
    es: 'alumnos',
    en: 'students',
    cat: 'alumnes',
    eus: 'ikasleak',
    pt: 'alunos'
  },
  INFO_OPTION_TEACHERS: {
    es: 'profesores',
    en: 'teachers',
    cat: 'professors',
    eus: 'irakasleak',
    pt: 'professores'
  },
  INFO_OPTION_COURSES: {
    es: 'cursos',
    en: 'courses',
    cat: 'cursos',
    eus: 'ikastaroak',
    pt: 'cursos'
  },
  NO_NEWS_TO_SHOW: {
    es: 'No hay noticias para mostrar.',
    en: 'No news to show.',
    cat: 'No hi ha notícies per mostrar.',
    eus: 'Ez dago albisterik erakusteko.',
    pt: 'Não há notícias para mostrar.'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak',
    pt: 'Estatísticas'
  },
  DOWNLOAD_CSAT: {
    es: 'Descargar CSAT',
    en: 'Download CSAT',
    cat: 'Descarregar CSAT',
    eus: 'CSAT deskargatu',
    pt: 'Descarregar CSAT'
  },
  DOWNLOAD_TICKETS: {
    es: 'Descargar dudas',
    en: 'Download tickets',
    cat: 'Descarregar tickets',
    eus: 'Dudak deskargatu',
    pt: 'Descarregar tickets'
  },
  DOWNLOAD_ACTIVITY_RATING: {
    es: 'Descargar valoraciones',
    en: 'Download activity rating',
    cat: 'Descarregar valoracions',
    eus: 'Balorazioak deskargatu',
    pt: 'Descarregar valorações'
  },
  DOWNLOAD_UPLOADED_FILES: {
    es: 'Descargar archivos subidos',
    en: 'Download uploaded files',
    cat: 'Descarregar fitxers pujats',
    eus: 'Igotako fitxategiak deskargatu',
    pt: 'Descarregar ficheiros carregados'
  },
  NEXT_LIVE_CLASSES: {
    es: 'Próximas clases en directo',
    en: 'Next live classes',
    cat: 'Properes classes en viu',
    eus: 'Hurrengo klaseak zuzenean',
    pt: 'Próximas aulas ao vivo'
  },
  CREATE_LIVE_CLASS: {
    es: 'Crear clase',
    en: 'Create class',
    cat: 'Crear classe',
    eus: 'Sortu klase',
    pt: 'Criar aula'
  },
  YOUR_CLASSES: {
    es: 'Das clase en estos cursos:',
    en: 'You teach in these courses:',
    cat: 'Donar classe en aquests cursos:',
    eus: 'Klaseak eman ikastaro hauek:',
    pt: 'Ensinar em estes aulas:'
  },
  PENDING_TASKS: {
    es: 'Actividades pendientes de realizar',
    en: 'Pending activities to perform',
    cat: 'Activitats pendents de realitzar',
    eus: 'Egin beharreko jarduera pendienteak',
    pt: 'Tarefas pendentes de realizar'
  },
  PENDING_TASKS_COUNT: {
    es: '{{ totalTasks }} actividades pendientes',
    en: '{{ totalTasks }} pending activities',
    cat: '{{ totalTasks }} activitats pendents',
    eus: '{{ totalTasks }} jarduera pendiente',
    pt: '{{ totalTasks }} tarefas pendentes'
  },
  TASK_NOT_ON_YOUR_COURSES: {
    es: 'Esta tarea está asociada a un curso que no impartes.',
    en: 'This task is associated with a course you do not teach.',
    cat: 'Aquesta tasca està associada a un curs que no impartiu.',
    eus: 'Jarduera hau irakas ezazun ikastarora lotuta dago.',
    pt: 'Esta tarefa está associada a um curso que não ensina.'
  },
  DEADLINE_DATE: {
    es: 'Fecha máxima de entrega:',
    en: 'Deadline date:',
    cat: 'Data màxima d’entrega:',
    eus: 'Entregako epea:',
    pt: 'Data máxima de entrega:'
  },
  ASSIGNED_TO: {
    es: 'Asignada a:',
    en: 'Assigned to:',
    cat: 'Assignada a:',
    eus: 'Asignatua:',
    pt: 'Atribuída a:'
  },
  EDIT_CONTENT: {
    es: 'Editar contenido',
    en: 'Edit content',
    cat: 'Editar contingut',
    eus: 'Editatu edukia',
    pt: 'Editar conteúdo'
  },
  TASK_HISTORY: {
    es: 'Historial de la tarea',
    en: 'Task history',
    cat: 'Historial de la tasca',
    eus: 'Lanaren historia',
    pt: 'Histórico da tarefa'
  },
  OPEN_DOUBTS: {
    es: 'Dudas abiertas',
    en: 'Open doubts',
    cat: 'Dubtes oberts',
    eus: 'Zabalik dauden zalantzak',
    pt: 'Dúvidas abertas'
  },
  NO_SCHEDULED_EVENTS_TITLE: {
    es: 'No hay eventos',
    en: 'There are no events',
    cat: 'No hi ha esdeveniments',
    eus: 'Ez dago ekitaldirik',
    pt: 'Não há eventos'
  },
  NO_SCHEDULED_EVENTS_DESCRIPTION: {
    es: 'No hay ningún evento de tu formación programado para los próximos 30 días.',
    en: 'There are no events from your formation scheduled for the next 30 days.',
    cat: 'No hi ha cap esdeveniment de la teva formació programat pels propers 30 dies.',
    eus: 'Ez dago zure formazioko ekitaldirik programatuta hurrengo 30 egunetarako.',
    pt: 'Não há eventos da sua formação programados para os próximos 30 dias.'
  },
  NO_PENDING_TASKS_TITLE: {
    es: 'Vaya, vaya, vaya...',
    en: 'Oops, oops, oops...',
    cat: 'Uy, uy, uy...',
    eus: 'Epa, epa, epa...',
    pt: 'Oops, oops, oops...'
  },
  NO_PENDING_TASKS_DESCRIPTION: {
    es: 'Parece que eres tan crack que no tienes ninguna tarea pendiente. Si quieres tareas puedes solicitarlas por el chat.',
    en: 'It seems that you are so crack that you do not have any pending tasks. If you want tasks you can request them through the chat.',
    cat: 'Sembla que ets tan crack que no tens cap tasca pendent. Si vols tasques pots sol·licitar-les pel xat.',
    eus: 'Zuretzat ez dago zeregin pendienteik, zuk zereginak eskatu ditzakezu txaten bidez.',
    pt: 'Parece que você é tão crack que não tem nenhuma tarefa pendente. Se quiser tarefas, pode solicitá-las através do chat.'
  },
  NO_OPEN_DOUBTS_TITLE: {
    es: 'Sin dudas abiertas',
    en: 'Without open doubts',
    cat: 'Sense dubtes oberts',
    eus: 'Zalantzarik gabe',
    pt: 'Sem dúvidas abertas'
  },
  NO_OPEN_DOUBTS_DESCRIPTION: {
    es: 'O tus alumnos son genios y no tienen dudas o eres el más rápido del oeste respondiéndolas.',
    en: 'Either your students are geniuses and have no doubts or you are the fastest in the west answering them.',
    cat: 'O els teus alumnes són genis i no tenen dubtes o ets el més ràpid de l’oest responent-los.',
    eus: 'Zure ikasleak genioak dira eta ez dute zalantzarik edo zure erantzunak oso azkarak dira.',
    pt: 'Os seus alunos são genios e não têm dúvidas ou você é o mais rápido no oeste respondendo-as.'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image',
    cat: 'Imatge',
    eus: 'Irudia',
    pt: 'Imagem'
  },
  [EPostStatus.PUBLISHED]: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua',
    pt: 'Publicado'
  },
  TOPIC: {
    es: 'Tema',
    en: 'Topic',
    cat: 'Tema',
    eus: 'Gaia',
    pt: 'Tema'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia',
    pt: 'Conteúdo'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Formations',
    cat: 'Formacions',
    eus: 'Formazioak',
    pt: 'Formações'
  },
  SELECT_TOPIC: {
    es: 'Selecciona un tema',
    en: 'Select a topic',
    cat: 'Selecciona un tema',
    eus: 'Hautatu gaia',
    pt: 'Selecionar tema'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update',
    cat: 'Actualitzar',
    eus: 'Eguneratu',
    pt: 'Atualizar'
  },
  ADD: {
    es: 'Agregar',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu',
    pt: 'Adicionar'
  },
  NEW_TOPIC: {
    es: 'Nuevo tema',
    en: 'New topic',
    cat: 'Nou tema',
    eus: 'Gaia berria',
    pt: 'Novo tema'
  },
  SLUG: {
    es: 'Slug',
    en: 'Slug',
    cat: 'Slug',
    eus: 'Slug',
    pt: 'Slug'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu',
    pt: 'Eliminar'
  },
  DELETE_POST_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este post?',
    en: 'Are you sure to delete this post?',
    cat: 'Estàs segur d`eliminar aquest post?',
    eus: 'Ziur zaude post hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este post?'
  },
  LECTURE_TIME: {
    es: 'Tiempo de lectura',
    en: 'Reading time',
    cat: 'Temps de lectura',
    eus: 'Irakurtzeko denbora',
    pt: 'Tempo de leitura'
  },
  SHORT_DESCRIPTION: {
    es: 'Descripción corta',
    en: 'Short description',
    cat: 'Descripció curta',
    eus: 'Deskribapen laburra',
    pt: 'Descrição curta'
  },
  CLASSES_AND_SYLLABUS: {
    es: 'Clases y temario',
    en: 'Classes and syllabus',
    cat: 'Classes i temari',
    eus: 'Klaseak eta syllabus',
    pt: 'Aulas e plano de estudo'
  },
  GO_TO_CONTENT: {
    es: 'Ir al contenido',
    en: 'Go to content',
    cat: 'Anar al contingut',
    eus: 'Joan edukira',
    pt: 'Ir para o conteúdo'
  },
  CHANGE_COURSE: {
    es: 'Cambiar curso',
    en: 'Change course',
    cat: 'Canviar curs',
    eus: 'Aldatu edukira',
    pt: 'Mudar curso'
  },
  WHAT_TO_DO_TODAY: {
    es: 'Qué hacer hoy',
    en: 'What to do today',
    cat: 'Què fer avui',
    eus: 'Egun hau zer egin',
    pt: 'O que fazer hoje'
  },
  LIVE_CLASSES: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en viu',
    eus: 'Klaseak zuzenean',
    pt: 'Aulas ao vivo'
  },
  MY_EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Azterketa taldeak',
    pt: 'Grupos de exames'
  },
  QUIZZES: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Proves',
    eus: 'Probatzak',
    pt: 'Provas'
  },
  FREE_MODE_TITLE: {
    es: 'Test personalizado',
    en: 'Custom test',
    cat: 'Prova personalitzada',
    eus: 'Eredu personalizatua',
    pt: 'Prova personalizada'
  },
  REVIEW_MODE_TITLE: {
    es: 'Corrige tus fallos',
    en: 'Review your mistakes',
    cat: 'Repassa els teus errors',
    eus: 'Berrikusi zure erroreak',
    pt: 'Revisar os seus erros'
  },
  PRACTICE_MODE_TITLE: {
    es: 'Haz un examen',
    en: 'Take a test',
    cat: 'Fes una prova',
    eus: 'Egin proba',
    pt: 'Fazer uma prova'
  },
  FREE_MODE_DESCRIPTION: {
    es: 'Elige cuántas preguntas quieres responder y practica a tu ritmo.',
    en: 'Choose how many questions you want to answer and practice at your own pace.',
    cat: 'Tria zenbat galdera egin nahi duzun eta praktikatu zure ereduaren arabera.',
    eus: 'Aukeratu zenbat galdera egin nahi duzun eta praktikatu zure ereduaren arabera.',
    pt: 'Escolha quantas perguntas quer responder e pratique no seu ritmo.'
  },
  REVIEW_MODE_DESCRIPTION: {
    es: 'Vuelve a responder las preguntas que fallaste en los tests anteriores.',
    en: 'Review the questions you have failed from any test you have taken.',
    cat: 'Repassa les preguntes que hagis fallat de qualsevol prova que hagis fet.',
    eus: 'Berrikusi zure galdu dituzun galderak egin dituzun edozein probatik.',
    pt: 'Revise as perguntas que falhou em qualquer prova que fez.'
  },
  PRACTICE_TESTS_DESCRIPTION: {
    es: 'Realiza un examen real con cronómetro y practica experiencia.',
    en: 'Take a real exam with a timer and practice experience.',
    cat: 'Fes una prova real amb cronòmetre i practica experiència.',
    eus: 'Egin proba erreala denbora kronometroarekin eta praktika errealitatea.',
    pt: 'Fazer uma prova real com cronómetro e praticar experiência.'
  },
  FREE_MODE_BUTTON_TEXT: {
    es: 'Iniciar test',
    en: 'Start test',
    cat: 'Començar prova',
    eus: 'Probatzak hasita',
    pt: 'Iniciar prova'
  },
  REVIEW_MODE_BUTTON_TEXT: {
    es: 'Repasar fallos',
    en: 'Review mistakes',
    cat: 'Repassar errors',
    eus: 'Berrikusi erroreak',
    pt: 'Revisar erros'
  },
  PRACTICE_MODE_BUTTON_TEXT: {
    es: 'Haz un examen',
    en: 'Take a test',
    cat: 'Fes una prova',
    eus: 'Egin proba',
    pt: 'Fazer uma prova'
  },
  TEST_HISTORY: {
    es: 'Historial de tests',
    en: 'Test history',
    cat: 'Historial de proves',
    eus: 'Probatzak historia',
    pt: 'Histórico de provas'
  },
  INVITE_A_FRIEND_AND_EARN: {
    es: 'Invita a un amigo y gana {{amount}}€* 💶🫰🤑',
    en: 'Invite a friend and earn {{amount}}€* 💶🫰🤑',
    cat: 'Convida un amic i guanya {{amount}}€* 💶🫰🤑',
    eus: 'Deitu lagun bati eta irabazi {{amount}}€* 💶🫰🤑',
    pt: 'Convidar um amigo e ganhar {{amount}}€* 💶🫰🤑'
  },
  INVITE_4_FRIENDS_AND_EARN: {
    es: 'Invita a 4 amigos y consigue 600€',
    en: 'Invite 4 friends and get 600€',
    cat: 'Convida 4 amics i aconsegueix 600€',
    eus: 'Deitu 4 lagunei eta lortu 600€',
    pt: 'Convidar 4 amigos e ganhar 600€'
  },
  EMAIL_OF_YOUR_FRIEND: {
    es: 'Email de tu amigo o amiga',
    en: 'Email of your friend',
    cat: 'Email del teu amic o amiga',
    eus: 'Zure lagunaren emaila',
    pt: 'Email do seu amigo ou amigo'
  },
  REFERRAL_MODAL_DESCRIPTION: {
    es: '*Nos pondremos en contacto contigo en caso de que se convierta en alumno de Ucademy para realizarte el pago.',
    en: '*We will contact you in case he becomes a Ucademy student to make the payment.',
    cat: '*Ens posarem en contacte amb tu en cas que es converteixi en alumne de Ucademy per realitzar-te el pagament.',
    eus: '*Zurekin harremanetan jarriko gara Ucademyko ikasle bihurtzen bada ordainketa egiteko.',
    pt: '*Entraremos em contato caso ele se torne aluno da Ucademy para realizar o pagamento.'
  },
  SEND_INVITATION: {
    es: 'Enviar invitación',
    en: 'Send invitation',
    cat: 'Enviar invitació',
    eus: 'Bidali gonbidapena',
    pt: 'Enviar convite'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten',
    pt: 'Sair'
  },
  INVITATION_SENT: {
    es: '¡Invitación enviada!',
    en: 'Invitation sent!',
    cat: 'Invitació enviada!',
    eus: 'Gonbidapena bidalia!',
    pt: 'Convite enviado!'
  },
  INVITATION_FAILED: {
    es: '¡Ups! Algo ha fallado al enviar la invitación.',
    en: 'Oops! Something went wrong sending the invitation.',
    cat: '¡Ups! Alguna cosa ha fallat en enviar la invitació.',
    eus: '¡Ups! Zerbait gaizki joan da gonbidapena bidaltzean.',
    pt: '¡Ups! Algo falhou ao enviar o convite.'
  },
  HOW_LIKELY_IS_IT_THAT_YOU_WOULD_RECOMMEND_UCADEMY: {
    es: '¿Con qué probabilidad recomendarías Ucademy a un amigo?',
    en: 'How likely is it that you would recommend Ucademy to a friend?',
    cat: 'Amb quina probabilitat recomanaries Ucademy a un amic?',
    eus: 'Zer probabilitatearekin gomendatuko zenukezu Ucademy lagun bati?',
    pt: 'Com probabilidade você recomendaria Ucademy para um amigo?'
  },
  NOT_PROBABLE: {
    es: 'No es probable',
    en: 'Not probable',
    cat: 'No és probable',
    eus: 'Ez da probagarria',
    pt: 'Não é provável'
  },
  VERY_PROBABLE: {
    es: 'Muy probable',
    en: 'Very probable',
    cat: 'Molt probable',
    eus: 'Oso probagarria',
    pt: 'Muito provável'
  },
  NEXT: {
    es: 'Siguiente',
    en: 'Next',
    cat: 'Següent',
    eus: 'Hurrengoa',
    pt: 'Próximo'
  },
  WRITE_HERE: {
    es: 'Escribe aquí...',
    en: 'Write here...',
    cat: 'Escriu aquí...',
    eus: 'Idatzi hemen...',
    pt: 'Escreva aqui...'
  },
  NPS_THANK_YOU: {
    es: 'Muchas gracias. Tendremos en cuenta tus comentarios para convertir Ucademy en lo que TÚ quieres. ¡Suerte y ánimo en el estudio!',
    en: 'Thank you very much. We will take your comments into account to make Ucademy what YOU want. Good luck and encouragement in your studies!',
    cat: 'Moltes gràcies. Tindrem en compte els teus comentaris per convertir Ucademy en el que TU vols. Sort i ànim en l’estudi!',
    eus: 'Eskerrik asko. Kontuan hartuko ditugu zure iruzkinak Ucademy zure nahi den modura bihurtzeko. Zorte on eta animo ikasketetan!',
    pt: 'Muito obrigado. Vamos ter em conta os seus comentários para tornar o Ucademy no que você quer. Boa sorte e animação no seu estudo!'
  },
  CAN_YOU_WRITE_A_REVIEW: {
    es: 'Por último, por favor te lo rogamos de ❤️corazón❤️: ¿Nos puedes poner un comentario en trustpilot? Es para enseñárselo a nuestras mamás y que estén orgullosas.',
    en: 'Finally, we kindly ask you from the ❤️heart❤️: Can you leave us a review on trustpilot? It is to show it to our moms and make them proud.',
    cat: 'Per últim, per favor t’ho demanem de ❤️cor❤️: Ens pots posar un comentari a trustpilot? És per ensenyar-ho a les nostres mares i que estiguin orgulloses.',
    eus: 'Azkenik, mesedez, bihotzetik ❤️: Trustpilot-en iruzkin bat utzi dezakezu? Gure amek erakusteko eta harro egoteko.',
    pt: 'Por último, por favor, pedimos de ❤️coração❤️: Pode deixar-nos um comentário no Trustpilot? É para mostrá-lo para as nossas mães e fazê-las orgulhosas.'
  },
  CLICK_HERE: {
    es: 'Haz click aquí 😁',
    en: 'Click here 😁',
    cat: 'Fes clic aquí 😁',
    eus: 'Klik egin hemen 😁',
    pt: 'Clique aqui 😁'
  },
  REGULAR_NPS_DETRACTOR_TITLE: {
    es: '¿Nos puedes contar que no te ha gustado? Prometemos mejorarlo.',
    en: 'Can you tell us what you did not like? We promise to improve it.',
    cat: 'Ens pots dir què no t’ha agradat? Prometem millorar-ho.',
    eus: 'Esan dezakezu zer ez zait gustatu? Hobetzeko promesa egiten dugu.',
    pt: 'Pode nos dizer o que não gostou? Prometemos melhorar.'
  },
  REGULAR_NPS_NEUTRAL_TITLE: {
    es: '¿Qué tendríamos que mejorar para que la próxima sea un 10?',
    en: 'What should we improve to get a 10 next time?',
    cat: 'Què hauríem de millorar perquè la propera sigui un 10?',
    eus: 'Zer hobetu behar genuke hurrengoan 10 bat jartzeko?',
    pt: 'O que deveríamos melhorar para obter um 10 na próxima vez?'
  },
  REGULAR_NPS_PROMOTER_TITLE: {
    es: '¿Qué es lo que más valoras de Ucademy?',
    en: 'What do you value most about Ucademy?',
    cat: 'Què és el que més valores de Ucademy?',
    eus: 'Zer baloratzen duzu gehien Ucademyren?',
    pt: 'O que valoriza mais em Ucademy?'
  },
  [`${EVoteReason.OTHER}_PROMOTER`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste',
    pt: 'Outro'
  },
  [`${EVoteReason.LIVE_CLASSES}_PROMOTER`]: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Klaseak zuzenean',
    pt: 'Aulas ao vivo'
  },
  [`${EVoteReason.VIDEO_CONTENT}_PROMOTER`]: {
    es: 'Contenido en formato vídeo',
    en: 'Content in video format',
    cat: 'Contingut en format vídeo',
    eus: 'Edukia bideo formatuan',
    pt: 'Conteúdo em formato de vídeo'
  },
  [`${EVoteReason.STUDY_MATERIAL}_PROMOTER`]: {
    es: 'Apuntes',
    en: 'Study material',
    cat: 'Apunts',
    eus: 'Apunteak',
    pt: 'Materiais de estudo'
  },
  [`${EVoteReason.TEACHERS}_PROMOTER`]: {
    es: 'El profesorado',
    en: 'The teaching staff',
    cat: 'El professorat',
    eus: 'Irakasleak',
    pt: 'Corpo docente'
  },
  [`${EVoteReason.QUIZZES}_PROMOTER`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota',
    pt: 'Teste de múltipla escolha'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_PROMOTER`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea',
    pt: 'Equipo de apoyo al alumno'
  },
  [`${EVoteReason.VIDEO_CONTENT}_DETRACTOR`]: {
    es: 'Contenido en formato vídeo',
    en: 'Content in video format',
    cat: 'Contingut en format vídeo',
    eus: 'Edukia bideo formatuan',
    pt: 'Conteúdo em formato de vídeo'
  },
  [`${EVoteReason.TEACHERS}_DETRACTOR`]: {
    es: 'Seguimiento del profesorado',
    en: 'Teaching staff follow-up',
    cat: 'Seguiment del professorat',
    eus: 'Irakasleen jarraipen gehiago',
    pt: 'Acompanhamento do corpo docente'
  },
  [`${EVoteReason.STUDY_MATERIAL}_DETRACTOR`]: {
    es: 'Material de estudio (apuntes)',
    en: 'Study material (notes)',
    cat: 'Material d’estudi (apunts)',
    eus: 'Ikaslearen arreta taldea',
    pt: 'Equipo de apoyo al alumno'
  },
  [`${EVoteReason.LIVE_CLASSES}_DETRACTOR`]: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Klaseak zuzenean',
    pt: 'Aulas ao vivo'
  },
  [`${EVoteReason.QUIZZES}_DETRACTOR`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota',
    pt: 'Teste de múltipla escolha'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_DETRACTOR`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea',
    pt: 'Equipo de apoyo al alumno'
  },
  [`${EVoteReason.TEACHERS}_NEUTRAL`]: {
    es: 'Más seguimiento del profesorado',
    en: 'More follow-up by the teaching staff',
    cat: 'Més seguiment del professorat',
    eus: 'Irakasleen jarraipen gehiago',
    pt: 'Acompanhamento do corpo docente'
  },
  [`${EVoteReason.LIVE_CLASSES}_NEUTRAL`]: {
    es: 'Más clases en directo',
    en: 'More live classes',
    cat: 'Més classes en directe',
    eus: 'Klase gehiago zuzenean',
    pt: 'Aulas ao vivo mais'
  },
  [`${EVoteReason.QUIZZES}_NEUTRAL`]: {
    es: 'Tipo test',
    en: 'Multiple choice test',
    cat: 'Tipus test',
    eus: 'Test mota',
    pt: 'Teste de múltipla escolha'
  },
  [`${EVoteReason.VIDEO_CONTENT}_NEUTRAL`]: {
    es: 'Mejorar la calidad del contenido',
    en: 'Improve content quality',
    cat: 'Millorar la qualitat del contingut',
    eus: 'Edukien kalitatea hobetu',
    pt: 'Melhorar a qualidade do conteúdo'
  },
  [`${EVoteReason.STUDY_MATERIAL}_NEUTRAL`]: {
    es: 'Completar el temario',
    en: 'Complete the syllabus',
    cat: 'Completar el temari',
    eus: 'Syllabus osatu',
    pt: 'Completar o programa'
  },
  [`${EVoteReason.CUSTOMER_SUPPORT}_NEUTRAL`]: {
    es: 'Equipo de atención al alumno',
    en: 'Student support team',
    cat: 'Equip d’atenció a l’alumne',
    eus: 'Ikaslearen arreta taldea',
    pt: 'Equipo de apoyo al alumno'
  },
  [`${EVoteReason.OTHER}_NEUTRAL`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste',
    pt: 'Outro'
  },
  [`${EVoteReason.OTHER}_DETRACTOR`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Un altre',
    eus: 'Beste',
    pt: 'Outro'
  },

  IS_EVERYTHING_CLEAR: {
    es: '¿Tienes claro cómo estudiar con nosotros?',
    en: 'Is everything clear on how to study with us?',
    cat: 'Tens clar com estudiar amb nosaltres?',
    eus: 'Argi daukazu nola ikasi gurekin?',
    pt: 'Tem certeza de como estudar con nós?'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai',
    pt: 'Sim'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez',
    pt: 'Não'
  },
  ONBOARDING_NPS_PROMOTER_TITLE: {
    es: '¿Qué es lo que más te ha gustado en tus primeros días?',
    en: 'What did you like the most in your first days?',
    cat: 'Què és el que més t’ha agradat en els teus primers dies?',
    eus: 'Zer gustatu zaizu gehien zure lehenengo egunetan?',
    pt: 'O que mais gostou nos seus primeiros dias?'
  },
  ONBOARDING_NPS_DETRACTOR_TITLE: {
    es: '¿Qué echas de menos/cambiarías del servicio?',
    en: 'What do you miss/change about the service?',
    cat: 'Què trobes a faltar/canviaries del servei?',
    eus: 'Zer falta zaizu/aldatu nahi duzu zerbitzuari buruz?',
    pt: 'O que falta/muda do serviço?'
  },
  WHAT_DO_YOU_NOT_UNDERSTAND: {
    es: '¿Qué no tienes claro?',
    en: 'What do you not understand?',
    cat: 'Què no tens clar?',
    eus: 'Zer ez duzu argi?',
    pt: 'O que não compreende?'
  },
  YOU_CAN_TALK_WITH_US_ON_WHATSAPP: {
    es: 'Si quieres consultar cualquier duda sobre el funcionamiento de la app, puedes hablar con nosotros por WhatsApp.',
    en: 'If you want to ask any questions about the operation of the app, you can talk to us on WhatsApp.',
    cat: 'Si vols consultar qualsevol dubte sobre el funcionament de l’app, pots parlar amb nosaltres per WhatsApp.',
    eus: 'Apparen funtzionamenduari buruzko zalantza edo galdera batzuk badituzu, gurekin harremanetan jarri zaitezke WhatsApp-en.',
    pt: 'Se quiser consultar qualquer dúvida sobre o funcionamento da app, pode falar connosco no WhatsApp.'
  },
  GO_TO_WHATSAPP: {
    es: 'Ir a WhatsApp',
    en: 'Go to WhatsApp',
    cat: 'Anar a WhatsApp',
    eus: 'Joan WhatsApp-era',
    pt: 'Ir para o WhatsApp'
  },
  ADVENTURE_STARTS_HERE: {
    es: 'Aquí empieza la aventura',
    en: 'The adventure begins here',
    cat: 'L’aventura comenca aquí',
    eus: 'Aventura hasi hemen',
    pt: 'A aventura começa aqui'
  },
  CONTINUE_YOUR_STUDY: {
    es: 'Continúa tu aprendizaje',
    en: 'Continue your learning',
    cat: 'Continúa el teu aprenentatge',
    eus: 'Jarraitu zure ikastetxea',
    pt: 'Continuar o seu aprendizado'
  },
  START: {
    es: 'Empezar',
    en: 'Start',
    cat: 'Començar',
    eus: 'Hasita',
    pt: 'Começar'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu',
    pt: 'Continuar'
  },
  CLASS_STARTED_AT: {
    es: 'Empezó hace {{time}} min',
    en: 'Started {{time}} min ago',
    cat: 'Començà fa {{time}} min',
    eus: 'Has emandako {{time}} minutu',
    pt: 'Começou {{time}} minutos atrás'
  },
  CLASS_STARTING_IN: {
    es: 'Comienza en {{time}} min',
    en: 'Starts in {{time}} min',
    cat: 'Comença en {{time}} min',
    eus: 'Has emandako {{time}} minutu',
    pt: 'Começa em {{time}} minutos'
  },
  JOIN_CLASS: {
    es: 'Unirme a la clase',
    en: 'Join the class',
    cat: 'Unir-me a la classe',
    eus: 'Klasean sartu',
    pt: 'Entrar na aula'
  },
  NO_GOOGLE_MEET_LINK: {
    es: 'No se ha añadido ningún enlace de Google Meet',
    en: 'No Google Meet link added',
    cat: 'No s’ha afegit cap enllaç de Google Meet',
    eus: 'Google Meet esteka ez da gehitu',
    pt: 'Nenhum link de Google Meet adicionado'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: '¡Gracias por tu valoración!',
    en: 'Thank you for your rating!',
    cat: 'Gràcies per la teva valoració!',
    eus: 'Zerindu zure ordena!',
    pt: 'Obrigado pela sua avaliação!'
  },
  YOU_SOMETHING_TO_SAY: {
    es: '¿Tienes algo que destacar o mejorar en esta clase?',
    en: 'Do you have something to highlight or improve in this class?',
    cat: '¿Tens alguna cosa que destacar o millorar en aquesta classe?',
    eus: 'Zer duzu zerbait argitaratu edo hobetu zenuen klasean?',
    pt: 'Tem algo para destacar ou melhorar nesta aula?'
  },
  WRITE_YOUR_COMMENT: {
    es: 'Escribe tu comentario...',
    en: 'Write your comment...',
    cat: 'Escriu el teu comentari...',
    eus: 'Idatzi zure iruzkin hau...',
    pt: 'Escreva o seu comentário...'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  CHAT_WIDGET_TITLE: {
    es: 'Chat con tutor',
    en: 'Chat with tutor',
    cat: 'Chat amb tutor',
    eus: 'Ikastetxearen tutorarekin harremanetan',
    pt: 'Chat com o tutor'
  },
  NO_MESSAGES: {
    es: 'No hay nuevos mensajes',
    en: 'No new messages',
    cat: 'No hi ha nous missatges',
    eus: 'Ez dago mezu berriak',
    pt: 'Não há novos mensagens'
  },
  POST_TAGS_COUNT_one: {
    es: '{{count}} formación',
    en: '{{count}} training',
    cat: '{{count}} formació',
    eus: '{{count}} ikastetxe',
    pt: '{{count}} formação'
  },
  POST_TAGS_COUNT_other: {
    es: '{{count}} formaciones',
    en: '{{count}} trainings',
    cat: '{{count}} formacions',
    eus: '{{count}} ikastetxeak',
    pt: '{{count}} formações'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu',
    pt: 'Editar'
  },
  POST_CREATED_SUCCESSFULLY: {
    es: 'Post creado correctamente',
    en: 'Post created successfully',
    cat: 'Post creat correctament',
    eus: 'Posta behar bezala sortua',
    pt: 'Post criado com sucesso'
  },
  POST_UPDATED_SUCCESSFULLY: {
    es: 'Post actualizado correctamente',
    en: 'Post updated successfully',
    cat: 'Post actualitzat correctament',
    eus: 'Posta behar bezala eguneratua',
    pt: 'Post atualizado com sucesso'
  },
  POST_DELETED_SUCCESSFULLY: {
    es: 'Post eliminado correctamente',
    en: 'Post deleted successfully',
    cat: 'Post eliminat correctament',
    eus: 'Posta behar bezala ezabatua',
    pt: 'Post eliminado com sucesso'
  },
  TOPIC_CREATED_SUCCESSFULLY: {
    es: 'Tema creado correctamente',
    en: 'Topic created successfully',
    cat: 'Tema creat correctament',
    eus: 'Gai behar bezala sortua',
    pt: 'Tema criado com sucesso'
  },

  TOPIC_REMOVED_SUCCESSFULLY: {
    es: 'Tema eliminado correctamente',
    en: 'Topic removed successfully',
    cat: 'Tema eliminat correctament',
    eus: 'Gai behar bezala ezabatua',
    pt: 'Tema eliminado com sucesso'
  },
  RELATED_POSTS: {
    es: 'Artículos relacionados 👇',
    en: 'Related posts 👇',
    cat: 'Articles relacionats 👇',
    eus: 'Erlazionatutako artikuluak 👇',
    pt: 'Artigos relacionados 👇'
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_POST: {
    es: '¿Estás seguro de querer eliminar este post?',
    en: 'Are you sure you want to delete this post?',
    cat: 'Estàs segur de voler eliminar aquest post?',
    eus: 'Zer zaude ziur zaude zerbitzua ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este post?'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  SCHEDULE_POST: {
    es: 'Programar publicación',
    en: 'Schedule post',
    cat: 'Programar publicació',
    eus: 'Posta planifikatu',
    pt: 'Publicar post programado'
  },
  SCHEDULE_POST_DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  SCHEDULE_POST_TIMEZONE: {
    es: 'Zona horaria',
    en: 'Timezone',
    cat: 'Zona horària',
    eus: 'Denbora-zonal',
    pt: 'Fuso horário'
  },
  SCHEDULED_POST: {
    es: 'Programado',
    en: 'Scheduled',
    cat: 'Programat',
    eus: 'Planifikatua',
    pt: 'Programado'
  },
  PUBLISHED_POST: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua',
    pt: 'Publicado'
  },
  CREATE_NEWS: {
    es: 'Crear noticia',
    en: 'Create news',
    cat: 'Crear notícia',
    eus: 'Berri sortu',
    pt: 'Criar notícia'
  },
  EDIT_NEWS: {
    es: 'Editar noticia',
    en: 'Edit news',
    cat: 'Editar notícia',
    eus: 'Berriaren editatu',
    pt: 'Editar notícia'
  },
  PUBLISH_NEWS: {
    es: 'Publicar',
    en: 'Publish',
    cat: 'Publicar',
    eus: 'Argitaratu',
    pt: 'Publicar'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Guardar canvis',
    eus: 'Aldatuak gorde',
    pt: 'Guardar alterações'
  },
  SAVE_DRAFT: {
    es: 'Guardar borrador',
    en: 'Save draft',
    cat: 'Guardar borrador',
    eus: 'Gorde zuzendua',
    pt: 'Guardar rascunho'
  },
  DRAFT_POST: {
    es: 'Borrador',
    en: 'Draft',
    cat: 'Borrador',
    eus: 'Zuzendua',
    pt: 'Rascunho'
  },
  FEATURED_NEWS: {
    es: 'Noticia destacada',
    en: 'Featured news',
    cat: 'Notícia destacada',
    eus: 'Berri argitaratua',
    pt: 'Notícia destacada'
  },
  FEATURE_POST: {
    es: 'Publicar como destacada',
    en: 'Publish as featured',
    cat: 'Publicar com a destacada',
    eus: 'Argitaratu berri zehaztasuneko atala bezala',
    pt: 'Publicar como notícia destacada'
  },
  FEATURE_POST_TOOLTIP: {
    es: 'La noticia destacada aparece fija en la sección de noticias.',
    en: 'The featured post appears fixed in the news section.',
    cat: 'La notícia destacada apareix fixa en la secció de notícies.',
    eus: 'Berri argitaratua zehaztasuneko atala erakusten da.',
    pt: 'A notícia destacada aparece fixa na secção de notícias.'
  },
  NO_TAGS_SELECTED_ALERT: {
    es: 'No has seleccionado ninguna formación. Si no seleccionas ninguna, esta noticia se considerará como una noticia global, por lo que todos los alumnos la verán.',
    en: 'If you do not select any training, this news will be considered as a global news, so all students will see it.',
    cat: 'Si no selecciones cap formació, aquesta notícia es considerarà com a notícia global, per la qual cosa tots els alumnes la veuran.',
    eus: 'Ez baduzu ikastetxe bat hautatu, berria globala izango da, eta guztiak ikusi dituzte.',
    pt: 'Se não selecionar nenhuma formação, a notícia será considerada como uma notícia global, e todos os alunos a verão.'
  },
  DOWNLOAD_LIVE_CLASS_VOTE: {
    es: 'Descargar votos de clases en directo',
    en: 'Download live class votes',
    cat: 'Descargar votos de classes en directe',
    eus: 'Ekitaldi-klaseen ordenak deskargatu',
    pt: 'Descarregar votos de aulas ao vivo'
  },
  SEND_EMAIL_NOTIFICATION: {
    es: 'Enviar notificación por email',
    en: 'Send email notification',
    cat: 'Enviar notificació per email',
    eus: 'Posta elektronikoan jakinarazpen bidali',
    pt: 'Enviar notificação por email'
  },
  SEND_EMAIL_NOTIFICATION_TOOLTIP: {
    es: 'Si seleccionas esta opción, el post se enviará por email a todos los alumnos que tengan las formaciones seleccionadas. Si la noticia esta programada, se enviará a los alumnos el día que se publique.',
    en: 'If you select this option, the post will be sent by email to all students who have the selected trainings. If the news is scheduled, it will be sent to the students the day it is published.',
    cat: 'Si selecciones aquesta opció, el post es enviarà per email a tots els alumnes que tinguin les formacions seleccionades. Si la notícia esta programada, es enviarà a tots els alumnes el dia que es publiqui.',
    eus: 'Aukera hau hautatzen baduzu, hautatutako prestakuntza duten ikasle guztiei bidaliko zaie posta elektronikoz. Albistea programatuta badago, argitaratzen den egunean bidaliko zaie ikasleei.',
    pt: 'Se selecionar esta opção, o post será enviado por email a todos os alunos que têm as formações selecionadas. Se a notícia estiver programada, será enviada aos alunos no dia que for publicada.'
  }
};
