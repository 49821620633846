import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 16px 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  overflow-y: auto;
  height: 100%;
`;
