export const StudentCourseTranslations = {
  NEW: {
    en: 'New',
    es: 'Nuevo',
    cat: 'Nou',
    eus: 'Berria',
    pt: 'Novo'
  },
  CANT_LOAD_SUBSCRIPTION: {
    en: "Can't load subscription",
    es: 'No se puede cargar la suscripción',
    cat: 'No es pot carregar la subscripció',
    eus: 'Ezin da harpidetza kargatu',
    pt: 'Não é possível carregar a subscrição'
  },
  CONTENT: {
    en: 'Lessons',
    es: 'Lecciones',
    cat: 'Leccions',
    eus: 'Ikastarok',
    pt: 'Aulas'
  },
  GENERAL: {
    en: 'General',
    es: 'General',
    cat: 'General',
    eus: 'Orokorra',
    pt: 'Geral'
  },
  DESCRIPTION_SUBTITLE: {
    es: 'Pregunta cualquier duda sobre temario, convocatorias... lo que necesites',
    en: 'Ask any question about the syllabus, calls... whatever you need',
    cat: 'Pregunta qualsevol dubte sobre el temari, convocatòries... el que necessitis',
    eus: 'Galdetu edozein zalantza izan dezakezun gai hori buruz',
    pt: 'Pergunte qualquer dúvida sobre o programa, provas... o que você precisar'
  },
  NOTES: {
    en: 'Take notes',
    es: 'Tomar apuntes',
    cat: 'Preneu notes',
    eus: 'Egin oharra',
    pt: 'Faça anotações'
  },
  CANT_LOAD_ACTIVITY: {
    en: "Can't load activity",
    es: 'No se puede cargar la actividad',
    cat: "No es pot carregar l'activitat",
    eus: 'Ezin da jarduera kargatu',
    pt: 'Não é possível carregar a atividade'
  },
  AN_ERROR_HAS_OCCURED_WHILE_LOADING_THE_ACTIVITY: {
    en: 'An error has occurred while loading the activity try to reload the page, choose another activity or contact the academy',
    es: 'Ha ocurrido un error al cargar la actividad, intenta recargar la página, elige otra actividad o contacta a la academia',
    cat: "S'ha produït un error en carregar l'activitat. Intenta tornar a carregar la pàgina, tria una altra activitat o contacta amb l'acadèmia",
    eus: 'Ekintza kargatzerakoan errore bat gertatu da. Saiatu orrialdea berriz kargatu, aukeratu beste ekintza bat edo harremanetan jarri akademiarekin',
    pt: 'Ocorreu um erro ao carregar a atividade. Tente recarregar a página, escolha outra atividade ou entre em contato com a academia'
  },
  RELOAD_THE_PAGE: {
    en: 'Reload the page',
    es: 'Recargar la página',
    cat: 'Torna a carregar la pàgina',
    eus: 'Orrialdea berriz kargatu',
    pt: 'Recarregar a página'
  },
  CANT_LOAD_ACTIVITY_RESOURCE: {
    en: "Can't load activity resource",
    es: 'No se puede cargar el recurso de la actividad',
    cat: "No es pot carregar el recurs de l'activitat",
    eus: 'Ezin da jarduera baliabidea kargatu',
    pt: 'Não é possível carregar o recurso da atividade'
  },
  ATTACHMENTS_TITLE: {
    en: 'Attachments',
    es: 'Material adicional',
    cat: 'Adjunts',
    eus: 'Erantsiak',
    pt: 'Anexos'
  },
  EXTERNAL_LINK: {
    en: 'External link',
    es: 'Enlace externo',
    cat: 'Enllaç extern',
    eus: 'Kanpo estekak',
    pt: 'Link externo'
  },
  SECTIONS: {
    en: 'Sections',
    es: 'Secciones',
    cat: 'Seccions',
    eus: 'Atalak',
    pt: 'Seções'
  },
  DURATION: {
    en: 'of duration',
    es: 'de duración',
    cat: 'de durada',
    eus: 'iraupena',
    pt: 'duração'
  },
  COURSE_DETAIL: {
    en: 'Course Details',
    es: 'Detalles del curso',
    cat: 'Detalls del curs',
    eus: 'Ikastaroaren xehetasunak',
    pt: 'Detalhes do curso'
  },
  TEACHERS: {
    en: 'Teachers',
    es: 'Profesores',
    cat: 'Professors',
    eus: 'Irakasleak',
    pt: 'Professores'
  },
  HOURS_ABBREVIATION: {
    en: 'hrs',
    es: 'hrs',
    cat: 'h',
    eus: 'ord',
    pt: 'h'
  },
  I_NEED_HELP: {
    en: 'I need help',
    es: 'Tengo una consulta',
    cat: 'Necessito ajuda',
    eus: 'Laguntza behar dut',
    pt: 'Preciso de ajuda'
  },
  TEACHER: {
    en: 'Teacher',
    es: 'Profesor',
    cat: 'Professor',
    eus: 'Irakasle',
    pt: 'Professor'
  },
  WE_ARE_WORKING_IN_THIS_ACTIVITY_WILL_BE_AVAILABLE_SOON: {
    en: 'We are working in this activity, it will be available soon 😄',
    es: 'Estamos trabajando en esta actividad, estará disponible pronto 😄',
    cat: 'Estem treballant en aquesta activitat, estarà disponible aviat 😄',
    eus: 'Jardueran lan egiten ari gara, laster eskuragarri egongo da 😄',
    pt: 'Estamos a trabalhar nesta atividade, estará disponível em breve 😄'
  },
  ACTIVITY_REVIEW_QUESTION: {
    es: '¿Qué te ha parecido esta actividad?',
    en: 'What do you think about this activity?',
    cat: 'Què et sembla aquesta activitat?',
    eus: 'Zer deritzoz jarduera honi buruz?',
    pt: 'O que você acha desta atividade?'
  },
  SEND_REVIEW: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  THANKS_FOR_THE_ACTIVITY_REVIEW: {
    es: 'Gracias por tu valoración',
    en: 'Thanks for your review',
    cat: 'Gràcies per la teva valoració',
    eus: 'Mila esker zure berrikuspenagatik',
    pt: 'Muito obrigado pela sua avaliação'
  },
  CONGRATS: {
    es: '¡Felicidades!',
    en: 'Congrats!',
    cat: 'Felicitats!',
    eus: 'Zorionak!',
    pt: 'Parabéns!'
  },
  FINISHED_ACTIVITY: {
    es: 'Has finalizado: {{activityName}}',
    en: 'You have finished: {{activityName}}',
    cat: 'Has finalitzat: {{activityName}}',
    eus: 'Amaitu duzu: {{activityName}}',
    pt: 'Você terminou: {{activityName}}'
  },
  DELIVER: {
    es: 'Entregar',
    en: 'Deliver',
    cat: 'Lliurar',
    eus: 'Banatu',
    pt: 'Entregar'
  },
  DELIVERED: {
    es: 'Entregado',
    en: 'Delivered',
    cat: 'Lliurat',
    eus: 'Banatua',
    pt: 'Entregado'
  },
  DELIVERABLE_CREATED_SUCCESSFULLY: {
    es: 'Entregable entregado correctamente',
    en: 'Deliverable delivered successfully',
    cat: 'Lliurament realitzat amb èxit',
    eus: 'Banaketa arrakastatsua',
    pt: 'Entrega realizada com sucesso'
  },
  CHECK_DELIVERY_STATUS: {
    es: 'Consultar estado de la entrega',
    en: 'Check delivery status',
    cat: 'Comprovar l’estat del lliurament',
    eus: 'Banaketaren egoera egiaztatu',
    pt: 'Verificar o status da entrega'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde',
    pt: 'Salvar'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  NOTE_ERROR: {
    es: 'Error',
    en: 'Error',
    cat: 'Error',
    eus: 'Errorea',
    pt: 'Erro'
  },
  NOTE_CREATED: {
    es: 'Nota creada',
    en: 'Note created',
    cat: 'Nota creada',
    eus: 'Oharra sortu da',
    pt: 'Nota criada'
  },
  NOTE_UPDATED: {
    es: 'Nota actualizada',
    en: 'Note updated',
    cat: 'Nota actualitzada',
    eus: 'Oharra eguneratu da',
    pt: 'Nota atualizada'
  },
  NOTE_REMOVED: {
    es: 'Nota eliminada',
    en: 'Note deleted',
    cat: 'Nota eliminada',
    eus: 'Oharra ezabatu da',
    pt: 'Nota excluída'
  },
  NOTE_EDITOR_PLACEHOLDER: {
    es: 'Escribe aquí tu nota...',
    en: 'Write your note here...',
    cat: 'Escriu aquí la teva nota...',
    eus: 'Idatzi hemen zure oharra...',
    pt: 'Escreva aqui a sua nota...'
  },
  PROBLEM_SELECTOR_PLACEHOLDER: {
    es: 'Selecciona una opción',
    en: 'Select an option',
    cat: 'Selecciona una opció',
    eus: 'Hautatu aukera bat',
    pt: 'Selecione uma opção'
  },
  PROBLEM_REPORT_SELECTOR_TITLE: {
    es: 'Tipo de problema',
    en: 'Problem type',
    cat: 'Tipus de problema',
    eus: 'Arazo mota',
    pt: 'Tipo de problema'
  },
  ERROR_REPORT_MODAL_TITLE: {
    es: 'Reportar un problema',
    en: 'Report an error',
    cat: 'Informar d’un problema',
    eus: 'Errorea jakinarazi',
    pt: 'Reportar um erro'
  },
  PROBLEM_REPORT_THANK_YOU: {
    es: '¡Gracias por informarnos!',
    en: 'Thanks for your report!',
    cat: 'Gràcies per informar-nos!',
    eus: 'Eskerrik asko gureari jakinarazteagatik!',
    pt: 'Muito obrigado pelo seu relatório!'
  },
  PROBLEM_REPORT_RECEIVED: {
    es: 'Hemos recibido tu reporte y estamos trabajando para resolver el problema lo antes posible.',
    en: 'We have received your report and are working to resolve the issue as soon as possible.',
    cat: 'Hem rebut el teu informe i estem treballant per resoldre el problema el més aviat possible.',
    eus: 'Jaso dugu zure txostena eta arazoari ahalik eta azkarren aurre egiteko lan egiten ari gara.',
    pt: 'Recebemos o seu relatório e estamos a trabalhar para resolver o problema o mais rapidamente possível.'
  },
  SHARE_MODAL_TITLE: {
    es: 'Compartir clase',
    en: 'Share class',
    cat: 'Compartir classe',
    eus: 'Klasea partekatu',
    pt: 'Compartilhar aula'
  },
  SHARE_MODAL_TEXT: {
    es: '¿Quieres compartir esta clase con tus amigos? ¡Puedes compartir una versión limitada de esta clase a quien tú quieras!',
    en: '¿Quieres compartir esta clase con tus amigos? ¡Puedes compartir una versión limitada de esta clase a quien tú quieras!',
    cat: 'Vols compartir aquesta classe amb els teus amics? Pots compartir una versió limitada d’aquesta classe amb qui vulguis!',
    eus: 'Zure lagunekin klase hau partekatu nahi duzu? Klase honen bertsio mugatu bat partekatu dezakezu nahi duzun pertsonarekin!',
    pt: 'Quer compartilhar esta aula com seus amigos? Você pode compartilhar uma versão limitada desta aula com quem você quiser!'
  },
  SHARE_MODAL_COPY_LABEL: {
    es: 'Copia el enlace',
    en: 'Copy link',
    cat: 'Copia l’enllaç',
    eus: 'Kopiatu esteka',
    pt: 'Copiar o link'
  },
  SHARE_MODAL_COPY_BUTTON: {
    es: 'Copiar',
    en: 'Copy',
    cat: 'Copia',
    eus: 'Kopiatu',
    pt: 'Copiar'
  },
  SHARE_MODAL_EMAIL_LABEL: {
    es: 'O comparte por email',
    en: 'Or share by email',
    cat: 'O comparteix per correu electrònic',
    eus: 'Edo partekatu email bidez',
    pt: 'Compartilhar por email'
  },
  SHARE_MODAL_EMAIL_PLACEHOLDER: {
    es: 'Escribe una dirección de email',
    en: 'Write an email address',
    cat: 'Escriu una adreça de correu electrònic',
    eus: 'Idatzi email helbide bat',
    pt: 'Escreva um endereço de email'
  },
  SHARE_MODAL_DEFAULT_TEXT: {
    es: '¡Ey! Mira este video de Ucademy.',
    en: '¡Ey! Look at this Ucademy video',
    cat: 'Ey! Mira aquest vídeo d’Ucademy.',
    eus: 'Ey! Ikusi Ucademy-ren bideo hau',
    pt: 'Ey! Veja este vídeo do Ucademy.'
  },
  SHARE_MODAL_SUBMIT: {
    es: 'Enviar',
    en: 'Submit',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena',
    pt: 'Resumo'
  },
  COPIED_TO_CLIPBOARD: {
    es: 'Copiado al portapapeles',
    en: 'Copied to clipboard',
    cat: 'Copiat al porta-retalls',
    eus: 'Irudiratzean kopiatua',
    pt: 'Copiado para a área de transferência'
  },
  ACCESS_TO_CERTIFICATION_QUIZ: {
    es: 'Acceso al test de certificación',
    en: 'Access to certification quiz',
    cat: 'Accés al test de certificació',
    eus: 'Zerbitzu testaren sarbidea',
    pt: 'Acesso ao teste de certificação'
  },
  ACCESS_TO_CERTIFICATION_QUIZ_TEXT: {
    es: 'Para acceder al test de certificación, debes haber completado al menos el 75% del curso.',
    en: 'To access the certification test, you must have completed at least 75% of the course.',
    cat: 'Per accedir al test de certificació, has de completar com a mínim el 75% del curs.',
    eus: 'Zerbitzu testa eskuratzeko, gutxienez ikastaroaren %75a osatu behar duzu.',
    pt: 'Para acessar o teste de certificação, você deve ter completado pelo menos 75% do curso.'
  },
  LABELS: {
    es: 'Etiquetas',
    en: 'Labels',
    cat: 'Etiquetes',
    eus: 'Etiketak',
    pt: 'Etiquetas'
  },
  QUIZ_RESOURCE_DETAIL: {
    es: 'Número de preguntas: {{ questions }} | Duración: {{ duration }} min',
    en: 'Number of questions: {{ questions }} | Duration: {{ duration }} min',
    cat: 'Número de preguntes: {{ questions }} | Duració: {{ duration }} min',
    eus: 'Galderak zenbakia: {{ questions }} | Iraupena: {{ duration }} minutu',
    pt: 'Número de questões: {{ questions }} | Duração: {{ duration }} minutos'
  },
  START_TEST: {
    es: 'Comenzar',
    en: 'Start',
    cat: 'Iniciar',
    eus: 'Hasi',
    pt: 'Iniciar'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Nº de preguntas',
    en: 'Nº of questions',
    cat: 'Nº de preguntes',
    eus: 'Galderen zenbakia',
    pt: 'Número de questões'
  },
  TEST_LENGTH: {
    es: 'Duración del test',
    en: 'Test length',
    cat: 'Durada del test',
    eus: 'Zerbitzu testaren iraupena',
    pt: 'Duração do teste'
  },
  RETRY_QUIZ: {
    es: 'Repetir test',
    en: 'Retry test',
    cat: 'Repetir el test',
    eus: 'Birpentsatu testa',
    pt: 'Repetir o teste'
  },
  RECAP_MODAL_TITLE: {
    es: 'Repasa el contenido',
    en: 'Review class',
    cat: 'Repassa el contingut',
    eus: 'Berrikusi klasea',
    pt: 'Revisar aula'
  },
  RECAP_MODAL_SEE_AGAIN: {
    es: 'Ver de nuevo',
    en: 'Repeat',
    cat: 'Torna a veure',
    eus: 'Errepikatu',
    pt: 'Repetir'
  },
  RECAP_MODAL_END_BUTTON: {
    es: 'Finalizar',
    en: 'End',
    cat: 'Finalitzar',
    eus: 'Amaitu',
    pt: 'Finalizar'
  },
  RECAP_MODAL_SKIP_BUTTON: {
    es: 'Saltar repaso',
    en: 'Skip recap',
    cat: 'Saltar repàs',
    eus: 'Berrikuspena utzi',
    pt: 'Pular revisão'
  },
  RECAP_MODAL_CONFIRM_BUTTON: {
    es: 'Confirmar respuesta',
    en: 'Confirm answer',
    cat: 'Confirmar la resposta',
    eus: 'Berrespen erantzuna',
    pt: 'Confirmar a resposta'
  },
  RECAP_MODAL_SUCCESS_TITLE: {
    es: '¡Has acertado todas las preguntas!',
    en: 'You got all the questions right!',
    cat: 'Has encertat totes les preguntes!',
    eus: 'Galdera guztiak asmatu dituzu!',
    pt: 'Você acertou todas as questões!'
  },
  RECAP_MODAL_SUCCESS_SUBTITLE: {
    es: '¡Enhorabuena! Ya estás un poco más preparado para tu examen',
    en: 'Congratulations! You are now a little more prepared for your exam',
    cat: 'Felicitats! Ara estàs una mica més preparat per al teu examen',
    eus: 'Zorionak! Orain gehiago prest zaude zure azterketa egiteko',
    pt: 'Parabéns! Agora você está um pouco mais preparado para o seu exame'
  },
  RECAP_MODAL_REVIEW_TITLE: {
    es: '¡Test completado! Has acertado {{count}} de {{total}}',
    en: 'Test completed! You got {{count}} out of {{total}}',
    cat: 'Test completat! Has encertat {{count}} de {{total}}',
    eus: 'Eginda dagoen proba! {{count}} zuzendu dituzu {{total}}-etatik',
    pt: 'Prova concluída! Você acertou {{count}} de {{total}}'
  },
  RECAP_MODAL_ANSWERS_BUTTON: {
    es: 'Ver respuestas',
    en: 'View answers',
    cat: 'Veure respostes',
    eus: 'Erantzunak ikusi',
    pt: 'Ver respostas'
  },
  RECAP_MODAL_WRONG_SUBTITLE: {
    es: 'Siempre puedes volver a ver el contenido para repasar. Estas son las respuestas correctas:',
    en: 'You can always go back to the content to review. These are the correct correct answers:',
    cat: 'Sempre pots tornar a veure el contingut per repassar. Aquestes són les respostes correctes:',
    eus: 'Zure erantzunak berrikusi nahi izanez gero, edukiari itzultzeko aukera daukazu. Hauek dira erantzun zuzenak:',
    pt: 'Você pode sempre voltar para o conteúdo para rever. Estas são as respostas corretas:'
  },
  RECAP_MODAL_WRONG_TITLE: {
    es: '{{count}} de {{total}} preguntas acertadas',
    en: '{{count}} of {{total}} questions answered correctly',
    cat: '{{count}} de {{total}} preguntes encertades',
    eus: '{{count}} {{total}} galderak zuzen erantzun dituzu',
    pt: '{{count}} de {{total}} questões respondidas corretamente'
  },
  RECAP_MODAL_QUESTIONS_TITLE: {
    es: 'Pregunta {{count}} de {{total}}:',
    en: 'Question {{count}} of {{total}}:',
    cat: 'Pregunta {{count}} de {{total}}:',
    eus: '{{count}} galdera {{total}}-etatik',
    pt: '{{count}} questão {{total}}-etik'
  },
  RESULTS: {
    es: 'Resultados',
    en: 'Results',
    cat: 'Resultats',
    eus: 'Emaitzak',
    pt: 'Resultados'
  },
  CORRECT_ANSWERS: {
    es: 'Aciertos: {{count}}',
    en: 'Correct answers: {{count}}',
    cat: 'Encerts: {{count}}',
    eus: 'Zuzendu dituzun erantzunak: {{count}}',
    pt: 'Respostas corretas: {{count}}'
  },
  INCORRECT_ANSWERS: {
    es: 'Fallos: {{count}}',
    en: 'Incorrect answers: {{count}}',
    cat: 'Errors: {{count}}',
    eus: 'Erantzun okerrak: {{count}}',
    pt: 'Respostas incorretas: {{count}}'
  },
  BLANK_ANSWERS: {
    es: 'En blanco: {{count}}',
    en: 'Blank answers: {{count}}',
    cat: 'En blanc: {{count}}',
    eus: 'Ez erantzunak: {{count}}',
    pt: 'Respostas em branco: {{count}}'
  },
  SPENT_TIME: {
    es: 'minutos',
    en: 'minutes',
    cat: 'minuts',
    eus: 'minutuak',
    pt: 'minutos'
  },
  SEE_ANSWERS: {
    es: 'Ver respuestas',
    en: 'See answers',
    cat: 'Veure respostes',
    eus: 'Erantzunak ikusi',
    pt: 'Ver respostas'
  },
  YOUR_WORK: {
    es: 'Tu trabajo',
    en: 'Your work',
    cat: 'La teva feina',
    eus: 'Zure lanbidea',
    pt: 'O seu trabalho'
  },
  EMPTY_DELIVERABLE: {
    es: 'No se ha adjuntado ningún trabajo',
    en: 'No work attached',
    cat: 'No s`ha adjuntat cap treball',
    eus: 'Ez da lanik gehitu',
    pt: 'Nenhuma entrega anexada'
  },
  ADD_DELIVERY: {
    es: 'Arrastra o haz click aquí para subir tu trabajo',
    en: 'Drag or click here to upload your work',
    cat: 'Arrossega o fes click aquí per pujar el teu treball',
    eus: 'Errorean klik egin edo lana gordetu',
    pt: 'Arraste ou clique aqui para subir o seu trabalho'
  },
  SET_AS_COMPLETE: {
    es: 'Marcar actividad como completada',
    en: 'Set activity as completed',
    cat: 'Marcar activitat com a completada',
    eus: 'Markatu aktibitatea osatuta',
    pt: 'Marcar atividade como completada'
  },
  COMPLETED_ACTIVITY: {
    es: 'Actividad completada',
    en: 'Completed activity',
    cat: 'Activitat completada',
    eus: 'Aktibitatea osatuta',
    pt: 'Atividade completada'
  },
  RATING: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Ebaluazioa',
    pt: 'Avaliação'
  },
  DOWNLOAD_MATERIAL_ERROR: {
    es: 'Error al descargar el material',
    en: 'Download material error',
    cat: 'Error en descarregar el material',
    eus: 'Materiala deskargatzean errorea',
    pt: 'Erro ao descarregar o material'
  },
  DELETE_CONFIRMATION: {
    es: '¿Quieres eliminar esta pregunta?',
    en: 'Do you want to delete this question?',
    cat: 'Vols eliminar aquesta pregunta?',
    eus: 'Galdera hau ezabatu nahi duzu?',
    pt: 'Quer eliminar esta pergunta?'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Ticket creado correctamente',
    en: 'Ticket created successfully',
    cat: 'Tiquet creat amb èxit',
    eus: 'Tiketa behar bezala sortu da',
    pt: 'Ticket criado com sucesso'
  },
  TICKET_HAS_BEEN_SET_AS_RESOLVED: {
    es: 'El ticket ha sido marcado como resuelto',
    en: 'The ticket has been set as resolved',
    cat: 'El tiquet s’ha marcat com a resolt',
    eus: 'Tiketa ebazten markatuta dago',
    pt: 'O ticket foi marcado como resolvido'
  },
  REMOVE_NOTE_CONFIRMATION: {
    es: '¿Estás seguro?',
    en: 'Are you sure?',
    cat: 'N’estàs segur?',
    eus: 'Zihur zaude?',
    pt: 'Tem certeza?'
  },
  AI: {
    es: 'IA',
    en: 'AI',
    cat: 'IA',
    eus: 'IA',
    pt: 'IA'
  },
  WRITE_YOUR_QUESTION: {
    es: 'Escribe tu pregunta',
    en: 'Write your question',
    cat: 'Escriu la teva pregunta',
    eus: 'Idatzi zure galdera',
    pt: 'Escreva a sua pergunta'
  },
  THIS_CONTENT_IS_LOCKED: {
    es: 'Ups, de momento este contenido está bloqueado.',
    en: 'Ups, this content is currently locked.',
    cat: 'Ups, de moment aquest contingut està bloquejat.',
    eus: 'Ups, une honetan eduki hau blokeatuta dago.',
    pt: 'Ups, este conteúdo está bloqueado por enquanto.'
  },
  UMY: {
    es: 'Umy',
    en: 'Umy',
    cat: 'Umy',
    eus: 'Umy',
    pt: 'Umy'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curso',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  QUESTIONS: {
    es: 'Preguntas',
    en: 'Questions',
    cat: 'Preguntes',
    eus: 'Galderak',
    pt: 'Questões'
  },
  MY_NOTES: {
    es: 'Mis notas',
    en: 'My notes',
    cat: 'Les meves notes',
    eus: 'Nire notak',
    pt: 'Minhas notas'
  },
  WATCHING_NOW: {
    es: 'Viendo ahora',
    en: 'Watching now',
    cat: 'Veient ara',
    eus: 'Ikusten dago',
    pt: 'Vendo agora'
  },
  VIDEO_DESCRIPTION: {
    es: 'Video · {{duration}} minutos',
    en: 'Video · {{duration}} minutes',
    cat: 'Video · {{duration}} minuts',
    eus: 'Bideoa · {{duration}} minutu',
    pt: 'Vídeo · {{duration}} minutos'
  },
  PDF_DESCRIPTION: {
    es: 'Lectura',
    en: 'Reading',
    cat: 'Lectura',
    eus: 'Irakurketa',
    pt: 'Leitura'
  },
  QUIZ_DESCRIPTION: {
    es: 'Test · {{questions}} preguntas',
    en: 'Test · {{questions}} questions',
    cat: 'Test · {{questions}} preguntes',
    eus: 'Test · {{questions}} galdera',
    pt: 'Teste · {{questions}} questões'
  },
  QUIZ_DESCRIPTION_WITH_SCORE: {
    es: 'Test · Nota: {{score}}',
    en: 'Test · Score: {{score}}',
    cat: 'Test · Nota: {{score}}',
    eus: 'Test · Nota: {{score}}',
    pt: 'Teste · Nota: {{score}}'
  },
  DELIVERABLE_DESCRIPTION: {
    es: 'Entregable · Pendiente',
    en: 'Deliverable · Pending',
    cat: 'Entregable · Pendent',
    eus: 'Entregagarria · Edozein',
    pt: 'Entregável · Pendente'
  },
  DELIVERABLE_DESCRIPTION_SENT: {
    es: 'Entregable · Enviado',
    en: 'Deliverable · Sent',
    cat: 'Entregable · Enviat',
    eus: 'Entregagarria · Bidali',
    pt: 'Entregável · Enviado'
  },
  DELIVERABLE_DESCRIPTION_WITH_SCORE: {
    es: 'Entregable · Nota: {{score}}',
    en: 'Deliverable · Score: {{score}}',
    cat: 'Entregable · Nota: {{score}}',
    eus: 'Entregagarria · Nota: {{score}}',
    pt: 'Entregável · Nota: {{score}}'
  },
  LESSON: {
    es: 'Lección',
    en: 'Lesson',
    cat: 'Lecció',
    eus: 'Ikastaroa',
    pt: 'Aula'
  },
  NO_QUESTIONS_YET: {
    es: 'Aún nadie a preguntado nada a los profes sobre esta clase. ¡Se el primero en preguntar!',
    en: 'No one has asked anything to the teachers about this class yet. Be the first to ask!',
    cat: 'Encara ningú a preguntat res als professors sobre aquesta classe. Sigues el primer a preguntar!',
    eus: 'Ez dago inor galdera egin ikasleekin hori buruz hemen. Lehenengo galdera egin!',
    pt: 'Ninguém perguntou nada aos professores sobre esta aula. Seja o primeiro a perguntar!'
  },
  ASK_SOMETHING: {
    es: 'Preguntar algo',
    en: 'Ask something',
    cat: 'Preguntar algo',
    eus: 'Galdera egin',
    pt: 'Perguntar algo'
  },
  CANCEL_QUESTION: {
    es: 'Cancelar pregunta',
    en: 'Cancel question',
    cat: 'Cancel·lar pregunta',
    eus: 'Utzi galdera',
    pt: 'Cancelar pergunta'
  },
  QUESTION_TIPS: {
    es: 'Tips para escribir preguntas',
    en: 'Tips for writing questions',
    cat: 'Punts per escriure preguntes',
    eus: 'Galdera idatzeko pautak',
    pt: 'Dicas para escrever perguntas'
  },
  QUESTION_TIPS_TOOLTIP: {
    es: 'Sé claro y específico, evita las ambigüedades. Utiliza un lenguaje sencillo y correcto, y no preguntes varias cosas a la vez.',
    en: 'Be clear and specific, avoid ambiguities. Use simple and correct language, and do not ask several things at once.',
    cat: 'Sigues clar i específic, evita les ambigüitats. Utilitza un llenguatge senzill i correcte, i no preguntis diverses coses alhora.',
    eus: 'Argi eta zehatza, ambiguitateak saihestuz. Hizkera sinple eta zuzena erabili, eta ez galderik ezazu hainbeste.',
    pt: 'Seja claro e específico, evite ambiguidades. Utilize um idioma simples e correto, e não faça várias perguntas ao mesmo tempo.'
  },
  SEE_MORE: {
    es: 'Ver más',
    en: 'See more',
    cat: 'Veure més',
    eus: 'Ikusi gehiago',
    pt: 'Ver mais'
  },
  SEE_LESS: {
    es: 'Ver menos',
    en: 'See less',
    cat: 'Veure menys',
    eus: 'Ikusi gutxiago',
    pt: 'Ver menos'
  },
  ANSWER: {
    es: 'Responder',
    en: 'Answer',
    cat: 'Responder',
    eus: 'Erantzun',
    pt: 'Responder'
  },
  SEE_MORE_ANSWERS: {
    es: 'Ver más respuestas',
    en: 'See more answers',
    cat: 'Veure més respostes',
    eus: 'Erantzun gehiago ikusi',
    pt: 'Ver mais respostas'
  },
  SEE_LESS_ANSWERS: {
    es: 'Ver menos',
    en: 'See less',
    cat: 'Veure menys',
    eus: 'Ikusi gutxiago',
    pt: 'Ver menos'
  },
  DELETE_MESSAGE_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar esta respuesta?',
    en: 'Are you sure you want to delete this answer?',
    cat: 'Estàs segur de que vols eliminar aquesta resposta?',
    eus: 'Ziur zaude erantzun hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta resposta?'
  },
  MOST_USEFUL: {
    es: 'Más votados',
    en: 'Most useful',
    cat: 'Més votats',
    eus: 'Eraginkorrak',
    pt: 'Mais úteis'
  },
  MOST_RECENT: {
    es: 'Más recientes',
    en: 'Most recent',
    cat: 'Més recents',
    eus: 'Azkenak',
    pt: 'Mais recentes'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea',
    pt: 'Estudante'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur',
    pt: 'Hoje'
  },
  DAYS_AGO_one: {
    es: 'Hace 1 día',
    en: '1 day ago',
    cat: '1 dies',
    eus: '1 egun',
    pt: '1 dia'
  },
  DAYS_AGO_other: {
    es: 'Hace {{count}} días',
    en: '{{count}} days ago',
    cat: '{{count}} dies',
    eus: '{{count}} egun',
    pt: '{{count}} dias'
  },
  WEEKS_AGO_one: {
    es: 'Hace 1 semana',
    en: '1 week ago',
    cat: '1 setmana',
    eus: '1 aste',
    pt: '1 semana'
  },
  WEEKS_AGO_other: {
    es: 'Hace {{count}} semanas',
    en: '{{count}} weeks ago',
    cat: '{{count}} setmanes',
    eus: '{{count}} aste',
    pt: '{{count}} semanas'
  },
  MONTHS_AGO_one: {
    es: 'Hace 1 mes',
    en: '1 month ago',
    cat: '1 mes',
    eus: '1 hilabete',
    pt: '1 mês'
  },
  MONTHS_AGO_other: {
    es: 'Hace {{count}} meses',
    en: '{{count}} months ago',
    cat: '{{count}} mesos',
    eus: '{{count}} hilabete',
    pt: '{{count}} meses'
  },
  YEARS_AGO_one: {
    es: 'Hace 1 año',
    en: '1 year ago',
    cat: '1 any',
    eus: '1 urte',
    pt: '1 ano'
  },
  YEARS_AGO_other: {
    es: 'Hace {{count}} años',
    en: '{{count}} years ago',
    cat: '{{count}} anys',
    eus: '{{count}} urte',
    pt: '{{count}} anos'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  TICKET_NOT_PUBLISHED: {
    es: 'Comentario no publicado',
    en: 'Comment not published',
    cat: 'Comentari no publicat',
    eus: 'Erantzun ez publikatua',
    pt: 'Comentário não publicado'
  },
  TICKET_NOT_PUBLISHED_DESCRIPTION: {
    es: 'Hemos detectado que tu pregunta infringe nuestras políticas de contenido o que no está relacionada con lo que estudias.',
    en: 'We have detected that your question violates our content policies or is not related to what you are studying.',
    cat: 'Hem detectat que la teva pregunta infringe les nostres polítiques de contingut o que no està relacionada amb el que estudies.',
    eus: 'Gure ereduak zure galdera ez du egiaztatzen edo ez duzula ikastaroko edukiak buruzkoa.',
    pt: 'O nosso sistema detectou que a sua pergunta viola as nossas políticas de conteúdo ou não está relacionada com o que está a estudar.'
  },
  CONTACT_SUPPORT: {
    es: 'Si lo necesitas, puedes contactar con nosotros a través del chat con nuestros agentes o <a>vía email.</a>',
    en: 'If you need it, you can contact us through the chat with our agents or <a>by email.</a>',
    cat: 'Si ho necessites, pots contactar amb nosaltres a través del chat amb els nostres agents o <a>per email.</a>',
    eus: 'Edozein beharrezkoa dagoenean, gurekin harremanetan egiteko chat batean gure agenteak edo <a>posta elektronikoz.</a>',
    pt: 'Se precisar, pode contactar connosco através do chat com os nossos agentes ou <a>por email.</a>'
  },
  ACTIVITY_NOTES_COUNT_other: {
    es: '{{count}} notas en esta lección',
    en: '{{count}} notes in this lesson',
    cat: '{{count}} notes in this lesson',
    eus: '{{count}} notak hau ikastaroko ikastaroa',
    pt: '{{count}} notas nesta aula'
  },
  ACTIVITY_NOTES_COUNT_one: {
    es: '1 nota en esta lección',
    en: '1 note in this lesson',
    cat: '1 note in this lesson',
    eus: '1 notazio hau ikastaroko ikastaroa',
    pt: '1 nota nesta aula'
  },
  NEW_NOTE: {
    es: 'Nueva nota',
    en: 'New note',
    cat: 'Nota nova',
    eus: 'Egin notazio berria',
    pt: 'Nova nota'
  },
  EMPTY_NOTES: {
    es: 'No has guardado ninguna nota en esta actividad.',
    en: 'You have not saved any notes in this activity.',
    cat: 'No has guardat cap nota en aquesta activitat.',
    eus: 'Ez duzu inor notazio gordetu hau ikastaroko ikastaroa.',
    pt: 'Não guardou notas nesta aula.'
  },
  SAVE_NOTE: {
    es: 'Guardar nota',
    en: 'Save note',
    cat: 'Guardar nota',
    eus: 'Notazio gordetu',
    pt: 'Guardar nota'
  },
  NEW_PDF_RESOURCE_INFO: {
    es: 'Nueva nota en página {{count}}',
    en: 'New note in page {{count}}',
    cat: 'Nova nota en pàgina {{count}}',
    eus: 'Egin notazio berria {{count}} orrian',
    pt: 'Nova nota na página {{count}}'
  },
  NEW_VIDEO_RESOURCE_INFO: {
    es: 'Nueva nota en {{time}}',
    en: 'New note in {{time}}',
    cat: 'Nova nota en {{time}}',
    eus: 'Egin notazio berria {{time}}',
    pt: 'Nova nota em {{time}}'
  },
  NEW_OTHER_RESOURCES_INFO: {
    es: 'Nueva nota',
    en: 'New note',
    cat: 'Nova nota',
    eus: 'Egin notazio berria',
    pt: 'Nova nota'
  },
  PDF_RESOURCE_INFO: {
    es: 'Nota en página {{count}}',
    en: 'Note in page {{count}}',
    cat: 'Nota en pàgina {{count}}',
    eus: 'Notazio {{count}} orrian',
    pt: 'Nota na página {{count}}'
  },
  VIDEO_RESOURCE_INFO: {
    es: 'Nota en {{time}}',
    en: 'Note in {{time}}',
    cat: 'Nota en {{time}}',
    eus: 'Notazio {{time}}',
    pt: 'Nota em {{time}}'
  },
  OTHER_RESOURCES_INFO: {
    es: 'Nota',
    en: 'Note',
    cat: 'Nota',
    eus: 'Notazio',
    pt: 'Nota'
  },
  WRITE_HERE_YOUR_NOTE: {
    es: 'Escribe aquí tu nota',
    en: 'Write here your note',
    cat: 'Escriu aquí la teva nota',
    eus: 'Idatzi zure notazio',
    pt: 'Escreva aqui a sua nota'
  },
  NOTE_DELETE_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar esta nota?',
    en: 'Are you sure you want to delete this note?',
    cat: 'Estàs segur de que vols eliminar aquesta nota?',
    eus: 'Ziur zaude notazio hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta nota?'
  },
  BACK_TO_COURSE: {
    es: 'Volver al curso',
    en: 'Back to course',
    cat: 'Volver al curs',
    eus: 'Ikastarora itzuli',
    pt: 'Voltar para o curso'
  },
  REPORT: {
    es: 'Reportar',
    en: 'Report',
    cat: 'Reportar',
    eus: 'Eradi',
    pt: 'Reportar'
  },
  PREVIOUS: {
    es: 'Anterior',
    en: 'Previous',
    cat: 'Anterior',
    eus: 'Aurrekoa',
    pt: 'Anterior'
  },
  NEXT: {
    es: 'Siguiente',
    en: 'Next',
    cat: 'Següent',
    eus: 'Hurrengoa',
    pt: 'Seguinte'
  },
  ACTIVITY_REVIEW_TITLE: {
    es: '¿Qué te ha parecido la lección?',
    en: 'What did you think of the lesson?',
    cat: '¿Què et va semblar la lliçó?',
    eus: 'Zer moduz ikastaroa egin duzu?',
    pt: 'O que você achou da aula?'
  },
  NEXT_LESSON: {
    es: 'Siguiente lección',
    en: 'Next lesson',
    cat: 'Següent lliçó',
    eus: 'Hurrengo ikastaroa',
    pt: 'Próxima aula'
  },
  QUIZ_RESOURCE_TITLE: {
    es: 'Prueba de conocimientos',
    en: 'Knowledge test',
    cat: 'Test de coneixements',
    eus: 'Ezagutza testua',
    pt: 'Teste de conhecimentos'
  },
  QUIZ_RESOURCE_TOOLTIP: {
    es: 'Puedes repetir este test todas las veces que quieras para mejorar tu nota',
    en: 'You can repeat this test as many times as you want to improve your score',
    cat: 'Pots repetir aquest test totes les vegades que vulguis per millorar la teva nota',
    eus: 'Egin ditzakezu testu hau guretzat errepikatzea, guretzat zure nota hobetu ahal izateko.',
    pt: 'Você pode repetir este teste quantas vezes quiser para melhorar sua nota'
  },
  END_QUIZ: {
    es: 'Finalizar test',
    en: 'End quiz',
    cat: 'Finalitzar test',
    eus: 'Egin testu hau bukatu',
    pt: 'Finalizar teste'
  },
  QUIZ_SUMMARY_DESCRIPTION: {
    es: 'Recuerda que las preguntas falladas restan y las que dejas en blanco no.',
    en: 'Remember that failed questions subtract and those left blank do not.',
    cat: 'Recorda que les preguntes fallades resten i les que deixes en blanc no.',
    eus: 'Gogoratu ez duzula galdera, eta ez duzu inor galdera gordetu.',
    pt: 'Lembre-se de que as perguntas erradas subtraem e as que você deixou em branco não.'
  },
  ANSWER_INDEX: {
    es: 'Respuesta {{index}}',
    en: 'Answer {{index}}',
    cat: 'Resposta {{index}}',
    eus: 'Erantzuna {{index}}',
    pt: 'Resposta {{index}}'
  },
  QUIZ_CANCEL_CONFIRMATION: {
    es: '¿Seguro que quieres salir? Tu progreso en el test se perderá.',
    en: 'Are you sure you want to leave? Your progress in the test will be lost.',
    cat: 'Estàs segur de que vols sortir? La teva progressió en el test es perduda.',
    eus: 'Ziur zaude ezabatzeko? Testuaren zure progresua galtzen da.',
    pt: 'Tem certeza que deseja sair? O seu progresso no teste será perdido.'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten',
    pt: 'Sair'
  },
  REPORT_QUESTION: {
    es: 'Reportar pregunta',
    en: 'Report question',
    cat: 'Reportar pregunta',
    eus: 'Erantzun galtzea',
    pt: 'Reportar pergunta'
  },
  END_QUIZ_CONFIRMATION: {
    es: '¿Seguro que quieres finalizar el test y enviar tus resultados?',
    en: 'Are you sure you want to end the test and send your results?',
    cat: 'Estàs segur de que vols finalitzar el test i enviar els teus resultats?',
    eus: 'Ziur zaude testu hau bukatu nahi duzula eta zure emaitzak bidali nahi duzula?',
    pt: 'Tem certeza que deseja finalizar o teste e enviar os seus resultados?'
  },
  FINISH_QUIZ: {
    es: 'Finalizar test',
    en: 'Finish quiz',
    cat: 'Finalitzar test',
    eus: 'Egin testu hau bukatu',
    pt: 'Finalizar teste'
  },
  QUIZ_RESULTS: {
    es: 'Resultados del test',
    en: 'Quiz results',
    cat: 'Resultats del test',
    eus: 'Testuaren emaitzak',
    pt: 'Resultados do teste'
  },
  FINAL_SCORE: {
    es: 'Nota final',
    en: 'Final score',
    cat: 'Nota final',
    eus: 'Azken notazioa',
    pt: 'Nota final'
  },
  QUIZ_RELATED_CONTENT: {
    es: 'Contenido examinado:',
    en: 'Examined content:',
    cat: 'Contingut examinat:',
    eus: 'Ikastaroko edukia:',
    pt: 'Conteúdo examinado:'
  },
  UMY_FAILED_QUIZ: {
    es: 'No pasa nada, todos tenemos un mal día. ¡A por el siguiente!',
    en: "No problem, everyone has a bad day. Let's go for the next one!",
    cat: 'No passa res, tots tenim un mal dia. ¡A por el següent!',
    eus: 'Ez duzu inor problema, guretzat inor egun bat. Hurrengo bati ari gara!',
    pt: 'Não há problema, todos nós temos um dia ruim. Vamos para o próximo!'
  },
  UMY_PASSED_QUIZ: {
    es: '¡Lo damos por bueno! Sigue practicando para mejorar tu nota',
    en: '¡We consider it good! Keep practicing to improve your score',
    cat: '¡Ho considerem bo! Mantingueu-vos a practicar per millorar la teva nota',
    eus: '¡Egiaztatu dugu! Guretzat egiaztatu duzu. Hurrengo bati ari gara!',
    pt: '¡Consideramos isso bom! Continue praticando para melhorar sua nota'
  },
  UMY_GOOD_QUIZ: {
    es: 'Parece que alguien ha estado estudiando... ¡No pierdas el ritmo!',
    en: "It seems like someone has been studying... Don't lose the rhythm!",
    cat: 'Sembla que algún ha estat estudiant... ¡No perdis el ritme!',
    eus: 'Edozein gizona ikastaroa egin du. Ez duzu inor ritmo galtzen!',
    pt: 'Parece que alguém tem estado a estudar... Não perca o ritmo!'
  },
  UMY_OUTSTANDING_QUIZ: {
    es: 'Este examen es como tú: Sobresaliente. ¡Sigue así, crack!',
    en: 'This exam is like you: Outstanding. Keep going, crack!',
    cat: 'Aquest examen és com tu: Sobresalient. Mantingueu-vos a practicar per millorar la teva nota',
    eus: 'Testu hau guretzat egiaztatu duzu. Hurrengo bati ari gara!',
    pt: 'Este exame é como você: Sobresaliente. Continue praticando para melhorar sua nota'
  },
  REVIEW_QUESTIONS: {
    es: 'Revisar preguntas',
    en: 'Review questions',
    cat: 'Revisar preguntes',
    eus: 'Galdera egiaztatu',
    pt: 'Revisar perguntas'
  },
  EXIT_AND_FINISH: {
    es: 'Salir y acabar',
    en: 'Save and exit',
    cat: 'Guardar i acabar',
    eus: 'Gordetu eta bukatu',
    pt: 'Salvar e sair'
  },
  UMY_SECTION_FOOTER: {
    es: 'Umy puede cometer errores. Considera verificar la información importante.',
    en: 'Umy can make mistakes. Consider verifying important information.',
    cat: 'Umy pot cometre errors. Considera verificar la informació important.',
    eus: 'Umy egin duzun erroreak. Guretzat egiaztatu duzu.',
    pt: 'Umy pode cometer erros. Considere verificar as informações importantes.'
  },
  UMY_PLACEHOLDER: {
    es: 'Escríbele algo a Umy',
    en: 'Write something to Umy',
    cat: 'Escriu algo a Umy',
    eus: 'Egin notazio berria',
    pt: 'Escreva algo para Umy'
  },
  UMY_EMPTY_CHAT_TITLE: {
    es: '{{name}}, ¿en qué puedo ayudarte?',
    en: '{{name}}, How can I help you?',
    cat: '{{name}}, ¿en qué pot ajudar-te?',
    eus: '{{name}}, Zer moduz guretzat laguntza egin dezakezu?',
    pt: '{{name}}, Como posso ajudar você?'
  },
  UMY_ACTION_OPTIONS: {
    es: 'Te dejo un par de ideas:',
    en: 'I leave you a couple of ideas:',
    cat: "Te deixo un parell d'idees:",
    eus: 'Egin ditzakezu guretzat laguntza:',
    pt: 'Deixo-te um par de ideias:'
  },
  SUMMARIZE_VIDEO: {
    es: 'Resumir el vídeo',
    en: 'Summarize the video',
    cat: 'Resumir el vídeo',
    eus: 'Laburtu bideoa',
    pt: 'Resumir o vídeo'
  },
  GENERATE_NOTES: {
    es: 'Generar esquemas',
    en: 'Generate the notes',
    cat: 'Generar els apunts',
    eus: 'Sortu oharrak',
    pt: 'Gerar notas'
  },
  SUMMARIZE_PDF: {
    es: 'Resumir el archivo',
    en: 'Summarize the file',
    cat: 'Resumir el fitxer',
    eus: 'Laburtu dokumentua',
    pt: 'Resumir o documento'
  },
  HIGHLIGHT_IMPORTANT_PARTS: {
    es: 'Subrayar lo importante',
    en: 'Highlight important parts',
    cat: 'Subrayar lo important',
    eus: 'Azpimarratu zer den garrantzitsua',
    pt: 'Sublinhar as partes importantes'
  },
  COPY: {
    es: 'Copiar',
    en: 'Copy',
    cat: 'Copiar',
    eus: 'Kopiatu',
    pt: 'Copiar'
  },
  COPIED: {
    es: 'Copiado',
    en: 'Copied',
    cat: 'Copiado',
    eus: 'Kopiatu',
    pt: 'Copiado'
  },
  COMING_SOON: {
    es: 'Próximamente',
    en: 'Coming soon',
    cat: 'Próximamente',
    eus: 'Eguneroko',
    pt: 'Em breve'
  },
  TAKE_NOTE: {
    es: 'Tomar nota',
    en: 'Take note',
    cat: 'Tomar nota',
    eus: 'Oharra gordetu',
    pt: 'Tomar nota'
  },
  FULL_SCREEN: {
    es: 'Pantalla completa',
    en: 'Full screen',
    cat: 'Pantalla completa',
    eus: 'Ekran osoa',
    pt: 'Tela cheia'
  },
  FAVORITE_TOOLTIP: {
    es: 'Añadir a favoritos',
    en: 'Add to favorites',
    cat: 'Añadir a favoritos',
    eus: 'Oharra gordetu',
    pt: 'Adicionar a favoritos'
  },
  DOCS_AND_LINKS: {
    es: 'Documentos y enlaces',
    en: 'Documents and links',
    cat: 'Documentos i enllaços',
    eus: 'Dokumentuak eta loturen',
    pt: 'Documentos e links'
  },
  PROCESSING_TRANSCRIPTION: {
    es: 'Estamos procesando la transcripción de este video',
    en: 'We are processing the transcription of this video',
    cat: "Estem processant la transcripció d'aquest video",
    eus: 'Bideoaren testua gordetzen ari gara',
    pt: 'Estamos a processar a transcrição deste vídeo'
  },
  DELIVERABLE_INSTRUCTIONS: {
    es: 'Instrucciones del entregable',
    en: 'Deliverable instructions',
    cat: "Instruccions de l'entregable",
    eus: 'Entregablearen instrukzioak',
    pt: 'Instruções do entregável'
  },
  DELIVERABLE_SUBTITLE: {
    es: 'Podrás ver la nota y los comentarios del profesor de tu entregable en la sección de entregables dentro del contenido de la formación',
    en: "You can see the note and the teacher's comments on your deliverable in the deliverables section within the training content",
    cat: "Podràs veure la nota i els comentaris del professor de la teva entrega en la secció d'entregables dins del contingut de la formació",
    eus: 'Zure entregaren notazioa eta ikastaroko edukien zentroan dagoen entregaren atal ikusteko ahal duzu.',
    pt: 'Você pode ver a nota e os comentários do professor da sua entrega na secção de entregas dentro do conteúdo de formação.'
  },
  UPLOAD_FILE: {
    es: 'Subir archivo',
    en: 'Upload file',
    cat: 'Subir fitxer',
    eus: 'Fitxategi gordetu',
    pt: 'Subir ficheiro'
  }
};
