import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const ActivityChatService = sdk.getService('ActivityChatService');

export const useActivityChat = (activityId: string | null) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['activity-chat', activityId],
    queryFn: () => ActivityChatService.findActivityChat(activityId!),
    enabled: !!activityId,
    refetchOnWindowFocus: false
  });

  return {
    activityChat: data,
    isLoading,
    error
  };
};
