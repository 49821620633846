import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 16px 40px 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
`;

export const DisclaimerWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black5};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
