export const EventAttendConfirmationTranslations = {
  YOU_WILL_ATTEND_TO_AN_EVENT: {
    es: 'Te has apuntado al Evento',
    en: 'You will attend to the Event',
    cat: "T'has apuntat a l'Event",
    eus: 'Gertaerara apuntatu zara',
    pt: 'Você se inscreveu no evento'
  },
  WE_WILL_SEND_YOU_A_REMINDER: {
    es: 'Te enviaremos un correo 15 minutos antes del evento.',
    en: 'We will send you a reminder 15 minutes before the event.',
    cat: 'T`enviarem un correu 15 minuts abans de l`event.',
    eus: 'Gertaera 15 minutu lehenago, korreo bat bidaliko dizugu.',
    pt: 'Vamos enviar um lembrete 15 minutos antes do evento.'
  },
  ADD_TO_CALENDAR: {
    es: 'Añadir al calendario',
    en: 'Add to calendar',
    cat: 'Afegir al calendari',
    eus: 'Egunerakoan gehitu',
    pt: 'Adicionar ao calendário'
  }
};
