import { Modal } from 'antd';
import katex from 'katex';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MathField, addStyles } from 'react-mathquill';

import { FormulaWrapper } from './InsertFormula.styles';
import { TexSymbols } from './TexSymbols';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChange: (formatedHtml?: string) => void;
};

addStyles();

export const InsertFormula: FC<Props> = ({ isOpen, setIsOpen, onChange }) => {
  const { t } = useTranslation('CommonQuizFormTranslations');
  const [formula, setFormula] = useState('');

  const handleChange = (mathField: MathField) => setFormula(mathField.latex());

  const handleCancel = () => {
    setIsOpen(false);
    setFormula('');
  };

  const handleInsert = () => {
    if (formula === '') return onChange(undefined);
    const katexHtml = katex.renderToString(formula, { displayMode: true });
    onChange(katexHtml);
    handleCancel();
  };

  return (
    <Modal
      centered
      open={isOpen}
      title={t('INSERT_FORMULA')}
      onCancel={handleCancel}
      onOk={handleInsert}>
      <FormulaWrapper onChange={handleChange} latex={formula} mathquillDidMount={handleChange} />
      <hr />
      <TexSymbols latex={formula} setLatex={setFormula} />
    </Modal>
  );
};
