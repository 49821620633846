import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../profile';

import { sdk } from '@/services';

const LiveClassVoteService = sdk.getService('LiveClassVoteService');

export const useMyLastWeekLiveClassVotes = () => {
  const { profile } = useProfile();
  const queryKey = ['live-class-vote', 'last-week', profile?._id];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => LiveClassVoteService.findMyLastWeekVotes(),
    enabled: !!profile?._id
  });

  return {
    votes: data,
    isPending
  };
};
