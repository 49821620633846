export const TeacherStudentsPageTranslations = {
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Apelido'
  },
  TAGS: {
    es: 'Formación',
    en: 'Tags',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formatura'
  },
  QUIZ_NUMBER: {
    es: 'Nº Test realizados',
    en: 'Quizzes number',
    cat: 'Nº Test realitzats',
    eus: 'Egindako test kopurua',
    pt: 'Nº de testes realizados'
  },
  AVG_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batez besteko puntuazioa',
    pt: 'Nota média'
  },
  TICKETS_NUMBER: {
    es: 'Nº Dudas',
    en: 'Tickets number',
    cat: 'Nº Dubtes',
    eus: 'Zalantzak kopurua',
    pt: 'Nº de dúvidas'
  },
  STUDENTS_TOTAL_COUNT: {
    es: '{{total}} alumnos',
    en: '{{total}} students',
    cat: '{{total}} estudiants',
    eus: '{{total}} ikasleak',
    pt: '{{total}} alunos'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragaziak',
    pt: 'Filtros'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikasturtea',
    pt: 'Curso'
  },
  SELECT_COURSE: {
    es: 'Selecciona un curso',
    en: 'Select a course',
    cat: 'Selecciona un curs',
    eus: 'Hautatu ikasturte bat',
    pt: 'Selecione um curso'
  }
};
