import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

export const EmojiWrapper = styled.div`
  font-size: 30px;
`;
