import styled from 'styled-components';

export const ItemWrapper = styled.div`
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenteredItemWrapper = styled(ItemWrapper)`
  margin-left: auto;
`;
