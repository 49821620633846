import { INote } from '@sdk/contracts';
import { create } from 'zustand';

interface IStore {
  isOpen: boolean;
  openForm: (note?: INote) => void;
  closeForm: () => void;
  note?: INote;
}

export const useActivityNoteForm = create<IStore>(set => ({
  isOpen: false,
  openForm: (note?: INote) => set({ isOpen: true, note }),
  closeForm: () => set({ isOpen: false, note: undefined })
}));
