export const ManagerExamDetailTranslations = {
  EDIT_EXAM: {
    es: 'Editar examen',
    en: 'Edit exam',
    cat: 'Editar examen',
    eus: 'Examen editatu',
    pt: 'Editar exame'
  },
  PUT_SCORE: {
    es: 'Poner nota',
    en: 'Put score',
    cat: 'Poner nota',
    eus: 'Ezabatu',
    pt: 'Excluir'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde',
    pt: 'Guardar'
  }
};
