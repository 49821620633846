export const DeliverableUploadTranslations = {
  UPLOAD_DELIVERABLE: {
    es: 'Subir entregable',
    en: 'Upload deliverable',
    cat: 'Pujar lliurament',
    eus: 'Igo entregabidea',
    pt: 'Enviar entregável'
  },
  WHICH_DELIVERABLE_TO_UPLOAD: {
    es: '¿Qué entregable quieres subir?',
    en: 'Which deliverable do you want to upload?',
    cat: 'Quin lliurament vols pujar?',
    eus: 'Ze entregabidea igo nahi duzu?',
    pt: 'Qual entregável você quer enviar?'
  },
  SAVE_DELIVERABLE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde',
    pt: 'Salvar'
  },
  SELECT_A_DELIVERABLE: {
    es: 'Selecciona un entregable',
    en: 'Select a deliverable',
    cat: 'Selecciona un lliurament',
    eus: 'Aukeratu entregabide bat',
    pt: 'Selecione um entregável'
  },
  SELECT_A_FILE: {
    es: 'Selecciona un archivo',
    en: 'Select a file',
    cat: 'Selecciona un arxiu',
    eus: 'Aukeratu fitxategi bat',
    pt: 'Selecione um arquivo'
  },
  PLEASE_SELECT_A_FILE: {
    es: 'Por favor, selecciona un archivo',
    en: 'Please select a file',
    cat: 'Si us plau, selecciona un arxiu',
    eus: 'Mesedez, aukeratu fitxategi bat',
    pt: 'Por favor, selecione um arquivo'
  },
  NO_FILE_SELECTED: {
    es: 'No hay archivo seleccionado',
    en: 'No file selected',
    cat: 'No hi ha cap arxiu seleccionat',
    eus: 'Ez dago fitxategirik hautatuta',
    pt: 'Nenhum arquivo selecionado'
  }
};
