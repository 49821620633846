import styled from 'styled-components';

const PROGRESS_WIDTH = 240;
const PROGRESS_HEIGHT = 40;

export const Wrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1000;
  cursor: pointer;

  .ant-progress-bg {
    border-radius: 4px;
  }
  .ant-progress-inner {
    border-radius: 4px;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const TextWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.colors.black9};
  z-index: 1000;
`;

export const TimeWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black6};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 1000;
`;

export const ProgressBar = styled.div`
  width: ${PROGRESS_WIDTH}px;
  height: ${PROGRESS_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;

export const ProgressFill = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ theme }) => theme.colors.green2};
  transition: width 0.05s linear;
`;
