import {
  EDateFilterOptions,
  EDeliverableStatus,
  ETicketStatus,
  ENotificationType,
  EActivityReportOptions,
  EQuestionReportOptions,
  EExamGroupsType,
  ESectionTrackStatus,
  EUserStatus,
  ERoles,
  ECountry,
  EYearMonths,
  EQuizMode,
  EActivityAuditStatus,
  EStudyField,
  EVerticalNames,
  ETimePreference,
  EEventWeekdays,
  EActivityType,
  EInternalNoteType
} from '@constants';
import { EProvince, EAutonomousCommunity, EStudentPaymentStatus } from '@constants';

export const DefaultTranslations = {
  NAME: {
    en: 'Name',
    es: 'Nombre',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LAST_NAME: {
    en: 'Last name',
    es: 'Apellido',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Apelido'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email',
    pt: 'Email'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contrasenya',
    eus: 'Pasahitza',
    pt: 'Palavra-passe'
  },
  LOADING: {
    es: 'Cargando...',
    en: 'Loading...',
    cat: 'Carregant...',
    eus: 'Kargatzen...',
    pt: 'Carregando...'
  },
  SEARCH: {
    en: 'Search',
    es: 'Buscar...',
    cat: 'Cercar...',
    eus: 'Bilatu...',
    pt: 'Pesquisar...'
  },
  REQUIRED_FIELD: {
    es: 'Campo requerido',
    en: 'Required field',
    cat: 'Camp requerit',
    eus: 'Beharrezko eremua',
    pt: 'Campo obrigatório'
  },
  REQUIRED_QUESTION: {
    es: 'Por favor, responde a esta pregunta',
    en: 'Please answer this question  ',
    cat: 'Per favor, respon a aquesta pregunta',
    eus: 'Mesedez, erantzun galdera hau',
    pt: 'Por favor, responda a esta pergunta'
  },
  WRONG_FORMAT_URL: {
    es: 'Formato de URL incorrecto',
    en: 'Wrong URL format',
    cat: 'Format de URL incorrecte',
    eus: 'URL formatu okerra',
    pt: 'Formato de URL incorrecto'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai',
    pt: 'Sim'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez',
    pt: 'Não'
  },
  POSITION: {
    es: 'Posición',
    en: 'Position',
    cat: 'Posició',
    eus: 'Posizioa',
    pt: 'Posição'
  },
  SELECT_DATE: {
    es: 'Seleccionar fecha',
    en: 'Select date',
    cat: 'Seleccionar data',
    eus: 'Hautatu data',
    pt: 'Selecionar data'
  },
  SELECT_USER: {
    es: 'Selecciona un usuario',
    en: 'Select a user',
    cat: 'Selecciona un usuari',
    eus: 'Erabiltzaile bat hautatu',
    pt: 'Selecionar um usuário'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktibo',
    pt: 'Conta ativa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu erabiltzen ez dena',
    pt: 'Conta inativa'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde',
    pt: 'Guardar alterações'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tanca',
    eus: 'Itxi',
    pt: 'Fechar'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila',
    pt: 'Perfil'
  },
  ACCOUNT: {
    es: 'Cuenta',
    en: 'Account',
    cat: 'Compte',
    eus: 'Kontua',
    pt: 'Conta'
  },
  LOGOUT: {
    es: 'Cerrar sesión',
    en: 'Logout',
    cat: 'Tanca la sessió',
    eus: 'Saioa itxi',
    pt: 'Sair'
  },
  CHANGE_PASSWORD: {
    es: 'Cambiar contraseña',
    en: 'Change password',
    cat: 'Canviar contrasenya',
    eus: 'Aldatu pasahitza',
    pt: 'Alterar palavra-passe'
  },
  PROFILE_UPDATED: {
    es: 'Perfil actualizado 😀',
    en: 'Profile updated 😀',
    cat: 'Perfil actualitzat 😀',
    eus: 'Profila eguneratuta 😀',
    pt: 'Perfil actualizado 😀'
  },
  CURRENT_PASSWORD: {
    es: 'Contraseña actual',
    en: 'Current password',
    cat: 'Contrasenya actual',
    eus: 'Gaurko pasahitza',
    pt: 'Palavra-passe atual'
  },
  NEW_PASSWORD: {
    es: 'Nueva contraseña',
    en: 'New password',
    cat: 'Nova contrasenya',
    eus: 'Pasahitz berria',
    pt: 'Palavra-passe nova'
  },
  CONFIRM_PASSWORD: {
    es: 'Confirmar contraseña',
    en: 'Confirm password',
    cat: 'Confirmar contrasenya',
    eus: 'Pasahitza baieztatu',
    pt: 'Confirmar palavra-passe'
  },
  PASSWORDS_NOT_MATCH: {
    es: 'Las contraseñas no coinciden',
    en: 'Passwords do not match',
    cat: 'Les contrasenyes no coincideixen',
    eus: 'Pasahitzak ez datoz bat',
    pt: 'As palavras-passe não coincidem'
  },
  PASSWORD_UPDATED: {
    es: 'Contraseña actualizada',
    en: 'Password updated',
    cat: 'Contrasenya actualitzada',
    eus: 'Pasahitza eguneratuta',
    pt: 'Palavra-passe atualizada'
  },
  UPDATE_PASSWORD: {
    es: 'Actualizar contraseña',
    en: 'Update password',
    cat: 'Actualitzar contrasenya',
    eus: 'Pasahitza eguneratu',
    pt: 'Atualizar palavra-passe'
  },
  LOG_OUT: {
    es: 'Cerrar sesión',
    en: 'Log out',
    cat: 'Tancar sessió',
    eus: 'Saioa itxi',
    pt: 'Sair'
  },
  LOG_OUT_CONFIRM: {
    es: '¿Estás seguro que deseas cerrar sesión?',
    en: 'Are you sure you want to log out?',
    cat: 'N`estàs segur que vols tancar sessió?',
    eus: 'Ziur zaude saioa itxi nahi duzula?',
    pt: 'Tem certeza que deseja sair?'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena',
    pt: 'Nome de usuário'
  },
  AUDIO_CAN_NOT_BE_LOADED: {
    es: 'No se ha podido cargar el archivo de audio',
    en: 'Audio file could not be loaded',
    cat: 'No s`ha pogut carregar l`arxiu d`àudio',
    eus: 'Audio fitxategia ezin izan da kargatu',
    pt: 'O arquivo de áudio não pôde ser carregado'
  },
  SELECT_COURSE: {
    es: 'Seleccionar curso...',
    en: 'Select course...',
    cat: 'Selecciona curs...',
    eus: 'Hautatu ikastaroa...',
    pt: 'Selecione um curso...'
  },
  MY_PROFILE: {
    es: 'Mi perfil',
    en: 'My profile',
    cat: 'El meu perfil',
    eus: 'Nire profila',
    pt: 'O meu perfil'
  },
  EDIT_PROFILE: {
    es: 'Editar perfil',
    en: 'Edit profile',
    cat: 'Editar perfil',
    eus: 'Profila editatu',
    pt: 'Editar perfil'
  },
  DAY_STREAK: {
    es: 'día seguido',
    en: 'day in a row',
    cat: 'dia seguit',
    eus: 'egun segidan',
    pt: 'dia seguido'
  },
  DAYS_STREAK: {
    es: 'días seguidos',
    en: 'days in a row',
    cat: 'dies seguits',
    eus: 'egun segidan',
    pt: 'dias seguidos'
  },
  DASHBOARD: {
    es: 'Dashboard',
    en: 'Dashboard',
    cat: 'Tauler',
    eus: 'Kontroletxe',
    pt: 'Painel de controle'
  },
  MY_FORMATION: {
    es: 'Mi formación',
    en: 'My formation',
    cat: 'La meva formació',
    eus: 'Nire formakuntza',
    pt: 'A minha formação'
  },
  EXPLORE: {
    es: 'Explorar',
    en: 'Explore',
    cat: 'Explorar',
    eus: 'Bilatu',
    pt: 'Explorar'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Tags',
    cat: 'Formacions',
    eus: 'Formakuntzak',
    pt: 'Formaturas'
  },
  OTHER: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak',
    pt: 'Outros'
  },
  [ETicketStatus.NEW]: {
    es: 'Nueva',
    en: 'Open',
    cat: 'Nova',
    eus: 'Irekita',
    pt: 'Aberto'
  },
  [ETicketStatus.IN_PROGRESS]: {
    es: 'En curso',
    en: 'In progress',
    cat: 'En curs',
    eus: 'Hasiera bidean',
    pt: 'Em curso'
  },
  [ETicketStatus.CLOSED_NOT_RESOLVED]: {
    es: 'No resuelta',
    en: 'Not resolved',
    cat: 'No resolta',
    eus: 'Ez da konpondu',
    pt: 'Não resolvido'
  },
  [ETicketStatus.CLOSED_RESOLVED]: {
    es: 'Resuelta',
    en: 'Resolved',
    cat: 'Resolta',
    eus: 'Ebaztuta',
    pt: 'Resolvido'
  },
  YOU_HAVE_NOT_CREATED_DOUBT_TICKETS: {
    es: 'No hay dudas abiertas',
    en: 'There are no open doubts',
    cat: 'No hi ha dubtes oberts',
    eus: 'Ez dago zalantzarik ireki',
    pt: 'Não há dúvidas abertas'
  },
  SOMETHING_WENT_WRONG: {
    es: 'Algo salió mal',
    en: 'Something went wrong',
    cat: 'Alguna cosa ha anat malament',
    eus: 'Zerbait gaizki joan da',
    pt: 'Algo saiu mal'
  },
  UNKNOW_ERROR: {
    es: 'Error desconocido',
    en: 'Unknown error',
    cat: 'Error desconegut',
    eus: 'Errore ezezaguna',
    pt: 'Erro desconhecido'
  },
  RELOAD_COMPONENT: {
    es: 'Recargar componente',
    en: 'Reload component',
    cat: 'Recarregar component',
    eus: 'Komponentea berriz kargatu',
    pt: 'Recarregar componente'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  TIME: {
    es: 'Hora',
    en: 'Time',
    cat: 'Hora',
    eus: 'Ordua',
    pt: 'Hora'
  },
  SPANISH: {
    es: 'Español',
    en: 'Spanish',
    cat: 'Espanyol',
    eus: 'Espainiera',
    pt: 'Português'
  },
  ENGLISH: {
    es: 'Inglés',
    en: 'English',
    cat: 'Anglès',
    eus: 'Ingelesa',
    pt: 'Inglês'
  },
  CATALAN: {
    es: 'Catalán',
    en: 'Catalan',
    cat: 'Català',
    eus: 'Katalana',
    pt: 'Catalão'
  },
  EUSKERA: {
    es: 'Euskera',
    en: 'Basque',
    cat: 'Euskera',
    eus: 'Euskara',
    pt: 'Basco'
  },
  ASK_FOR_HELP: {
    es: 'Pedir ayuda',
    en: 'Ask for help',
    cat: 'Demanar ajuda',
    eus: 'Laguntza eskatu',
    pt: 'Pedir ajuda'
  },
  HELP_CENTER: {
    es: 'Centro de ayuda',
    en: 'Help center',
    cat: 'Centre d`ajuda',
    eus: 'Laguntza zentroa',
    pt: 'Centro de ajuda'
  },
  SORRY_WE_ARE_WORKING_WE_WILL_BE_BACK_SOON: {
    es: 'Estamos trabajando en una actualización del campus virtual que te va a ENCANTAR. Necesitamos unas horas para subirlo ¡Acabamos cuánto antes!',
    en: 'We are working on an update of the virtual campus that you will LOVE. We need a few hours to upload it. We will finish as soon as possible!',
    cat: 'Estem treballant en una actualització del campus virtual que t`ENCANTARÀ. Necessitem unes hores per pujar-ho. Acabem aviat!',
    eus: 'Campus birtualaren eguneratze baten lan egiten ari gara, zure gustukoa izango dena. Ordu gutxi behar ditugu igo dezagun. Aurrera!',
    pt: 'Estamos a trabalhar numa actualização do campus virtual que vai encantar-te. Precisamos de algumas horas para o subir. Terminamos o mais rapidamente possível!'
  },
  MAINTENANCE_MODE: {
    es: 'Modo mantenimiento',
    en: 'Maintenance mode',
    cat: 'Mode de manteniment',
    eus: 'Mantentze modua',
    pt: 'Modo de manutenção'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala',
    pt: 'Secção'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  ACTIVITY: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduerak',
    pt: 'Atividade'
  },
  SELECT_RELATED_CONTENT: {
    es: 'Seleccionar contenido relacionado',
    en: 'Select related content',
    cat: 'Selecciona contingut relacionat',
    eus: 'Hautatu eduki lotura',
    pt: 'Selecionar conteúdo relacionado'
  },
  [EDeliverableStatus.DELIVERED]: {
    es: 'Entregado',
    en: 'Delivered',
    cat: 'Lliurat',
    eus: 'Entregatua',
    pt: 'Entregado'
  },
  [EDeliverableStatus.IN_REVIEW]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikuspenetan',
    pt: 'Em revisão'
  },
  [EDeliverableStatus.COMPLETED]: {
    es: 'Completado',
    en: 'Completed',
    cat: 'Completat',
    eus: 'Osatu',
    pt: 'Completo'
  },

  SELECT_COURSE_OR_TAG: {
    es: 'Selecciona un curso o una etiqueta',
    en: 'Select a course or a tag',
    cat: 'Selecciona un curs o una etiqueta',
    eus: 'Hautatu ikastaro bat edo etiketa bat',
    pt: 'Selecione um curso ou uma etiqueta'
  },
  ASSIGN_COURSE: {
    es: 'Asignar curso',
    en: 'Assign course',
    cat: 'Assignar curs',
    eus: 'Ikastaroa asignatu',
    pt: 'Atribuir curso'
  },
  COURSES_ASSOCIATED_TO_TAG_SUCCESSFULLY: {
    es: 'Cursos asociados a la etiqueta "{{tag}}" asignados correctamente.',
    en: 'Courses associated to the tag "{{tag}}" assigned successfully.',
    cat: 'Cursos associats a l`etiqueta "{{tag}}" assignats correctament.',
    eus: 'Tag-arekin elkargatutako ikastaroak ongi asignatu dira "{{tag}}" erabiliz.',
    pt: 'Cursos associados à etiqueta "{{tag}}" atribuídos com sucesso.'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  NAME_AND_LASTNAME: {
    es: 'Nombre y apellido',
    en: 'Name and lastname',
    cat: 'Nom i cognom',
    eus: 'Izena eta abizena',
    pt: 'Nome e apelido'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Apelido'
  },
  INSCRIPTION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Inscription date',
    cat: 'Data d`inscripció',
    eus: 'Izena eman den data',
    pt: 'Data de inscrição'
  },
  THERE_ARE_NOT_COURSES_ASOCIATED_TO_THIS_USER: {
    es: 'No hay cursos asociados a este usuario',
    en: 'There are no courses asociated to this user',
    cat: 'No hi ha cursos associats a aquest usuari',
    eus: 'Ez dago erabiltzaile honi atxikitako ikastaroik',
    pt: 'Não há cursos associados a este usuário'
  },
  NEW_STUDENT: {
    es: 'Nuevo estudiante',
    en: 'New student',
    cat: 'Nou estudiant',
    eus: 'Ikasle berria',
    pt: 'Novo aluno'
  },
  AUTO_GENERATED_USERNAME: {
    es: 'Nombre de usuario autogenerado, es posible que no sea único',
    en: 'Auto generated username, it is possible that it is not unique',
    cat: 'Nom d`usuari autogenerat, potser no és únic',
    eus: 'Erabiltzaile izen autogeneratua, posible da ez izatea bakarra',
    pt: 'Nome de usuário gerado automaticamente, é possível que não seja único'
  },
  CREATE_STUDENT: {
    es: 'Crear estudiante',
    en: 'Create student',
    cat: 'Crear estudiant',
    eus: 'Ikasle berria sortu',
    pt: 'Criar aluno'
  },
  NEW_STUDENT_ADDED: {
    es: 'Nuevo estudiante agregado a la academia',
    en: 'New student added to the academy',
    cat: 'Nou estudiant afegit a l`acadèmia',
    eus: 'Ikasle berria akademian gehitu da',
    pt: 'Novo aluno adicionado à academia'
  },
  USER_ALREADY_EXISTS: {
    es: 'Ups! Este email ya está en uso.',
    en: 'Ups! This email is already in use.',
    cat: 'Ups! Aquest email ja està en ús.',
    eus: 'Ups! Posta hau jadanik erabiltzen ari da.',
    pt: 'Ups! Este email já está em uso.'
  },
  STUDENTS_TOTAL_COUNT: {
    es: '{{total}} alumnos',
    en: '{{total}} students',
    cat: '{{total}} estudiants',
    eus: '{{total}} ikasleak',
    pt: '{{total}} alunos'
  },
  DELIVERABLES_TOTAL_COUNT: {
    es: '{{total}} entregables',
    en: '{{total}} deliverables',
    cat: '{{total}} lliurables',
    eus: '{{total}} entregagarriak',
    pt: '{{total}} entregáveis'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  STUDY_PROGRESS: {
    es: 'Progreso en el estudio',
    en: 'Study progress',
    cat: 'Progrés en l`estudi',
    eus: 'Ikasketetan aurrera egitea',
    pt: 'Progresso no estudo'
  },
  EDIT_STUDENT: {
    es: 'Editar alumno',
    en: 'Edit student',
    cat: 'Editar alumne',
    eus: 'Ikaslearen editatu',
    pt: 'Editar aluno'
  },
  ARE_YOU_SURE_TO_DELETE_USER: {
    es: '¿Estás seguro que deseas eliminar al usuario?',
    en: 'Are you sure you want to delete the user?',
    cat: 'N`estàs segur que vols eliminar l`usuari?',
    eus: 'Ziur zaude erabiltzailea ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar o usuário?'
  },
  USER_DELETED_SUCCESSFULLY: {
    es: 'Usuario eliminado correctamente',
    en: 'User deleted successfully',
    cat: 'Usuari eliminat correctament',
    eus: 'Erabiltzailea ongi ezabatu da',
    pt: 'Usuário eliminado com sucesso'
  },
  REMOVE_STUDENT: {
    es: 'Eliminar alumno',
    en: 'Remove student',
    cat: 'Eliminar alumne',
    eus: 'Ikaslearen ezabatu',
    pt: 'Eliminar aluno'
  },
  REMOVE: {
    es: 'Eliminar',
    en: 'Remove',
    cat: 'Eliminar',
    eus: 'Kendu',
    pt: 'Eliminar'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Contraseña reiniciada correctamente, el usuario recibirá un correo con la nueva contraseña',
    en: 'Password reset successfully, the user will receive an email with the new password',
    cat: 'Contrasenya reiniciada correctament, l`usuari rebrà un correu amb la nova contrasenya',
    eus: 'Pasahitza ongi berrezarri da, erabiltzaileak posta bat jasoko du pasahitz berriarekin',
    pt: 'Senha reiniciada com sucesso, o usuário receberá um email com a nova senha'
  },
  STUDENT_UPDATED_SUCCESSFULLY: {
    es: 'Estudiante actualizado correctamente',
    en: 'Student updated successfully',
    cat: 'Estudiant actualitzat correctament',
    eus: 'Ikaslea ongi eguneratuta',
    pt: 'Aluno atualizado com sucesso'
  },
  USER_PREVIEW_COULD_NOT_BE_LOADED: {
    es: 'No se pudo cargar la vista previa del usuario',
    en: 'User preview could not be loaded',
    cat: 'No s`ha pogut carregar la vista prèvia de l`usuari',
    eus: 'Ezin izan da erabiltzailearen aurrebista kargatu',
    pt: 'Não foi possível carregar a vista prévia do usuário'
  },
  RESET_STUDENT_PASSWORD: {
    es: 'Reiniciar contraseña',
    en: 'Reset student password',
    cat: 'Reiniciar contrasenya',
    eus: 'Ikaslearen pasahitza berrezarri',
    pt: 'Reiniciar senha do aluno'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro que deseas reiniciar la contraseña?',
    en: 'Are you sure to reset password?',
    cat: 'N`estàs segur que vols reiniciar la contrasenya?',
    eus: 'Ziur zaude pasahitza berrezarri nahi duzula?',
    pt: 'Tem certeza que deseja reiniciar a senha?'
  },
  CONNECTION: {
    es: 'Conexión',
    en: 'Connection',
    cat: 'Connexió',
    eus: 'Konexioa',
    pt: 'Conexão'
  },
  ARE_YOU_SURE_YOU_REMOVE_SUBSCRIPTION: {
    es: '¿Estás seguro que deseas eliminar la suscripción?',
    en: 'Are you sure you remove subscription?',
    cat: 'N`estàs segur que vols eliminar la subscripció?',
    eus: 'Ziur zaude harpidetza kendu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar a subscrição?'
  },
  ACCEPT: {
    es: 'Aceptar',
    en: 'Accept',
    cat: 'Accepta',
    eus: 'Onartu',
    pt: 'Aceitar'
  },
  [EDateFilterOptions.TODAY]: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur',
    pt: 'Hoje'
  },
  [EDateFilterOptions.SEVEN_DAYS]: {
    es: '7 días',
    en: '7 days',
    cat: '7 dies',
    eus: '7 egun',
    pt: '7 dias'
  },
  [EDateFilterOptions.THIRTY_DAYS]: {
    es: '30 días',
    en: '30 days',
    cat: '30 dies',
    eus: '30 egun',
    pt: '30 dias'
  },
  [EDateFilterOptions.ONE_YEAR]: {
    es: '1 año',
    en: '1 year',
    cat: '1 any',
    eus: '1 urte',
    pt: '1 ano'
  },
  [EDateFilterOptions.PERSONALIZED]: {
    es: 'Personalizado',
    en: 'Personalized',
    cat: 'Personalitzat',
    eus: 'Pertsonalizatua',
    pt: 'Personalizado'
  },
  RESOLUTION_TIME: {
    es: 'Tiempo de resolución:',
    en: 'Resolution time:',
    cat: 'Temps de resolució:',
    eus: 'Ebazpen denbora:',
    pt: 'Tempo de resolução:'
  },
  FIRST_RESPONSE_TIME: {
    es: 'Tiempo de 1ª respuesta:',
    en: 'First response time:',
    cat: 'Temps de 1ª resposta:',
    eus: 'Lehenengo erantzun denbora:',
    pt: 'Tempo de resposta inicial:'
  },
  RESOLUTION_TIME_YEARS: {
    es: '{{years}} a',
    en: '{{years}} y',
    cat: '{{years}} anys',
    eus: '{{years}} urte',
    pt: '{{years}} anos'
  },
  RESOLUTION_TIME_MONTHS: {
    es: '{{months}} m',
    en: '{{months}} m',
    cat: '{{months}} mesos',
    eus: '{{months}} hilabete',
    pt: '{{months}} meses'
  },
  RESOLUTION_TIME_DAYS: {
    es: '{{days}} d',
    en: '{{days}} d',
    cat: '{{days}} dies',
    eus: '{{days}} egun',
    pt: '{{days}} dias'
  },
  RESOLUTION_TIME_HOURS: {
    es: '{{hours}} h',
    en: '{{hours}} h',
    cat: '{{hours}} hores',
    eus: '{{hours}} ordu',
    pt: '{{hours}} horas'
  },
  RESOLUTION_TIME_MINUTES: {
    es: '{{minutes}} min',
    en: '{{minutes}} min',
    cat: '{{minutes}} minuts',
    eus: '{{minutes}} minutu',
    pt: '{{minutes}} minutos'
  },
  TOTAL: {
    es: 'Total {{total}}',
    en: 'Total {{total}}',
    cat: 'Total {{total}}',
    eus: 'Guztira {{total}}',
    pt: 'Total {{total}}'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu',
    pt: 'Estado'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  NOTIFICATIONS: {
    es: 'Notificaciones',
    en: 'Notifications',
    cat: 'Notificacions',
    eus: 'Jakinarazpenak',
    pt: 'Notificações'
  },
  RECEIVED: {
    es: 'Recibidas',
    en: 'Received',
    cat: 'Rebudes',
    eus: 'Jasoak',
    pt: 'Recebidas'
  },
  UNREAD: {
    es: 'No leídas',
    en: 'Unread',
    cat: 'No llegides',
    eus: 'Irkuskatugabeak',
    pt: 'Não lidas'
  },
  ARCHIVED: {
    es: 'Archivadas',
    en: 'Archived',
    cat: 'Arxivades',
    eus: 'Arkatuak',
    pt: 'Arquivadas'
  },
  NO_NOTIFICATIONS: {
    es: '¡Todo limpio! No tienes notificaciones. 😀',
    en: 'All clean! You have no notifications. 😀',
    cat: 'Tot net! No tens notificacions. 😀',
    eus: 'Guztia garbia! Ez duzu jakinarik. 😀',
    pt: 'Tudo limpo! Você não tem notificações. 😀'
  },
  NEWS: {
    es: '¡Nuestras novedades!',
    en: 'Our news!',
    cat: 'Les nostres novetats!',
    eus: 'Gure berriak!',
    pt: 'As nossas notícias!'
  },
  WHATS_NEXT: {
    es: '¿Qué es lo siguiente?',
    en: "What's next?",
    cat: 'Què és el següent?',
    eus: 'Zer dator gero?',
    pt: 'O que é o próximo?'
  },
  CSV_TEMPLATE: {
    es: '¡Haz click <1>aquí</1> para descargar tu plantilla!',
    en: 'Click <1>here</1> to download your template!',
    cat: 'Fes clic <1>aquí</1> per descarregar la plantilla!',
    eus: 'Egin klik <1>hemen</1> zure txantiloi-a jeisteko!',
    pt: 'Clique <1>aqui</1> para baixar o seu modelo!'
  },
  CONFIRM: {
    es: 'Confirmar',
    en: 'Confirm',
    cat: 'Confirmar',
    eus: 'Baieztatu',
    pt: 'Confirmar'
  },
  TICKET_TITLE: {
    es: 'Asunto',
    en: 'Subject',
    cat: 'Assumpte',
    eus: 'Gaia',
    pt: 'Assunto'
  },
  ACTIVITY_TITLE: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera',
    pt: 'Atividade'
  },
  USER: {
    es: 'Usuario',
    en: 'User',
    cat: 'Usuari',
    eus: 'Erabiltzaile',
    pt: 'Usuário'
  },
  CSAT: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Avaluació',
    eus: 'Balorazioa',
    pt: 'Avaliação'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  SELECT_PAYMENT_METHOD: {
    es: 'Selecciona tu <1>modalidad de pago:</1>',
    en: 'Select your <1>payment method:</1>',
    cat: 'Selecciona la teva <1>modalitat de pagament:</1>',
    eus: 'Hautatu zure <1>ordainketa modua:</1>',
    pt: 'Selecione o seu <1>método de pagamento:</1>'
  },
  SEQURA_6_MONTHS: {
    es: 'Hasta 6 cuotas* de <1>{{ count }} €</1>',
    en: 'Up to 6 installments* of <1>{{ count }} €</1>',
    cat: 'Fins a 6 quotes* de <1>{{ count }} €</1>',
    eus: '<1>{{ count }} €</1>-ra arteko 6 kopuru*',
    pt: 'Até 6 parcelas* de <1>{{ count }} €</1>'
  },
  STRIPE_3_MONTHS: {
    es: 'Hasta 3 cuotas* de <1>{{ count }} {{ symbol }}</1>',
    en: 'Up to 3 installments* of <1>{{ count }} {{ symbol }}</1>',
    cat: 'Fins a 3 quotes* de <1>{{ count }} {{ symbol }}</1>',
    eus: '<1>{{ count }} {{ symbol }}</1>-ra arteko 3 kopuru*',
    pt: 'Até 3 parcelas* de <1>{{ count }} {{ symbol }}</1>'
  },
  CONTACT_INFO: {
    es: 'Para cualquier consulta o aclaración, no dudes en contactarnos en el teléfono (+34)<1>919 01 95 15</1>.',
    en: 'For any questions or clarifications, please do not hesitate to contact us at (+34)<1>919 01 95 15</1>.',
    cat: 'Per a qualsevol consulta o aclariment, no dubtis en contactar-nos al telèfon (+34)<1>919 01 95 15</1>.',
    eus: 'Kontsultarik edo argibide gehiagorako, ez ezazu ez zaudenetik kontaktatu gurekin (+34)<1>919 01 95 15</1> telefonoan.',
    pt: 'Para qualquer consulta ou esclarecimento, não hesite em nos contatar pelo telefone (+34)<1>919 01 95 15</1>.'
  },
  CERTIFICATES: {
    es: 'Certificados',
    en: 'Certificates',
    cat: 'Certificats',
    eus: 'Zirriborroak',
    pt: 'Certificados'
  },
  YOU_HAVE_NOT_CERTIFICATES: {
    es: 'No tienes certificados.',
    en: 'You have no certificates.',
    cat: 'No tens certificats.',
    eus: 'Ez duzu ziurtagiriak.',
    pt: 'Você não tem certificados.'
  },
  GENERAL_INFORMATION: {
    es: 'Información general',
    en: 'General information',
    cat: 'Informació general',
    eus: 'Informazio orokorra',
    pt: 'Informação geral'
  },
  SPECIFIC_INFORMATION: {
    es: 'Datos específicos',
    en: 'Specific information',
    cat: 'Dades específiques',
    eus: 'Datu espezifikoak',
    pt: 'Informação específica'
  },
  BIRTHDAY: {
    es: 'Fecha de nacimiento',
    en: 'Birthday',
    cat: 'Data de naixement',
    eus: 'Jaiotze data',
    pt: 'Data de nascimento'
  },
  MY_INFORMATION: {
    es: 'Mi información',
    en: 'My information',
    cat: 'La meva informació',
    eus: 'Nire informazioa',
    pt: 'A minha informação'
  },
  REVIEW_REQUESTS: {
    es: 'Revisar mis solicitudes',
    en: 'Review my requests',
    cat: 'Revisar les meves sol·licituds',
    eus: 'Nire eskaerak berrikusi',
    pt: 'Revisar as minhas solicitações'
  },
  PAY_NOW: {
    es: 'Pagar ya',
    en: 'Pay now',
    cat: 'Paga ara',
    eus: 'Ordaindu orain',
    pt: 'Pagar agora'
  },
  START_NOW: {
    es: 'Empezar ya',
    en: 'Start now',
    cat: 'Comença ara',
    eus: 'Hasi orain',
    pt: 'Começar agora'
  },
  SCORE: {
    es: 'Calificación',
    en: 'Score',
    cat: 'Puntuació',
    eus: 'Kalifikazioa',
    pt: 'Pontuação'
  },
  CREATED_AT: {
    es: 'Fecha de creación',
    en: 'Creation date',
    cat: 'Data de creació',
    eus: 'Sortze data',
    pt: 'Data de criação'
  },
  STUDENT: {
    es: 'Alumno',
    en: 'Student',
    cat: 'Alumne',
    eus: 'Ikasle',
    pt: 'Estudante'
  },
  ACCEPT_TERMS_AND_CONDITIONS: {
    es: 'He leído y acepto los <a>Términos y Condiciones</a> y <b>la Política de Privacidad</b>',
    en: 'I have read and accept the <a>Terms and Conditions</a> and <b>the Privacy Policy</b>',
    cat: 'He llegit i accepto els <a>Termes i Condicions</a> i <b>la Política de Privacitat</b>',
    eus: 'Irakurri eta onartu dut <a>Erabilera baldintzak</a> eta <b>Pribatutasun politika</b>',
    pt: 'Li leu e aceitei os <a>Termos e Condições</a> e <b>Política de Privacidade</b>'
  },
  TERMS_AND_CONDITIONS_INFO: {
    es: 'En UCADEMY, S.L. (en adelante, “UCADEMY”) tratamos tus datos para poder prestarte los servicios de UCADEMY y enviarte información sobre nuestros productos y/o servicios. Estos datos son necesarios para llevar a cabo la prestación de los servicios que se hayan solicitado a través del Sitio Web, así como el consentimiento que nos hayas otorgado conforme a nuestra Política de Privacidad. Tus datos no podrán cederse a terceras empresas. El interesado tiene derecho a ejercitar su derecho de: acceso, rectificación, supresión, oposición, portabilidad de los Datos, limitación del Tratamiento, no ser objeto de decisiones automatizadas individualizadas. Tanto si tienes alguna duda o sugerencia como si quieres darte de baja ponte en contacto con nosotros enviando un email a la siguiente dirección: info@ucademy.com',
    en: 'In UCADEMY, S.L. (hereinafter, "UCADEMY") we process your data in order to provide you with the services of UCADEMY and send you information about our products and / or services. This data is necessary to carry out the provision of the services that have been requested through the Website, as well as the consent you have given us in accordance with our Privacy Policy. Your data cannot be transferred to third companies. The interested party has the right to exercise their right of: access, rectification, deletion, opposition, portability of the Data, limitation of the Treatment, not to be subject to individualized automated decisions. If you have any questions or suggestions or if you want to unsubscribe, please contact us by sending an email to the following address:',
    cat: 'A UCADEMY, S.L. (d’ara endavant, "UCADEMY") tractem les teves dades per poder prestar-te els serveis d’UCADEMY i enviar-te informació sobre els nostres productes i / o serveis. Aquestes dades són necessàries per dur a terme la prestació dels serveis que s’hagin sol·licitat a través del Lloc Web, així com el consentiment que ens hagis atorgat conforme a la nostra Política de Privacitat. Les teves dades no podran cedir-se a tercers. L’interessat té dret a exercir el seu dret de: accés, rectificació, supressió, oposició, portabilitat de les Dades, limitació del Tractament, no ser objecte de decisions automatitzades individualitzades. Tant si tens algun dubte o suggeriment com si vols donar-te de baixa posa’t en contacte amb nosaltres enviant un email a la següent adreça: info@ucademy.com',
    eus: 'UCADEMY, S.L. (hemen ondoren, "UCADEMY")-n zure datuak prozesatzen ditugu UCADEMY-rena diren zerbitzuak hornitzeko eta gure produktuen eta/edo zerbitzuen informazioa bidaltzeko. Datu hauek zerbitzuak eskatzeko gune bidez eskatu diren zerbitzuak hornitzeko beharrezkoak dira, eta ere zu egindako baimenaren arabera gure Pribatutasun Politikaren arabera emandako baimena. Zure datuak ezin dira hirugarren enpresetara eraman. Interesdunak bere datuen gaineko berdintasun, zuzenketaren, ezabaketaren, aurkakoaren, Datuen Portabilitatearen, Tratamenduaren Murrizketaren, erabakia automatizatu pertsonalizatuak jasotzen ez izateko eskubidea du. Galdera edo iradokizunik baduzu edo erregistratzea nahi baduzu, jarri gurekin harremanetan helbide honetara bidali email bat: info@ucademy.com',
    pt: 'À UCADEMY, S.L. (para endavant, "UCADEMY") fornecemos informações para lhe fornecer os serviços da UCADEMY e enviar-lhe informações sobre os nossos produtos e/ou serviços. Estes dados são necessários durante a prestação dos serviços solicitados através do Lloc Web, juntamente com o consentimento que concedeu de acordo com a nossa Política de Privacidade. Estes dados não podem ser transferidos para terceiros. O interesse é exercer o direito de: acesso, retificação, eliminação, oposição, portabilidade de dados, limitação do Tratado, não estar sujeito a decisões automatizadas individualizadas. Se tiver alguma dúvida ou sugestão ou se quiser doar abaixo, por favor contacte-nos enviando um email para o seguinte endereço: info@ucademy.com'
  },
  STUDENT_INFO_TITLE: {
    es: 'Vamos al lío.<br /> Confirma tu ficha de <a>estudiante:</a>',
    en: "Let's get down to business.<br /> Confirm your student <a>info:</a>",
    cat: 'Anem al gra.<br /> Confirma la teva ficha <a>d’estudiant:</a>',
    eus: 'Aldi honetan geratu gara.<br /> Egiaztatu zure <a>ikasle informazioa:</a>',
    pt: 'Vamos ao assunto.<br /> Confirme a sua ficha de <a>estudante:</a>'
  },
  FUN_FACT: {
    es: 'Dato curioso: <a>¿Sabías que el primer examen formal documentado se atribuye a China en el siglo II a.C. y era para opositar al servicio civil?</a>',
    en: 'Fun fact: <a>Did you know that the first documented formal exam is attributed to China in the 2nd century BC and was for civil service exams?</a>',
    cat: 'Dada curiosa: <a>Sabies que el primer examen formal documentat es atribueix a la Xina en el segle II aC i era per oposar al servei civil?</a>',
    eus: 'Datu kurioso: <a>Zer diozu lehenengo egiaztapen dokumentatuari? Txinak II. mendea a.C. atribuitzen du eta zerbitzu zibila oposatzeko zen.</a>',
    pt: 'Dado curioso: <a>Sabia que o primeiro exame formal documentado foi atribuído à China no século II a.C. e foi para oposições ao serviço público?</a>'
  },
  MARK_AS_READ: {
    es: 'Marcar todo como leído',
    en: 'Mark all as read',
    cat: 'Marca tot com a llegit',
    eus: 'Markatu dena irakurri gisa',
    pt: 'Marcar tudo como lido'
  },
  ALL_NOTIFICATIONS_FILTER: {
    es: 'Todas las notificaciones',
    en: 'All notifications',
    cat: 'Totes les notificacions',
    eus: 'Jakinarazpen guztiak',
    pt: 'Todas as notificações'
  },
  [`${ENotificationType.NEW_EVENT}_FILTER`]: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldiak',
    pt: 'Eventos'
  },
  [`${ENotificationType.NEW_RECURRING_EVENT}_FILTER`]: {
    es: 'Eventos recurrentes',
    en: 'Recurring events',
    cat: 'Esdeveniments recurrents',
    eus: 'Ekitaldi errepikatuak',
    pt: 'Eventos recorrentes'
  },
  [`${ENotificationType.NEW_POST}_FILTER`]: {
    es: 'Posts',
    en: 'Posts',
    cat: 'Posts',
    eus: 'Posts',
    pt: 'Posts'
  },
  [`${ENotificationType.NEW_TICKET}_FILTER`]: {
    es: 'Dudas',
    en: 'Tickets',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  [ENotificationType.NEW_TUTOR]: {
    es: 'Tutor asignado',
    en: 'Tutor assigned',
    cat: 'Tutor assignat',
    eus: 'Tutorea esleituta',
    pt: 'Tutora atribuída'
  },
  [ENotificationType.NEW_TEACHER_ACTIVITY]: {
    es: 'Nueva actividad subida',
    en: 'New activity uploaded',
    cat: 'Nova activitat pujada',
    eus: 'Jarduera berria igo da',
    pt: 'Atividade nova subida'
  },
  [ENotificationType.NEW_TEACHER_QUIZ]: {
    es: 'Nuevo test subido',
    en: 'New quiz uploaded',
    cat: 'Nou test pujat',
    eus: 'Test berria igo da',
    pt: 'Teste novo subido'
  },
  [ENotificationType.NEW_TUTORING_GROUP_MEMBER]: {
    es: 'Nuevo alumno',
    en: 'New student',
    cat: 'Nou alumne',
    eus: 'Ikasle berria',
    pt: 'Aluno novo'
  },
  [ENotificationType.NEW_EVENT]: {
    es: 'Nuevo evento disponible',
    en: 'New event published',
    cat: 'Nou esdeveniment disponible',
    eus: 'Ekitaldi berria argitaratu da',
    pt: 'Evento novo publicado'
  },
  [ENotificationType.NEW_RECURRING_EVENT]: {
    es: 'Nuevo evento recurrente disponible',
    en: 'New recurring event published',
    cat: 'Nou esdeveniment recurrent disponible',
    eus: 'Ekitaldi berria errepikatua argitaratu da',
    pt: 'Evento recorrente novo publicado'
  },
  [ENotificationType.EVENT_UPDATE]: {
    es: 'Evento actualizado',
    en: 'Event updated',
    cat: 'Esdeveniment actualitzat',
    eus: 'Ekitaldia eguneratua',
    pt: 'Evento atualizado'
  },
  [ENotificationType.NEW_POST]: {
    es: 'Nueva noticia publicada',
    en: 'New post published',
    cat: 'Nova notícia publicada',
    eus: 'Albiste berria argitaratu da',
    pt: 'Artigo novo publicado'
  },
  [ENotificationType.NEW_TICKET]: {
    es: 'Nueva duda',
    en: 'New ticket',
    cat: 'Nou dubte',
    eus: 'Zalantza berria',
    pt: 'Dúvida nova'
  },
  [ENotificationType.TICKET_UPDATE]: {
    es: 'Duda actualizada',
    en: 'Ticket updated',
    cat: 'Dubte actualitzat',
    eus: 'Zalantza eguneratua',
    pt: 'Dúvida atualizada'
  },
  [ENotificationType.NEW_DELIVERABLE]: {
    es: 'Nuevo entregable',
    en: 'New deliverable',
    cat: 'Nou lliurament',
    eus: 'Entrega berria',
    pt: 'Entrega nova'
  },
  [ENotificationType.DELIVERABLE_MESSAGE_CREATED]: {
    es: 'Entregable actualizado',
    en: 'Deliverable updated',
    cat: 'Lliurament actualitzat',
    eus: 'Entrega eguneratua',
    pt: 'Entrega atualizada'
  },
  [ENotificationType.DELIVERABLE_CORRECTED]: {
    es: 'Entregable corregido',
    en: 'Deliverable corrected',
    cat: 'Lliurament corregit',
    eus: 'Entregatu zuzendua',
    pt: 'Entrega corrigida'
  },
  [ENotificationType.NEW_EXAM_GROUP]: {
    es: 'Nueva convocatoria publicada',
    en: 'New exam group published',
    cat: 'Nova convocatòria publicada',
    eus: 'Deialdi berria argitaratu da',
    pt: 'Convocatória nova publicada'
  },
  [ENotificationType.NEW_EXAM_GROUP_EXAM]: {
    es: 'Nuevo examen para tu convocatoria publicado',
    en: 'New exam for your exam group published',
    cat: 'Nou examen per a la teva convocatòria publicat',
    eus: 'Proba berria zure deialdiko argitaratu da',
    pt: 'Prova nova publicada na sua convocatória'
  },
  [ENotificationType.NEW_LIVERECORD]: {
    es: 'Nueva grabación disponible',
    en: 'New recording available',
    cat: 'Nova gravació disponible',
    eus: 'Grabazio berria eskuragarri',
    pt: 'Gravação nova disponível'
  },
  [ENotificationType.TASK_RESOURCE_UPLOADED]: {
    es: 'Tarea pasada a revisión',
    en: 'Task passed to review',
    cat: 'Tasca passada a revisió',
    eus: 'Lan bat berrikuspenetara pasatu da',
    pt: 'Tarefa passada para revisão'
  },
  [ENotificationType.TASK_DEADLINE]: {
    es: 'Fecha de entrega',
    en: 'Due date',
    cat: 'Data de lliurament',
    eus: 'Entregatzeko data',
    pt: 'Data de entrega'
  },
  [ENotificationType.TASK_DEADLINE_3_DAYS]: {
    es: 'Fecha de entrega',
    en: 'Due date',
    cat: 'Data de lliurament',
    eus: 'Entregatzeko data',
    pt: 'Data de entrega'
  },
  [ENotificationType.TASK_REJECTED]: {
    es: 'Actividad rechazada',
    en: 'Activity rejected',
    cat: 'Activitat rebutjada',
    eus: 'Jarduera baztertua',
    pt: 'Atividade rejeitada'
  },
  [ENotificationType.TASK_APPROVED]: {
    es: 'Actividad aprobada',
    en: 'Activity approved',
    cat: 'Activitat aprovada',
    eus: 'Jarduera onartua',
    pt: 'Atividade aprovada'
  },
  [ENotificationType.NEW_STUDENT_VOTE]: {
    es: 'Valora tu experiencia',
    en: 'Rate your experience',
    cat: 'Valora la teva experiència',
    eus: 'Eskubidearen ziurgabetasuna',
    pt: 'Avalie sua experiência'
  },
  TEACHERS_CREATE_QUIZ_LINK: {
    es: 'Selecciona un test de la lista. Si no está creado, por favor, <a>ve a Test</a> y crea uno.',
    en: 'Select a test from the list. If it is not already created, please <a>go to Test</a> and create one.',
    cat: 'Selecciona un test de la llista. Si no està creat, si us plau, <a>vés a Test</a> i crea un.',
    eus: 'Aukeratu test bat zerrendatik. Ez badago sortuta, mesedez, <a>joan Testera</a> eta sortu bat.',
    pt: 'Selecione um teste da lista. Se não estiver criado, por favor, <a>vá para Test</a> e crie um.'
  },
  [EQuestionReportOptions.GRAMMAR]: {
    es: 'Errores ortográficos en la pregunta',
    en: 'Spelling errors in the question',
    cat: 'Errors ortogràfics a la pregunta',
    eus: 'Galderan ortografia akatsak',
    pt: 'Erros de ortografia na pergunta'
  },
  [EQuestionReportOptions.QUESTION_INCOMPLETE]: {
    es: 'Pregunta o respuesta incompleta',
    en: 'Incomplete question or answer',
    cat: 'Pregunta o resposta incompleta',
    eus: 'Galdera edo erantzun oso inkompletua',
    pt: 'Pergunta ou resposta incompleta'
  },
  [EQuestionReportOptions.WRONG_ANSWER]: {
    es: 'La respuesta marcada como correcta es errónea',
    en: 'The answer marked as correct is wrong',
    cat: 'La resposta marcada com a correcta és errònia',
    eus: 'Zuzen moduan markatutako erantzuna okerra da',
    pt: 'A resposta marcada como correta está errada'
  },
  [EQuestionReportOptions.WRONG_RELATED_COMTENT]: {
    es: 'La pregunta no corresponde al tema',
    en: 'The question does not correspond to the topic',
    cat: 'La pregunta no correspon al tema',
    eus: 'Galderak gaia ez du',
    pt: 'A pergunta não corresponde ao tema'
  },
  [EQuestionReportOptions.OTHER_QUESTION_REPORT]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak',
    pt: 'Outro'
  },
  [EActivityReportOptions.OTHER_ACTIVITY_REPORT]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altres',
    eus: 'Besteak',
    pt: 'Outro'
  },
  [EActivityReportOptions.AUDIO_OR_VIDEO]: {
    es: 'Problemas técnicos con el audio o el vídeo',
    en: 'Technical problems with the audio or video',
    cat: "Problemes tècnics amb l'àudio o el vídeo",
    eus: 'Zalantza teknikoak audioa edo bideoa baliatzeko',
    pt: 'Problemas técnicos com o áudio ou vídeo'
  },
  [EActivityReportOptions.LESSON_CONTENT]: {
    es: 'El contenido no corresponde con la lección',
    en: 'The information (texts, images, diagrams...) is not clear.',
    cat: 'El contingut no correspon amb la lliçó',
    eus: 'Edukiak (testuak, irudiak, diagramak...) argiak ez dira.',
    pt: 'O conteúdo não corresponde à lição'
  },
  [EActivityReportOptions.INCOMPLETE_CONTENT]: {
    es: 'El contenido está incompleto',
    en: 'The content is incomplete',
    cat: 'El contingut està incomplet',
    eus: 'Edukiak osatuak ez dira',
    pt: 'O conteúdo está incompleto'
  },
  [EActivityReportOptions.TEACHER_EXPLANATION]: {
    es: 'La explicación del profesor no es clara',
    en: "The teacher's explanation is not clear",
    cat: "L'explicació del professor no és clara",
    eus: 'Irakaslearen azalpena ez da argia',
    pt: 'A explicação do professor não é clara'
  },
  [EActivityReportOptions.REPEATED_VIDEO]: {
    es: 'Este video está repetido en otro tema',
    en: 'This video is repeated in another topic',
    cat: 'Aquest vídeo està repetit en un altre tema',
    eus: 'Bideo hau beste gaian berriz ere errepikatua dago',
    pt: 'Este vídeo está repetido em outro tema'
  },
  [EActivityReportOptions.WRONG_INFORMATION]: {
    es: 'La información (textos, imágenes, esquemas...) no es clara',
    en: 'The information (texts, images, diagrams...) is not clear.',
    cat: 'La informació (texts, imatges, esquemes...) no és clara',
    eus: 'Informazioa (testuak, irudiak, diagramak...) ez da argia.',
    pt: 'A informação (testes, imagens, diagramas...) não é clara.'
  },
  [EActivityReportOptions.TECHNICAL_PROBLEM]: {
    es: 'Problema técnico / Algo no funciona',
    en: 'Technical problem / Something is not working',
    cat: 'Problema tècnic / Alguna cosa no funciona',
    eus: 'Zalantza teknikoa / Zerbait ez dago funtzionatzen',
    pt: 'Problema técnico / Alguma coisa não está funcionando'
  },
  VISIBILITY: {
    es: 'Visibilidad',
    en: 'Visibility',
    cat: 'Visibilitat',
    eus: 'Ikusgarritasuna',
    pt: 'Visibilidade'
  },
  VOTES: {
    es: 'Votos',
    en: 'Votes',
    cat: 'Vots',
    eus: 'Botoak',
    pt: 'Votos'
  },
  PUBLIC: {
    es: 'Pública',
    en: 'Public',
    cat: 'Pública',
    eus: 'Publikoa',
    pt: 'Pública'
  },
  PRIVATE: {
    es: 'Privada',
    en: 'Private',
    cat: 'Privada',
    eus: 'Pribatua',
    pt: 'Privada'
  },
  CHANGE_VISIBILITY_CONFIRMATION: {
    es: '¿Estás seguro de que quieres hacer pública esta duda? <a>Todos los usuarios podrán verla de ahora en adelante.</a>',
    en: 'Are you sure you want to make this question public? <a>All users will be able to see it from now on.</a>',
    cat: "Estàs segur que vols fer pública aquesta pregunta? <a>Tots els usuaris la podran veure d'ara endavant.</a>",
    eus: 'Zihur zaude zalantza hau publikoa egin nahi duzula? <a>Erabiltzaile guztiak ikus dezakete geroztik.</a>',
    pt: 'Tem certeza que deseja tornar pública esta dúvida? <a>Todos os usuários poderão ver a partir de agora.</a>'
  },
  [EExamGroupsType.OPPOSITION]: {
    es: 'Oposición',
    en: 'Opposition',
    cat: 'Oposició',
    eus: 'Oposizioa',
    pt: 'Oposição'
  },
  [EExamGroupsType.CONTEST]: {
    es: 'Concurso',
    en: 'Contest',
    cat: 'Concurs',
    eus: 'Lehiaketa',
    pt: 'Concurso'
  },
  [EExamGroupsType.CONTEST_OPPOSITION]: {
    es: 'Concurso-oposición',
    en: 'Contest-opposition',
    cat: 'Concurs-oposició',
    eus: 'Lehiaketa-oposizioa',
    pt: 'Concurso-oposição'
  },
  [EExamGroupsType.ORDINARY]: {
    es: 'Ordinaria',
    en: 'Ordinary',
    cat: 'Ordinària',
    eus: 'Ohiko',
    pt: 'Ordinária'
  },
  [EExamGroupsType.EXTRAORDINARY]: {
    es: 'Extraordinaria',
    en: 'Extraordinary',
    cat: 'Extraordinària',
    eus: 'Berezi',
    pt: 'Extraordinária'
  },
  [EExamGroupsType.JOB_BOARD]: {
    es: 'Bolsa de trabajo',
    en: 'Job board',
    cat: 'Bolsa de treball',
    eus: 'Lan-poltsa',
    pt: 'Bolsa de emprego'
  },
  FILES_AND_LINKS: {
    es: 'Archivos y enlaces',
    en: 'Files and links',
    cat: 'Arxius i enllaços',
    eus: 'Fitxategiak eta estekak',
    pt: 'Ficheiros e links'
  },
  COMMUNITY: {
    es: 'Comunidad',
    en: 'Community',
    cat: 'Comunitat',
    eus: 'Komunitatea',
    pt: 'Comunidade'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad Autónoma',
    en: 'Autonomous Community',
    cat: 'Comunitat Autònoma',
    eus: 'Autonomia Erkidegoa',
    pt: 'Comunidade Autónoma'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia',
    pt: 'Província'
  },
  [EAutonomousCommunity.ANDALUCIA]: {
    es: 'Andalucía',
    en: 'Andalucía',
    cat: 'Andalucía',
    eus: 'Andalucía',
    pt: 'Andaluzia'
  },
  [EAutonomousCommunity.PAIS_VASCO]: {
    es: 'País Vasco',
    en: 'País Vasco',
    cat: 'País Vasco',
    eus: 'País Vasco',
    pt: 'País Vasco'
  },
  [EAutonomousCommunity.CANARIAS]: {
    es: 'Islas Canarias',
    en: 'Islas Canarias',
    cat: 'Isles Canàries',
    eus: 'Islas Canarias',
    pt: 'Ilhas Canárias'
  },
  [EAutonomousCommunity.VALENCIA]: {
    es: 'Comunidad Valenciana',
    en: 'Comunidad Valenciana',
    cat: 'Comunitat Valenciana',
    eus: 'Comunitat Valenciana',
    pt: 'Comunidade Valenciana'
  },
  [EAutonomousCommunity.CATALUNYA]: {
    es: 'Cataluña',
    en: 'Cataluña',
    cat: 'Cataluña',
    eus: 'Cataluña',
    pt: 'Catalunha'
  },
  [EAutonomousCommunity.MADRID]: {
    es: 'Madrid',
    en: 'Madrid',
    cat: 'Madrid',
    eus: 'Madrid',
    pt: 'Madrid'
  },
  [EAutonomousCommunity.ARAGON]: {
    es: 'Aragón',
    en: 'Aragón',
    cat: 'Aragón',
    eus: 'Aragón',
    pt: 'Aragónia'
  },
  [EAutonomousCommunity.GALICIA]: {
    es: 'Galicia',
    en: 'Galicia',
    cat: 'Galicia',
    eus: 'Galicia',
    pt: 'Galiza'
  },
  [EAutonomousCommunity.EXTREMADURA]: {
    es: 'Extremadura',
    en: 'Extremadura',
    cat: 'Extremadura',
    eus: 'Extremadura',
    pt: 'Extremadura'
  },
  [EAutonomousCommunity.CASTILLA_LEON]: {
    es: 'Castilla y León',
    en: 'Castilla y León',
    cat: 'Castilla y León',
    eus: 'Castilla y León',
    pt: 'Castela e Leão'
  },
  [EAutonomousCommunity.CASTILLA_LA_MANCHA]: {
    es: 'Castilla-La Mancha',
    en: 'Castilla-La Mancha',
    cat: 'Castilla-La Mancha',
    eus: 'Castilla-La Mancha',
    pt: 'Castela-La Mancha'
  },
  [EAutonomousCommunity.ASTURIAS]: {
    es: 'Asturias',
    en: 'Asturias',
    cat: 'Asturias',
    eus: 'Asturias',
    pt: 'Astúrias'
  },
  [EAutonomousCommunity.BALEARES]: {
    es: 'Islas Baleares',
    en: 'Islas Baleares',
    cat: 'Isles Balears',
    eus: 'Islas Baleares',
    pt: 'Ilhas Baleares'
  },
  [EAutonomousCommunity.MELILLA]: {
    es: 'Melilla',
    en: 'Melilla',
    cat: 'Melilla',
    eus: 'Melilla',
    pt: 'Melilha'
  },
  [EAutonomousCommunity.CANTABRIA]: {
    es: 'Cantabria',
    en: 'Cantabria',
    cat: 'Cantabria',
    eus: 'Cantabria',
    pt: 'Cantábria'
  },
  [EAutonomousCommunity.LA_RIOJA]: {
    es: 'La Rioja',
    en: 'La Rioja',
    cat: 'La Rioja',
    eus: 'La Rioja',
    pt: 'La Rioja'
  },
  [EAutonomousCommunity.MURCIA]: {
    es: 'Murcia',
    en: 'Murcia',
    cat: 'Murcia',
    eus: 'Murcia',
    pt: 'Murcia'
  },
  [EAutonomousCommunity.CEUTA]: {
    es: 'Ceuta',
    en: 'Ceuta',
    cat: 'Ceuta',
    eus: 'Ceuta',
    pt: 'Ceuta'
  },
  [EAutonomousCommunity.NAVARRA]: {
    es: 'Navarra',
    en: 'Navarra',
    cat: 'Navarra',
    eus: 'Navarra',
    pt: 'Navarra'
  },
  [EProvince.ALMERIA]: {
    es: 'Almería',
    en: 'Almería',
    cat: 'Almería',
    eus: 'Almería',
    pt: 'Almeria'
  },
  [EProvince.CADIZ]: {
    es: 'Cádiz',
    en: 'Cádiz',
    cat: 'Cádiz',
    eus: 'Cádiz',
    pt: 'Cádiz'
  },
  [EProvince.CORDOBA]: {
    es: 'Córdoba',
    en: 'Córdoba',
    cat: 'Córdoba',
    eus: 'Córdoba',
    pt: 'Córdoba'
  },
  [EProvince.GRANADA]: {
    es: 'Granada',
    en: 'Granada',
    cat: 'Granada',
    eus: 'Granada',
    pt: 'Granada'
  },
  [EProvince.HUELVA]: {
    es: 'Huelva',
    en: 'Huelva',
    cat: 'Huelva',
    eus: 'Huelva',
    pt: 'Huelva'
  },
  [EProvince.JAEN]: {
    es: 'Jaén',
    en: 'Jaén',
    cat: 'Jaén',
    eus: 'Jaén',
    pt: 'Jaén'
  },
  [EProvince.MALAGA]: {
    es: 'Málaga',
    en: 'Málaga',
    cat: 'Málaga',
    eus: 'Málaga',
    pt: 'Málaga'
  },
  [EProvince.SEVILLA]: {
    es: 'Sevilla',
    en: 'Sevilla',
    cat: 'Sevilla',
    eus: 'Sevilla',
    pt: 'Sevilha'
  },
  [EProvince.ALAVA]: {
    es: 'Álava',
    en: 'Álava',
    cat: 'Álava',
    eus: 'Álava',
    pt: 'Álava'
  },
  [EProvince.GUIPUZCOA]: {
    es: 'Guipúzcoa',
    en: 'Gipuzkoa',
    cat: 'Gipuzkoa',
    eus: 'Gipuzkoa',
    pt: 'Gipuzkoa'
  },
  [EProvince.VIZCAYA]: {
    es: 'Vizcaya',
    en: 'Bizkaia',
    cat: 'Bizkaia',
    eus: 'Bizkaia',
    pt: 'Bizkaia'
  },
  [EProvince.LAS_PALMAS]: {
    es: 'Las Palmas',
    en: 'Las Palmas',
    cat: 'Las Palmas',
    eus: 'Las Palmas',
    pt: 'Las Palmas'
  },
  [EProvince.SANTA_CRUZ_DE_TENERIFE]: {
    es: 'Santa Cruz de Tenerife',
    en: 'Santa Cruz de Tenerife',
    cat: 'Santa Cruz de Tenerife',
    eus: 'Santa Cruz de Tenerife',
    pt: 'Santa Cruz de Tenerife'
  },
  [EProvince.VALENCIA]: {
    es: 'Valencia',
    en: 'Valencia',
    cat: 'Valencia',
    eus: 'Valencia',
    pt: 'Valência'
  },
  [EProvince.CASTELLON]: {
    es: 'Castellón',
    en: 'Castellón',
    cat: 'Castellón',
    eus: 'Castellón',
    pt: 'Castellón'
  },
  [EProvince.ALICANTE]: {
    es: 'Alicante',
    en: 'Alicante',
    cat: 'Alicante',
    eus: 'Alicante',
    pt: 'Alicante'
  },
  [EProvince.BARCELONA]: {
    es: 'Barcelona',
    en: 'Barcelona',
    cat: 'Barcelona',
    eus: 'Barcelona',
    pt: 'Barcelona'
  },
  [EProvince.GERONA]: {
    es: 'Gerona',
    en: 'Girona',
    cat: 'Girona',
    eus: 'Girona',
    pt: 'Girona'
  },
  [EProvince.LERIDA]: {
    es: 'Lérida',
    en: 'Lérida',
    cat: 'Lérida',
    eus: 'Lérida',
    pt: 'Lérida'
  },
  [EProvince.TARRAGONA]: {
    es: 'Tarragona',
    en: 'Tarragona',
    cat: 'Tarragona',
    eus: 'Tarragona',
    pt: 'Tarragona'
  },
  [EProvince.MADRID]: {
    es: 'Madrid',
    en: 'Madrid',
    cat: 'Madrid',
    eus: 'Madrid',
    pt: 'Madrid'
  },
  [EProvince.HUESCA]: {
    es: 'Huesca',
    en: 'Huesca',
    cat: 'Huesca',
    eus: 'Huesca',
    pt: 'Huesca'
  },
  [EProvince.TERUEL]: {
    es: 'Teruel',
    en: 'Teruel',
    cat: 'Teruel',
    eus: 'Teruel',
    pt: 'Teruel'
  },
  [EProvince.ZARAGOZA]: {
    es: 'Zaragoza',
    en: 'Zaragoza',
    cat: 'Zaragoza',
    eus: 'Zaragoza',
    pt: 'Zaragoza'
  },
  [EProvince.A_CORUNA]: {
    es: 'A Coruña',
    en: 'A Coruña',
    cat: 'A Coruña',
    eus: 'A Coruña',
    pt: 'A Coruña'
  },
  [EProvince.LUGO]: {
    es: 'Lugo',
    en: 'Lugo',
    cat: 'Lugo',
    eus: 'Lugo',
    pt: 'Lugo'
  },
  [EProvince.OURENSE]: {
    es: 'Ourense',
    en: 'Ourense',
    cat: 'Ourense',
    eus: 'Ourense',
    pt: 'Ourense'
  },
  [EProvince.PONTEVEDRA]: {
    es: 'Pontevedra',
    en: 'Pontevedra',
    cat: 'Pontevedra',
    eus: 'Pontevedra',
    pt: 'Pontevedra'
  },
  [EProvince.BADAJOZ]: {
    es: 'Badajoz',
    en: 'Badajoz',
    cat: 'Badajoz',
    eus: 'Badajoz',
    pt: 'Badajoz'
  },
  [EProvince.CACERES]: {
    es: 'Cáceres',
    en: 'Cáceres',
    cat: 'Cáceres',
    eus: 'Cáceres',
    pt: 'Cáceres'
  },
  [EProvince.AVILA]: {
    es: 'Ávila',
    en: 'Ávila',
    cat: 'Ávila',
    eus: 'Ávila',
    pt: 'Ávila'
  },
  [EProvince.BURGOS]: {
    es: 'Burgos',
    en: 'Burgos',
    cat: 'Burgos',
    eus: 'Burgos',
    pt: 'Burgos'
  },
  [EProvince.LEON]: {
    es: 'León',
    en: 'León',
    cat: 'León',
    eus: 'León',
    pt: 'Leão'
  },
  [EProvince.PALENCIA]: {
    es: 'Palencia',
    en: 'Palencia',
    cat: 'Palencia',
    eus: 'Palencia',
    pt: 'Palência'
  },
  [EProvince.SALAMANCA]: {
    es: 'Salamanca',
    en: 'Salamanca',
    cat: 'Salamanca',
    eus: 'Salamanca',
    pt: 'Salamanca'
  },
  [EProvince.SEGOVIA]: {
    es: 'Segovia',
    en: 'Segovia',
    cat: 'Segovia',
    eus: 'Segovia',
    pt: 'Segovia'
  },
  [EProvince.SORIA]: {
    es: 'Soria',
    en: 'Soria',
    cat: 'Soria',
    eus: 'Soria',
    pt: 'Soria'
  },
  [EProvince.VALLADOLID]: {
    es: 'Valladolid',
    en: 'Valladolid',
    cat: 'Valladolid',
    eus: 'Valladolid',
    pt: 'Valladolid'
  },
  [EProvince.ZAMORA]: {
    es: 'Zamora',
    en: 'Zamora',
    cat: 'Zamora',
    eus: 'Zamora',
    pt: 'Zamora'
  },
  [EProvince.ALBACETE]: {
    es: 'Albacete',
    en: 'Albacete',
    cat: 'Albacete',
    eus: 'Albacete',
    pt: 'Albacete'
  },
  [EProvince.CIUDAD_REAL]: {
    es: 'Ciudad Real',
    en: 'Ciudad Real',
    cat: 'Ciudad Real',
    eus: 'Ciudad Real',
    pt: 'Cidade Real'
  },
  [EProvince.CUENCA]: {
    es: 'Cuenca',
    en: 'Cuenca',
    cat: 'Cuenca',
    eus: 'Cuenca',
    pt: 'Cuenca'
  },
  [EProvince.GUADALAJARA]: {
    es: 'Guadalajara',
    en: 'Guadalajara',
    cat: 'Guadalajara',
    eus: 'Guadalajara',
    pt: 'Guadalajara'
  },
  [EProvince.TOLEDO]: {
    es: 'Toledo',
    en: 'Toledo',
    cat: 'Toledo',
    eus: 'Toledo',
    pt: 'Toledo'
  },
  [EProvince.ASTURIAS]: {
    es: 'Asturias',
    en: 'Asturias',
    cat: 'Asturias',
    eus: 'Asturias',
    pt: 'Astúrias'
  },
  [EProvince.BALEARES]: {
    es: 'Islas Baleares',
    en: 'Islas Baleares',
    cat: 'Isles Balears',
    eus: 'Islas Baleares',
    pt: 'Ilhas Baleares'
  },
  [EProvince.MELILLA]: {
    es: 'Melilla',
    en: 'Melilla',
    cat: 'Melilla',
    eus: 'Melilla',
    pt: 'Melilha'
  },
  [EProvince.CANTABRIA]: {
    es: 'Cantabria',
    en: 'Cantabria',
    cat: 'Cantabria',
    eus: 'Cantabria',
    pt: 'Cantábria'
  },
  [EProvince.LA_RIOJA]: {
    es: 'La Rioja',
    en: 'La Rioja',
    cat: 'La Rioja',
    eus: 'La Rioja',
    pt: 'La Rioja'
  },
  [EProvince.MURCIA]: {
    es: 'Murcia',
    en: 'Murcia',
    cat: 'Murcia',
    eus: 'Murcia',
    pt: 'Murcia'
  },
  [EProvince.CEUTA]: {
    es: 'Ceuta',
    en: 'Ceuta',
    cat: 'Ceuta',
    eus: 'Ceuta',
    pt: 'Ceuta'
  },
  [EProvince.NAVARRA]: {
    es: 'Navarra',
    en: 'Navarra',
    cat: 'Navarra',
    eus: 'Navarra',
    pt: 'Navarra'
  },
  [ESectionTrackStatus.NOT_STARTED]: {
    es: 'Pendiente',
    en: 'Pending',
    cat: 'Pendent',
    eus: 'Pendiente',
    pt: 'Pendente'
  },
  [ESectionTrackStatus.SEEN]: {
    es: 'Visto',
    en: 'Seen',
    cat: 'Vist',
    eus: 'Ikusita',
    pt: 'Visto'
  },
  [ESectionTrackStatus.SUMMARISED]: {
    es: 'Resumido',
    en: 'Summarised',
    cat: 'Resumit',
    eus: 'Laburtuta',
    pt: 'Resumido'
  },
  [ESectionTrackStatus.STUDIED]: {
    es: 'Estudiado',
    en: 'Studied',
    cat: 'Estudiat',
    eus: 'Ikasketak eginak',
    pt: 'Estudado'
  },
  [ESectionTrackStatus.REVISED]: {
    es: 'Falta repaso',
    en: 'Missing review',
    cat: 'Repas faltant',
    eus: 'Berrikusitako falta',
    pt: 'Reparo pendente'
  },
  [ESectionTrackStatus.KNOWN]: {
    es: 'Me lo sé',
    en: 'I know it',
    cat: 'Me lo sé',
    eus: 'Dakit',
    pt: 'Sei'
  },
  CONTRACT: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua',
    pt: 'Contrato'
  },
  DOWNLOAD_CONTRACT: {
    es: 'Descargar contrato',
    en: 'Download contract',
    cat: 'Descarregar contracte',
    eus: 'Kontratuaren deskargatzea',
    pt: 'Descarregar contrato'
  },
  DOES_HE_WORK: {
    es: '¿Trabaja?',
    en: 'Does he work?',
    cat: 'Treballa?',
    eus: 'Lan egiten du?',
    pt: 'Trabalha?'
  },
  AVAILABLE_TIME: {
    es: 'Tiempo disponible para estudiar',
    en: 'Available time to study',
    cat: 'Temps disponible per estudiar',
    eus: 'Ikastea daitekeen denbora',
    pt: 'Tempo disponível para estudar'
  },
  HOURS_PER_WEEK: {
    es: '{{ investTime }} h/semana',
    en: '{{ investTime }} h/week',
    cat: '{{ investTime }} h/setmana',
    eus: '{{ investTime }} h/aste',
    pt: '{{ investTime }} h/semana'
  },
  HISTORY: {
    es: 'Históricos',
    en: 'History',
    cat: 'Històrics',
    eus: 'Historikoak',
    pt: 'Histórico'
  },
  STATUS_HISTORY: {
    es: 'Histórico de estados',
    en: 'Status history',
    cat: "Històric d'estats",
    eus: 'Egoera historikoa',
    pt: 'Histórico de estados'
  },
  PAYMENT_HISTORY: {
    es: 'Histórico de pagos',
    en: 'Payment history',
    cat: 'Històric de pagaments',
    eus: 'Ordainketa historikoa',
    pt: 'Histórico de pagamentos'
  },
  ACCESS_HISTORY: {
    es: 'Accesos',
    en: 'Access',
    cat: 'Accessos',
    eus: 'Sarbidea',
    pt: 'Acesso'
  },
  ACCESS_LABEL: {
    es: 'Acceso',
    en: 'Access',
    cat: 'Accés',
    eus: 'Sarbidea',
    pt: 'Acesso'
  },
  ACCESS_HISTORY_COUNT_one: {
    es: '1 acceso',
    en: '1 access',
    cat: '1 accés',
    eus: '1 sarbidea',
    pt: '1 acesso'
  },
  ACCESS_HISTORY_COUNT_other: {
    es: '{{count}} accesos',
    en: '{{count}} accesses',
    cat: '{{count}} accés',
    eus: '{{count}} sarbidea',
    pt: '{{count}} acessos'
  },
  QUIZ_NUMBER: {
    es: 'Nº test realizados',
    en: 'Nº tests done',
    cat: 'Nº test realitzat',
    eus: 'Egindako test kopurua',
    pt: 'Nº de testes realizados'
  },
  AVG_SCORE: {
    es: 'Nota media',
    en: 'Avg score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko puntuazioa',
    pt: 'Média das notas'
  },
  TICKETS_NUMBER: {
    es: 'Nº dudas',
    en: 'Nº doubts',
    cat: 'Nº dubtes',
    eus: 'Zalantz kopurua',
    pt: 'Nº de dúvidas'
  },
  CSAT_TITLE: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT',
    pt: 'CSAT'
  },
  APP_TIME: {
    es: 'Tiempo total',
    en: 'Total time',
    cat: 'Temps total',
    eus: 'Denbora guztira',
    pt: 'Tempo total'
  },
  [EUserStatus.ACTIVE]: {
    es: 'Activo',
    en: 'Active',
    cat: 'Actiu',
    eus: 'Aktibo',
    pt: 'Ativo'
  },
  [EUserStatus.INACTIVE]: {
    es: 'Inactivo',
    en: 'Inactive',
    cat: 'Inactiu',
    eus: 'Inaktibo',
    pt: 'Inativo'
  },
  [EUserStatus.NOT_ACTIVATED]: {
    es: 'No activado',
    en: 'Not activated',
    cat: 'No activat',
    eus: 'Ez da aktibatu',
    pt: 'Não activado'
  },
  [EUserStatus.SLEEP]: {
    es: 'Dormido',
    en: 'Sleep',
    cat: 'Dormit',
    eus: 'Ikasturtea',
    pt: 'Dormir'
  },
  [EStudentPaymentStatus.PAID]: {
    es: 'Pago correcto',
    en: 'Paid',
    cat: 'Pagament correcte',
    eus: 'Ordainketa egokia',
    pt: 'Pagamento correto'
  },
  [EStudentPaymentStatus.DOWNTURN]: {
    es: 'Baja',
    en: 'Downturn',
    cat: 'Baixa',
    eus: 'Baxua',
    pt: 'Baixo'
  },
  [EStudentPaymentStatus.NON_PAYMENT]: {
    es: 'Impago',
    en: 'Non payment',
    cat: 'Impagament',
    eus: 'Ordainketa ezezaguna',
    pt: 'Pagamento não realizado'
  },
  [EStudentPaymentStatus.FORMER_STUDENT]: {
    es: 'Antiguo',
    en: 'Former student',
    cat: 'Antic',
    eus: 'Ohiko ikaslea',
    pt: 'Antigo aluno'
  },
  [EStudentPaymentStatus.EXAMINED]: {
    es: 'Examinado',
    en: 'Examined',
    cat: 'Examinat',
    eus: 'Aztertua',
    pt: 'Examinado'
  },
  ACTIVITY_STATUS: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Ekintza',
    pt: 'Atividade'
  },
  PAYMENT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  APP_TIME_TOOLTIP: {
    es: 'Tiempo total que ha pasado el alumno en la plataforma',
    en: 'Total time the student has spent in the platform',
    cat: "Temps total que ha passat l'alumne a la plataforma",
    eus: 'Ikasleak plataforma guztian igaro duen denbora guztira',
    pt: 'Tempo total que o aluno passou na plataforma'
  },
  SELECT_VISIBILITY: {
    es: 'Seleccionar visibilidad',
    en: 'Select visibility',
    cat: 'Seleccionar visibilitat',
    eus: 'Ikusgarritasuna aukeratu',
    pt: 'Selecionar visibilidade'
  },
  PUBLIC_VISIBILITY: {
    es: 'Pública',
    en: 'Public',
    cat: 'Pública',
    eus: 'Publikoa',
    pt: 'Pública'
  },
  PRIVATE_VISIBILITY: {
    es: 'Privada',
    en: 'Private',
    cat: 'Privada',
    eus: 'Pribatua',
    pt: 'Privada'
  },
  CLAIMED: {
    es: 'Reclamado',
    en: 'Claimed',
    cat: 'Reclamat',
    eus: 'Erreklamatuta',
    pt: 'Reclamado'
  },
  NEW_USER_ADDED: {
    es: 'Nuevo usuario agregado a la academia',
    en: 'New user added to the academy',
    cat: "Nou usuari afegit a l'acadèmia",
    eus: 'Erabiltzaile berria akademiara gehitu da',
    pt: 'Novo usuário adicionado à academia'
  },
  USER_UPDATED_SUCCESSFULLY: {
    es: 'Usuario actualizado correctamente',
    en: 'User updated successfully',
    cat: 'Usuari actualitzat correctament',
    eus: 'Erabiltzailea ongi eguneratu da',
    pt: 'Usuário atualizado com sucesso'
  },
  [ERoles.MANAGER]: {
    es: 'Manager',
    en: 'Manager',
    cat: 'Manager',
    eus: 'Kudeatzailea',
    pt: 'Gestor'
  },
  [ERoles.TEACHER]: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  [ERoles.SALES]: {
    es: 'Ventas',
    en: 'Sales',
    cat: 'Vendes',
    eus: 'Saltzak',
    pt: 'Vendas'
  },
  [ERoles.SUPPORT]: {
    es: 'Soporte',
    en: 'Support',
    cat: 'Suport',
    eus: 'Laguntza',
    pt: 'Suporte'
  },
  [ERoles.INSPECTOR]: {
    es: 'Inspector',
    en: 'Inspector',
    cat: 'Inspector',
    eus: 'Azterketa',
    pt: 'Inspetor'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA irakaslea',
    pt: 'Professor IA'
  },
  ARE_YOU_SURE_TO_IMPERSONATE: {
    es: '¿Estás seguro de que quieres impersonar a {{ name }}?',
    en: 'Are you sure you want to impersonate {{ name }}?',
    cat: 'Estàs segur que vols impersonar a {{ name }}?',
    eus: 'Zihur zaude {{ name }} impersonatu nahi duzula?',
    pt: 'Tem certeza que deseja имитировать {{ name }}?'
  },
  TEST_WIDGET_TOTAL_QUESTIONS: {
    es: 'Preguntas realizadas',
    en: 'Questions asked',
    cat: 'Preguntes realitzades',
    eus: 'Galderei egin zaien galderak',
    pt: 'Questões realizadas'
  },
  TEST_WIDGET_CORRECT_QUESTIONS: {
    es: 'Preguntas acertadas',
    en: 'Successful questions',
    cat: 'Preguntes encertades',
    eus: 'Ondo erantzun diren galderak',
    pt: 'Questões acertadas'
  },
  TEST_WIDGET_WRONG_QUESTIONS: {
    es: 'Preguntas falladas',
    en: 'Failed questions',
    cat: 'Preguntes fallades',
    eus: 'Gaizki erantzun diren galderak',
    pt: 'Questões erradas'
  },
  TEST_WIDGET_BLANK_QUESTIONS: {
    es: 'Preguntas sin contestar',
    en: 'Unanswered questions',
    cat: 'Preguntes sense contestar',
    eus: 'Erantzunik gabeko galderak',
    pt: 'Questões não respondidas'
  },
  TEST_WIDGET_AVG_TIME: {
    es: 'Tiempo medio por pregunta',
    en: 'Average time per question',
    cat: 'Temps mitjà per pregunta',
    eus: 'Galdera bakoitzeko batazbesteko denbora',
    pt: 'Tempo médio por questão'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  TEST_WIDGET_TITLE: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Proba',
    pt: 'Prova'
  },
  TEST_WIDGET_SUBTITLE: {
    es: 'Estadísticas de los test realizados',
    en: 'Statistics of the tests performed',
    cat: 'Estadístiques de les proves realitzades',
    eus: 'Egin diren proben estatistikak',
    pt: 'Estatísticas das provas realizadas'
  },
  TEST_WIDGET_AVG_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko puntuazioa',
    pt: 'Média das notas'
  },
  ACTIVITY_NAME: {
    es: 'Nombre de actividad',
    en: 'Activity name',
    cat: 'Nom de l`activitat',
    eus: 'Jarduera izena',
    pt: 'Nome da atividade'
  },
  UPLOAD_DATE: {
    es: 'Fecha de subida',
    en: 'Upload date',
    cat: 'Data de pujada',
    eus: 'Igoera data',
    pt: 'Data de upload'
  },
  NUMBER_OF_PAGES: {
    es: 'Número de páginas',
    en: 'Number of pages',
    cat: 'Nombre de pàgines',
    eus: 'Orrialde kopurua',
    pt: 'Número de páginas'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Número de preguntas',
    en: 'Number of questions',
    cat: 'Nombre de preguntes',
    eus: 'Galdera kopurua',
    pt: 'Número de questões'
  },
  LAYOUT: {
    es: 'Maquetado',
    en: 'Layout',
    cat: 'Maquetació',
    eus: 'Diseinua',
    pt: 'Layout'
  },
  PUBLISHED: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua',
    pt: 'Publicado'
  },
  FINAL_REVISION: {
    es: 'Revisión final',
    en: 'Final revision',
    cat: 'Revisió final',
    eus: 'Azken errepaso',
    pt: 'Revisão final'
  },
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Proba',
    pt: 'Prova'
  },
  VIDEOS: {
    es: 'Vídeos',
    en: 'Videos',
    cat: 'Vídeos',
    eus: 'Bideoak',
    pt: 'Vídeos'
  },
  DOCUMENTS: {
    es: 'Apuntes',
    en: 'Documents',
    cat: 'Apunts',
    eus: 'Dokumentuak',
    pt: 'Documentos'
  },
  REVIEWED: {
    es: 'Revisada',
    en: 'Reviewed',
    cat: 'Revisada',
    eus: 'Errebisatua',
    pt: 'Revisado'
  },
  NOT_REVIEWED: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Aztertzen',
    pt: 'Em revisão'
  },
  QUESTIONS: {
    es: 'Nº de Preguntas',
    en: 'Nº of Questions',
    cat: 'Núm. de Preguntes',
    eus: 'Galdera zenbakia',
    pt: 'Número de questões'
  },
  SOUND: {
    es: 'Sonido',
    en: 'Sound',
    cat: 'So',
    eus: 'Soinua',
    pt: 'Sons'
  },
  IMAGE: {
    es: 'Imagen',
    en: 'Image',
    cat: 'Imatge',
    eus: 'Irudia',
    pt: 'Imagem'
  },
  SETTINGS: {
    es: 'Ajustes',
    en: 'Settings',
    cat: 'Configuració',
    eus: 'Ezarpenak',
    pt: 'Configurações'
  },
  SORT: {
    es: 'Clase',
    en: 'Class',
    cat: 'Classe',
    eus: 'Klase',
    pt: 'Classe'
  },
  HISTORIC: {
    es: 'Tabla de gestión',
    en: 'Management table',
    cat: 'Taula de gestió',
    eus: 'Kudeaketa taula',
    pt: 'Tabela de gestão'
  },
  DOWNLOAD_ALL: {
    es: 'Exportar todo',
    en: 'Export all',
    cat: 'Exportar tot',
    eus: 'Denak esportatu',
    pt: 'Exportar todos'
  },
  DOWNLOAD: {
    es: 'Descargar',
    en: 'Download',
    cat: 'Descarregar',
    eus: 'Deskargatu',
    pt: 'Descarregar'
  },
  PROCESSING_DATA: {
    es: 'Procesando los datos...',
    en: 'Processing data...',
    cat: 'Processant les dades...',
    eus: 'Datuak prozesatzen...',
    pt: 'Processando os dados...'
  },
  DOWNLOAD_SELECTED: {
    es: 'Descargar seleccionados',
    en: 'Download selected',
    cat: 'Descarregar seleccionats',
    eus: 'Hautatutakoak deskargatu',
    pt: 'Descarregar selecionados'
  },
  TEACHER_EMAIL: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  VERTICAL: {
    es: 'Vertical',
    en: 'Vertical',
    cat: 'Vertical',
    eus: 'Bertikala',
    pt: 'Vertical'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak',
    pt: 'Estatísticas'
  },
  STUDENT_TIME_WIDGET_TITLE: {
    es: 'Tiempo',
    en: 'Time',
    cat: 'Temps',
    eus: 'Denbora',
    pt: 'Tempo'
  },
  STUDENT_TIME_WIDGET_SUBTITLE: {
    es: 'De uso en la plataforma',
    en: 'Using the platform',
    cat: 'D`ús a la plataforma',
    eus: 'Plataforman erabiliz',
    pt: 'Usando a plataforma'
  },
  TOTAL_TICKETS: {
    es: 'total',
    en: 'total',
    cat: 'total',
    eus: 'guztiak',
    pt: 'total'
  },
  SELECT_SECTION: {
    es: 'Seleccionar sección',
    en: 'Select section',
    cat: 'Seleccionar secció',
    eus: 'Aukeratu atala',
    pt: 'Selecionar secção'
  },
  QUIZ_TIP: {
    es: '<a>Consejo:</a><b> usa las teclas ← y → para moverte más rápido entre las preguntas.</b>',
    en: '<a>Tip:</a><b> use the ← and → keys to move faster between questions.</b>',
    cat: '<a>Consell:</a><b> utilitza les tecles ← i → per moure`t més ràpid entre les preguntes.</b>',
    eus: '<a>Aholkua:</a><b> erabili ← eta → teklatuak galdera artean azkarrago mugitzeko.</b>',
    pt: '<a>Conselho:</a><b> use as teclas ← e → para mover-se mais rapidamente entre as questões.</b>'
  },
  WELCOME_SUBTITLE_1: {
    es: 'Soy <a>Umy</a>, tu compi en esta lucha hacia tus objetivos. ⚡⚡️<br>Espero que traigas muchos sandwiches de queso 🤤<br>porque te necesitamos con muuucha energía.<br>¡Vamos a por todas! 😎',
    en: "I am <a>Umy</a>, your partner in this fight towards your goals. ⚡⚡️<br>I hope you bring many cheese sandwiches 🤤<br>because we need you with a lot of energy.<br>Let's go for it! 😎",
    cat: 'Sóc <a>Umy</a>, el teu company en aquesta lluita cap als teus objectius. ⚡⚡️<br>Espero que portis molts entrepans de formatge 🤤<br>perquè et necessitem amb muuucha energia.<br>Anem a per totes! 😎',
    eus: 'Nire izena <a>Umy</a> da, zure laguna helburu horietara heltzen. ⚡⚡️<br>Espero dutela gazta-odolostu asko 🤤<br>zure energia behar dugu.<br>¡Hemen gaude! 😎',
    pt: 'Sou <a>Umy</a>, seu parceiro nesta luta para alcançar seus objetivos. ⚡⚡️<br>Espero que traga muitos sanduíches de queijo 🤤<br>porque precisamos de você com muita energia.<br>Vamos lutar! 😎'
  },
  ADD_POINTS: {
    es: '+ {{ points }} puntos',
    en: '+ {{ points }} points',
    cat: '+ {{ points }} punts',
    eus: '+ {{ points }} puntu',
    pt: '+ {{ points }} pontos'
  },
  CLAIM_POINTS: {
    es: 'Recibir mis puntos',
    en: 'Claim my points',
    cat: 'Rebre els meus punts',
    eus: 'Nire puntuak jaso',
    pt: 'Reclamar meus pontos'
  },
  CLAIM_NUMBER_OF_POINTS: {
    es: 'RECLAMAR {{ points }} PUNTOS',
    en: 'CLAIM {{ points }} POINTS',
    cat: 'RECLAMAR {{ points }} PUNTS',
    eus: '{{ points }} PUNTU JASO',
    pt: 'RECLAMAR {{ points }} PONTOS'
  },
  QUESTIONS_TOTAL: {
    es: '<a>Total:</a> {{total}} preguntas',
    en: '<a>Total:</a> {{total}} questions',
    cat: '<a>Total:</a> {{total}} preguntes',
    eus: '<a>Totala:</a> {{total}} galdera',
    pt: '<a>Total:</a> {{total}} questões'
  },
  ANSWERS_TOTAL: {
    es: '<a>{{answer}}:</a> <b>{{total}}</b>',
    en: '<a>{{answer}}:</a> <b>{{total}}</b>',
    cat: '<a>{{answer}}:</a> <b>{{total}}</b>',
    eus: '<a>{{answer}}:</a> <b>{{total}}</b>',
    pt: '<a>{{answer}}:</a> <b>{{total}}</b>'
  },
  QUIZ_MODE: {
    es: 'Tipo',
    en: 'Mode',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Modo'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera',
    pt: 'Atividade'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Libre',
    en: 'Free',
    cat: 'Lliure',
    eus: 'Askea',
    pt: 'Livre'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Simulacro',
    en: 'Practice',
    cat: 'Simulacre',
    eus: 'Simulakro',
    pt: 'Simulação'
  },
  SELECTED_QUESTIONS_TO_ADD: {
    es: '<a>{{total}} preguntas</a> seleccionadas',
    en: '<a>{{total}} questions</a> selected',
    cat: '<a>{{total}} preguntes</a> seleccionades',
    eus: '<a>{{total}} galdera</a> hautatuak',
    pt: '<a>{{total}} questões</a> selecionadas'
  },
  [ECountry.AD]: {
    es: 'Andorra',
    en: 'Andorra',
    cat: 'Andorra',
    eus: 'Andorra',
    pt: 'Andorra'
  },
  [ECountry.AR]: {
    es: 'Argentina',
    en: 'Argentina',
    cat: 'Argentina',
    eus: 'Argentina',
    pt: 'Argentina'
  },
  [ECountry.BO]: {
    es: 'Bolivia',
    en: 'Bolivia',
    cat: 'Bolívia',
    eus: 'Bolivia',
    pt: 'Bolívia'
  },
  [ECountry.BR]: {
    es: 'Brasil',
    en: 'Brazil',
    cat: 'Brasil',
    eus: 'Brasil',
    pt: 'Brasil'
  },
  [ECountry.CL]: {
    es: 'Chile',
    en: 'Chile',
    cat: 'Xile',
    eus: 'Txile',
    pt: 'Chile'
  },
  [ECountry.CO]: {
    es: 'Colombia',
    en: 'Colombia',
    cat: 'Colòmbia',
    eus: 'Kolonbia',
    pt: 'Colômbia'
  },
  [ECountry.CR]: {
    es: 'Costa Rica',
    en: 'Costa Rica',
    cat: 'Costa Rica',
    eus: 'Costa Rica',
    pt: 'Costa Rica'
  },
  [ECountry.CU]: {
    es: 'Cuba',
    en: 'Cuba',
    cat: 'Cuba',
    eus: 'Kuba',
    pt: 'Cuba'
  },
  [ECountry.DO]: {
    es: 'República Dominicana',
    en: 'Dominican Republic',
    cat: 'República Dominicana',
    eus: 'Dominikar Errepublika',
    pt: 'República Dominicana'
  },
  [ECountry.EC]: {
    es: 'Ecuador',
    en: 'Ecuador',
    cat: 'Equador',
    eus: 'Ekuador',
    pt: 'Equador'
  },
  [ECountry.ES]: {
    es: 'España',
    en: 'Spain',
    cat: 'Espanya',
    eus: 'Espainia',
    pt: 'Espanha'
  },
  [ECountry.FR]: {
    es: 'Francia',
    en: 'France',
    cat: 'França',
    eus: 'Frantzia',
    pt: 'França'
  },
  [ECountry.GF]: {
    es: 'Guayana Francesa',
    en: 'French Guiana',
    cat: 'Guaiana Francesa',
    eus: 'Guyana Frantsesa',
    pt: 'Guiana Francesa'
  },
  [ECountry.MA]: {
    es: 'Marruecos',
    en: 'Morocco',
    cat: 'Marroc',
    eus: 'Maroko',
    pt: 'Marrocos'
  },

  [ECountry.GT]: {
    es: 'Guatemala',
    en: 'Guatemala',
    cat: 'Guatemala',
    eus: 'Guatemala',
    pt: 'Guatemala'
  },
  [ECountry.HT]: {
    es: 'Haiti',
    en: 'Haiti',
    cat: 'Haití',
    eus: 'Haiti',
    pt: 'Haiti'
  },
  [ECountry.HN]: {
    es: 'Honduras',
    en: 'Honduras',
    cat: 'Hondures',
    eus: 'Honduras',
    pt: 'Honduras'
  },
  [ECountry.JM]: {
    es: 'Jamaica',
    en: 'Jamaica',
    cat: 'Jamaica',
    eus: 'Jamaika',
    pt: 'Jamaica'
  },
  [ECountry.MX]: {
    es: 'México',
    en: 'Mexico',
    cat: 'Mèxic',
    eus: 'Mexiko',
    pt: 'México'
  },
  [ECountry.NI]: {
    es: 'Nicaragua',
    en: 'Nicaragua',
    cat: 'Nicaragua',
    eus: 'Nikaragua',
    pt: 'Nicarágua'
  },
  [ECountry.PA]: {
    es: 'Panamá',
    en: 'Panama',
    cat: 'Panamà',
    eus: 'Panama',
    pt: 'Panamá'
  },
  [ECountry.PY]: {
    es: 'Paraguay',
    en: 'Paraguay',
    cat: 'Paraguai',
    eus: 'Paraguai',
    pt: 'Paraguai'
  },
  [ECountry.PE]: {
    es: 'Perú',
    en: 'Peru',
    cat: 'Perú',
    eus: 'Peru',
    pt: 'Peru'
  },
  [ECountry.PR]: {
    es: 'Puerto Rico',
    en: 'Puerto Rico',
    cat: 'Puerto Rico',
    eus: 'Puerto Rico',
    pt: 'Porto Rico'
  },
  [ECountry.PT]: {
    es: 'Portugal',
    en: 'Portugal',
    cat: 'Portugal',
    eus: 'Portugal',
    pt: 'Portugal'
  },
  [ECountry.SR]: {
    es: 'Surinam',
    en: 'Suriname',
    cat: 'Surinam',
    eus: 'Surinam',
    pt: 'Suriname'
  },
  [ECountry.SV]: {
    es: 'El Salvador',
    en: 'El Salvador',
    cat: 'El Salvador',
    eus: 'El Salvador',
    pt: 'El Salvador'
  },
  [ECountry.UY]: {
    es: 'Uruguay',
    en: 'Uruguay',
    cat: 'Uruguai',
    eus: 'Uruguai',
    pt: 'Uruguai'
  },
  [ECountry.VE]: {
    es: 'Venezuela',
    en: 'Venezuela',
    cat: 'Veneçuela',
    eus: 'Venezuela',
    pt: 'Venezuela'
  },
  [EYearMonths.JANUARY]: {
    es: 'Enero',
    en: 'January',
    cat: 'Gener',
    eus: 'Urtarrila',
    pt: 'Janeiro'
  },
  [EYearMonths.FEBRUARY]: {
    es: 'Febrero',
    en: 'February',
    cat: 'Febrer',
    eus: 'Otsaila',
    pt: 'Fevereiro'
  },
  [EYearMonths.MARCH]: {
    es: 'Marzo',
    en: 'March',
    cat: 'Març',
    eus: 'Martxoa',
    pt: 'Março'
  },
  [EYearMonths.APRIL]: {
    es: 'Abril',
    en: 'April',
    cat: 'Abril',
    eus: 'Apirila',
    pt: 'Abril'
  },
  [EYearMonths.MAY]: {
    es: 'Mayo',
    en: 'May',
    cat: 'Maig',
    eus: 'Maiatza',
    pt: 'Maio'
  },
  [EYearMonths.JUNE]: {
    es: 'Junio',
    en: 'June',
    cat: 'Juny',
    eus: 'Ekaina',
    pt: 'Junho'
  },
  [EYearMonths.JULY]: {
    es: 'Julio',
    en: 'July',
    cat: 'Juliol',
    eus: 'Uztaila',
    pt: 'Julho'
  },
  [EYearMonths.AUGUST]: {
    es: 'Agosto',
    en: 'August',
    cat: 'Agost',
    eus: 'Abuztua',
    pt: 'Agosto'
  },
  [EYearMonths.SEPTEMBER]: {
    es: 'Septiembre',
    en: 'September',
    cat: 'Setembre',
    eus: 'Iraila',
    pt: 'Setembro'
  },
  [EYearMonths.OCTOBER]: {
    es: 'Octubre',
    en: 'October',
    cat: 'Octubre',
    eus: 'Urria',
    pt: 'Outubro'
  },
  [EYearMonths.NOVEMBER]: {
    es: 'Noviembre',
    en: 'November',
    cat: 'Novembre',
    eus: 'Azaroa',
    pt: 'Novembro'
  },
  [EYearMonths.DECEMBER]: {
    es: 'Diciembre',
    en: 'December',
    cat: 'Desembre',
    eus: 'Abendua',
    pt: 'Dezembro'
  },
  YOUR_EXAM_GROUPS_EMPTY: {
    es: 'No estás apuntado a ninguna convocatoria',
    en: 'You are not registered for any exam group',
    cat: 'No estàs apuntat a cap convocatòria',
    eus: 'Ez zaude inor ere apuntatu',
    pt: 'Não está inscrito em nenhuma convocatória'
  },
  JOIN_EXAM_GROUP: {
    es: 'Unirme a una convocatoria',
    en: 'Join exam group',
    cat: 'Unir-me a convocatòria',
    eus: 'Taldean apuntatu',
    pt: 'Inscreva-se em uma convocatória'
  },
  NEW_CHAT_MESSAGE: {
    es: 'Nuevo mensaje',
    en: 'New message',
    cat: 'Nou missatge',
    eus: 'Mezua berria',
    pt: 'Nova mensagem'
  },
  HUBSPOT_CONTACT: {
    es: 'Contacto de Hubspot',
    en: 'Hubspot contact',
    cat: 'Contacte de Hubspot',
    eus: 'Hubspot kontaktua',
    pt: 'Contacto de Hubspot'
  },
  SELECT_HUBSPOT_CONTACT: {
    es: 'Seleccionar contacto de Hubspot',
    en: 'Select Hubspot contact',
    cat: 'Seleccionar contacte de Hubspot',
    eus: 'Hubspot kontaktua hautatu',
    pt: 'Selecionar contacto de Hubspot'
  },
  GO_TO_HUBSPOT_CONTACT: {
    es: 'Ir al contacto asociado de hubspot',
    en: 'Go to the associated hubspot contact',
    cat: 'Anar al contacte associat de hubspot',
    eus: 'Joan hubspot kontaktu batera',
    pt: 'Ir para o contacto associado de Hubspot'
  },
  [EActivityAuditStatus.CORRECT]: {
    es: 'Correcto',
    en: 'Correct',
    cat: 'Correcte',
    eus: 'Zuzena',
    pt: 'Correto'
  },
  [EActivityAuditStatus.OBSOLETE]: {
    es: 'Obsoleto',
    en: 'Obsolete',
    cat: 'Obsolet',
    eus: 'Zaharkitua',
    pt: 'Obsoleto'
  },
  [EActivityAuditStatus.NON_COMPLIANT_STANDARDS]: {
    es: 'No cumple estándares',
    en: 'Does not meet standards',
    cat: 'No compleix estàndards',
    eus: 'Estandariek ez ditu betetzen',
    pt: 'Não cumpre os padrões'
  },
  [EActivityAuditStatus.OLD_FORMAT]: {
    es: 'Formato antiguo',
    en: 'Old format',
    cat: 'Format antic',
    eus: 'Formatu zaharra',
    pt: 'Formato antigo'
  },
  STUDENT_INFO: {
    es: 'Información del alumno',
    en: 'Student info',
    cat: 'Informació de l`alumne',
    eus: 'Ikaslearen informazioa',
    pt: 'Informação do aluno'
  },
  STUDENT_FIRST_PAYMENT: {
    es: 'Fecha de inscripción (primer pago)',
    en: 'Registration date (first payment)',
    cat: 'Data d`inscripció (primer pagament)',
    eus: 'Izen-emate data (lehenengo ordaina)',
    pt: 'Data de inscrição (primeiro pagamento)'
  },
  TAG_ASSOCIATED: {
    es: 'Categoría (tag) asociada',
    en: 'Associated category (tag)',
    cat: 'Categoria (tag) associada',
    eus: 'Kategoria (tag) lotura',
    pt: 'Categoria (tag) associada'
  },
  RESEND_KEYS: {
    es: 'Regenerar y enviar claves de acceso',
    en: 'Regenerate and send access keys',
    cat: 'Regenerar i enviar claus d`accés',
    eus: 'Berriz sortu eta bidali sarbide gakoak',
    pt: 'Regenerar e enviar chaves de acesso'
  },
  IMPERSONATE_STUDENT: {
    es: 'Impersonar al alumno',
    en: 'Impersonate student',
    cat: 'Impersonar a l`alumne',
    eus: 'Ikaslearen pertsona batu',
    pt: 'Simular aluno'
  },
  HUBSPOT_CONTACT_NOT_FOUND: {
    es: 'No se ha asociado este alumno con ningún contacto de Hubspot',
    en: 'No se ha asociado este alumno con ningún contacto de Hubspot',
    cat: 'No se ha asociado aquest alumne amb cap contacte de Hubspot',
    eus: 'Ikaslearen hubspot kontaktua ez da aurkitu',
    pt: 'Não foi associado este aluno a nenhum contacto de Hubspot'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena',
    pt: 'Resumo'
  },
  TRAINING_INFO: {
    es: 'Datos de tu formación',
    en: 'Your training data',
    cat: 'Dades de la teva formació',
    eus: 'Zure prestakuntzaren datuak',
    pt: 'Dados da sua formação'
  },
  ALL_EXAM_GROUPS: {
    es: 'Todas las convocatorias',
    en: 'All exam groups',
    cat: 'Totes les convocatòries',
    eus: 'Azterketa talde guztiak',
    pt: 'Todas as convocatórias'
  },
  NOTIFICATION_SETTINGS: {
    es: 'Configuración de notificaciones',
    en: 'Notification settings',
    cat: 'Configuració de notificacions',
    eus: 'Jakinarazpenen ezarpenak',
    pt: 'Configurações de notificações'
  },
  UCADEMY_COMMUNITY: {
    es: 'Comunidad de Ucademy',
    en: 'Ucademy community',
    cat: 'Comunitat de Ucademy',
    eus: 'Ucademy komunitatea',
    pt: 'Comunidade de Ucademy'
  },
  PUBLISHED_AT: {
    es: 'Publicada el: {{ date }}',
    en: 'Published at: {{ date }}',
    cat: 'Publicada el: {{ date }}',
    eus: 'Argitaratua: {{ date }}',
    pt: 'Publicado em: {{ date }}'
  },
  VIEW_MORE_EXAM_GROUPS: {
    es: 'Ver más convocatorias',
    en: 'View more exam groups',
    cat: 'Veure més convocatòries',
    eus: 'Ikusi gehiago azterketak',
    pt: 'Ver mais convocatórias'
  },
  STREAK_DAYS: {
    es: 'Días seguidos accediendo',
    en: 'Consecutive days accessing',
    cat: 'Dies seguits accedint',
    eus: 'Egun jarraituak sartzen',
    pt: 'Dias consecutivos acessando'
  },
  DAYS_one: {
    es: '1 día',
    en: '1 day',
    cat: '1 dia',
    eus: '1 egun',
    pt: '1 dia'
  },
  DAYS_other: {
    es: '{{count}} días',
    en: '{{count}} days',
    cat: '{{count}} dies',
    eus: '{{count}} egun',
    pt: '{{count}} dias'
  },
  CHOOSE_AN_OPTION: {
    es: 'Elige una opción',
    en: 'Choose an option',
    cat: 'Tria una opció',
    eus: 'Aukeratu aukera bat',
    pt: 'Escolher uma opção'
  },
  [EStudyField.SCIENCES]: {
    es: 'Ciencias',
    en: 'Sciences',
    cat: 'Ciències',
    eus: 'Zientziak',
    pt: 'Ciências'
  },
  [EStudyField.SOCIALS]: {
    es: 'Sociales',
    en: 'Socials',
    cat: 'Socials',
    eus: 'Sozialak',
    pt: 'Socials'
  },
  [EStudyField.HUMANITIES]: {
    es: 'Humanidades',
    en: 'Humanities',
    cat: 'Humanitats',
    eus: 'Gizarte zientziak',
    pt: 'Humanidades'
  },
  [EStudyField.ARTS]: {
    es: 'Artes',
    en: 'Arts',
    cat: 'Arts',
    eus: 'Arteak',
    pt: 'Artes'
  },
  THIS_DATA_IS_PRIVATE: {
    es: 'Este dato es completamente privado y lo usaremos solo con fines estadísticos',
    en: 'This data is completely private and we will use it only for statistical purposes',
    cat: 'Aquesta dada és completament privada i la farem servir només amb finalitats estadístiques',
    eus: 'Datu hau erabat pribatua da eta estatistika helburuetarako erabiliko dugu soilik',
    pt: 'Este dado é completamente privado e usaremos apenas para fins estatísticos'
  },
  [EVerticalNames.UNI]: {
    es: 'Acceso a la Universidad',
    en: 'University access',
    cat: 'Accés a la Universitat',
    eus: 'Unibertsitatean sartzeko',
    pt: 'Acesso à Universidade'
  },
  [EVerticalNames.OPOS]: {
    es: 'Oposiciones',
    en: 'Oppositions',
    cat: 'Oposicions',
    eus: 'Oposizioak',
    pt: 'Oposições'
  },
  [EVerticalNames.FP]: {
    es: 'Formación Profesional',
    en: 'Professional training',
    cat: 'Formació Professional',
    eus: 'Lanbide Heziketa',
    pt: 'Formação Profissional'
  },
  [EVerticalNames.WKC]: {
    es: 'Workcamps',
    en: 'Workcamps',
    cat: 'Workcamps',
    eus: 'Workcamps',
    pt: 'Workcamps'
  },
  [ETimePreference.MORNING]: {
    es: 'Por la mañana',
    en: 'In the morning',
    cat: 'Al matí',
    eus: 'Goizean',
    pt: 'Pela manhã'
  },
  [ETimePreference.NOON]: {
    es: 'Al mediodía',
    en: 'At noon',
    cat: 'Al migdia',
    eus: 'Egunerako',
    pt: 'À meio-dia'
  },
  [ETimePreference.AFTERNOON]: {
    es: 'Por la tarde',
    en: 'In the afternoon',
    cat: 'A la tarda',
    eus: 'Arratsaldean',
    pt: 'À tarde'
  },
  [ETimePreference.EVENING]: {
    es: 'A última hora',
    en: 'At the last minute',
    cat: 'A última hora',
    eus: 'Gauan',
    pt: 'À noite'
  },
  HOW_DID_YOU_DISCOVER_US: {
    es: 'Ficha confirmada. Dinos {{name}}: ¿Cómo nos conociste por <a>primera vez?</a>',
    en: 'Confirmed tab. Tell us {{name}}: How did you find us for the <a>first time?</a>',
    cat: 'Fitxa confirmada. Digues-nos {{name}}: Com ens vas conèixer per <a>primera vegada?</a>',
    eus: 'Fitxa baieztatua. Esan {{name}}: Nola ezagutu zaitugu <a>lehenengo aldiz?</a>',
    pt: 'Ficha confirmada. Diz-nos {{name}}: Como nos conheceu pela <a>primeira vez?</a>'
  },
  STUDENT_SPECIFIC_INFORMATION: {
    es: 'Datos específicos',
    en: 'Specific data',
    cat: 'Dades específiques',
    eus: 'Berezko datuak',
    pt: 'Dados específicos'
  },
  LAST_14_DAYS: {
    es: 'Últimos 14 días',
    en: 'Last 14 days',
    cat: 'Últims 14 dies',
    eus: 'Azken 14 egunak',
    pt: 'Últimos 14 dias'
  },
  NUMBER_OF_ACTIVITIES: {
    es: '{{count}} actividades',
    en: '{{count}} activities',
    cat: '{{count}} activitats',
    eus: '{{count}} eskubideak',
    pt: '{{count}} atividades'
  },
  COMPLETED_PREVIEW: {
    es: 'completadas',
    en: 'completed',
    cat: 'completades',
    eus: 'betetzen',
    pt: 'completados'
  },
  NUMBER_OF_HOURS: {
    es: '{{count}} horas',
    en: '{{count}} hours',
    cat: '{{count}} hores',
    eus: '{{count}} ordu',
    pt: '{{count}} horas'
  },
  IN_APP: {
    es: 'en la plataforma',
    en: 'in the app',
    cat: 'en la plataforma',
    eus: 'plataforman',
    pt: 'na plataforma'
  },
  NUMBER_OF_TESTS: {
    es: '{{count}} tests',
    en: '{{count}} tests',
    cat: '{{count}} tests',
    eus: '{{count}} testak',
    pt: '{{count}} testes'
  },
  DONE: {
    es: 'realizados',
    en: 'done',
    cat: 'realitzats',
    eus: 'egindakoak',
    pt: 'realizados'
  },
  ACTIVITIES_COMPLETED_IN_LAST_14_DAYS: {
    es: 'Actividades completadas en los últimos 14 días',
    en: 'Activities completed in the last 14 days',
    cat: 'Activitats completades en els darrers 14 dies',
    eus: 'Eskubideak azken 14 egunetan betetzen',
    pt: 'Atividades completadas nos últimos 14 dias'
  },
  DONE_ON: {
    es: 'Realizada el',
    en: 'Done on',
    cat: 'Realitzada el',
    eus: 'Eskubidearen eguna',
    pt: 'Realizada em'
  },
  PLANIFICATION_RESULT_CONTENT_UNDER_TIME: {
    es: 'Tendrías que estudiar <a>{{minutes}} minutos al día.</a>',
    en: 'You would have to study <a>{{minutes}} minutes a day.</a>',
    cat: 'Tindries d`estudiar <a>{{minutes}} minuts al dia.</a>',
    eus: 'Egunero <a>{{minutes}} minutu</a> ikasi beharko zenituzke.',
    pt: 'Teria que estudar <a>{{minutes}} minutos por dia.</a>'
  },
  PLANIFICATION_RESULT_CONTENT_OVER_TIME: {
    es: 'Tendrías que estudiar <a>{{hours}} horas y {{minutes}} minutos al día.</a>',
    en: 'You would have to study <a>{{hours}} hours and {{minutes}} minutes a day.</a>',
    cat: 'Tindries d`estudiar <a>{{hours}} hores i {{minutes}} minuts al dia.</a>',
    eus: 'Egunero <a>{{hours}} ordu eta {{minutes}} minutu</a> ikasi beharko zenituzke.',
    pt: 'Teria que estudar <a>{{hours}} horas e {{minutes}} minutos por dia.</a>'
  },
  TUTOR: {
    es: 'Tutor',
    en: 'Tutor',
    cat: 'Tutor',
    eus: 'Tutorea',
    pt: 'Tutora'
  },
  TUTOR_NOT_ASSIGNED: {
    es: 'Tutor no asignado',
    en: 'Tutor not assigned',
    cat: 'Tutor no assignat',
    eus: 'Tutorea esleitua',
    pt: 'Tutora não atribuída'
  },
  SELECT_TUTOR: {
    es: 'Seleccionar tutor',
    en: 'Select tutor',
    cat: 'Seleccionar tutor',
    eus: 'Aukeratu tutorea',
    pt: 'Selecionar tutora'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol',
    pt: 'Rol'
  },
  ONLINE_USERS: {
    es: 'Usuarios online',
    en: 'Online users',
    cat: 'Usuaris online',
    eus: 'Online erabiltzaileak',
    pt: 'Usuários online'
  },
  LAST_SESSION_LOG: {
    es: 'Último registro de sesión',
    en: 'Last session log',
    cat: 'Últim registre de sessió',
    eus: 'Azken inprimatzen den erregistro',
    pt: 'Último registro de sessão'
  },
  NO_SESSION_LOGS: {
    es: 'No hay registros de sesión',
    en: 'No session logs',
    cat: 'No hi ha registres de sessió',
    eus: 'Ez da erregistro inprimatzen',
    pt: 'Não há registros de sessão'
  },
  DOWNLOAD_APP: {
    es: 'Descarga la app',
    en: 'Download the app',
    cat: "Descarrega l'app",
    eus: 'Deskargatu aplikazioa',
    pt: 'Descarregar a aplicação'
  },
  ALL_TESTS: {
    es: 'Todos los tests',
    en: 'All tests',
    cat: 'Tots els tests',
    eus: 'Guztiak testak',
    pt: 'Todos os testes'
  },
  REVIEW_FAILURES: {
    es: 'Repaso de fallos',
    en: 'Review failures',
    cat: 'Revisió de falles',
    eus: 'Hutsak berrikustea',
    pt: 'Revisão de erros'
  },
  STATISTICS: {
    es: 'Estadísticas',
    en: 'Statistics',
    cat: 'Estadístiques',
    eus: 'Estatistikak',
    pt: 'Estatísticas'
  },
  NEW_APP: {
    es: '¡Tenemos nueva app!',
    en: 'We have a new app!',
    cat: 'Tenim una nova app!',
    eus: 'Aplikazio berria daukagu!',
    pt: 'Temos uma nova aplicação!'
  },
  DOWNLOAD_APP_NOW: {
    es: 'Descárgala ahora:',
    en: 'Download it now:',
    cat: 'Descarrega-la ara:',
    eus: 'Deskargatu orain:',
    pt: 'Descarregue-a agora:'
  },
  GET_IT_ON_GOOGLE_PLAY: {
    es: '¡Consíguela en Google Play!',
    en: 'Get it on Google Play!',
    cat: 'Aconsegueix-la a Google Play!',
    eus: 'Lortu Google Play-en!',
    pt: 'Obtenha-a no Google Play!'
  },
  DOWNLOAD_ON_THE_APP_STORE: {
    es: '¡Descárgala en la App Store!',
    en: 'Download it on the App Store!',
    cat: "Descarrega-la a l'App Store!",
    eus: 'Deskargatu App Storean!',
    pt: 'Descarregue-a na App Store!'
  },
  CHANGE_STATUS: {
    es: 'Cambiar estado',
    en: 'Change status',
    cat: 'Canviar estat',
    eus: 'Egoera aldatu',
    pt: 'Alterar estado'
  },
  CHANGE_STATUS_CONFIRMATION: {
    es: '¿Estás seguro de que deseas cambiar el estado de {{count}} estudiantes?',
    en: 'Are you sure you want to change the status of {{count}} students?',
    cat: 'Estàs segur que vols canviar l`estat d`aquests {{count}} alumnes?',
    eus: 'Ziur {{count}} ikasleen egoera aldatu nahi duzula?',
    pt: 'Tem certeza que deseja alterar o estado de {{count}} alunos?'
  },
  PLANIFICATION_EMPTY: {
    es: 'Crea tu planificación del estudio para saber qué tienes que hacer cada día',
    en: 'Create your study plan to know what you have to do each day',
    cat: 'Crea la teva planificació del estudi per saber què has de fer cada dia',
    eus: 'Eskubide-planifikazioa sortu zure eguneroko egin beharko duzun gauzak ikusteko',
    pt: 'Crie o seu plano de estudo para saber o que tem de fazer cada dia'
  },
  PLANIFICATION_SHOW: {
    es: 'Mostrar planificación',
    en: 'Show planification',
    cat: 'Mostrar planificació',
    eus: 'Erakutsi planifikazioa',
    pt: 'Mostrar planificação'
  },
  PLANIFICATION_HIDE: {
    es: 'Ocultar planificación',
    en: 'Hide planification',
    cat: 'Ocultar planificació',
    eus: 'Ez erakutsi planifikazioa',
    pt: 'Esconder planificação'
  },
  PLANIFICATION_NEW: {
    es: 'Planificar mi estudio',
    en: 'Plan my study',
    cat: 'Planificar el meu estudi',
    eus: 'Eskubide-planifikazioa sortu',
    pt: 'Criar plano de estudo'
  },
  PLANIFICATION_CURRENT_WEEK_INFO: {
    es: 'Semana {{week}}, día {{day}}',
    en: 'Week {{week}}, day {{day}}',
    cat: 'Setmana {{week}}, dia {{day}}',
    eus: 'Aste {{week}}, egun {{day}}',
    pt: 'Semana {{week}}, dia {{day}}'
  },
  PLANIFICATION_VIEW_ALL: {
    es: 'Ver planificación',
    en: 'View planification',
    cat: 'Veure planificació',
    eus: 'Erakutsi planifikazioa',
    pt: 'Ver planificação'
  },
  PLANIFICATION_HOW_GOING: {
    es: '¿Cómo voy?',
    en: 'How am I going?',
    cat: 'Com vaig?',
    eus: 'Nola egongo?',
    pt: 'Como estou indo?'
  },
  PLANIFICATION_THIS_WEEK_STUDY: {
    es: 'Esta semana estudias:',
    en: 'This week you study:',
    cat: 'Aquesta setmana estudies:',
    eus: 'Aste hau zuk egiten duzula:',
    pt: 'Esta semana você estuda:'
  },
  PLANIFICATION_REST_DAY: {
    es: 'Vacaciones',
    en: 'Holidays',
    cat: 'Vacances',
    eus: 'Urtebeteak',
    pt: 'Férias'
  },
  PLANIFICATION_ACTIVITIES_one: {
    es: '{{count}} actividad por completar',
    en: '{{count}} activity to complete',
    cat: '{{count}} activitat per completar',
    eus: '{{count}} eskubide betetu behar',
    pt: '{{count}} atividade por completar'
  },
  PLANIFICATION_ACTIVITIES_other: {
    es: '{{count}} actividades por completar',
    en: '{{count}} activities to complete',
    cat: '{{count}} activitats per completar',
    eus: '{{count}} eskubide betetu behar',
    pt: '{{count}} atividades por completar'
  },
  PLANIFICATION_MORE_SECTIONS: {
    es: '+ {{count}} temas más',
    en: '+ {{count}} more sections',
    cat: '+ {{count}} temes més',
    eus: '+ {{count}} gehiago temak',
    pt: '+ {{count}} temas mais'
  },
  PLANIFICATION_ALL_DAY_COMPLETED: {
    es: 'Todas las actividades completadas',
    en: 'All activities completed',
    cat: 'Totes les activitats completades',
    eus: 'Eskubide guztiak betetzen',
    pt: 'Todas as atividades completadas'
  },
  PLANIFICATION_CAROUSEL_ITEM_MODAL_DESCRIPTION: {
    es: 'Te decimos lo que deberías hacer este día para cumplir con tu planificación. No olvides repasar después haciendo tests.',
    en: 'We tell you what you should do today to comply with your plan. Don`t forget to review after doing tests.',
    cat: 'Te decimos lo que deberías hacer este día para cumplir con tu planificación. No olvides repasar después haciendo tests.',
    eus: 'Egunero zuk egin beharko duzun gauza eta planifikazioa betetzea. Ez ahaztu gauzak egin eta testak egitea gero.',
    pt: 'Te dizemos o que deveria fazer hoje para cumprir com o seu plano. Não se esqueça de rever depois de fazer os testes.'
  },
  PROMOTION_CREATED_SUCCESSFULLY: {
    es: 'Promoción creada correctamente',
    en: 'Promotion created successfully',
    cat: 'Promoció creada correctament',
    eus: 'Promozioa ongi sortu',
    pt: 'Promoção criada com sucesso'
  },
  PROMOTION_UPDATED_SUCCESSFULLY: {
    es: 'Promoción actualizada correctamente',
    en: 'Promotion updated successfully',
    cat: 'Promoció actualitzada correctament',
    eus: 'Promozioa ongi eguneratua',
    pt: 'Promoção atualizada com sucesso'
  },
  PROMOTION_DELETED_SUCCESSFULLY: {
    es: 'Promoción eliminada correctamente',
    en: 'Promotion deleted successfully',
    cat: 'Promoció eliminada correctament',
    eus: 'Promozioa ongi ezabatua',
    pt: 'Promoção eliminada com sucesso'
  },
  EXAMS_SCORES: {
    es: 'Notas de examen',
    en: 'Exam scores',
    cat: "Notes d'examen",
    eus: 'Ikasle-notak',
    pt: 'Notas de exame'
  },
  SELECT_PROMOTION: {
    es: 'Selecciona promoción',
    en: 'Select promotion',
    cat: 'Selecciona promoció',
    eus: 'Aukeratu promozioa',
    pt: 'Selecionar promoção'
  },
  SELECT_TEST: {
    es: 'Selecciona test',
    en: 'Select test',
    cat: 'Selecciona test',
    eus: 'Aukeratu testua',
    pt: 'Selecionar teste'
  },
  EXAM_CREATED_SUCCESSFULLY: {
    es: 'Examen creado correctamente',
    en: 'Exam created successfully',
    cat: 'Examen creat correctament',
    eus: 'Ekzamen ongi sortu',
    pt: 'Exame criado com sucesso'
  },
  EXAM_UPDATED_SUCCESSFULLY: {
    es: 'Examen actualizado correctamente',
    en: 'Exam updated successfully',
    cat: 'Examen actualitzat correctament',
    eus: 'Ekzamen ongi eguneratua',
    pt: 'Exame atualizado com sucesso'
  },
  EXAM_DELETED_SUCCESSFULLY: {
    es: 'Examen eliminado correctamente',
    en: 'Exam deleted successfully',
    cat: 'Examen eliminat correctament',
    eus: 'Ekzamen ongi ezabatua',
    pt: 'Exame eliminado com sucesso'
  },
  TEACHER_SCORE: {
    es: 'Nota del profesor',
    en: 'Teacher score',
    cat: 'Nota del professor',
    eus: 'Ikasle-notak',
    pt: 'Nota do professor'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  EXAM_DATE: {
    es: 'Día del examen',
    en: 'Exam day',
    cat: 'Data de l`examen',
    eus: 'Ikasle-notak',
    pt: 'Dia do exame'
  },
  SEE_INFORMATION: {
    es: 'Ver información',
    en: 'See information',
    cat: 'Veure informació',
    eus: 'Ikusi informazioa',
    pt: 'Ver informação'
  },
  NOT_SCORED: {
    es: 'No puntuado',
    en: 'Not scored',
    cat: 'No puntuado',
    eus: 'Ez da puntuatua',
    pt: 'Não pontuado'
  },
  DELIVERABLE: {
    es: 'Documento entregable',
    en: 'Deliverable document',
    cat: 'Document entregable',
    eus: 'Entregatutako dokumentua',
    pt: 'Documento entregável'
  },
  ONSITE: {
    es: 'Examen presencial',
    en: 'Onsite exam',
    cat: 'Examen presencial',
    eus: 'Preskribatutako ikasle-notak',
    pt: 'Exame presencial'
  },
  ONLINE: {
    es: 'Examen online',
    en: 'Online exam',
    cat: 'Examen online',
    eus: 'Online ikasle-notak',
    pt: 'Exame online'
  },
  LAST_UPDATE: {
    es: 'Última actualización',
    en: 'Last update',
    cat: 'Última actualització',
    eus: 'Azken eguneraketa',
    pt: 'Última atualização'
  },
  FILE: {
    es: 'Archivo',
    en: 'File',
    cat: 'Fitxer',
    eus: 'Fitxategia',
    pt: 'Ficheiro'
  },
  EXAM_DAY: {
    es: '<a>Día del examen:</a> <b>{{date}}</b>',
    en: '<a>Exam day:</a> <b>{{date}}</b>',
    cat: "<a>Dia de l'examen:</a> <b>{{date}}</b>",
    eus: '<a>Ezamenaren eguna:</a> <b>{{date}}</b>',
    pt: '<a>Dia do exame:</a> <b>{{date}}</b>'
  },
  SCORE_CREATED_SUCCESSFULLY: {
    es: 'Nota puesta correctamente',
    en: 'Score created successfully',
    cat: 'Nota creada correctament',
    eus: 'Ikasle-notak ongi sortu',
    pt: 'Nota criada com sucesso'
  },
  SCORE_UPDATED_SUCCESSFULLY: {
    es: 'Nota actualizada correctamente',
    en: 'Score updated successfully',
    cat: 'Nota actualitzada correctament',
    eus: 'Ikasle-notak ongi eguneratua',
    pt: 'Nota atualizada com sucesso'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia',
    pt: 'Conteúdo'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formação'
  },
  HOW_IS_GOING_STATUS_GOOD_TITLE: {
    es: '¡Vas súper bien!',
    en: '¡You are doing great!',
    cat: '¡Estàs fent molt bé!',
    eus: '¡Eskubide guztiak betetzen!',
    pt: '¡Estás a fazer um excelente trabalho!'
  },
  HOW_IS_GOING_STATUS_GOOD_SUMMARY: {
    es: 'Prácticamente vas al día según tu planificación.',
    en: 'You are almost on track with your plan.',
    cat: 'Estàs gairebé en ruta amb la teva planificació.',
    eus: 'Planifikazioz zuzentzen zara.',
    pt: 'Está quase no caminho com o seu plano.'
  },
  HOW_IS_GOING_STATUS_GOOD_FOOTER: {
    es: '¡Sigue así y lo conseguirás!',
    en: '¡Keep it up and you will get it!',
    cat: '¡Mantente així i ho aconseguiràs!',
    eus: 'Jarraitu eta lortuko duzu!',
    pt: 'Continue assim e você vai conseguir!'
  },
  HOW_IS_GOING_STATUS_NORMAL_TITLE: {
    es: '¡No vas mal!',
    en: '¡You are not doing bad!',
    cat: '¡No vas mal!',
    eus: 'Ez da okerra!',
    pt: 'Não está mal!'
  },
  HOW_IS_GOING_STATUS_NORMAL_SUMMARY: {
    es: 'Pero tienes que meterle un poco de chicha al asunto porque vas un pelín retrasado.',
    en: 'But you have to put a little more effort into it because you are a bit behind.',
    cat: "Però has de posar-hi un poquet d'esforç perquè vas un pelín retingut.",
    eus: 'Baina zuk ez duzu gauza hori betetzen, ezta? Zuk ez duzu gauza hori betetzen, ezta?',
    pt: 'Mas você tem que se esforçar um pouco mais porque está um pouco atrasado.'
  },
  HOW_IS_GOING_STATUS_NORMAL_FOOTER: {
    es: '¡Sigue dándole!',
    en: '¡Keep it up and you will get it!',
    cat: '¡Mantente així i ho aconseguiràs!',
    eus: 'Jarraitu eta lortuko duzu!',
    pt: 'Continue assim e você vai conseguir!'
  },
  HOW_IS_GOING_STATUS_BAD_TITLE: {
    es: '¡Repámpanos y centellas!',
    en: '¡Repámpanos y centellas!',
    cat: '¡Repàmpanos i centelles!',
    eus: '¡Errepamoa eta zentilak!',
    pt: '¡Repámpanos e centelhas!'
  },
  HOW_IS_GOING_STATUS_BAD_SUMMARY: {
    es: 'Creo que alguien, y no te digo quién, ha estado haciendo pellas.',
    en: 'I think someone, and I don`t tell you who, has been making trouble.',
    cat: 'Crec que algún, i no et digués quién, ha estat fent problemes.',
    eus: 'Ez dakit ziuruenik, ezta? Ez dakit ziuruenik, ezta? Zer moduz gauza hori betetzen duzula?',
    pt: 'Acho que alguém, e não te digo quem, tem estado fazendo bagaço.'
  },
  HOW_IS_GOING_STATUS_BAD_FOOTER: {
    es: '¡Tu puedes! ¡A estudiar!',
    en: '¡You can do it! ¡Study!',
    cat: '¡Ho pots fer! ¡Estudia!',
    eus: 'Egin dezakezu! ¡Eskubide-egitea!',
    pt: 'Você pode! Estude!'
  },
  HOW_IS_GOING_AVERAGE_DAILY_HOURS_one: {
    es: '{{count}} hora',
    en: '{{count}} hour',
    cat: '{{count}} hora',
    eus: '{{count}} ordua',
    pt: '{{count}} hora'
  },
  HOW_IS_GOING_AVERAGE_DAILY_HOURS_other: {
    es: '{{count}} horas',
    en: '{{count}} hours',
    cat: '{{count}} hores',
    eus: '{{count}} ordu',
    pt: '{{count}} hora'
  },
  HOW_IS_GOING_AVERAGE_DAILY_HOURS_DESCRIPTION: {
    es: 'Tu estudio medio/día',
    en: 'Your average daily study',
    cat: 'El teu estudi mitjà/dia',
    eus: 'Zure eguneroko batez besteko eskubide-egitea',
    pt: 'O seu estudo médio/dia'
  },
  HOW_IS_GOING_AVERAGE_DAILY_HOURS_ESTIMATION_DESCRIPTION: {
    es: 'tu estimación diaria',
    en: 'your daily estimation',
    cat: 'la teva estimació diària',
    eus: 'zure eguneroko estimazioa',
    pt: 'a sua estimativa diária'
  },
  HOW_IS_GOING_TOTAL_CONTENT: {
    es: 'del contenido total',
    en: 'of total content',
    cat: 'del contingut total',
    eus: 'guztira duen edukia',
    pt: 'o conteúdo total'
  },
  HOW_IS_GOING_ACTIVITIES_one: {
    es: '{{count}} actividad',
    en: '{{count}} activity',
    cat: '{{count}} activitat',
    eus: '{{count}} eskubide',
    pt: '{{count}} atividade'
  },
  HOW_IS_GOING_ACTIVITIES_other: {
    es: '{{count}} actividades',
    en: '{{count}} activities',
    cat: '{{count}} activitats',
    eus: '{{count}} eskubide',
    pt: '{{count}} atividade'
  },
  HOW_IS_GOING_ACTIVITIES_COMPLETED_one: {
    es: 'Completada por ahora',
    en: 'Completed so far',
    cat: 'Completades fins ara',
    eus: 'Betetzen diren',
    pt: 'Completadas até agora'
  },
  HOW_IS_GOING_ACTIVITIES_COMPLETED_other: {
    es: 'Completadas por ahora',
    en: 'Completed so far',
    cat: 'Completades fins ara',
    eus: 'Betetzen diren',
    pt: 'Completadas até agora'
  },
  HOW_IS_GOING_ACTIVITIES_ACCUMULATED_one: {
    es: 'Acumulada por ahora',
    en: 'Accumulated so far',
    cat: 'Acumulada fins ara',
    eus: 'Betetzen diren',
    pt: 'Acumuladas até agora'
  },
  HOW_IS_GOING_ACTIVITIES_ACCUMULATED_other: {
    es: 'Acumuladas por ahora',
    en: 'Accumulated so far',
    cat: 'Acumulades fins ara',
    eus: 'Betetzen diren',
    pt: 'Acumuladas até agora'
  },
  HOW_IS_GOING_VIEW_PLANIFICATION: {
    es: 'Ver planificación',
    en: 'View planification',
    cat: 'Veure planificació',
    eus: 'Ikusi planifikazioa',
    pt: 'Ver planificação'
  },
  HOW_IS_GOING_REPLANIFY: {
    es: 'Replanificar',
    en: 'Replanify',
    cat: 'Replanificar',
    eus: 'Planifikazioa eguneratu',
    pt: 'Replanificar'
  },
  DO_YOU_WANT_TO_PLAN_YOUR_STUDIES: {
    es: '¡Listo! ¿Quieres que hagamos ahora la planificación de tu estudio?',
    en: 'Ready! Do you want us to plan your study now?',
    cat: 'Fet! Vols que ara fem la planificació del teu estudi?',
    eus: 'Egina! Nahi duzu gurekin zure ikasketen planifikazioa egin?',
    pt: 'Pronto! Quer que agora façamos a planificação do seu estudo?'
  },
  IT_WILL_ONLY_TAKE_A_MINUTE: {
    es: 'Aunque no te lo creas ahora, no vamos a tardar más de UN MINUTO en planificar absolutamente TODO tu estudio. Vas a flipar, palabra.',
    en: "Even if you don't believe it now, we are not going to take more than ONE MINUTE to plan absolutely EVERYTHING about your study. You are going to be amazed, word of honor.",
    cat: 'Encara que no t`ho creguis ara, no triguem més d`UN MINUT a planificar absolutament TOT el teu estudi. Vas a flipar, paraula.',
    eus: 'Zeruan ez baduzu sinesten, ez dugu gehiago behar UN MINUTU baino zure ikasketen guztiaren planifikazioa egiteko. Harrituko zara, hitza emanez.',
    pt: 'Mesmo que não acredite agora, não vamos levar mais de UM MINUTO para planificar absolutamente TODO o seu estudo. Você vai ficar impressionado, prometo.'
  },
  YOU_CAN_LEAVE_IT_FOR_LATER: {
    es: 'Pero bueno, si lo quieres dejar para más tarde no pasa nada.',
    en: 'But hey, if you want to leave it for later, it’s okay.',
    cat: 'Però bé, si ho vols deixar per més tard no passa res.',
    eus: 'Baina oso ondo, atzeratu nahi baduzu ez da inolako arazorik.',
    pt: 'Mas o que é, se quiser deixar para mais tarde, tudo bem.'
  },
  STUDY_PLAN: {
    es: 'Planificación de tu estudio',
    en: 'Your study plan',
    cat: 'Planificació del teu estudi',
    eus: 'Zure ikasketen planifikazioa',
    pt: 'O seu plano de estudo'
  },
  DO_YOU_HAVE_AN_EXAM_DATE: {
    es: '¿Tienes una fecha de examen o en la que quieras haber acabado de estudiar?',
    en: 'Do you have an exam date or a date in which you want to finish studying?',
    cat: 'Tens una data d`examen o una data en la que vulguis acabar d`estudiar?',
    eus: 'Ba al duzu azterketa data edo noiz amaitu nahi duzun ikasketak?',
    pt: 'Tem uma data de exame ou uma data em que você quer terminar de estudar?'
  },
  I_HAVE_A_DATE: {
    es: 'Ya tengo una fecha tope en la que me tengo que saber todo',
    en: 'I already have a deadline by which I have to know everything',
    cat: 'Ja tinc una data límit en la que m`he de saber tot',
    eus: 'Dagoeneko nireganako data bat daukat non dena jakin behar dut',
    pt: 'Já tenho uma data limite em que tenho de saber tudo'
  },
  I_DONT_HAVE_A_DATE: {
    es: 'De momento puedo estudiar al ritmo que me apetezca',
    en: 'For now I can study at the pace I want',
    cat: 'De moment puc estudiar al ritme que em vingui de gust',
    eus: 'Une honetan nahi duen erritmoan ikasi dezaket',
    pt: 'Por agora posso estudar ao ritmo que quero'
  },
  CAN_YOU_TELL_US_WHEN: {
    es: 'Genial. ¿Nos puedes decir cuando?',
    en: 'Great. Can you tell us when?',
    cat: 'Genial. Ens pots dir quan?',
    eus: 'Zoragarria. Esan dezakezu noiz?',
    pt: 'Ótimo. Pode nos dizer quando?'
  },
  CAN_YOU_TELL_US_WHEN_SUBTITLE: {
    es: 'Así sabremos como distribuir todo lo que tienes que estudiar.',
    en: 'This way we will know how to distribute everything you have to study.',
    cat: 'Així sabrem com distribuir tot el que has d`estudiar.',
    eus: 'Hala, jakin dezakegu nola banatu behar duzun ikasteko.',
    pt: 'Assim saberemos como distribuir tudo o que tem de estudar.'
  },
  HOW_LONG_WILL_YOU_STUDY: {
    es: 'Genial. ¿Y cuánto tienes pensado estudiar al día?',
    en: 'Great. And how much do you plan to study per day?',
    cat: 'Genial. I quant tens pensat estudiar al dia?',
    eus: 'Zoragarria. Eta zenbat planifikatzen duzu egunero ikasteko?',
    pt: 'Ótimo. E quanto você planeja estudar por dia?'
  },
  HOW_LONG_WILL_YOU_STUDY_SUBTITLE: {
    es: 'Así sabremos como distribuirte todo el contenido en el calendario. Lo podrás cambiar en cualquier momento.',
    en: 'This way we will know how to distribute all the content in the calendar. You can change it at any time.',
    cat: 'Així sabrem com distribuir tot el contingut al calendari. El podràs canviar en qualsevol moment.',
    eus: 'Hala, jakin dezakegu nola banatu behar duzun ikasteko.',
    pt: 'Assim saberemos como distribuir tudo o que tem de estudar.'
  },
  SELECT_A_DATE: {
    es: 'Selecciona una fecha',
    en: 'Select a date',
    cat: 'Selecciona una data',
    eus: 'Hautatu data bat',
    pt: 'Selecione uma data'
  },
  HOURS: {
    es: '* Horas',
    en: '* Hours',
    cat: '* Hores',
    eus: '* Orduak',
    pt: '* Horas'
  },
  MINUTES: {
    es: '* Minutos',
    en: '* Minutes',
    cat: '* Minuts',
    eus: '* Minutuak',
    pt: '* Minutos'
  },
  REST_DAYS_TITLE: {
    es: '¿Qué días vas a descansar?',
    en: 'What days are you going to rest?',
    cat: 'Quins dies descansaràs?',
    eus: 'Zein egun oporretan egongo zara?',
    pt: 'Quais dias você vai descansar?'
  },
  REST_DAYS_SUBTITLE: {
    es: 'Días que te vayas de vacaciones. los fines de semana, los juernes... marca los días que no vayas a estudiar.',
    en: 'Days you go on vacation. weekends, Thursdays... mark the days you are not going to study.',
    cat: 'Dies que et vagis de vacances. els caps de setmana, els dijous... marca els dies que no aniràs a estudiar.',
    eus: 'Oporrak joan zaren egunak. asteburua, osteguna... markatu ez duzun egunetan ikasi.',
    pt: 'Dias que você vai descansar. fins finais de semana, quinta-feira... marque os dias que não vai estudar.'
  },
  [EEventWeekdays.MONDAY]: {
    es: 'Lun',
    en: 'Mon',
    cat: 'Dl',
    eus: 'Al',
    pt: 'Seg'
  },
  [EEventWeekdays.TUESDAY]: {
    es: 'Mar',
    en: 'Tue',
    cat: 'Dm',
    eus: 'Ar',
    pt: 'Ter'
  },
  [EEventWeekdays.WEDNESDAY]: {
    es: 'Mié',
    en: 'Wed',
    cat: 'Dc',
    eus: 'Az',
    pt: 'Qua'
  },
  [EEventWeekdays.THURSDAY]: {
    es: 'Jue',
    en: 'Thu',
    cat: 'Dj',
    eus: 'Og',
    pt: 'Qui'
  },
  [EEventWeekdays.FRIDAY]: {
    es: 'Vie',
    en: 'Fri',
    cat: 'Dv',
    eus: 'Or',
    pt: 'Sex'
  },
  [EEventWeekdays.SATURDAY]: {
    es: 'Sáb',
    en: 'Sat',
    cat: 'Ds',
    eus: 'La',
    pt: 'Sáb'
  },
  [EEventWeekdays.SUNDAY]: {
    es: 'Dom',
    en: 'Sun',
    cat: 'Dg',
    eus: 'Ig',
    pt: 'Dom'
  },
  SPECIFIC_DAYS: {
    es: 'Fechas específicas (vacaciones)',
    en: 'Specific dates (holidays)',
    cat: 'Dates específiques (vacances)',
    eus: 'Data zehatzak (oporrak)',
    pt: 'Datas específicas (feriados)'
  },
  IS_THERE_SOMETHING_YOU_ARENT_STUDYING: {
    es: '¿Hay algo que no vayas a estudiar?',
    en: 'Is there something you are not going to study?',
    cat: 'Hi ha alguna cosa que no vagis a estudiar?',
    eus: 'Zerbait ez duzu ikasi nahi?',
    pt: 'Há algo que você não vai estudar?'
  },
  FILTER_SUBSCRIPTIONS: {
    es: 'Quita el contenido que no vayas a estudiar. Lo podrás cambiar en cualquier momento.',
    en: 'Remove the content you are not going to study. You can change it at any time.',
    cat: 'Treu el contingut que no vagis a estudiar. El podràs canviar en qualsevol moment.',
    eus: 'Kendu ez duzun edukiak ikasi. Momentu batean aldatu dezakezu.',
    pt: 'Remova o conteúdo que você não vai estudar. Você pode alterá-lo em qualquer momento.'
  },
  PLANIFICATION_RESULT_TITLE: {
    es: 'Resultado:',
    en: 'Result:',
    cat: 'Resultat:',
    eus: 'Emaitza:',
    pt: 'Resultado:'
  },
  PLANIFICATION_RESULT_UNDER_TIME: {
    es: 'Te lo tomas con mucha calma.',
    en: 'Take it with a lot of calm.',
    cat: 'T`ho agafes amb molta calma.',
    eus: 'Hobetu gurekin.',
    pt: 'Pegue com uma calma.'
  },
  PLANIFICATION_RESULT_OVER_TIME: {
    es: 'Nos vamos un poco de tiempos.',
    en: 'We took a little longer than expected.',
    cat: 'Nos vam un poc de temps.',
    eus: 'Gurekin zituen pixka bat gehiago.',
    pt: 'Fizemos um pouco mais do que o esperado.'
  },
  PLANIFICATION_CORRECT_TIME_END_DATE: {
    es: 'Todo listo. Esto es lo que necesitas para lograr tu objetivo a tiempo:',
    en: 'All set. This is what you need to achieve your goal in time:',
    cat: 'Tot listo. Això és el que necessites per aconseguir el teu objectiu a temps:',
    eus: 'Egina. Zer moduz erabili behar duzu gurekin.',
    pt: 'Tudo pronto. Isso é o que você precisa para atingir seu objetivo no tempo:'
  },
  PLANIFICATION_CORRECT_TIME_HOURS: {
    es: 'Todo listo. Según nuestros cálculos acabarías de estudiar este día:',
    en: 'All set. According to our calculations, you would have studied today:',
    cat: 'Tot listo. Segons els nostres càlculs, avui hauriaies d`estudiar:',
    eus: 'Egina. Kalkulazioekin, egun hau ikastea beharko litzateke.',
    pt: 'Tudo pronto. De acordo com os nossos cálculos, você estudaria hoje:'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_HOURS_INFO: {
    es: 'Cálculos hechos para estudiártelo todo una vez. Te iremos diciendo día a día, semana a semana, lo que tienes que hacer.',
    en: 'Calculations made for you to study everything once. We will tell you day by day, week by week, what you have to do.',
    cat: 'Càlculs fets per a que et estudiïs tot una vegada. Et diriem dia a dia, setmana a setmana, lo que has de fer.',
    eus: 'Kalkulazioak egin ditugu zurekin, behin bakarrik ikasteko.',
    pt: 'Cálculos feitos para você estudar tudo uma vez. Vamos lhe contando dia a dia, semana a semana, o que você tem que fazer.'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_HOURS: {
    es: 'Día de finalización',
    en: 'Completion date',
    cat: 'Data de finalització',
    eus: 'Amaitzaren data',
    pt: 'Data de finalização'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_TOOLTIP: {
    es: 'Cálculo realizado con tus parámetros introducidos teniendo en cuenta la duración total de tu formación',
    en: 'Calculation made with your parameters taken into account the total duration of your training',
    cat: 'Càlcul realitzat amb els teus paràmetres introduïts tenint en compte la durada total de la teva formació',
    eus: 'Kalkulazioa egin duzu zure parametroekin, gurekin kalkulatu duzun ikasteko denbora totalarekin.',
    pt: 'Cálculo feito com os seus parâmetros, levando em consideração o tempo total de sua formação.'
  },
  PLANIFICATION_RESULT_CONTENT: {
    es: 'Según la fecha de finalización que has indicado, días que no vas a estudiar y contenido...',
    en: 'According to the completion date you have indicated, days you are not going to study and content...',
    cat: 'Segons la data de finalització que has indicat, dies que no vagis a estudiar i contingut...',
    eus: 'Egiaztapen data zehatz baten arabera, ez duzun egunetan ikasi eta edukiak...',
    pt: 'De acordo com a data de finalização que você indicou, dias que você não vai estudar e conteúdo...'
  },
  PLANIFICATION_RESULT_CONTENT_UNDER_TIME_EXPLANATION_FIRST: {
    es: 'Piensa que te calculamos la planificación para estudiarlo y repasarlo todo una sola vez.',
    en: 'Think that we calculated the plan for you to study and review everything at once.',
    cat: 'Pensa que et hem calculat la planificació per estudiar-ho i repassar-ho tot alhora.',
    eus: 'Kontuan izan planifikazioa kalkulatu dizugula dena behin bakarrik ikasteko eta errepasatzeko.',
    pt: 'Pense que calculamos o plano para você estudar e revisar tudo de uma vez.'
  },
  PLANIFICATION_RESULT_CONTENT_UNDER_TIME_EXPLANATION_SECOND: {
    es: 'Prueba a intentar ponerte una fecha para acabar de estudiar un poco antes.',
    en: 'Try to set a date to finish studying a little earlier.',
    cat: 'Prova a intentar posar-te una data per acabar d`estudiar una mica abans.',
    eus: 'Saiatu zaitez ikasketak pixka bat lehenago amaitzeko data bat jartzen.',
    pt: 'Tente definir uma data para terminar de estudar um pouco antes.'
  },
  PLANIFICATION_RESULT_CONTENT_OVER_TIME_EXPLANATION: {
    es: 'Pero que no cunda el pánico. Puedes volver a editar los datos ahora o hacerlo más tarde.',
    en: 'But don’t panic. You can edit the data now or do it later.',
    cat: 'Però que no cunda el pànic. Podes tornar a editar les dades ara o fer-ho més tard.',
    eus: 'Baina ez duzu gogorik. Momentu batean aldatu dezakezu.',
    pt: 'Mas não se apresse. Você pode editar os dados agora ou fazê-lo mais tarde.'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_END_DATE_INFO: {
    es: 'Te iremos diciendo día a día, semana a semana, lo que tienes que hacer.',
    en: 'We will tell you day by day, week by week, what you have to do.',
    cat: 'Et diriem dia a dia, setmana a setmana, lo que has de fer.',
    eus: 'Egunero egingo dizugun, astea astean, zer egin behar duzun.',
    pt: 'Vamos lhe contando dia a dia, semana a semana, o que você tem que fazer.'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_END_DATE: {
    es: 'Tiempo de estudio mínimo al día',
    en: 'Minimum study time per day',
    cat: 'Temps mínim d`estudi al dia',
    eus: 'Eguneko gutxieneko ikasteko denbora',
    pt: 'Tempo mínimo de estudo por dia'
  },
  PLANIFICATION_RESULT_CORRECT_TIME_END_DATE_STUDY_TIME: {
    es: '{{hours}} horas, {{minutes}} minutos',
    en: '{{hours}} hours, {{minutes}} minutes',
    cat: '{{hours}} hores, {{minutes}} minuts',
    eus: '{{hours}} ordu, {{minutes}} minutu',
    pt: '{{hours}} horas, {{minutes}} minutos'
  },
  EMPTY_REST_DAYS_TITLE: {
    es: 'No me lo creo. ¿No vas a descansar NI UN SOLO DÍA?',
    en: 'I don’t believe it. Are you not going to rest NOT EVEN ONE DAY?',
    cat: 'No m`ho crec. No descansaràs NI UN SOL DIA?',
    eus: 'Ez dut sinistu. Ez duzu oporretan ERE BATA ere egongo?',
    pt: 'Não acredito. Você não vai descansar NEM UM ÚNICO DIA?'
  },
  EMPTY_REST_DAYS_SUBTITLE: {
    es: 'Nos apasiona tu entusiasmo, pero seamos personas sanas. Te aconsejamos que te pongas al menos un día para descansar.',
    en: 'We love your enthusiasm, but let’s be healthy people. We advise you to take at least one day to rest.',
    cat: 'Ens apassiona el teu entusiasme, però siguem persones sanes. Et recomanem que et posi almenys un dia per descansar.',
    eus: 'Gure zaletasuna da zure entusiasmoa, baina izan gaizki pertsona. Aholkatzen dizugu gutxienez egun bat oporretan jartzeko.',
    pt: 'Nosso entusiasmo é o seu entusiasmo, mas sejamos pessoas saudáveis. Recomendamos que você se de um dia para descansar.'
  },
  CHOOSE_REST_DAYS: {
    es: 'Elegir días de descanso',
    en: 'Choose rest days',
    cat: 'Triar dies de descans',
    eus: 'Aukeratu opor egunak',
    pt: 'Escolher dias de descanso'
  },
  I_NEVER_REST: {
    es: 'No descanso nunca',
    en: 'I never rest',
    cat: 'No descanso mai',
    eus: 'Inoiz ez dut oporretan',
    pt: 'Nunca descanco'
  },
  PLANIFICATION_RESULT_LEAVE: {
    es: 'Dejarlo así de momento',
    en: 'Leave it as it is for now',
    cat: 'Dejar-ho així per ara',
    eus: 'Ez duzu gogorik. Momentu batean aldatu dezakezu.',
    pt: 'Não descanco nunca'
  },
  PLANIFICATION_RESULT_EDIT: {
    es: 'Editar la planificación',
    en: 'Edit the plan',
    cat: 'Editar la planificació',
    eus: 'Editatu planifikazioa',
    pt: 'Editar o plano'
  },
  PLANIFICATION_RESULT_DONE: {
    es: '¡A estudiar!',
    en: 'Let’s go study!',
    cat: 'Anem-hi a estudiar!',
    eus: 'Ikasten joan!',
    pt: 'Vamos estudar!'
  },
  PLANIFICATION_RESULT_EDIT_HOURS: {
    es: 'Editar horas de estudio',
    en: 'Edit study hours',
    cat: 'Editar hores d`estudi',
    eus: 'Ikasteko denbora aldatu',
    pt: 'Editar horas de estudo'
  },
  PLANIFICATION_RESULT_EDIT_END_DATE: {
    es: 'Editar fecha de finalización',
    en: 'Edit completion date',
    cat: 'Editar data de finalització',
    eus: 'Amaitzaren datara aldatu',
    pt: 'Editar data de finalização'
  },
  GO_TO_STUDY: {
    es: 'No, ir a estudiar',
    en: 'No, go study',
    cat: 'No, anar a estudiar',
    eus: 'Ez, ikasten joan',
    pt: 'Não, vamos estudar'
  },
  PLAN_MY_STUDY: {
    es: 'Planificar mi estudio',
    en: 'Plan my study',
    cat: 'Planificar el meu estudi',
    eus: 'Nire ikasketak planifikatu',
    pt: 'Planificar meu estudo'
  },
  GO_BACK: {
    es: 'Atrás',
    en: 'Back',
    cat: 'Atrás',
    eus: 'Atzera',
    pt: 'Voltar'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu',
    pt: 'Continuar'
  },
  SELECT_TAG: {
    es: 'Selecciona una formación',
    en: 'Select a training',
    cat: 'Selecciona una formació',
    eus: 'Aukeratu ikastaroa',
    pt: 'Selecionar formação'
  },
  PLANIFICATION_RESULT_SUCCESS: {
    es: '¡A estudiar!',
    en: 'Let’s go study!',
    cat: 'Anem-hi a estudiar!',
    eus: 'Ikasten joan!',
    pt: 'Vamos estudar!'
  },
  PLANIFICATION: {
    es: 'Planificación del estudio',
    en: 'Study plan',
    cat: 'Planificació del meu estudi',
    eus: 'Ikasteko planifikazioa',
    pt: 'Plano de estudo'
  },
  PLANIFICATION_HOW_IT_WORKS_TITLE: {
    es: '¿Cómo funciona la planificación?',
    en: 'How does the plan work?',
    cat: 'Nola funtzionatzen du planifikazioa?',
    eus: 'Nola funtzionatzen du planifikazioa?',
    pt: 'Como funciona o plano?'
  },
  PLANIFICATION_HOW_IT_WORKS_CONTENT: {
    es: 'En esta primera versión de la planificación hacemos esto: Cogemos todo el temario y te lo distribuimos en el calendario teniendo en cuenta:',
    en: 'In this first version of the plan, we do this: We take all the syllabus and distribute it in the calendar taking into account:',
    cat: 'En aquesta primera versió de la planificació, fem això: agafem tot el temari i el distribuïm en el calendari tenint en compte:',
    eus: 'Plangintzaren lehen bertsio honetan hau egiten dugu: Agenda osoa hartu eta egutegian banatzen dugu kontuan hartuta:',
    pt: 'Na primeira versão do plano, fazemos isto: pegamos em todo o temário e distribuímos no calendário, levando em consideração:'
  },
  PLANIFICATION_HOW_IT_WORKS_POINTS_1: {
    es: 'El tiempo de cada actividad de tu formación más el tiempo de repaso de cada una.',
    en: 'The time of each activity of your training plus the time of review of each one.',
    cat: 'Den temps de cada activitat de la teva formació més el temps de repàs de cada una.',
    eus: 'Ikastaroko edukiaren denbora eta erreparatzeko denbora bakoitza.',
    pt: 'O tempo de cada atividade da sua formação mais o tempo de revisão de cada uma.'
  },
  PLANIFICATION_HOW_IT_WORKS_POINTS_2: {
    es: 'La fecha en la que quieres acabar de estudiar o el tiempo que quieres estudiar al día.',
    en: 'The date you want to finish studying or the time you want to study per day.',
    cat: 'Data en la que vols acabar d`estudiar o el temps que vols estudiar al dia.',
    eus: 'Amaitzeko data eta egunero ikasteko denbora.',
    pt: 'A data que você quer terminar de estudar ou o tempo que você quer estudar por dia.'
  },
  PLANIFICATION_HOW_IT_WORKS_POINTS_3: {
    es: 'Los días que no quieres o puedes estudiar.',
    en: 'The days you do not want or can study.',
    cat: 'Dies que no vols o pots estudiar.',
    eus: 'Ez duzun egunak eta ikasteko egunak.',
    pt: 'Os dias que você não quer ou pode estudar.'
  },
  PLANIFICATION_HOW_IT_WORKS_POINTS_4: {
    es: 'El temario que quieres descartar.',
    en: 'The syllabus you want to discard.',
    cat: 'El temari que vols descartar.',
    eus: 'Ez duzun temari.',
    pt: 'O temário que você quer descartar.'
  },
  PLANIFICATION_HOW_IT_WORKS_CONCLUSION: {
    es: 'Después calculamos automáticamente las tareas que deberías realizar cada día.',
    en: 'Then we automatically calculate the tasks you should do each day.',
    cat: 'Despres, calculem automàticament les tasques que hauries de fer cada dia.',
    eus: 'Gero, kalkulatu automatikoki egunero egin beharko duzun jarduera.',
    pt: 'Depois, calculamos automaticamente as tarefas que você deveria fazer todos os dias.'
  },
  PLANIFICATION_HOW_IT_WORKS_REMINDER: {
    es: 'Recuerda que además de estudiar también deberías repasar con tests.',
    en: 'Remember that you should also review with tests.',
    cat: 'Recorda que hauries de repassar amb tests.',
    eus: 'Gogoratu erreparatzeko testak egin beharko duzu.',
    pt: 'Lembre-se de que també deveria revisar com testes.'
  },
  PLANIFICATION_HOW_IT_WORKS_FEEDBACK: {
    es: '¿Te gustaría dejarnos feedback para mejorar?',
    en: 'Would you like to leave us feedback to improve?',
    cat: 'Zelatzen duzu gurekin feedback utztea hobetu?',
    eus: 'Feedback utzi nahi duzu hobetu?',
    pt: 'Você gostaria de nos deixar feedback para melhorar?'
  },
  PLANIFICATION_HOW_IT_WORKS_SEND_FEEDBACK: {
    es: 'Enviar feedback',
    en: 'Send feedback',
    cat: 'Enviar feedback',
    eus: 'Feedback bidali',
    pt: 'Enviar feedback'
  },
  PLANIFICATION_HOW_IT_WORKS_SEND_FEEDBACK_SUCCESS: {
    es: '¡Feedback enviado!',
    en: 'Feedback sent!',
    cat: 'Feedback enviat!',
    eus: 'Feedback bidali da!',
    pt: 'Feedback enviado!'
  },
  INCLUDE_ALL_ACTIVITIES_TITLE: {
    es: '¿Quieres planificar las lecciones que ya has completado?',
    en: 'Do you want us to plan the lessons you have already completed?',
    cat: 'Vols que planifiquem les lliçons que ja has completat?',
    eus: 'Zer moduz erabili behar duzu?',
    pt: 'Quer que planeie as aulas que já completou?'
  },
  INCLUDE_ALL_ACTIVITIES_SUBTITLE: {
    es: 'Las que hayas completado seguirán marcadas como completadas, pero las tendremos en cuenta en tu planificación.',
    en: 'The ones you have completed will remain marked as completed, but we will take them into account in your plan.',
    cat: 'Les que has completat seguiran marcades com a completes, però les tindrem en compte en el teu pla.',
    eus: 'Ez duzun jarduera egiten duzula',
    pt: 'As que você completou continuarão marcadas como completadas, mas vamos considerá-las no seu plano.'
  },
  INCLUDE_ALL_ACTIVITIES_YES: {
    es: 'Quiero todo, incluidas las actividades que ya he hecho.',
    en: 'I want everything, including the activities I have already done.',
    cat: 'Vull tot, incloent les activitats que ja he fet.',
    eus: 'Nahi dut jarduera egiten duzula',
    pt: 'Quero tudo, incluindo as atividades que já fiz.'
  },
  INCLUDE_ALL_ACTIVITIES_NO: {
    es: 'Para mi planificación solo quiero las que no he completado',
    en: 'For my plan, I only want the ones I have not completed',
    cat: 'Per al meu pla, només vull les que no he completat',
    eus: 'Nahi dut jarduera egiten duzula',
    pt: 'Para o meu plano, quero apenas as que ainda não completei'
  },
  ACTIVITY_ESTIMATED_STUDY_TIME: {
    es: 'Tiempo de la actividad',
    en: 'Activity study time',
    cat: 'Temps d`estudi de l`activitat',
    eus: 'Jarduera ikasteko denbora',
    pt: 'Tempo de estudo da atividade'
  },
  [`ACTIVITY_ESTIMATED_STUDY_TIME_${EActivityType.VIDEO}_INFO`]: {
    es: 'El tiempo que se toma en cuenta para la planificación. Por defecto asignaremos automáticamente este tiempo: Tiempo del vídeo x 2',
    en: 'The time taken into account for the plan. By default, we will automatically assign this time: Video time x 2',
    cat: 'El temps que es té en compte per a la planificació. Per defecte, assignarem automàticament aquest temps: Temps del vídeo x 2',
    eus: 'Planifikazioan hartzen duen denbora. Lehenetsitako moduan, denbora automatikoki esleitu dugu: Ikusteko denbora x 2',
    pt: 'Tempo de estudo da atividade que considera o plano. Por defeito, atribuiremos automaticamente este tempo: Tempo do vídeo x 2'
  },
  [`ACTIVITY_ESTIMATED_STUDY_TIME_${EActivityType.INTERACTIVE_VIDEO}_INFO`]: {
    es: 'El tiempo que se toma en cuenta para la planificación. Por defecto asignaremos automáticamente este tiempo: Tiempo del vídeo x 2',
    en: 'The time taken into account for the plan. By default, we will automatically assign this time: Video time x 2',
    cat: 'El temps que es té en compte per a la planificació. Per defecte, assignarem automàticament aquest temps: Temps del vídeo x 2',
    eus: 'Planifikazioan hartzen duen denbora. Lehenetsitako moduan, denbora automatikoki esleitu dugu: Ikusteko denbora x 2',
    pt: 'Tempo de estudo da atividade que considera o plano. Por defeito, atribuiremos automaticamente este tempo: Tempo do vídeo x 2'
  },
  [`ACTIVITY_ESTIMATED_STUDY_TIME_${EActivityType.PDF}_INFO`]: {
    es: 'El tiempo que se toma en cuenta para la planificación. Por defecto asignaremos automáticamente este tiempo: Σ Palabras totales en todas las páginas x 4,8 segundos',
    en: 'The time taken into account for the plan. By default, we will automatically assign this time: Σ Total words in all pages x 4.8 seconds',
    cat: 'El temps que es té en compte per a la planificació. Per defecte, assignarem automàticament aquest temps: Σ Total de paraules en totes les pàgines x 4,8 segons',
    eus: 'Planifikazioan hartzen duen denbora. Lehenetsitako moduan, denbora automatikoki esleitu dugu: Σ Orrialde guztietako hitz kopurua x 4,8 segundoa',
    pt: 'Tempo de estudo da atividade que considera o plano. Por defeito, atribuiremos automaticamente este tempo: Σ Palavras totais em todas as páginas x 4,8 segundos'
  },
  [`ACTIVITY_ESTIMATED_STUDY_TIME_${EActivityType.QUIZ}_INFO`]: {
    es: 'El tiempo que se toma en cuenta para la planificación. Por defecto asignaremos automáticamente este tiempo: Σ Preguntas del test x 90 segundos',
    en: 'The time taken into account for the plan. By default, we will automatically assign this time: Σ Questions of the test x 90 seconds',
    cat: 'El temps que es té en compte per a la planificació. Per defecte, assignarem automàticament aquest temps: Σ Preguntes del test x 90 segons',
    eus: 'Planifikazioan hartzen duen denbora. Lehenetsitako moduan, denbora automatikoki esleitu dugu: Σ Testeko galdera kopurua x 90 segundoa',
    pt: 'Tempo de estudo da atividade que considera o plano. Por defeito, atribuiremos automaticamente este tempo: Σ Perguntas do test x 90 segundos'
  },
  [`ACTIVITY_ESTIMATED_STUDY_TIME_${EActivityType.DELIVERABLE}_INFO`]: {
    es: 'El tiempo que se toma en cuenta para la planificación. Por defecto asignaremos automáticamente 10 horas.',
    en: 'The time taken into account for the plan. By default, we will automatically assign this time: 10 hours',
    cat: 'El temps que es té en compte per a la planificació. Per defecte, assignarem automàticament aquest temps: 10 hores',
    eus: 'Planifikazioan hartzen duen denbora. Lehenetsitako moduan, denbora automatikoki esleitu dugu: 10 ordu',
    pt: 'Tempo de estudo da atividade que considera o plano. Por defeito, atribuiremos automaticamente este tempo: 10 horas'
  },
  ACTIVITY_ESTIMATED_STUDY_TIME_INFO: {
    es: 'Si no te encaja, puedes modificar el tiempo cuando quieras.',
    en: 'If it doesn’t fit, you can modify the time whenever you want.',
    cat: 'Si no et encaixa, pots modificar el temps quan vulguis.',
    eus: 'Ez badu zuzena, aldatu dezakezu denbora denbora.',
    pt: 'Se não couber, você pode modificar o tempo quando quiser.'
  },
  ACTIVITY_ESTIMATED_STUDY_TIME_HOURS: {
    es: 'Horas',
    en: 'Hours',
    cat: 'Horas',
    eus: 'Orduak',
    pt: 'Horas'
  },
  ACTIVITY_ESTIMATED_STUDY_TIME_MINUTES: {
    es: 'Minutos',
    en: 'Minutes',
    cat: 'Minutos',
    eus: 'Minutuak',
    pt: 'Minutos'
  },
  ACTIVITY_ESTIMATED_STUDY_TIME_DELETE: {
    es: 'Borrar',
    en: 'Delete',
    cat: 'Borrar',
    eus: 'Ezabatu',
    pt: 'Apagar'
  },
  REMOVE_CONTRACT: {
    es: 'Eliminar contrato',
    en: 'Remove contract',
    cat: 'Eliminar contrato',
    eus: 'Ezabatu kontratua',
    pt: 'Remover contrato'
  },
  REMOVE_CONTRACT_CONFIRMATION: {
    es: '¿Estás seguro de que deseas eliminar el contrato? El proceso es irreversible y conlleva un gran coste para la empresa.',
    en: 'Are you sure you want to remove the contract? The process is irreversible and involves a high cost for the company.',
    cat: 'Estàs segur que vols ezabatu kontratua? Procedimenta irreversible i suposa un alt cost per a l`empresa.',
    eus: 'Ziur zaude kontratua ezabatu nahi duzula? Prozesua irreversiboa da eta enpresa handi bati esanahi bat du.',
    pt: 'Tem certeza de que deseja remover o contrato? O processo é irreversível e acarreta um alto custo para a empresa.'
  },
  SELECT_ONE_OPTION: {
    es: 'Selecciona una opción',
    en: 'Select one option',
    cat: 'Alegeix una opció',
    eus: 'Aukeratu bat',
    pt: 'Selecione uma opção'
  },
  RECORDING_TODAY: {
    es: 'Subido hoy',
    en: 'Uploaded today',
    cat: 'Carregat avui',
    eus: 'Gaur egun igortua',
    pt: 'Carregado hoje'
  },
  RECORDING_DAYS_one: {
    es: 'Subido hace 1 día',
    en: 'Uploaded 1 day ago',
    cat: 'Carregat 1 dia atzera',
    eus: 'Gaur egun igortua',
    pt: 'Carregado há um dia'
  },
  RECORDING_DAYS_other: {
    es: 'Subido hace {{count}} días',
    en: 'Uploaded {{count}} days ago',
    cat: 'Carregat {{count}} dies atzera',
    eus: '{{count}} egun igortua',
    pt: 'Carregado há {{count}} dias'
  },
  RECORDING_WEEKS_one: {
    es: 'Subido hace 1 semana',
    en: 'Uploaded 1 week ago',
    cat: 'Carregat 1 setmana atzera',
    eus: '1 aste igortua',
    pt: 'Carregado há uma semana'
  },
  RECORDING_WEEKS_other: {
    es: 'Subido hace {{count}} semanas',
    en: 'Uploaded {{count}} weeks ago',
    cat: 'Carregat {{count}} setmanes atzera',
    eus: '{{count}} aste igortua',
    pt: 'Carregado há {{count}} semanas'
  },
  RECORDING_MONTHS_one: {
    es: 'Subido hace 1 mes',
    en: 'Uploaded 1 month ago',
    cat: 'Carregat 1 mes atzera',
    eus: '1 hilabete igortua',
    pt: 'Carregado há um mês'
  },
  RECORDING_MONTHS_other: {
    es: 'Subido hace {{count}} meses',
    en: 'Uploaded {{count}} months ago',
    cat: 'Carregat {{count}} mesos atzera',
    eus: '{{count}} hilabete igortua',
    pt: 'Carregado há {{count}} meses'
  },
  RECORDING_YEARS_one: {
    es: 'Subido hace 1 año',
    en: 'Uploaded 1 year ago',
    cat: 'Carregat 1 any atzera',
    eus: '1 urte igortua',
    pt: 'Carregado há um ano'
  },
  RECORDING_YEARS_other: {
    es: 'Subido hace {{count}} años',
    en: 'Uploaded {{count}} years ago',
    cat: 'Carregat {{count}} anys atzera',
    eus: '{{count}} urte igortua',
    pt: 'Carregado há {{count}} anos'
  },
  CREATE_INTERNAL_NOTE_SUCCESS: {
    es: 'Nota interna creada correctamente',
    en: 'Internal note created successfully',
    cat: 'Nota interna creada correctament',
    eus: 'Eskuzko nota egitea ongi egin da',
    pt: 'Nota interna criada com sucesso'
  },
  UPDATE_INTERNAL_NOTE_SUCCESS: {
    es: 'Nota interna actualizada correctamente',
    en: 'Internal note updated successfully',
    cat: 'Nota interna actualitzada correctament',
    eus: 'Eskuzko nota egitea ongi egin da',
    pt: 'Nota interna atualizada com sucesso'
  },
  DELETE_INTERNAL_NOTE_SUCCESS: {
    es: 'Nota interna eliminada correctamente',
    en: 'Internal note deleted successfully',
    cat: 'Nota interna eliminada correctament',
    eus: 'Eskuzko nota ezabatua ongi egin da',
    pt: 'Nota interna eliminada com sucesso'
  },
  ADD_INTERNAL_NOTE_TYPE: {
    es: 'Añadir etiqueta',
    en: 'Add tag',
    cat: 'Afegeix etiqueta',
    eus: 'Etiketa gehitu',
    pt: 'Adicionar etiqueta'
  },
  [EInternalNoteType.PERSONAL]: {
    es: 'Personal',
    en: 'Personal',
    cat: 'Personal',
    eus: 'Pertsonal',
    pt: 'Pessoal'
  },
  [EInternalNoteType.INCIDENT]: {
    es: 'Incidencia',
    en: 'Incident',
    cat: 'Incident',
    eus: 'Errorea',
    pt: 'Incidente'
  },
  [EInternalNoteType.NON_ATTENDANCE]: {
    es: 'Inasistencia',
    en: 'Non-attendance',
    cat: 'Inasistencia',
    eus: 'Inasistenzia',
    pt: 'Inasistência'
  },
  [EInternalNoteType.PROGRESS]: {
    es: 'Progreso',
    en: 'Progress',
    cat: 'Progres',
    eus: 'Progresoa',
    pt: 'Progresso'
  },
  INTERNAL_NOTES: {
    es: 'Notas',
    en: 'Notes',
    cat: 'Notes',
    eus: 'Notak',
    pt: 'Notas'
  },
  ACCOUNT_AND_CONFIGURATION: {
    es: 'Configuración y cuenta',
    en: 'Configuration and account',
    cat: 'Configuració i compte',
    eus: 'Konfigurazioa eta kontua',
    pt: 'Configuração e conta'
  },
  SECTIONS_COUNT_one: {
    es: '1 tema',
    en: '1 section',
    cat: '1 tema',
    eus: '1 ikastar',
    pt: '1 tema'
  },
  SECTIONS_COUNT_other: {
    es: '{{count}} temas',
    en: '{{count}} sections',
    cat: '{{count}} temes',
    eus: '{{count}} ikastarak',
    pt: '{{count}} temas'
  },
  SECTIONS_PROGRESS_UNDER_20_one: {
    es: 'Completa el tema restante y demuestra que puedes con todo.',
    en: 'Complete the remaining section and prove that you can handle everything.',
    cat: 'Completa el tema restant i demostra que pots fer tot.',
    eus: 'Egin geroago ikastaroa eta erakustatu zituzke zuk egin duzula.',
    pt: 'Complete o tema restante e demonstre que pode lidar com tudo.'
  },
  SECTIONS_PROGRESS_UNDER_20_other: {
    es: 'Completa los {{count}} temas restantes y demuestra que puedes con todo.',
    en: 'Complete the remaining {{count}} sections and prove that you can handle everything.',
    cat: 'Completa els {{count}} temes restants i demostra que pots fer tot.',
    eus: 'Egin geroago {{count}} ikastarok eta erakustatu zituzke zuk egin duzula.',
    pt: 'Complete os {{count}} temas restantes e demonstre que pode lidar com tudo.'
  },
  SECTIONS_PROGRESS_UNDER_60_one: {
    es: 'Te queda 1 tema. ¡Vas por buen camino!',
    en: 'You have 1 section left. You are on the right track!',
    cat: 'Te queda 1 tema. ¡Vas per un bon camí!',
    eus: '1 ikastar geroago dagozu. Zuk zuzentzen zara!',
    pt: 'Você tem 1 tema restante. Você está no caminho certo!'
  },
  SECTIONS_PROGRESS_UNDER_60_other: {
    es: 'Te quedan {{count}} temas. ¡Vas por buen camino!',
    en: 'You have {{count}} sections left. You are on the right track!',
    cat: 'Te queden {{count}} temes. ¡Vas per un bon camí!',
    eus: '{{count}} ikastar geroago dagozu. Zuk zuzentzen zara!',
    pt: 'Você tem {{count}} temas restantes. Você está no caminho certo!'
  },
  SECTIONS_PROGRESS_UNDER_100_one: {
    es: '¡Ánimo! Solo te queda 1 tema para saberlo todo.',
    en: 'Cheer up! You have 1 section left to know everything.',
    cat: 'Ànim! Només et queda un tema per saber-ho tot.',
    eus: 'Anima zaitez! Gai bakarra geratzen zaizu guztia ezagutzeko.',
    pt: 'Cheer up! You have 1 section left to know everything.'
  },
  SECTIONS_PROGRESS_UNDER_100_other: {
    es: '¡Ánimo! Solo te quedan {{count}} temas para saberlo todo.',
    en: 'Cheer up! You have {{count}} sections left to know everything.',
    cat: 'Ànim! Només et queden {{count}} temes per saber-ho tot.',
    eus: 'Anima zaitez! Gai bakarra geratzen zaizu guztia ezagutzeko.',
    pt: 'Cheer up! You have {{count}} sections left to know everything.'
  },
  SECTIONS_PROGRESS_COMPLETED: {
    es: '¡Ya te sabes todos los temas!',
    en: '¡You know all the sections!',
    cat: '¡Ja saps tots els temes!',
    eus: '¡Egin duzun ikastarok egin duzu!',
    pt: '¡Você conhece todos os temas!'
  },
  ACTIVITY_NOTES_TOOLTIP_one: {
    es: 'Has tomado 1 nota en esta lección',
    en: 'You have taken 1 note in this lesson',
    cat: 'Has agafat 1 nota en aquesta lliçó',
    eus: '1 nota egin duzu ikastarok',
    pt: 'Você tem 1 nota nesta lição'
  },
  ACTIVITY_NOTES_TOOLTIP_other: {
    es: 'Has tomado {{count}} notas en esta lección',
    en: 'You have taken {{count}} notes in this lesson',
    cat: 'Has agafat {{count}} notes en aquesta lliçó',
    eus: '{{count}} nota egin duzu ikastarok',
    pt: 'Você tem {{count}} notas nesta lição'
  },
  THANKS_FOR_REVIEWING: {
    es: '¡Gracias por tu valoración!',
    en: 'Thank you for your review!',
    cat: '¡Gràcies per la teva valoració!',
    eus: 'Eskuzko nota egitea ongi egin da',
    pt: 'Obrigado pela sua revisão!'
  },
  TEACHER_VOTE_THANK_YOU: {
    es: '¡Gracias por valorar a {{name}}!',
    en: 'Thank you for voting for {{name}}!',
    cat: '¡Gràcies per valorar a {{name}}!',
    eus: 'Eskuzko votazioa {{name}}!',
    pt: 'Obrigado por votar por {{name}}!'
  },
  TUTOR_VOTE_THANK_YOU: {
    es: '¡Gracias por valorar a tu tutor!',
    en: 'Thank you for voting for your tutor!',
    cat: '¡Gràcies per valorar a tu tutor!',
    eus: 'Eskuzko votazioa tutor zure!',
    pt: 'Obrigado por votar por seu tutor!'
  },
  LIVE_CLASS_VOTE_SUCCESS: {
    es: '¡Gracias por tu valoración!',
    en: 'Thank you for your rating!',
    cat: '¡Gràcies per la teva valoració!',
    eus: 'Eskerrik asko zure iritziagatik!',
    pt: 'Obrigado por sua avaliação!'
  }
};
