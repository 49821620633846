import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(100% - 90px);
  background-color: ${({ theme }) => theme.colors.black1};
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid ${({ theme }) => theme.colors.black4};
  margin-top: 90px;
  padding: 12px;

  

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-tab {
    flex: 1;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .ant-tabs-tab-active {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tabs-nav-list {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black1};
    gap: 8px;
    overflow-x: auto;
  }

  .ant-tabs-nav {
    padding 0 8px;
    background-color: ${({ theme }) => theme.colors.black1};
  }

  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0;

    .ant-tabs-nav-list {
      width: unset;
      gap: 24px;
    }

    .ant-tabs-tab {
      flex: 0;
    }

    .ant-tabs-tab-active {
      flex: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    width: 35vw;
    height: 100%;
    margin-top: 0;

     .ant-tabs-nav-list {
     gap: 8px;
    }
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-tab {
      flex: 1;
    }

    .ant-tabs-tab-active {
      flex: 1;
    }
}
`;

export const TabWrapper = styled.div`
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
