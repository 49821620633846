import { ISessionLog } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { dateProvider } from '@utils';
import { useMemo } from 'react';

import i18n from '@/i18n';
import { sdk } from '@/services';

const SessionLogService = sdk.getService('SessionLogService');

export const useAccessHistoryByUser = (userId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['session-logs', 'access-history', userId],
    queryFn: () => SessionLogService.findAllByUser(userId)
  });

  const groupedAccessHistory = useMemo(() => {
    if (!data) return {};

    const sortedData = [...data].sort(
      (a, b) =>
        dateProvider.parse(b.createdAt).getTime() - dateProvider.parse(a.createdAt).getTime()
    );

    return sortedData.reduce((acc, log) => {
      const date = dateProvider.parse(log.createdAt);
      const key = `${dateProvider.monthName(date.getMonth(), i18n.language)} ${date.getFullYear()}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(log);
      return acc;
    }, {} as Record<string, ISessionLog[]>);
  }, [data]);

  return {
    accessHistory: data,
    groupedAccessHistory,
    isLoading
  };
};
