import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding: 16px 20px;
  align-self: stretch;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding: 8px 20px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.black3};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.black1};
  }
`;

export const StyledLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blue7};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoWrapper = styled.div`
  display: flex;
  padding-right: 8px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blue7};
  color: ${({ theme }) => theme.colors.white};
`;
