import Lottie from 'lottie-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const StyledLottie = styled(Lottie)`
  width: 40px;
  height: 40px;
  align-self: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  max-width: 90%;

  & > * {
    margin: 0 !important;
  }

  & > h1,
  h2,
  h3 {
    font-size: 1.2rem;
  }

  & > h4 {
    font-size: 1rem;
  }

  & > h5 {
    font-size: 0.8rem;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.red7};
  color: ${({ theme }) => theme.colors.white};
`;

export const FileInfoWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  z-index: 100;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.black9};
  font-weight: 500;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;
