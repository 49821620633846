import { EFeatures } from '@constants';

export const CourseTagsTranslations = {
  CATEGORIES: {
    es: 'Categorías',
    en: 'Tags',
    cat: 'Categories',
    eus: 'Kategoriak',
    pt: 'Categorias'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  ADD_NEW_TAG: {
    es: 'Añadir nueva categoría',
    en: 'Add new tag',
    cat: 'Afegir nova categoria',
    eus: 'Gehitu kategoria berria',
    pt: 'Adicionar nova categoria'
  },
  CATEGORIES_INFO: {
    es: 'Las categorías son lo que se asocia a cada alumno. Aquí podrás editar y crear las categorías (tags) de los cursos. En la pestaña de “Todos los cursos” es donde asocias cada curso a una o varias categorías. Aquí también puedes editar las funcionalidades que tiene activadas cada categoría.',
    en: 'Tags are what is associated with each student. Here you can edit and create the tags of the courses. In the "All courses" tab is where you associate each course with one or more tags. Here you can also edit the functionalities that each tag has activated.',
    cat: 'Les categories són el que s\'associa a cada alumne. Aquí podràs editar i crear les categories (tags) dels cursos. A la pestanya de "Tots els cursos" és on associes cada curs a una o diverses categories. Aquí també pots editar les funcionalitats que té activades cada categoria.',
    eus: 'Kategoriak ikasle bakoitzarekin zerikusia dutenak dira. Hemen editatu eta sortu ahal dituzu ikastaroen kategoriak (etiketak). "Ikast ume guztiak" fitxategian ikastaro bakoitzarekin kategoria bat edo gehiago lotzen duzu. Hemen ere editatu ahal dituzu kategoria bakoitzak aktibatuta dituen funtzionalitateak.',
    pt: 'As categorias são o que está associado a cada aluno. Aqui você pode editar e criar as categorias (tags) dos cursos. Na aba "Todos os cursos" é onde você associa cada curso a uma ou várias categorias. Aqui também pode editar as funcionalidades que cada categoria tem ativadas.'
  },
  TAG_ALREADY_EXISTS: {
    es: 'Ya existe una categoría con ese nombre',
    en: 'There is already a tag with that name',
    cat: 'Ja existeix una categoria amb aquest nom',
    eus: 'Dagoeneko kategoria bat dago izen horrekin',
    pt: 'Já existe uma categoria com esse nome'
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TAG: {
    es: '¿Estás seguro de que quieres eliminar esta categoría?',
    en: 'Are you sure you want to delete this tag?',
    cat: 'Estàs segur que vols eliminar aquesta categoria?',
    eus: 'Zihur zaude kategoria hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta categoria?'
  },
  VERTICAL: {
    es: 'Vertical',
    en: 'Group',
    cat: 'Vertical',
    eus: 'Taldea',
    pt: 'Grupo'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Desar canvis',
    eus: 'Aldaketak gorde',
    pt: 'Salvar alterações'
  },
  DISCARD_CHANGES: {
    es: 'Descartar cambios',
    en: 'Discard changes',
    cat: 'Descartar canvis',
    eus: 'Aldaketak baztertu',
    pt: 'Descartar alterações'
  },
  SAVED_SUCCESSFULLY: {
    es: '¡Cambios guardados con éxito!',
    en: 'Changes saved successfully!',
    cat: 'Canvis desats amb èxit!',
    eus: 'Aldaketak ongi gorde dira!',
    pt: 'Alterações salvas com sucesso!'
  },
  DELETE: {
    es: 'Eliminar categoría',
    en: 'Delete tag',
    cat: 'Eliminar categoria',
    eus: 'Kategoria ezabatu',
    pt: 'Eliminar categoria'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  EDIT_TAG: {
    es: 'Editar categoría',
    en: 'Edit tag',
    cat: 'Editar categoria',
    eus: 'Kategoria aldatu',
    pt: 'Editar categoria'
  },
  ADD_TAG: {
    es: 'Añadir categoría',
    en: 'Add tag',
    cat: 'Afegir categoria',
    eus: 'Gehitu kategoria',
    pt: 'Adicionar categoria'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar categoría',
    en: 'Search tag',
    cat: 'Cerca categoria',
    eus: 'Bilatu kategoria',
    pt: 'Pesquisar categoria'
  },
  FEATURES: {
    es: 'Funcionalidades',
    en: 'Features',
    cat: 'Funcionalitats',
    eus: 'Funtzionalitateak',
    pt: 'Funcionalidades'
  },
  [EFeatures.ANNOUNCEMENTS]: {
    es: 'Convocatorias',
    en: 'Announcements',
    cat: 'Convocatòries',
    eus: 'Deialdiak',
    pt: 'Convocatórias'
  },
  [EFeatures.CONTENT]: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia',
    pt: 'Conteúdo'
  },
  [EFeatures.LIVE_CLASSES]: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Klaseak zuzenean',
    pt: 'Aulas ao vivo'
  },
  [EFeatures.QUIZZES]: {
    es: 'Tests',
    en: 'Quizzes',
    cat: 'Tests',
    eus: 'Galdetegiak',
    pt: 'Testes'
  },
  [EFeatures.TUTORING]: {
    es: 'Tutorías (Chat)',
    en: 'Tutoring (Chat)',
    cat: 'Tutories (Xat)',
    eus: 'Tutoretza (Txat)',
    pt: 'Tutoriais (Chat)'
  },
  [EFeatures.EXAMS_SCORES]: {
    es: 'Notas de examen',
    en: 'Exam scores',
    cat: "Notes d'examen",
    eus: 'Ikasle-notak',
    pt: 'Notas de exame'
  },
  [EFeatures.PLANIFICATION]: {
    es: 'Planificación del estudio',
    en: 'Study plan',
    cat: "Planificació d'estudi",
    eus: 'Ikasle-plan',
    pt: 'Plano de estudo'
  },
  THIS_TAG_HAS_THIS_COURSES_ASSOCIATED: {
    es: 'La categoría de {{tagName}} tiene los siguientes cursos asociados:',
    en: '{{tagName}} has the following courses associated:',
    cat: '{{tagName}} té els següents cursos associats:',
    eus: '{{tagName}}-k hurrengo ikastaroak ditu lotuta:',
    pt: '{{tagName}} tem os seguintes cursos associados:'
  },
  THIS_TAG_HAS_NO_COURSES_ASSOCIATED: {
    es: '{{tagName}} no tiene cursos asociados',
    en: '{{tagName}} has no courses associated',
    cat: '{{tagName}} no té cursos associats',
    eus: '{{tagName}} ez du ikastarorik lotuta',
    pt: '{{tagName}} não tem cursos associados'
  },
  YOU_CAN_ASSOCIATE_COURSES_TO_THIS_TAG_WHEN_IS_CREATED: {
    es: 'Puedes asociar cursos a esta categoría cuando la crees',
    en: 'You can associate courses to this tag when you create it',
    cat: 'Pots associar cursos a aquesta categoria quan la creïs',
    eus: 'Ikastaroak lotu ditzakezu kategoria honi sortzen duzunean',
    pt: 'Pode associar cursos a esta categoria quando a criar'
  },
  ASSOCIATE_TAG_TO_COURSE: {
    es: 'Asociar curso',
    en: 'Associate course',
    cat: 'Associar curs',
    eus: 'Lotu ikastaroa',
    pt: 'Associar curso'
  },
  ARE_YOU_SURE_YOU_WANT_TO_ASSOCIATE_THIS_TAG_TO_THE_COURSE: {
    es: '¿Estás seguro de que quieres asociar la categoría {{tagName}} al curso {{courseName}}?',
    en: 'Are you sure you want to associate the tag {{tagName}} to the course {{courseName}}?',
    cat: 'Estàs segur que vols associar la categoria {{tagName}} al curs {{courseName}}?',
    eus: 'Zihur zaude {{tagName}} kategoria {{courseName}} ikastaroari lotu nahi diozula?',
    pt: 'Tem certeza que deseja associar a categoria {{tagName}} ao curso {{courseName}}?'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_COURSE_FROM_TAG: {
    es: '¿Estás seguro de que quieres eliminar el curso de {{courseName}} este curso de la categoría {{tagName}}?',
    en: 'Are you sure you want to remove the course {{courseName}} from the tag {{tagName}}?',
    cat: 'Estàs segur que vols eliminar el curs de {{courseName}} aquest curs de la categoria {{tagName}}?',
    eus: 'Zihur zaude {{courseName}} ikastaroa ezabatu nahi duzula {{tagName}} kategoria hauetik?',
    pt: 'Tem certeza que deseja eliminar o curso {{courseName}} desta categoria {{tagName}}?'
  }
};
