import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 72px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IndexWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.black9};
  background-color: ${({ theme }) => theme.colors.black9};
  color: ${({ theme }) => theme.colors.white};
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black5} !important;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
`;
