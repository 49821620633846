import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const TitleText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.black5};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const QuestionWrapper = styled.div<{
  $isDone: boolean;
  $isReviewMode: boolean;
  $isCorrect: boolean;
  $isWrong: boolean;
  $isBlank: boolean;
  $isCurrent: boolean;
}>`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  cursor: pointer;

  color: ${({ theme, $isCorrect, $isReviewMode, $isWrong, $isCurrent }) =>
    ($isReviewMode && ($isCorrect || $isWrong)) || $isCurrent
      ? theme.colors.white
      : theme.colors.black9};

  border: ${({ theme, $isReviewMode, $isCorrect, $isWrong, $isBlank }) => {
    if ($isReviewMode) {
      if ($isBlank) return `1px solid ${theme.colors.black9}`;
      if ($isCorrect) return `1px solid ${theme.colors.green6}`;
      if ($isWrong) return `1px solid ${theme.colors.red6}`;
      return `1px solid ${theme.colors.black9}`;
    }
    return `1px solid ${theme.colors.black9}`;
  }};

  background-color: ${({
    theme,
    $isDone,
    $isReviewMode,
    $isCorrect,
    $isWrong,
    $isBlank,
    $isCurrent
  }) => {
    if ($isReviewMode) {
      if ($isBlank) return theme.colors.black2;
      if ($isCorrect) return theme.colors.green6;
      if ($isWrong) return theme.colors.red6;
      return theme.colors.white;
    }

    if ($isCurrent) return theme.colors.black9;
    if ($isDone) return theme.colors.black4;

    return 'transparent';
  }};
`;
