import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TeacherVoteService = sdk.getService('TeacherVoteService');

export const useCurrentTeachersVotes = () => {
  const queryKey = ['teacher-vote', 'current-teachers-votes'];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => TeacherVoteService.findCurrentTeachersVotes()
  });

  return {
    teachersVotes: data,
    isPending
  };
};
