export const MyAcademyTranslations = {
  ACADEMY_NAME: {
    es: 'Nombre de la academia',
    en: 'Academy name',
    cat: 'Nom de l`acadèmia',
    eus: 'Akademiaren izena',
    pt: 'Nome da academia'
  },
  ACADEMY_DESCRIPTION: {
    es: 'Descripción de la academia',
    en: 'Academy description',
    cat: 'Descripció de l`acadèmia',
    eus: 'Akademiaren deskribapena',
    pt: 'Descrição da academia'
  },
  ACADEMY_DOMAIN: {
    es: 'Dominio de la academia',
    en: 'Academy domain',
    cat: 'Domini de l`acadèmia',
    eus: 'Akademiaren domeinua',
    pt: 'Domínio da academia'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Desar canvis',
    eus: 'Aldaketak gorde',
    pt: 'Guardar alterações'
  },
  ACADEMY_PROFILE_UPDATED: {
    es: 'Perfil de la academia actualizado',
    en: 'Academy profile updated',
    cat: 'Perfil de l`acadèmia actualitzat',
    eus: 'Akademiaren profilak eguneratu da',
    pt: 'Perfil da academia atualizado'
  },
  CERTIFICATE_TEMPLATE: {
    es: 'Plantilla de certificado',
    en: 'Certificate template',
    cat: 'Plantilla de certificat',
    eus: 'Agiriaren txantiloia',
    pt: 'Modelo de certificado'
  },
  WEBSITE: {
    es: 'Sitio web',
    en: 'Website',
    cat: 'Lloc web',
    eus: 'Webgunea',
    pt: 'Website'
  }
};
