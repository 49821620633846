export const CommonTutoringGroupTranslations = {
  ADD_MEMBERS: {
    es: 'Añadir miembros',
    en: 'Add members',
    cat: 'Afegir membres',
    eus: 'Gehitu kideak',
    pt: 'Adicionar membros'
  },
  DELETE_MEMBER: {
    es: 'Eliminar miembro',
    en: 'Delete member',
    cat: 'Eliminar membre',
    eus: 'Kide bat ezabatu',
    pt: 'Excluir membro'
  },
  TAGS: {
    es: 'Formación',
    en: 'Formation',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formação'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email',
    pt: 'Email'
  },
  LAST_NAME: {
    es: 'Apellidos',
    en: 'Last name',
    cat: 'Cognoms',
    eus: 'Abizenak',
    pt: 'Apelido'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  TUTOR: {
    es: 'Tutor:',
    en: 'Tutor:',
    cat: 'Tutor:',
    eus: 'Tutorea:',
    pt: 'Tutora:'
  },
  IN_GROUP: {
    es: 'En grupo:',
    en: 'In group:',
    cat: 'En grup:',
    eus: 'Taldean:',
    pt: 'No grupo:'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  MESSAGES: {
    es: 'Mensajes',
    en: 'Messages',
    cat: 'Missatges',
    eus: 'Mezuak',
    pt: 'Mensagens'
  },
  UNREAD_MANAGER: {
    es: 'Sin leer por el manager',
    en: 'Unread by manager',
    cat: 'Sense llegir pel manager',
    eus: 'Ez dago zuzenketa gabe',
    pt: 'Não corrigido pelo manager'
  },
  UNREAD_TEACHER: {
    es: 'Sin leer por el tutor',
    en: 'Unread by teacher',
    cat: 'Sense llegir pel tutor',
    eus: 'Ez dago zuzenketa gabe',
    pt: 'Não corrigido pelo tutor'
  },
  WITHOUT_CORRECTION: {
    es: 'Sin corrección',
    en: 'Without correction',
    cat: 'Sense correcció',
    eus: 'Zuzenketa gabe',
    pt: 'Sem correção'
  },
  REGISTRATION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Registration date',
    cat: "Data d'inscripció",
    eus: 'Izena eman den data',
    pt: 'Data de inscrição'
  },
  LAST_CONNECTION_DATE: {
    es: 'Última conexión',
    en: 'Last connection',
    cat: 'Última connexió',
    eus: 'Azken konexioa',
    pt: 'Última conexão'
  },
  SEND_BROADCAST_MESSAGE: {
    es: 'Enviar mensaje de difusión',
    en: 'Send broadcast message',
    cat: 'Enviar missatge difós',
    eus: 'Txertatu mezu zabaltu',
    pt: 'Enviar mensagem de difusão'
  },
  SEND_TO_ALL_MEMBERS: {
    es: 'Enviar mensaje a todos',
    en: 'Send message to all',
    cat: 'Enviar missatge a tots',
    eus: 'Txertatu mezu guztiei',
    pt: 'Enviar mensagem a todos'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Desar',
    eus: 'Txertatu',
    pt: 'Enviar'
  },
  MESSAGES_SENT_SUCCESSFULLY: {
    es: 'Mensajes enviados correctamente',
    en: 'Messages sent successfully',
    cat: 'Missatges enviats correctament',
    eus: 'Mezuak ongi bidali dira',
    pt: 'Mensagens enviados com sucesso'
  }
};
