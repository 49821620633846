import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-collapse {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none;
    background-color: ${({ theme }) => theme.colors.black1};
  }

  .ant-collapse-item {
    border-radius: 8px !important;
    border: 1px solid ${({ theme }) => theme.colors.black2};
    background: ${({ theme }) => theme.colors.white};
  }

  .ant-collapse-header {
    padding: 8px !important;
  }

  .ant-collapse-content-active {
    border-radius: 0 0 8px 8px !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-collapsible-disabled {
    background-color: ${({ theme }) => theme.colors.black3};
    border-radius: 8px !important;
  }
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;
