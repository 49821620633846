import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 0px 16px 24px 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  height: 100%;
  overflow-y: auto;
`;

export const UserMessageWrapper = styled.div`
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: flex-end;
  border-radius: 8px 8px 0px 8px;
  background: ${({ theme }) => theme.colors.black3};
  max-width: 80%;
  word-break: break-word;
`;
