import { ICreateTeacherVote, ICreateTutorVote } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const TeacherVoteService = sdk.getService('TeacherVoteService');

export const useMutateTeacherVote = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('DefaultTranslations');

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['teacher-vote'] });

  const { mutate: createTutorVote, isPending: isVotingTutor } = useMutation({
    mutationFn: (vote: ICreateTutorVote) => TeacherVoteService.createTutorVote(vote),
    onSuccess: () => {
      onSuccess();
      message.success(t('TUTOR_VOTE_THANK_YOU'));
    }
  });

  const { mutate: createTeacherVote, isPending: isVotingTeacher } = useMutation({
    mutationFn: (vote: ICreateTeacherVote) => TeacherVoteService.createTeacherVote(vote),
    onSuccess: data => {
      onSuccess();
      message.success(t('TEACHER_VOTE_THANK_YOU', { name: data.teacher.name }));
    }
  });

  return {
    createTutorVote,
    createTeacherVote,
    isVoting: isVotingTutor || isVotingTeacher
  };
};
