import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    width: 62vw;
    height: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TitleText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
