import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

  .ant-form {
    width: 100%;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  padding-bottom: 24px;

  .ant-select-selector {
    min-width: 140px;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
