import styled from 'styled-components';

import { WidgetWrapper } from '../styles';

export const Wrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const EventTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row-reverse;
  }

  & > div {
    display: flex;
    gap: 8px;
    align-items: center;

    & > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const SpeakersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    & > :nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      & > :first-child {
        font-weight: 400;
        font-size: 16px;
      }
      & > :last-child {
        font-weight: 300;
      }
    }
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex: none;
`;
