import styled from 'styled-components';

type Props = {
  $voted?: boolean;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme, $voted }) => ($voted ? 'transparent' : theme.colors.white)};

  .ant-rate {
    color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.green6)};
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 800px;
    padding: 16px 20px;
    gap: 32px;
  }
`;

export const TeacherNameWrapper = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.black9)};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const CourseNameWrapper = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.black9)};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    gap: 16px;
  }
`;
