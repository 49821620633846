import { ISubscription } from '@sdk/contracts';

export const getPreviousActivityTrack = (subscription?: ISubscription, activity?: string) => {
  if (!subscription) return null;
  const { course, currentActivity = '' } = subscription;
  const { sections = [] } = course || {};
  const activityCompleted = activity ?? currentActivity;

  const activities = sections.map(s => s.activities).flat();

  const unlockedActivities = activities.filter(a => !a.isLocked);

  const currentActivityIndex = unlockedActivities.findIndex(a => a._id === activityCompleted);
  const previousActivity = unlockedActivities[currentActivityIndex - 1];

  if (previousActivity) {
    const previousActivitySection = sections.find(s =>
      s.activities.some(a => a._id === previousActivity._id)
    );
    if (previousActivitySection) {
      return {
        course: course._id,
        section: previousActivitySection._id,
        activity: previousActivity._id
      };
    }
  }

  return null;
};
