export const ExamGroupsTranslations = {
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Convocatorias',
    pt: 'Convocatórias'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  CREATE_EXAM_GROUP: {
    es: 'Crear convocatoria',
    en: 'Create exam group',
    cat: 'Crear convocatòria',
    eus: 'Convocatoria sortu',
    pt: 'Criar convocatória'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  TAG: {
    es: 'Categoría',
    en: 'Category',
    cat: 'Categoria',
    eus: 'Kategoria',
    pt: 'Categoria'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  PUBLICATION_DATE: {
    es: 'Fecha de publicación',
    en: 'Publication date',
    cat: 'Data de publicació',
    eus: 'Argitaratze data',
    pt: 'Data de publicação'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  CCAA: {
    es: 'CCAA',
    en: 'CCAA',
    cat: 'CCAA',
    eus: 'CCAA',
    pt: 'CCAA'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu',
    pt: 'Editar'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu',
    pt: 'Eliminar'
  },
  DELETE_SELECTED: {
    es: 'Eliminar seleccionados ({{ count }})',
    en: 'Delete selected ({{ count }})',
    cat: 'Eliminar seleccionats ({{ count }})',
    eus: 'Ezabatu hautatutakoak ({{ count }})',
    pt: 'Eliminar selecionados ({{ count }})'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THESE_GROUPS: {
    es: '¿Seguro que deseas eliminar estas convocatorias?',
    en: 'Are you sure you want to delete these exam groups?',
    cat: 'Segur que vols eliminar aquestes convocatòries?',
    eus: '¿Seguro que deseas eliminar estas convocatorias?',
    pt: 'Tem certeza que deseja eliminar estas convocatórias?'
  },
  EDIT_EXAM_GROUP: {
    es: 'Editar convocatoria',
    en: 'Edit exam group',
    cat: 'Editar convocatòria',
    eus: 'Editatu convocatoria',
    pt: 'Editar convocatória'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  EXAM_GROUP_NUMBER: {
    es: 'Convocatoria {{ number }}',
    en: 'Exam group {{ number }}',
    cat: 'Convocatòria {{ number }}',
    eus: 'Convocatoria {{ number }}',
    pt: 'Convocatória {{ number }}'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad Autónoma',
    en: 'Autonomous Community',
    cat: 'Comunitat Autònoma',
    eus: 'Autonomia Erkidegoa',
    pt: 'Comunidade Autónoma'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia',
    pt: 'Província'
  },
  LOCATION: {
    es: 'Localidad',
    en: 'Location',
    cat: 'Localitat',
    eus: 'Herria',
    pt: 'Localidade'
  },
  SEATS: {
    es: 'Número de plazas',
    en: 'Number of places',
    cat: 'Nombre de places',
    eus: 'Plaza kopurua',
    pt: 'Número de lugares'
  },
  DEADLINE: {
    es: 'Plazo de presentación',
    en: 'Deadline',
    cat: 'Termini de presentació',
    eus: 'Aurkezteko epea',
    pt: 'Prazo de apresentação'
  },
  BUSINESS_DAYS: {
    es: 'días naturales',
    en: 'natural days',
    cat: 'dies naturals',
    eus: 'egun natural',
    pt: 'dias naturais'
  },
  EXAM_DATE: {
    es: 'Fecha del examen',
    en: 'Exam date',
    cat: "Data de l'examen",
    eus: 'Proba eguna',
    pt: 'Data do exame'
  },
  EXAM_DATE_TOOLTIP: {
    es: 'Introducir sólo cuando se haga pública la fecha definitiva del examen',
    en: 'Enter only when the final exam date is made public',
    cat: 'Introduir només quan es faci pública la data definitiva de l’examen',
    eus: 'Sartu bakarrik azken probaren data publikoa egiten den unean',
    pt: 'Introduzir apenas quando a data definitiva do exame for publicada'
  },
  ATTACH_MATERIAL: {
    es: 'Adjuntar material adicional',
    en: 'Attach additional material',
    cat: 'Adjuntar material addicional',
    eus: 'Gehigarri materiala erantsi',
    pt: 'Anexar material adicional'
  },
  ADD_LINK: {
    es: 'Añadir enlace externo',
    en: 'Add external link',
    cat: 'Afegir enllaç extern',
    eus: 'Gehitu esteka kanpoko',
    pt: 'Adicionar link externo'
  },
  EXAM_GROUP_UPDATED_SUCCESSFULLY: {
    es: 'Convocatoria actualizada correctamente',
    en: 'Exam group updated successfully',
    cat: 'Convocatòria actualitzada amb èxit',
    eus: 'Convocatoria ongi eguneratu da',
    pt: 'Convocatória atualizada com sucesso'
  },
  EXAM_GROUP_CREATED_SUCCESSFULLY: {
    es: 'Convocatoria creada correctamente',
    en: 'Exam group created successfully',
    cat: 'Convocatòria creada amb èxit',
    eus: 'Convocatoria ongi sortu da',
    pt: 'Convocatória criada com sucesso'
  },
  EXAM_GROUP_DELETED_SUCCESSFULLY: {
    es: 'Conovocatoria eliminada correctamente',
    en: 'Exam group deleted successfully',
    cat: 'Convocatòria eliminada amb èxit',
    eus: 'Convocatoria ongi ezabatu da',
    pt: 'Convocatória eliminada com sucesso'
  },
  EXAM_GROUPS_DELETED_SUCCESSFULLY: {
    es: 'Convocatorias eliminadas correctamente',
    en: 'Exam groups deleted successfully',
    cat: 'Convocatòries eliminades amb èxit',
    eus: 'Convocatorias ongi ezabatu dira',
    pt: 'Convocatórias eliminadas com sucesso'
  },
  MEMBERS_ADDED_SUCCESSFULLY: {
    es: 'Miembros añadidos correctamente',
    en: 'Members added successfully',
    cat: 'Membres afegits amb èxit',
    eus: 'Kideak ongi gehitu dira',
    pt: 'Membros adicionados com sucesso'
  },
  MEMBER_REMOVED_SUCCESSFULLY: {
    es: 'Miembro eliminado correctamente',
    en: 'Member removed successfully',
    cat: 'Membre eliminat amb èxit',
    eus: 'Kidea ongi kendu da',
    pt: 'Membro eliminado com sucesso'
  },
  EXAM_GROUPS_TOTAL_COUNT: {
    es: '{{total}} convocatorias',
    en: '{{total}} exam groups',
    cat: '{{total}} convocatòries',
    eus: '{{total}} convocatorias',
    pt: '{{total}} convocatórias'
  },
  REMOVE_EXAM_GROUP_CONFIRMATION: {
    es: '¿Estás seguro que deseas eliminar esta convocatoria?',
    en: 'Are you sure you want to remove this exam group?',
    cat: 'Estàs segur que vols eliminar aquesta convocatòria?',
    eus: 'Ziur zaude convocatoria hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta convocatória?'
  },
  EXAM_GROUPS_EMPTY: {
    es: 'No tienes convocatorias creadas',
    en: 'You have no exam groups created',
    cat: 'No tens convocatòries creades',
    eus: 'Ez duzu convocatoria sorturik',
    pt: 'Não tem convocatórias criadas'
  },
  INVALID_URL: {
    es: 'URL no válida',
    en: 'Invalid URL',
    cat: 'URL no vàlid',
    eus: 'URL baliogabea',
    pt: 'URL inválida'
  },
  POSITION: {
    es: 'Puesto',
    en: 'Position',
    cat: 'Posició',
    eus: 'Postua',
    pt: 'Posição'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email',
    pt: 'Email'
  },
  SCORING: {
    es: 'Scoring',
    en: 'Scoring',
    cat: 'Scoring',
    eus: 'Scoring',
    pt: 'Scoring'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota',
    pt: 'Nota média'
  },
  STUDIED_CONTENT_PERCENTAGE: {
    es: '% Estudiado',
    en: '% Studied',
    cat: '% Estudiat',
    eus: '% Ikasitako',
    pt: '% Estudado'
  },
  REMOVE_MEMBER_CONFIRMATION: {
    es: '¿Estás seguro que quieres eliminar a este miembro?',
    en: 'Are you sure you want to remove this member?',
    cat: 'Estàs segur que vols eliminar aquest membre?',
    eus: 'Ziur zaude kide hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este membro?'
  },
  ADD_MEMBERS: {
    es: 'Añadir miembros',
    en: 'Add members',
    cat: 'Afegir membres',
    eus: 'Gehitu kideak',
    pt: 'Adicionar membros'
  },
  SCORING_TOOLTIP: {
    es: 'El 50% del scoring es por la nota media obtenida en los tests. El 35% es del % del contenido total que has estudiado. El 15% son los puntos respecto de la puntuación máxima de la convocatoria',
    en: '50% of the scoring is for the average score obtained in the tests. 35% is the % of the total content you have studied. 15% are the points with respect to the maximum score of the exam group',
    cat: 'El 50% del scoring es per la nota mitjana obtinguda en els tests. El 35% és del % del contingut total que has estudiat. El 15% són els punts respecte de la puntuació màxima de la convocatòria',
    eus: 'Scoring-en % 50a probetan lortutako batazbesteko puntuazioa da. % 35a ikasitako eduki guztien % a da. % 15ak puntuazio maximoaren arabera daude',
    pt: 'O scoring de 50% é para a nota média obtida nos testes. 35% é do % do conteúdo total que estudou. 15% são os pontos em relação à pontuação máxima da convocatória'
  },
  EXAM_GROUP_DATA: {
    es: 'Datos de la convocatoria',
    en: 'Exam group data',
    cat: 'Dades de la convocatòria',
    eus: 'Deialdiaren datuak',
    pt: 'Dados da convocatória'
  },
  EXAM_GROUP_DATA_TOOLTIP: {
    es: 'Si el dato que buscas en esta tabla no está es que no ha sido publicado aún. Cuando se publique te lo notificaremos y actualizaremos la tabla',
    en: 'If the data you are looking for in this table is not there, it has not been published yet. When it is published we will notify you and update the table',
    cat: 'Si la dada que busques en aquesta taula no està és que no ha estat publicada encara. Quan es publiqui te la notificarem i actualitzarem la taula',
    eus: 'Taulan bilatzen duzun datua ez badago, oraindik ez da argitaratu. Argitaratzen dugunean jakinaraziko dizugu eta taula eguneratuko dugu',
    pt: 'Se o dado que você está procurando nesta tabela não está, é porque ainda não foi publicado. Quando for publicado, você será notificado e a tabela será atualizada'
  },
  NOT_PUBLISHED: {
    es: 'No publicado',
    en: 'Not published',
    cat: 'No publicat',
    eus: 'Ez da argitaratu',
    pt: 'Não publicado'
  },
  SEATS_TOTAL_COUNT: {
    es: '{{total}} plazas',
    en: '{{total}} seats',
    cat: '{{total}} places',
    eus: '{{total}} plaza',
    pt: '{{total}} lugares'
  },
  PUBLISHED_AT: {
    es: 'Fecha de publicación de la convocatoria',
    en: 'Publication date of the exam group',
    cat: 'Data de publicació de la convocatòria',
    eus: 'Deialdiaren argitaratze data',
    pt: 'Data de publicação da convocatória'
  },
  DEADLINE_DAYS: {
    es: 'Plazo de presentación',
    en: 'Deadline',
    cat: 'Termini de presentació',
    eus: 'Aurkezteko epea',
    pt: 'Prazo de apresentação'
  },
  EXAM_GROUP_TITLE: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak',
    pt: 'Convocatórias'
  },
  EXAM_GROUP_DESCRIPTION: {
    es: 'Aquí puedes ver los datos de las convocatorias a las que te has apuntado',
    en: 'Here you can see the data of the exam groups you have applied to',
    cat: 'Aquí pots veure les dades de les convocatòries a les quals t’has apuntat',
    eus: 'Hemen ikus dezakezu apuntatu zaren deialdien datuak',
    pt: 'Aqui pode ver os dados das convocatórias às quais você se inscreveu'
  },
  EMPTY_INFO: {
    es: 'No estás apuntando a ninguna convocatoria',
    en: 'You are not applying to any exam group',
    cat: 'No estàs apuntant a cap convocatòria',
    eus: 'Ez zaude inongo deialdira apuntatuta',
    pt: 'Você não está se inscrevendo em nenhuma convocatória'
  },
  EMPTY_INFO_DESCRIPTION: {
    es: 'Ups, parece que no has seleccionado aún una convocatoria. Selecciona al menos una ahora y mantente informado de todo lo que necesitas y mucho más.',
    en: 'Ups, it seems that you have not yet selected an exam group. Select at least one now and stay informed of everything you need and much more.',
    cat: 'Ups, sembla que encara no has seleccionat una convocatòria. Selecciona almenys una ara i mantingues informat de tot el que necessites i molt més.',
    eus: 'Ups, badirudi ez duzula oraindik deialdi bat hautatu. Hautatu gutxienez bat orain eta mantendu informatuta behar duzun guztiz eta askoz gehiago.',
    pt: 'Ups, parece que ainda não selecionou uma convocatória. Selecione pelo menos uma agora e mantenha-se informado sobre tudo o que precisa e muito mais.'
  },
  REVIEW_AUTOMATICALLY_SCRAPPED_DATA: {
    es: 'Revisar convocatorias automáticas',
    en: 'Review automatically scrapped data',
    cat: 'Revisar convocatòries automàtiques',
    eus: 'Berrikusi automatikoki eskuratutako datuak',
    pt: 'Revisar dados automaticamente extraídos'
  },
  SCRAPPED_EXAM_GROUPS: {
    es: 'Convocatorias automáticas',
    en: 'Automatic exam groups',
    cat: 'Convocatòries automàtiques',
    eus: 'Convocatorias automáticas',
    pt: 'Convocatórias automáticas'
  },
  SCRAPPED_EXAM_GROUPS_EMPTY: {
    es: 'Vaya, parece que no hay convocatorias automáticas',
    en: 'Oops, it seems there are no automatic exam groups',
    cat: 'Vaja, sembla que no hi ha convocatòries automàtiques',
    eus: 'Vaya, parece que no hay convocatorias automáticas',
    pt: 'Oops, parece que não há convocatórias automáticas'
  },
  ALREADY_USED: {
    es: 'En uso',
    en: 'Already used',
    cat: 'En ús',
    eus: 'En uso',
    pt: 'Em uso'
  },
  SIGN_UP_LATER: {
    es: 'Unirme más tarde',
    en: 'Sign up later',
    cat: 'Unir-me més tard',
    eus: 'Unirme más tarde',
    pt: 'Inscreva-se mais tarde'
  },
  DELETE_GROUP_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar esta convocatoria?',
    en: 'Are you sure you want to delete this exam group?',
    cat: 'Estàs segur que vols eliminar aquesta convocatòria?',
    eus: '¿Estás seguro de que quieres eliminar esta convocatoria?',
    pt: 'Tem certeza que deseja eliminar esta convocatória?'
  },
  SCRAPPED_EXAM_GROUP_DELETED_SUCCESSFULLY: {
    es: 'Convocatoria eliminada correctamente',
    en: 'Exam group deleted successfully',
    cat: 'Convocatòria eliminada correctament',
    eus: 'Convocatoria eliminada correctamente',
    pt: 'Convocatória eliminada com sucesso'
  },
  KNOWLEDGE: {
    es: 'Conocimiento',
    en: 'Knowledge',
    cat: 'Coneixement',
    eus: 'Conocimiento',
    pt: 'Conhecimento'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu',
    pt: 'Estado'
  },
  EXAMS: {
    es: 'Exámenes',
    en: 'Exams',
    cat: 'Exàmens',
    eus: 'Proba',
    pt: 'Exame'
  },
  HOW_DO_EXAM_GROUPS_WORK: {
    es: '¿Cómo funcionan las convocatorias?',
    en: 'How do exam groups work?',
    cat: 'Com funcionen les convocatòries?',
    eus: '¿Cómo funcionan las convocatorias?',
    pt: 'Como funcionam as convocatórias?'
  },
  EXAM_GROUP_NAME: {
    es: 'Nombre de la convocatoria',
    en: 'Exam group name',
    cat: 'Nom de la convocatòria',
    eus: 'Convocatoria izena',
    pt: 'Nome da convocatória'
  },
  JOIN_EXAM_GROUP: {
    es: 'Unirme a una convocatoria',
    en: 'Join an exam group',
    cat: 'Unir-me a una convocatòria',
    eus: 'Unirme a una convocatoria',
    pt: 'Inscreva-se em uma convocatória'
  },
  CONVENER: {
    es: 'Convocante',
    en: 'Convener',
    cat: 'Convocant',
    eus: 'Convocante',
    pt: 'Convocante'
  },
  JOINED_EXAM_GROUP: {
    es: 'Te has unido a esta convocatoria',
    en: 'You have joined this exam group',
    cat: 'Et has apuntat a aquesta convocatòria',
    eus: 'Ez zaude inongo deialdira sartu',
    pt: 'Você se inscreveu nesta convocatória'
  },
  LEFT_EXAM_GROUP: {
    es: 'Has abandonado esta convocatoria',
    en: 'You have left this exam group',
    cat: 'Has abandonat aquesta convocatòria',
    eus: 'Ez zaude inongo deialdira atera',
    pt: 'Você saiu desta convocatória'
  }
};
