export const AcademiesTranslations = {
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak',
    pt: 'Académias'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  DOMAIN: {
    es: 'Dominio',
    en: 'Domain',
    cat: 'Domini',
    eus: 'Dominioa',
    pt: 'Domínio'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  MANAGER: {
    es: 'Gerente',
    en: 'Manager',
    cat: 'Gerent',
    eus: 'Kudeatzailea',
    pt: 'Gerente'
  },
  NEW_ACADEMY: {
    es: 'Nueva Academia',
    en: 'New Academy',
    cat: 'Nova Acadèmia',
    eus: 'Akademia Berria',
    pt: 'Académia Nova'
  },
  EDIT_ACADEMY: {
    es: 'Editar Academia',
    en: 'Edit Academy',
    cat: 'Editar Acadèmia',
    eus: 'Akademia Aldatu',
    pt: 'Académia Editar'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde',
    pt: 'Salvar Alterações'
  },
  CREATE_ACADEMY: {
    es: 'Crear Academia',
    en: 'Create Academy',
    cat: 'Crear Acadèmia',
    eus: 'Akademia Sortu',
    pt: 'Académia Criar'
  },
  ACADEMIES_TOTAL_COUNT: {
    es: '{{total}} academias',
    en: '{{total}} academies',
    cat: '{{total}} acadèmies',
    eus: '{{total}} akademia',
    pt: '{{total}} académias'
  },
  CERTIFICATE_TEMPLATE: {
    es: 'Plantilla de Certificado',
    en: 'Certificate Template',
    cat: 'Plantilla de Certificat',
    eus: 'Agiria Txantiloia',
    pt: 'Modelo de Certificado'
  }
};
