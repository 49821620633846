import { RequestService } from '../request';

import { ILiveClassVote, IUpdateLiveClassVote } from './live-class-vote.contract';

export class LiveClassVoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  async findMyLastWeekVotes() {
    return this.fetcher<ILiveClassVote[]>(`/live-class-vote/last-week/me`);
  }

  async checkPendingVotes() {
    return this.fetcher<ILiveClassVote[]>(`/live-class-vote/check-pending-votes`);
  }

  async voteLiveClass(data: IUpdateLiveClassVote) {
    return this.putter<ILiveClassVote, IUpdateLiveClassVote>(`/live-class-vote`, data);
  }

  async downloadLiveClassVoteReport() {
    return this.fetcher<ILiveClassVote[]>(`/live-class-vote/download-live-class-vote-report`);
  }
}
