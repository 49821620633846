import { EOnboardingChosenReason, EOnboardingDiscoverySource } from '@constants';

export const StudentOnboardingTranslations = {
  [EOnboardingDiscoverySource.GOOGLE]: {
    es: 'Busqué en Google',
    en: 'I searched on Google',
    cat: 'Vaig buscar a Google',
    eus: 'Google-n bilatu nuen',
    pt: 'Busquei no Google'
  },
  [EOnboardingDiscoverySource.SOCIAL_MEDIA]: {
    es: 'En una red social',
    en: 'On social media',
    cat: 'En una xarxa social',
    eus: 'Sare sozialean',
    pt: 'No meio social'
  },
  [EOnboardingDiscoverySource.ADVERTISEMENT]: {
    es: 'En publicidad de redes sociales',
    en: 'In social media advertising',
    cat: 'En publicitat de xarxes socials',
    eus: 'Sare sozialen publizitatean',
    pt: 'Na publicidade de mídias sociais'
  },
  [EOnboardingDiscoverySource.FLYER]: {
    es: 'En una pancarta por la calle',
    en: 'On a street banner',
    cat: 'En una pancarta pel carrer',
    eus: 'Kaleko kartelan',
    pt: 'Na pancarta da rua'
  },
  [EOnboardingDiscoverySource.FRIEND]: {
    es: 'Recomendación de amigo/a',
    en: 'Friend recommendation',
    cat: 'Recomanació d`amic/a',
    eus: 'Lagun baten gomendioa',
    pt: 'Recomendação de amigo/a'
  },
  [EOnboardingDiscoverySource.OTHER]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altra',
    eus: 'Beste bat',
    pt: 'Outro'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continuar',
    eus: 'Jarraitu',
    pt: 'Continuar'
  },
  CHOSEN_REASON_TITLE: {
    es: '¿Y finalmente por qué motivo elegiste a Ucademy ☺️?',
    en: 'And finally, why did you choose Ucademy ☺️?',
    cat: 'I finalment, per quin motiu vas triar Ucademy ☺️?',
    eus: 'Eta azkenik, zergatik hautatu zinen Ucademy ☺️?',
    pt: 'E finalmente, por que motivo escolheu o Ucademy ☺️?'
  },
  [`${EOnboardingChosenReason.QUIZ}_OPTION`]: {
    es: 'Los tipo test',
    en: 'The multiple choice questions',
    cat: 'Els tipus test',
    eus: 'Test motak',
    pt: 'Questões de múltipla escolha'
  },
  [`${EOnboardingChosenReason.MICROLESSON}_OPTION`]: {
    es: 'Microclases en video',
    en: 'Video microlessons',
    cat: 'Microclasses en vídeo',
    eus: 'Bideo mikrolehenak',
    pt: 'Aulas em vídeo'
  },
  [`${EOnboardingChosenReason.TEACHER_FOLLOW_UP}_OPTION`]: {
    es: 'Seguimiento del profesorado',
    en: 'Teacher follow-up',
    cat: 'Seguiment del professorat',
    eus: 'Irakasleen jarraipena',
    pt: 'Acompanhamento do professor'
  },
  [`${EOnboardingChosenReason.SUCCESS_GUARANTEE}_OPTION`]: {
    es: 'Garantía de aprobado',
    en: 'Pass guarantee',
    cat: 'Garantia d`aprovat',
    eus: 'Onarpenaren bermea',
    pt: 'Garantia de aprovação'
  },
  [`${EOnboardingChosenReason.ATTENTION}_OPTION`]: {
    es: 'Atención de mi asesor/a',
    en: 'Attention from my advisor',
    cat: 'Atenció del meu assessor/a',
    eus: 'Nire aholkulariaren arreta',
    pt: 'Atendimento do meu assessor/a'
  },
  [`${EOnboardingChosenReason.HELP_IN_EVERYTHING}_OPTION`]: {
    es: 'Por ayudarme en todo',
    en: 'For helping me in everything',
    cat: 'Per ajudar-me en tot',
    eus: 'Denetan laguntzeko',
    pt: 'Para ajudar-me em tudo'
  },
  [`${EOnboardingChosenReason.OTHER}_OPTION`]: {
    es: 'Otro',
    en: 'Other',
    cat: 'Altra',
    eus: 'Beste bat',
    pt: 'Outro'
  },
  CAN_YOU_TELL_US_HIS_NAME: {
    es: '¿Puedes decirnos como se llama?',
    en: 'Can you tell us his name?',
    cat: 'Pots dir-nos com es diu?',
    eus: 'Zer izena duela esan dezakezu?',
    pt: 'Pode dizer-nos o nome dele?'
  },
  ONLY_TO_KNOW_IF_WE_KNOW_HIM: {
    es: 'Solo para saber si lo conocemos',
    en: 'Just to know if we know him',
    cat: 'Només per saber si el coneixem',
    eus: 'Guk ezagutzen dugula jakiteko bakarrik',
    pt: 'Apenas para saber se o conhecemos'
  },
  NAME_AND_SURNAME: {
    es: 'Nombre y apellidos',
    en: 'Name and surname',
    cat: 'Nom i cognoms',
    eus: 'Izena eta abizenak',
    pt: 'Nome e apelidos'
  },
  GREAT: {
    es: '¡Genial!',
    en: 'Great!',
    cat: 'Genial!',
    eus: 'Zoragarria!',
    pt: 'Ótimo!'
  },
  DO_YOU_KNOW_OTHER_PEOPLE: {
    es: '¿Y conoces a más gente que estudie lo mismo que tú?',
    en: 'Do you know more people who study the same as you?',
    cat: 'I coneixes més gent que estudiï el mateix que tu?',
    eus: 'Eta ezagutzen duzu zuk bera ikasten duen beste jende bat?',
    pt: 'Conhece mais pessoas que estudam o mesmo que você?'
  },
  TELL_US: {
    es: 'Cuéntanos',
    en: 'Tell us',
    cat: "Explica'ns",
    eus: 'Esan zaitez',
    pt: 'Explique-nos'
  },
  HOW_DID_YOU_DISCOVER_US: {
    es: '¿Cómo nos conociste por primera vez?',
    en: 'How did you first hear about us?',
    cat: 'Com ens vas conèixer per primera vegada?',
    eus: 'Nola ezagutu zintuzten lehenengo aldian?',
    pt: 'Como descobriu-nos pela primeira vez?'
  },
  WHY_DID_YOU_CHOOSE_US: {
    es: '¿Por qué nos has elegido?',
    en: 'Why did you choose us?',
    cat: 'Per què ens has triat?',
    eus: 'Zergatik hautatu zaitugu?',
    pt: 'Por que escolheu-nos?'
  },
  START_NOW: {
    es: 'Empezar ya',
    en: 'Start now',
    cat: 'Començar ara',
    eus: 'Hasi orain',
    pt: 'Começar agora'
  },
  START: {
    es: 'Empezar',
    en: 'Start',
    cat: 'Començar',
    eus: 'Hasi',
    pt: 'Começar'
  },
  PLAN_LATER: {
    es: 'Planificar más tarde',
    en: 'Plan later',
    cat: 'Planificar més tard',
    eus: 'Atzeratu planifikazioa',
    pt: 'Planear mais tarde'
  },
  CONTINUE_PLAN: {
    es: 'Continuar planificando',
    en: 'Continue planning',
    cat: 'Continuar planificant',
    eus: 'Jarraitu planifikatzen',
    pt: 'Continuar a planificar'
  },
  SURE_DONT_WANT_TO_PLAN: {
    es: '¿Estás seguro de que no quieres hacer la planificación del estudio ahora?',
    en: 'Are you sure you don’t want to plan your study now?',
    cat: 'Estàs segur que no vols fer la planificació de l`estudi ara?',
    eus: 'Ziur zaude ez zarela nahi zure ikasketen planifikazioa egin orain?',
    pt: 'Tem certeza de que não quer planejar o seu estudo agora?'
  },
  SKIP_PLAN_SUBTITLE: {
    es: 'Confía, que te va a venir bien: Un 72% de los alumnos que se planifican bien antes de empezar consiguen sus objetivos.',
    en: 'Trust me, it will be good for you: 72% of students who plan well before starting achieve their goals.',
    cat: 'Confia, que et vindrà bé: Un 72% dels alumnes que es planifiquen bé abans de començar aconsegueixen els seus objectius.',
    eus: 'Sinetsi, ondo etorriko zaizu: Ikasleek %72 ondo planifikatzen direnean hasi aurretik helburuak lortzen dituzte.',
    pt: 'Confie, vai ser bom para você: 72% dos alunos que planejam bem antes de começar conseguem os seus objetivos.'
  },
  SKIP_AND_STUDY: {
    es: 'Saltar e ir a estudiar',
    en: 'Skip and go study',
    cat: 'Saltar i anar a estudiar',
    eus: 'Saltatu eta ikasten joan',
    pt: 'Pular e ir para estudar'
  },
  WELCOME_MESSAGE: {
    es: 'Muchas gracias y bienvenido a Ucademy! 😁',
    en: 'Thank you very much and welcome to Ucademy! 😁',
    cat: 'Moltes gràcies i benvingut a Ucademy! 😁',
    eus: 'Mila esker eta ongi etorri Ucademy! 😁',
    pt: 'Muito obrigado e bem-vindo ao Ucademy! 😁'
  },
  YES: {
    es: 'Sí',
    en: 'Yes',
    cat: 'Sí',
    eus: 'Bai',
    pt: 'Sim'
  },
  NO: {
    es: 'No',
    en: 'No',
    cat: 'No',
    eus: 'Ez',
    pt: 'Não'
  }
};
