export const AdminPromptsTranslations = {
  PROMPTS: {
    es: 'Prompts',
    en: 'Prompts',
    cat: 'Prompts',
    eus: 'Prompts',
    pt: 'Prompts'
  },
  CREATE_PROMPT: {
    es: 'Crear prompt',
    en: 'Create prompt',
    cat: 'Crear prompt',
    eus: 'Prompt bat sortu',
    pt: 'Criar prompt'
  },
  EDIT_PROMPT: {
    es: 'Editar prompt',
    en: 'Edit prompt',
    cat: 'Editar prompt',
    eus: 'Prompt bat editatu',
    pt: 'Editar prompt'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  EMPTY_LIST: {
    es: 'No has creado ningun prompt',
    en: 'You have not created any prompts',
    cat: 'No has creat cap prompt',
    eus: 'Ez duzu promptik sortu',
    pt: 'Não criou nenhum prompt'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde',
    pt: 'Salvar'
  },
  AI_TEACHERS: {
    es: 'Profesores de IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak',
    pt: 'Professores de IA'
  },
  PROMPT_CREATED_SUCCESSFULLY: {
    es: 'Prompt creado exitosamente',
    en: 'Prompt created successfully',
    cat: 'Prompt creat correctament',
    eus: 'Prompt bat ongi sortu da',
    pt: 'Prompt criado com sucesso'
  },
  PROMPT_UPDATED_SUCCESSFULLY: {
    es: 'Prompt actualizado exitosamente',
    en: 'Prompt updated successfully',
    cat: 'Prompt actualitzat correctament',
    eus: 'Prompt bat ongi eguneratu da',
    pt: 'Prompt atualizado com sucesso'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  PROMPT_REMOVED_SUCCESSFULLY: {
    es: 'Prompt eliminado exitosamente',
    en: 'Prompt removed successfully',
    cat: 'Prompt eliminat correctament',
    eus: 'Prompt bat ongi ezabatu da',
    pt: 'Prompt eliminado com sucesso'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PROMPT: {
    es: '¿Estás seguro que deseas eliminar este prompt?',
    en: 'Are you sure you want to remove this prompt?',
    cat: 'Estàs segur que vols eliminar aquest prompt?',
    eus: 'Ziur zaude prompt hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este prompt?'
  }
};
