import { EActivityChatMessageRole } from '@constants';
import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { IActivityChatMessage } from '@sdk/contracts';
import { parseCookies } from '@utils';
import { v4 as uuidv4 } from 'uuid';
import { create as createStore } from 'zustand';

import { useProfile } from '../profile';

const API_URL = import.meta.env.VITE_API_URL;

interface IStore {
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isInitialLoading: boolean;
  setIsInitialLoading: (isInitialLoading: boolean) => void;
  messages: IActivityChatMessage[];
  setMessages: (messages: IActivityChatMessage[]) => void;
}

const store = createStore<IStore>(set => ({
  inputValue: '',
  setInputValue: inputValue => set({ inputValue }),
  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }),
  isInitialLoading: false,
  setIsInitialLoading: isInitialLoading => set({ isInitialLoading }),
  messages: [],
  setMessages: messages => set({ messages })
}));

export const useActivityChatMessages = () => {
  const {
    isLoading,
    setIsLoading,
    messages,
    setMessages,
    inputValue,
    setInputValue,
    isInitialLoading,
    setIsInitialLoading
  } = store();
  const { profile } = useProfile();

  const sendMessage = async (activityId: string, message: string, onSuccess?: () => void) => {
    setInputValue('');
    setIsInitialLoading(true);
    const { messages: currentMessages } = store.getState();
    const date = new Date();
    const userMessageId = uuidv4();
    const aiMessageId = uuidv4();
    setMessages([
      ...currentMessages,
      {
        _id: userMessageId,
        content: message,
        role: EActivityChatMessageRole.USER,
        createdAt: date,
        updatedAt: date
      },
      {
        _id: aiMessageId,
        content: '',
        role: EActivityChatMessageRole.ASSISTANT,
        createdAt: date,
        updatedAt: date
      }
    ]);
    const aiMessageElement = document.getElementById(`message-${aiMessageId}`);
    if (aiMessageElement) {
      aiMessageElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    setIsLoading(true);
    let accumulatedMessage = '';
    const abortController = new AbortController();
    const { token } = parseCookies();
    try {
      await fetchEventSource(
        `${API_URL}/activity-chats/activity/${activityId}/user/${profile?._id}/generate-message-response`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ message }),
          openWhenHidden: true,
          onerror(err: Error) {
            throw err;
          },
          signal: abortController.signal,
          onmessage(msg: EventSourceMessage) {
            if (msg.event === 'end') {
              setIsLoading(false);
              return;
            }
            if (msg.event === 'data' && msg.data) {
              const chunk = JSON.parse(msg.data);

              if (chunk.data.delta?.content) {
                setIsInitialLoading(false);
                if (!chunk.data.delta?.content[0].text.annotations?.length) {
                  accumulatedMessage += chunk.data.delta?.content[0].text.value;
                }
              }

              const currentState = store.getState();
              setMessages(
                currentState.messages.map(m =>
                  m._id === aiMessageId
                    ? {
                        ...m,
                        content: accumulatedMessage
                          .replace(/\\\[/gm, '```math')
                          .replace(/\\\]/gm, '```')
                          .replace(
                            /\\([a-zA-Z]+)\{(.*?)\}/g,
                            (_, command, content) => `$ \\${command}{${content}} $`
                          )
                      }
                    : m
                )
              );
              const messageElement = document.getElementById(`message-${aiMessageId}`);
              if (messageElement) {
                messageElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }
            }
          }
        }
      );
    } catch (error) {
      console.error('Error sending message', error);
    } finally {
      setIsLoading(false);
      abortController.abort();
      onSuccess?.();
    }
  };

  return {
    sendMessage,
    messages,
    setMessages,
    isLoading,
    inputValue,
    setInputValue,
    isInitialLoading,
    setIsInitialLoading
  };
};
