import { EditStudentToolbarTab, ERoles } from '@constants';

export const ManagerTeachersTranslations = {
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  NAME_AND_LASTNAME: {
    es: 'Nombre y apellido',
    en: 'Name and lastname',
    cat: 'Nom i cognom',
    eus: 'Izena eta abizena',
    pt: 'Nome e apelido'
  },
  LASTNAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Apelido'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak',
    pt: 'Professores'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  ACTIVITY_STATUS: {
    es: 'Estado actividad',
    en: 'Activity status',
    cat: 'Estat activitat',
    eus: 'Jarduera egoera',
    pt: 'Estado da atividade'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktiboa',
    pt: 'Conta ativa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu desaktibatua',
    pt: 'Conta inativa'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Emaila',
    pt: 'Email'
  },
  INSCRIPTION_DATE: {
    es: 'Fecha de inscripción',
    en: 'Inscription date',
    cat: "Data d'inscripció",
    eus: 'Izena eman den data',
    pt: 'Data de inscrição'
  },
  THERE_ARE_NOT_COURSES_ASOCIATED_TO_THIS_USER: {
    es: 'No hay cursos asociados a este usuario',
    en: 'There are not courses asociated to this user',
    cat: 'No hi ha cursos associats a aquest usuari',
    eus: 'Ez dago erabiltzaile honi lotutako ikastaroik',
    pt: 'Não há cursos associados a este usuário'
  },
  NEW_TEACHER_ADDED: {
    es: 'Nuevo profesor agregado a la academia',
    en: 'New teacher added to the academy',
    cat: "Nou professor afegit a l'acadèmia",
    eus: 'Irakasle berria akademiara gehitu da',
    pt: 'Novo professor adicionado à academia'
  },
  CREATE_TEACHER: {
    es: 'Crear profesor',
    en: 'Create teacher',
    cat: 'Crear professor',
    eus: 'Irakasle berria sortu',
    pt: 'Criar professor'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Lastname',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Apelido'
  },
  USERNAME: {
    es: 'Usuario',
    en: 'Username',
    cat: 'Usuari',
    eus: 'Erabiltzaile izena',
    pt: 'Nome de usuário'
  },
  NEW_TEACHER: {
    es: 'Nuevo profesor',
    en: 'New teacher',
    cat: 'Nou professor',
    eus: 'Irakasle berria',
    pt: 'Novo professor'
  },
  TEACHERS_TOTAL_COUNT: {
    es: '{{total}} profesor/es',
    en: '{{total}} teacher/s',
    cat: '{{total}} professor/s',
    eus: '{{total}} irakasle/ak',
    pt: '{{total}} professor/es'
  },
  TEACHER_UPDATED_SUCCESSFULLY: {
    es: 'Profesor actualizado correctamente',
    en: 'Teacher updated successfully',
    cat: 'Professor actualitzat correctament',
    eus: 'Irakasle eguneratua ongi',
    pt: 'Professor atualizado com sucesso'
  },
  USER_PREVIEW_COULD_NOT_BE_LOADED: {
    es: 'No se pudo cargar la vista previa del usuario',
    en: 'User preview could not be loaded',
    cat: "No es va poder carregar la vista prèvia de l'usuari",
    eus: 'Erabiltzailearen aurrebista ezin izan da kargatu',
    pt: 'A vista prévia do usuário não pôde ser carregada'
  },
  ASSIGN_COURSE: {
    es: 'Asignar curso',
    en: 'Assign course',
    cat: 'Assignar curs',
    eus: 'Ikastaroa asignatu',
    pt: 'Curso atribuído'
  },
  CANCEL_EDIT_TEACHER: {
    es: 'Cancelar edición',
    en: 'Cancel edit',
    cat: 'Cancel·lar edició',
    eus: 'Ezabatu editatu',
    pt: 'Cancelar edição'
  },
  EDIT_TEACHER: {
    es: 'Editar profesor',
    en: 'Edit teacher',
    cat: 'Editar professor',
    eus: 'Editatu irakaslea',
    pt: 'Editar professor'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila',
    pt: 'Perfil'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi',
    pt: 'Fechar'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  CONNECTION: {
    es: 'Conexión',
    en: 'Connection',
    cat: 'Connexió',
    eus: 'Konexioa',
    pt: 'Conexão'
  },
  THERE_ARE_NOT_CURSES_ASOCIATED_TO_THIS_TEACHER: {
    es: 'No hay cursos asociados a este profesor',
    en: 'There are not curses asociated to this teacher',
    cat: 'No hi ha cursos associats a aquest professor',
    eus: 'Ez dago irakasle honi lotutako ikastarorik',
    pt: 'Não há cursos associados a este professor'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol',
    pt: 'Rol'
  },
  [ERoles.MANAGER]: {
    es: 'Manager',
    en: 'Manager',
    cat: 'Manager',
    eus: 'Kudeatzailea',
    pt: 'Gestor'
  },
  [ERoles.TEACHER]: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  [ERoles.SALES]: {
    es: 'Ventas',
    en: 'Sales',
    cat: 'Vendes',
    eus: 'Saltzak',
    pt: 'Vendas'
  },
  [ERoles.SUPPORT]: {
    es: 'Soporte',
    en: 'Support',
    cat: 'Suport',
    eus: 'Laguntza',
    pt: 'Suporte'
  },
  [ERoles.INSPECTOR]: {
    es: 'Inspector',
    en: 'Inspector',
    cat: 'Inspector',
    eus: 'Azterketa',
    pt: 'Inspeção'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA irakaslea',
    pt: 'Professor IA'
  },
  ARE_YOU_SURE_TO_DELETE_USER: {
    es: '¿Estás seguro que deseas eliminar al usuario?',
    en: 'Are you sure you want to delete the user?',
    cat: "Estàs segur que vols eliminar l'usuari?",
    eus: 'Ziur zaude erabiltzailea ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir o usuário?'
  },
  USER_DELETED_SUCCESSFULLY: {
    es: 'Usuario eliminado correctamente',
    en: 'User deleted successfully',
    cat: 'Usuari eliminat correctament',
    eus: 'Erabiltzailea ongi ezabatu da',
    pt: 'O usuário foi excluído com sucesso'
  },
  REMOVE_STUDENT: {
    es: 'Eliminar teacher',
    en: 'Remove teacher',
    cat: 'Eliminar professor',
    eus: 'Irakaslea kendu',
    pt: 'Remover professor'
  },
  REMOVE: {
    es: 'Eliminar',
    en: 'Remove',
    cat: 'Eliminar',
    eus: 'Kendu',
    pt: 'Remover'
  },
  RESET_STUDENT_PASSWORD: {
    es: 'Reiniciar contraseña',
    en: 'Reset teacher password',
    cat: 'Reiniciar contrasenya',
    eus: 'Berrezarri irakaslearen pasahitza',
    pt: 'Reiniciar a senha do professor'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro que deseas reiniciar la contraseña?',
    en: 'Are you sure to reset password?',
    cat: 'Estàs segur que vols reiniciar la contrasenya?',
    eus: 'Ziur zaude pasahitza berrezarri nahi duzula?',
    pt: 'Tem certeza que deseja reiniciar a senha do professor?'
  },
  PASSWORD_RESET_SUCCESS: {
    es: 'Contraseña reiniciada correctamente, el usuario recibirá un correo con la nueva contraseña',
    en: 'Password reset successfully, the user will receive an email with the new password',
    cat: "Contrasenya reiniciada correctament, l'usuari rebrà un correu amb la nova contrasenya",
    eus: 'Pasahitza berrezarri da, erabiltzaileak mezu bat jasoko du berriro pasahitzarekin',
    pt: 'A senha foi reiniciada com sucesso, o usuário receberá um email com a nova senha'
  },
  [EditStudentToolbarTab.GENERAL_INFO]: {
    es: 'Información general',
    en: 'General information',
    cat: 'Informació general',
    eus: 'Orokorra informazioa',
    pt: 'Informação geral'
  },
  [EditStudentToolbarTab.SPECIFIC_INFO]: {
    es: 'Documentación FP',
    en: 'FP documentation',
    cat: 'Documentació FP',
    eus: 'FP dokumentazioa',
    pt: 'Documentação FP'
  },
  ACADEMIC_TITLES: {
    es: 'Titulación académica',
    en: 'Academic titles',
    cat: 'Titulacions acadèmiques',
    eus: 'Akademik tituluak',
    pt: 'Títulos académicos'
  },
  TEACHING_MASTERS: {
    es: 'Máster del profesorado y/o CAP',
    en: 'Teaching masters and/or CAP',
    cat: 'Màsters del professorat i/o CAP',
    eus: 'Irakasle-màster eta/edo CAP',
    pt: 'Mestres do professorado e/ou CAP'
  },
  SEXUAL_HARASSMENT_CERTIFICATE: {
    es: 'Certificado de no delitos sexuales',
    en: 'Sexual harassment certificate',
    cat: 'Certificat de no delits sexuals',
    eus: 'Sexual harassment certificate',
    pt: 'Certificado de não delitos sexuais'
  },
  CRIMINAL_RECORD: {
    es: 'Certificado de no antecedentes penales',
    en: 'Criminal record certificate',
    cat: 'Certificat de no antecedents penals',
    eus: 'Antekets penala ez duen sertifikatua',
    pt: 'Certificado de não antecedentes penais'
  },
  IDENTITY_FILES: {
    es: 'Fotocopia del DNI/NIE',
    en: 'DNI/NIE copy',
    cat: 'Còpia del DNI/NIE',
    eus: 'DNI/NIE kopia',
    pt: 'Cópia do DNI/NIE'
  },
  RESUME: {
    es: 'Currículum Vitae',
    en: 'Resume',
    cat: 'CV',
    eus: 'CV',
    pt: 'CV'
  }
};
