import { Skeleton } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
  padding: 128px 24px 0 24px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const BottomInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .ant-skeleton {
    height: 8px;
  }
`;

export const SkeletonInput = styled(Skeleton.Input)`
  border-radius: 100px !important;
  width: 90% !important;
  display: flex !important;
  justify-content: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const ActionButton = styled(Skeleton.Button)`
  border-radius: 100px !important;
`;
