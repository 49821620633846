import { AnalyticsTrackEvents } from '@constants';
import { IUpdateLiveClassVote } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const LiveClassVoteService = sdk.getService('LiveClassVoteService');

export const useMutateLiveClassVote = () => {
  const queryClient = useQueryClient();
  const { fireEvent } = useAnalytics();
  const { t } = useTranslation('DefaultTranslations');

  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['live-class-vote'] });

  const { mutate: voteLiveClass, isPending: isVoting } = useMutation({
    mutationFn: (vote: IUpdateLiveClassVote) => LiveClassVoteService.voteLiveClass(vote),
    onSuccess: liveClassVote => {
      onSuccess();
      message.success(t('LIVE_CLASS_VOTE_SUCCESS'));
      fireEvent(AnalyticsTrackEvents.RATING_CLASS, {
        classRating: liveClassVote.classRating,
        teacherRating: liveClassVote.teacherRating,
        status: liveClassVote.status
      });
    }
  });

  return {
    voteLiveClass,
    isVoting
  };
};
