import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { useEffect } from 'react';

import { Wrapper, ToolbarWrapper } from './PdfViewer.styles';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { usePdfResource } from '@/pages/Student/Course/store';

type Props = {
  url: string;
  height?: number | string;
  onPageChange?: (page: number) => void;
};

const DEFAULT_HEIGHT = 300;
const WORKWER_VERSION = '2.16.105';

export const PdfViewer: React.FC<Props> = ({ url, height = DEFAULT_HEIGHT, onPageChange }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { setViewerRef, viewerRef } = usePdfResource();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    if (!viewerRef) {
      setViewerRef(pageNavigationPluginInstance);
    }
  }, []);

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>
  });

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${WORKWER_VERSION}/build/pdf.worker.min.js`}>
      <Wrapper style={{ height, width: '-webkit-fill-available' }}>
        <ToolbarWrapper>
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        </ToolbarWrapper>
        <Viewer
          fileUrl={url}
          theme={{
            theme: 'dark'
          }}
          plugins={[toolbarPluginInstance, pageNavigationPluginInstance]}
          onPageChange={e => onPageChange?.(e.currentPage)}
        />
      </Wrapper>
    </Worker>
  );
};
