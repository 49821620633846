import { ITicketFormValues } from '@sdk/contracts';
import { Button, Flex, Form, FormInstance, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ButtonsWrapper,
  CancelWrapper,
  FileWrapper,
  FooterWrapper,
  InfoWrapper,
  Wrapper
} from './CreateTicketForm.styles';

import { Icon } from '@/components/Icon';
import { Upload } from '@/components/Upload';
import { useActivityTicketsList, useMutateMyTickets } from '@/store';

const ATTACHMENT_ICON_SIZE = 12;

type Props = {
  form: FormInstance;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  onFormSubmit: (values: ITicketFormValues) => void;
  onCancel?: () => void;
  alwaysExpanded?: boolean;
};

export const CreateTicketForm: React.FC<Props> = ({
  form,
  isActive,
  setIsActive,
  onFormSubmit,
  onCancel,
  alwaysExpanded = false
}) => {
  const { t } = useTranslation('StudentCourseTranslations');
  const description = Form.useWatch('description', form);
  const file = Form.useWatch('file', form);
  const { isWorking } = useMutateMyTickets();
  const { isLoading } = useActivityTicketsList();

  const handleCancel = () => {
    if (!alwaysExpanded) {
      setIsActive(false);
    }
    form.resetFields();
    onCancel?.();
  };

  const onSubmit = (values: ITicketFormValues) => {
    onFormSubmit(values);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Wrapper>
        {isActive && (
          <CancelWrapper onClick={handleCancel}>
            <Icon i="Close" />
            <div>{t('CANCEL_QUESTION')}</div>
          </CancelWrapper>
        )}
        <Form.Item noStyle name="description">
          <Input.TextArea
            autoFocus={isActive}
            allowClear
            disabled={isWorking}
            rows={isActive ? 4 : 1}
            placeholder={t('WRITE_YOUR_QUESTION')}
            onFocus={() => setIsActive(true)}
          />
        </Form.Item>
        {file && (
          <FileWrapper closable>
            <Flex align="center" gap="8px">
              <Icon size={ATTACHMENT_ICON_SIZE} i="Attachment" />
              <div>{file.name}</div>
            </Flex>
          </FileWrapper>
        )}
        {isActive && (
          <FooterWrapper>
            <Tooltip title={t('QUESTION_TIPS_TOOLTIP')} placement="bottomLeft">
              <InfoWrapper>
                <Icon i="Information" />
                <div>{t('QUESTION_TIPS')}</div>
              </InfoWrapper>
            </Tooltip>
            <ButtonsWrapper>
              <Form.Item noStyle name="file">
                <Upload
                  disabled={!!file || isWorking}
                  showUploadList={false}
                  iconOnly
                  mode="upload"
                  icon="Attachment"
                />
              </Form.Item>
              <Button
                type="primary"
                shape="circle"
                loading={isWorking || isLoading}
                icon={<Icon i="SendAlt" />}
                onClick={form.submit}
                disabled={!description}
              />
            </ButtonsWrapper>
          </FooterWrapper>
        )}
      </Wrapper>
    </Form>
  );
};
