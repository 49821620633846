import { Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  position: relative;
  width: fit-content;
  align-self: center;

  & > img {
    position: absolute;
    top: -16px;
    right: -24px;
  }
`;

export const ResultsWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  width: 100%;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
`;

export const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const ScoreTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
`;

export const ScoreSubtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 120px;
`;

export const StyledTag = styled(Tag)<{ backgroundcolor: string; bordercolor: string }>`
  border: 1px solid ${({ bordercolor }) => bordercolor};
  padding: 2px 8px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  margin-inline-end: 0 !important;
`;

export const StyledTagText = styled.div<{ color: string }>`
  font-weight: 600;
  color: ${({ color }) => color};
`;
