import { ETeacherProfileTabs } from '../types';

export const TeacherProfileTranslations = {
  [ETeacherProfileTabs.BASIC_INFO]: {
    es: 'Datos personales',
    en: 'Basic info',
    cat: 'Dades bàsiques',
    eus: 'Oinarrizko informazioa',
    pt: 'Informações básicas'
  },
  [ETeacherProfileTabs.CERTIFICATIONS]: {
    es: 'Certificados',
    en: 'Certifications',
    cat: 'Certificacions',
    eus: 'Serloketak',
    pt: 'Certificações'
  },
  EMAIL: {
    es: 'Correo',
    en: 'Email',
    cat: 'Correu',
    eus: 'Posta elektronikoa',
    pt: 'Email'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last name',
    cat: 'Apellido',
    eus: 'Azken izena',
    pt: 'Apelido'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contraseña',
    eus: 'Pasahitza',
    pt: 'Palavra-passe'
  },
  LANGUAGE: {
    es: 'Idioma de la plataforma',
    en: 'Platform language',
    cat: 'Idioma de la plataforma',
    eus: 'Plataforma-hizkuntza',
    pt: 'Idioma da plataforma'
  },
  LOGOUT: {
    es: 'Cerrar sesión',
    en: 'Logout',
    cat: 'Cerrar sesión',
    eus: 'Logout',
    pt: 'Sair'
  },
  SAVE_CHANGES: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Guardar canvis',
    eus: 'Gorde aldaketak',
    pt: 'Guardar alterações'
  },
  PROFILE_UPDATED_SUCCESS: {
    es: 'Perfil actualizado correctamente',
    en: 'Profile updated successfully',
    cat: 'Perfil actualitzat correctament',
    eus: 'Profila eguneratua',
    pt: 'Perfil actualizado com sucesso'
  },
  ACADEMIC_TITLES: {
    es: 'Tu titulación académica',
    en: 'Your academic title',
    cat: 'La teva titulació acadèmica',
    eus: 'Zure akademik zubirikoa',
    pt: 'Título académico'
  },
  ACADEMIC_TITLES_SUBTITLE: {
    es: 'Compulsada y con verificación de que puedes impartir las materias. Lo necesitamos para la CAM (administración pública).',
    en: 'Mandatory and verified that you can teach the subjects. We need it for the CAM (public administration).',
    cat: 'Obligatori i amb verificació de que pots impartir les matèries. Necesitem-lo per a la CAM (administració pública).',
    eus: 'Eskubideak eta beraien verifikazioa. Eskaera publikoaren onarpena.',
    pt: 'Evidência acadêmica obrigatória e verificada que pode lecionar as matérias. Precisamos disso para a CAM (administração pública).'
  },
  TEACHING_MASTERS: {
    es: 'Tu máster del profesorado y/o CAP',
    en: 'Your teaching masters and/or CAP',
    cat: 'La teva màster del professorat i/o CAP',
    eus: 'Zure ikasketa-masterrak eta/edo CAP',
    pt: 'Os seus mestrados de ensino e/ou CAP'
  },
  TEACHING_MASTERS_SUBTITLE: {
    es: 'Tu certificado de máster del profesorado. Lo necesitamos para la CAM (administración pública). Formato PDF.',
    en: 'Your teaching masters certificate. We need it for the CAM (public administration). Format PDF.',
    cat: 'La teva certificació de màster del professorat. Necesitem-lo per a la CAM (administració pública). Format PDF.',
    eus: 'Zure ikasketa-masterrak eta/edo CAP. Eskaera publikoaren onarpena.',
    pt: 'O seu certificado de mestrados de ensino e/ou CAP. Precisamos disso para a CAM (administração pública).'
  },
  SEXUAL_HARASSMENT_CERTIFICATE: {
    es: 'Certificado de No delitos sexuales',
    en: 'Sexual Harassment Certificate',
    cat: 'Certificat de No delits sexuals',
    eus: 'Eskubideak eta beraien verifikazioa.',
    pt: 'Certificado de Não delitos sexuais'
  },
  SEXUAL_HARASSMENT_CERTIFICATE_SUBTITLE: {
    es: 'Lo necesitamos para la CAM (administración pública). En formato PDF.',
    en: 'We need it for the CAM (public administration). Format PDF.',
    cat: 'Necesitem-lo per a la CAM (administració pública). Format PDF.',
    eus: 'Eskaera publikoaren onarpena.',
    pt: 'Precisamos disso para a CAM (administração pública).'
  },
  CRIMINAL_RECORD: {
    es: 'Certificado de No antecedentes penales',
    en: 'Criminal Record Certificate',
    cat: 'Certificat de No antecedents penals',
    eus: 'Eskubideak eta beraien verifikazioa.',
    pt: 'Certificado de Não antecedentes penais'
  },
  CRIMINAL_RECORD_SUBTITLE: {
    es: 'Lo necesitamos para la CAM (administración pública). En formato PDF.',
    en: 'We need it for the CAM (public administration). Format PDF.',
    cat: 'Necesitem-lo per a la CAM (administració pública). Format PDF.',
    eus: 'Eskaera publikoaren onarpena.',
    pt: 'Precisamos disso para a CAM (administração pública).'
  },
  IDENTITY_FILES: {
    es: 'Fotocopia de tu DNI/NIE',
    en: 'DNI/NIE copy',
    cat: 'Fotocòpia del teu DNI/NIE',
    eus: 'DNI/NIE-ren fotokopia',
    pt: 'Cópia do seu DNI/NIE'
  },
  IDENTITY_FILES_SUBTITLE: {
    es: 'Parte trasera y delantera. Lo necesitamos para la CAM (administración pública) y en formato PDF.',
    en: 'Back and front. We need it for the CAM (public administration) and in PDF format.',
    cat: 'Partes darrera i davant. Necesitem-lo per a la CAM (administració pública) i en format PDF.',
    eus: 'Atze eta aurreko zatia. Eskaera publikoaren onarpena.',
    pt: 'Parte traseira e frontal. Precisamos disso para a CAM (administração pública) e em formato PDF.'
  },
  RESUME: {
    es: 'Curriculum vitae',
    en: 'Resume',
    cat: 'Resum',
    eus: 'Eskubideak eta beraien verifikazioa.',
    pt: 'Currículo'
  },
  RESUME_SUBTITLE: {
    es: 'Tu curriculum vitae. Lo necesitamos para la CAM (administración pública). En formato PDF.',
    en: 'Your resume. We need it for the CAM (public administration). Format PDF.',
    cat: 'La teva curriculum vitae. Necesitem-lo per a la CAM (administració pública). Format PDF.',
    eus: 'Zure curriculum vitae. Eskaera publikoaren onarpena.',
    pt: 'O seu currículo. Precisamos disso para a CAM (administração pública) e em formato PDF.'
  }
};
