import { RequestService } from '../request';

import {
  ICreateTeacherVote,
  ICreateTutorVote,
  ICurrentTeacherVote,
  ICurrentTutorVote,
  ITeacherVote
} from './teacher-vote.contract';

export class TeacherVoteService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public findCurrentTeachersVotes() {
    return this.fetcher<ICurrentTeacherVote[]>('/teacher-vote/current-teachers');
  }

  public findCurrentTutorVote() {
    return this.fetcher<ICurrentTutorVote>('/teacher-vote/current-tutor');
  }

  public createTutorVote(createTutorVoteDto: ICreateTutorVote) {
    return this.poster<ITeacherVote, ICreateTutorVote>('/teacher-vote/tutor', createTutorVoteDto);
  }

  public createTeacherVote(createTeacherVoteDto: ICreateTeacherVote) {
    return this.poster<ITeacherVote, ICreateTeacherVote>(
      '/teacher-vote/teacher',
      createTeacherVoteDto
    );
  }
}
