import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;

export const ResourceInfoWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black6};
  cursor: pointer;
  transition: color 0.3s ease;

  :hover {
    color: ${({ theme }) => theme.colors.green6};
  }
`;

export const DateWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black6};
  line-height: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ContentWrapper = styled.div<{ expanded: string }>`
  max-height: ${({ expanded }) => (expanded === 'true' ? 'none' : '90px')};
  overflow: hidden;
  width: 100%;
`;

export const SeeMoreWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.green6};
  }
`;
