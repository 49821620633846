import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 65%;
  text-align: center;
  justify-self: center;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;
