export const DeliverableListTranslations = {
  DELIVERABLE: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Lliurament',
    eus: 'Entregable',
    pt: 'Entregável'
  },
  ALL_DELIVERABLES: {
    es: 'Todos los entregables',
    en: 'All deliverables',
    cat: 'Tots els lliuraments',
    eus: 'Entregable guztiak',
    pt: 'Todos os entregáveis'
  },
  DELIVERABLE_HAS_BEEN_UPDATED: {
    es: 'El entregable ha sido actualizado',
    en: 'The deliverable has been updated',
    cat: 'El lliurament ha estat actualitzat',
    eus: 'Entregablea eguneratu da',
    pt: 'O entregável foi atualizado'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  RATE: {
    es: 'Puntuar',
    en: 'Rate',
    cat: 'Puntuar',
    eus: 'Puntuatu',
    pt: 'Avaliar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde',
    pt: 'Salvar'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu',
    pt: 'Editar'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  SCORE: {
    es: 'Nota',
    en: 'Score',
    cat: 'Nota',
    eus: 'Nota',
    pt: 'Nota'
  },
  DELETE_DELIVERABLE: {
    es: 'Eliminar entregable',
    en: 'Delete deliverable',
    cat: 'Eliminar lliurament',
    eus: 'Entregablea ezabatu',
    pt: 'Excluir entregável'
  },
  DELIVERABLE_REMOVED_SUCCESSFULLY: {
    es: '¡Entregable eliminado con éxito!',
    en: 'Deliverable deleted successfully!',
    cat: 'Lliurament eliminat correctament!',
    eus: 'Entregablea ondo ezabatua!',
    pt: 'Entregável excluído com sucesso!'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: 'Gracias por tu feedback 😊',
    en: 'Thanks for your feedback 😊',
    cat: 'Gràcies pel teu feedback 😊',
    eus: 'Eskerrik asko zure feedbackagatik 😊',
    pt: 'Obrigado pelo seu feedback 😊'
  },
  DELIVERABLE_NAME: {
    es: 'Nombre del entregable',
    en: 'Deliverable name',
    cat: 'Nom del lliurament',
    eus: 'Entregablearen izena',
    pt: 'Nome do entregável'
  },
  YOU_HAVE_NOT_DELIVERED_DELIVERABLES: {
    es: 'No hay entregables',
    en: 'There are no deliverables',
    cat: 'No hi ha lliurables',
    eus: 'Entregagarriak ez daude',
    pt: 'Não há entregáveis'
  },
  DELIVERABLE_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  UPLOAD_DATE: {
    es: 'Fecha de entrega',
    en: 'Upload date',
    cat: 'Data de lliurament',
    eus: 'Entregako data',
    pt: 'Data de envio'
  },
  CSAT: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Balorazioa',
    pt: 'Avaliação'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  TOTAL: {
    es: 'Total: {{total}}',
    en: 'Total: {{total}}',
    cat: 'Total: {{total}}',
    eus: 'Guztira: {{total}}',
    pt: 'Total: {{total}}'
  },
  STUDENT: {
    es: 'Alumno',
    en: 'Student',
    cat: 'Alumne',
    eus: 'Ikaslea',
    pt: 'Aluno'
  },
  COURSE_NAME: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  TEACHER_ASSIGNEE: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  }
};
