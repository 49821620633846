import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 32px 80px;
  position: relative;
`;

export const RelatedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const RelatedContentTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const UmyWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`;

export const TooltipText = styled.div`
  color: ${({ theme }) => theme.colors.black9};
  font-size: 14px;
  line-height: 22px;
`;
