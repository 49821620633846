import { Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;

  .ant-input-outlined {
    :focus-within {
      box-shadow: 0px 0px 4px 2px ${({ theme }) => theme.colors.green3};
      border-color: ${({ theme }) => theme.colors.green6};
    }

    :hover {
      border-color: ${({ theme }) => theme.colors.green6};
    }
  }
`;

export const CancelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black5};
  font-weight: 600;
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const FileWrapper = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.green1};
  color: ${({ theme }) => theme.colors.green6};
  border: 1px solid ${({ theme }) => theme.colors.green6};
  width: 100%;
  justify-content: space-between;
  display: flex;
`;
