import styled from 'styled-components';

export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const RateTitleWrapper = styled.span`
  display: flex;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme }) => theme.colors.black9};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;
