import { ERoles } from '@constants';

export const AITeachersTranslations = {
  AI_TEACHERS: {
    es: 'Profesores de IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak',
    pt: 'Professores de IA'
  },
  EMPTY_LIST: {
    es: 'No hay profesores de IA',
    en: 'No AI Teachers',
    cat: 'No hi ha professors IA',
    eus: 'Ez dago IA irakaslerik',
    pt: 'Não há professores de IA'
  },
  CREATE_TEACHER: {
    es: 'Crear profesor de IA',
    en: 'Create AI Teacher',
    cat: 'Crear professor IA',
    eus: 'IA Irakasle bat sortu',
    pt: 'Criar professor de IA'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last name',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Sobrenome'
  },
  TEACHER_CREATED_SUCCESSFULLY: {
    es: 'Profesor de IA creado correctamente',
    en: 'AI Teacher created successfully',
    cat: 'Professor IA creat correctament',
    eus: 'IA Irakasle bat ongi sortu da',
    pt: 'Professor de IA criado com sucesso'
  },
  TEACHER_UPDATED_SUCCESSFULLY: {
    es: 'Profesor de IA actualizado correctamente',
    en: 'AI Teacher updated successfully',
    cat: 'Professor IA actualitzat correctament',
    eus: 'IA Irakasle bat ongi eguneratu da',
    pt: 'Professor de IA atualizado com sucesso'
  },
  PROMPT: {
    es: 'Prompt',
    en: 'Prompt',
    cat: 'Prompt',
    eus: 'Prompt',
    pt: 'Prompt'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email',
    pt: 'Email'
  },
  ACCOUNT_STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  ACTIVE_ACCOUNT: {
    es: 'Cuenta activa',
    en: 'Active account',
    cat: 'Compte actiu',
    eus: 'Kontu aktiboa',
    pt: 'Conta ativa'
  },
  INACTIVE_ACCOUNT: {
    es: 'Cuenta inactiva',
    en: 'Inactive account',
    cat: 'Compte inactiu',
    eus: 'Kontu inaktiboa',
    pt: 'Conta inativa'
  },
  [ERoles.AI_TEACHER]: {
    es: 'Profesor de IA',
    en: 'AI Teacher',
    cat: 'Professor IA',
    eus: 'IA Irakaslea',
    pt: 'Professor de IA'
  },
  EDIT_TEACHER: {
    es: 'Editar profesor de IA',
    en: 'Edit AI Teacher',
    cat: 'Editar professor IA',
    eus: 'Editatu IA Irakaslea',
    pt: 'Editar professor de IA'
  },
  NEW_TEACHER: {
    es: 'Nuevo profesor de IA',
    en: 'New AI Teacher',
    cat: 'Nou professor IA',
    eus: 'IA Irakasle berria',
    pt: 'Novo professor de IA'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Rol',
    pt: 'Papel'
  },
  AVATAR: {
    es: 'Avatar',
    en: 'Avatar',
    cat: 'Avatar',
    eus: 'Avatar',
    pt: 'Avatar'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_TEACHER: {
    es: '¿Estás seguro que deseas eliminar este profesor de IA?',
    en: 'Are you sure you want to remove this AI Teacher?',
    cat: 'Estàs segur que vols eliminar aquest professor IA?',
    eus: 'Ziur zaude IA Irakasle hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este professor de IA?'
  },
  TEACHER_DELETED_SUCCESSFULLY: {
    es: 'Profesor de IA eliminado correctamente',
    en: 'AI Teacher deleted successfully',
    cat: 'Professor IA eliminat correctament',
    eus: 'IA Irakasle bat ongi ezabatu da',
    pt: 'Professor de IA eliminado com sucesso'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  }
};
