import { AnalyticsTrackEvents } from '@constants';
import {
  IActivity,
  IActivityTrack,
  ICreateProblemReport,
  ISection,
  ISubscription,
  ITrackingUpdate
} from '@sdk/contracts';
import { message } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { create as createStore } from 'zustand';

import { useAnalytics } from '@/hooks';
import {
  useMutateMySubscriptions,
  useMutateProblemReports,
  useMySubscriptionDetail
} from '@/store';
import { getActivityTrace, getNextActivityTrack, getPreviousActivityTrack } from '@/utils';

type ICurrentCourseState = {
  activity?: IActivity;
  section?: ISection;
  index: number;
};

type IStore = {
  isRecapModalOpen: boolean;
  setIsRecapModalOpen: (isOpen: boolean) => void;
  showActivityReview: boolean;
  setShowActivityReview: (isOpen: boolean) => void;
  showNextActivityShortcut: boolean;
  setShowNextActivityShortcut: (showNextActivityShortcut: boolean) => void;
};

const store = createStore<IStore>(set => ({
  isRecapModalOpen: false,
  setIsRecapModalOpen: isOpen => set({ isRecapModalOpen: isOpen }),
  showActivityReview: false,
  setShowActivityReview: isOpen => set({ showActivityReview: isOpen }),
  showNextActivityShortcut: false,
  setShowNextActivityShortcut: isOpen => set({ showNextActivityShortcut: isOpen })
}));

export const useActivityTrack = () => {
  const {
    isRecapModalOpen,
    setIsRecapModalOpen,
    showActivityReview,
    setShowActivityReview,
    showNextActivityShortcut,
    setShowNextActivityShortcut
  } = store();
  const { isLoading, subscription } = useMySubscriptionDetail();
  const { isWorking, updateActivityTrack } = useMutateMySubscriptions();
  const { reportProblem } = useMutateProblemReports();
  const { fireEvent } = useAnalytics();
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const currentActivityId = searchParams.get('activity');
  const { t } = useTranslation();

  const updateActivityTracking = (trackingData: ITrackingUpdate, onSuccess?: () => void) => {
    if (!subscription) return;
    const metadata = getActivityTrace(subscription, trackingData.activity);

    updateActivityTrack(
      {
        subscriptionId: subscription._id,
        activityTrack: { ...metadata, ...trackingData }
      },
      { onSuccess: () => onSuccess?.() }
    );
  };

  const setActivityAsCompleted = (activityId?: string) => {
    const { currentActivity } = subscription || {};
    setShowActivityReview(true);

    const _id = activityId ? activityId : currentActivityId ?? currentActivity;
    if (!_id || !subscription) return;

    const { isCompleted } = getActivityTrackById(_id);
    if (isCompleted) return;

    updateActivityTracking(
      {
        activity: _id,
        isCompleted: true
      },
      () => {
        const nextActivityTrack = getNextActivityTrack(subscription, activityId);

        const { course } = subscription;
        const { sections = [] } = course;
        const section = sections.find(s => s.activities.some(a => a._id === activityId));
        const activity = section?.activities.find(a => a._id === activityId);
        fireEvent(AnalyticsTrackEvents.ACTIVITY_COMPLETED, {
          sectionName: section?.name,
          activityName: activity?.name,
          courseName: course.name,
          activityType: activity?.type
        });
        setShowNextActivityShortcut(true);
        if (!nextActivityTrack) return;
        updateActivityTracking(nextActivityTrack);
      }
    );
  };

  const setActivityAsIncomplete = () => {
    const { currentActivity } = subscription || {};

    const activity = currentActivityId ?? currentActivity;
    if (!activity) return;

    updateActivityTracking({
      activity,
      isCompleted: false
    });
  };

  const getActivityTrackById = (activity?: string) => {
    const { activityTracking = [] } = subscription || {};
    const currentActivityTrack = activityTracking.find(track => track.activity === activity);

    return {
      ...currentActivityTrack,
      activity: activity ?? ''
    };
  };

  const setActivityReview = (review: IActivityTrack['review'], onSuccess?: () => void) => {
    const { currentActivity } = subscription || {};

    const activity = currentActivityId ?? currentActivity;
    if (!activity) return;

    updateActivityTracking(
      {
        activity,
        review
      },
      () => {
        onSuccess?.();
        message.success(t('THANKS_FOR_REVIEWING'));
      }
    );
  };

  const sendActivityProblemReport = (
    problemReport: ICreateProblemReport,
    activity: IActivity,
    onSuccess: () => void
  ) => {
    reportProblem(problemReport, {
      onSuccess: () => {
        onSuccess();
        fireEvent(AnalyticsTrackEvents.REPORT_SENT, {
          reportType: 'activity',
          activityName: activity.name,
          reportOption: problemReport.title,
          tag: subscription?.course.tags.map(tag => tag.name),
          courseName: subscription?.course.name
        });
      }
    });
  };

  const getTimeTracking = (activityId?: string) => {
    if (!subscription) return 0;

    const time = subscription.activityTracking.find(
      track => track.activity === activityId
    )?.timeTracking;

    return time ?? 0;
  };

  const updateTimeTracking = (activityId: string, time: number) => {
    updateActivityTracking({
      activity: activityId,
      timeTracking: time
    });
  };

  const getCurrentActivity = useMemo(
    () => (selectedSuscription?: ISubscription) => {
      const { course: { sections = [] } = {}, currentActivity = '' } =
        selectedSuscription ?? (subscription || {});
      const activityId = currentActivityId ?? currentActivity;

      const selectedActivity = sections.reduce((acc, section, index) => {
        const activity = section.activities.find(({ _id }) => _id === activityId);
        if (activity) {
          return {
            activity,
            section,
            index
          };
        }
        return acc;
      }, {} as ICurrentCourseState);
      if (!selectedActivity.activity && sections[0]) {
        return {
          activity: sections[0].activities[0],
          section: sections[0],
          index: 0
        };
      }
      return selectedActivity;
    },
    [currentActivityId, subscription]
  );

  const areAllActivitiesLocked = (section: ISection) => {
    return section.activities.every(
      ({ isLocked, resource }) => isLocked || (!resource?.file && !resource?.quiz)
    );
  };

  const previousActivity = getPreviousActivityTrack(subscription, currentActivityId!);
  const nextActivity = getNextActivityTrack(subscription, currentActivityId!);

  const navigateToNextActivity = () => {
    if (!subscription || !nextActivity) return;
    if (showNextActivityShortcut) setShowNextActivityShortcut(false);
    if (isRecapModalOpen) setIsRecapModalOpen(false);
    searchParams.set('activity', nextActivity.activity);
    navigateTo({
      pathname,
      search: searchParams.toString()
    });
  };

  const navigateToPreviousActivity = () => {
    if (!subscription || !previousActivity) return;
    if (showNextActivityShortcut) setShowNextActivityShortcut(false);
    if (isRecapModalOpen) setIsRecapModalOpen(false);
    searchParams.set('activity', previousActivity.activity);
    navigateTo({
      pathname,
      search: searchParams.toString()
    });
  };

  const updateIntroductionActivity = (activityId: string, isCompleted: boolean) => {
    updateActivityTracking({
      activity: activityId,
      isCompleted
    });
  };

  return {
    subscription,
    isRecapModalOpen,
    showActivityReview,
    setShowActivityReview,
    setIsRecapModalOpen,
    navigateToNextActivity,
    navigateToPreviousActivity,
    getTimeTracking,
    updateTimeTracking,
    setActivityReview,
    updateActivityTracking,
    getActivityTrackById,
    setActivityAsCompleted,
    setActivityAsIncomplete,
    sendActivityProblemReport,
    getCurrentActivity,
    areAllActivitiesLocked,
    updateIntroductionActivity,
    isLoading,
    isWorking,
    currentActivityId,
    previousActivity,
    nextActivity,
    showNextActivityShortcut,
    setShowNextActivityShortcut
  };
};
