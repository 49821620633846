import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const TeacherVoteService = sdk.getService('TeacherVoteService');

export const useCurrentTutorVote = () => {
  const queryKey = ['teacher-vote', 'current-tutor-vote'];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => TeacherVoteService.findCurrentTutorVote()
  });

  return {
    tutorVote: data,
    isPending
  };
};
