export const StudentTeacherVoteTranslations = {
  TEACHER_VOTE_TITLE: {
    es: 'Valora a los profesores',
    en: 'Rate your teachers',
    cat: 'Valorar als professors',
    eus: 'Baloratu zure ikastarok',
    pt: 'Avaliar seus professores'
  },
  TEACHER_VOTE_SUBTITLE_one: {
    es: 'Hay 1 profesor sin valorar',
    en: 'There is 1 teacher without rating',
    cat: 'Hi ha 1 professor sense valorar',
    eus: '1 ikastarok ez baloratuta',
    pt: 'Há 1 professor sem avaliação'
  },
  TEACHER_VOTE_SUBTITLE_other: {
    es: 'Hay {{count}} profesores sin valorar',
    en: 'There are {{count}} teachers without rating',
    cat: 'Hi ha {{count}} professors sense valorar',
    eus: '{{count}} ikastarok ez baloratuta',
    pt: 'Há {{count}} professores sem avaliação'
  },
  TEACHER_VOTE_COMMENTS_TITLE: {
    es: '¿Quieres comentar algo sobre {{teacherName}}?',
    en: 'Do you want to comment something about {{teacherName}}?',
    cat: '¿Vols comentar alguna cosa sobre {{teacherName}}?',
    eus: '{{teacherName}} gaitzeko zer moduzko komentario batzuetan?',
    pt: 'Você deseja comentar algo sobre {{teacherName}}?'
  },
  TEACHER_VOTE_COMMENTS_SUBTITLE: {
    es: 'Tu opinión es privada. Ningún profesor podrá leer lo que escribas.',
    en: 'Your opinion is private. No teacher will be able to read what you write.',
    cat: 'La teva opinió és privada. Cap professor podrà llegir el que escriguis.',
    eus: 'Zure ikustarok pribatua da. Ezin duen ikastarok ez duzu idatzitakoaren ikusten.',
    pt: 'Sua opinião é privada. Nenhum professor poderá ler o que você escrever.'
  },
  SEND_VOTE_BUTTON: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  VOTE_DONE: {
    es: '¡Vota de nuevo el mes que viene!',
    en: 'Vote again next month!',
    cat: '¡Vota de nou el mes que ve!',
    eus: 'Uneko hilabetean berriro votatu!',
    pt: 'Vote novamente no mês que vem!'
  },
  TEACHER_VOTE_COMMENTS_PLACEHOLDER: {
    es: 'Escribe aquí...',
    en: 'Write here...',
    cat: 'Escriu aquí...',
    eus: 'Idatzi hemen...',
    pt: 'Escreva aqui...'
  }
};
