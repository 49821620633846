export enum ENotificationType {
  NEW_EVENT = 'NEW_EVENT',
  NEW_RECURRING_EVENT = 'NEW_RECURRING_EVENT',
  EVENT_UPDATE = 'EVENT_UPDATE',
  NEW_POST = 'NEW_POST',
  NEW_TICKET = 'NEW_TICKET',
  TICKET_UPDATE = 'TICKET_UPDATE',
  NEW_DELIVERABLE = 'NEW_DELIVERABLE',
  DELIVERABLE_MESSAGE_CREATED = 'DELIVERABLE_MESSAGE_CREATED',
  DELIVERABLE_CORRECTED = 'DELIVERABLE_CORRECTED',
  NEW_TUTOR = 'NEW_TUTOR',
  NEW_TUTORING_GROUP_MEMBER = 'NEW_TUTORING_GROUP_MEMBER',
  NEW_TEACHER_ACTIVITY = 'NEW_TEACHER_ACTIVITY',
  NEW_TEACHER_QUIZ = 'NEW_TEACHER_QUIZ',
  NEW_EXAM_GROUP = 'NEW_EXAM_GROUP',
  NEW_EXAM_GROUP_EXAM = 'NEW_EXAM_GROUP_EXAM',
  NEW_LIVERECORD = 'NEW_LIVERECORD',
  TASK_RESOURCE_UPLOADED = 'TASK_RESOURCE_UPLOADED',
  TASK_DEADLINE = 'TASK_DEADLINE',
  TASK_DEADLINE_3_DAYS = 'TASK_DEADLINE_3_DAYS',
  TASK_REJECTED = 'TASK_REJECTED',
  TASK_APPROVED = 'TASK_APPROVED',
  NEW_STUDENT_VOTE = 'NEW_STUDENT_VOTE'
}
