import styled from 'styled-components';

type Props = {
  $selected?: boolean;
  $voted?: boolean;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, $selected }) => ($selected ? theme.colors.green6 : theme.colors.black4)};
  background: ${({ theme, $voted }) => ($voted ? 'transparent' : theme.colors.white)};

  .ant-rate {
    color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.green6)};
  }

  &:hover {
    border: ${({ theme, $selected, $voted }) =>
      !$selected && !$voted && `1px solid ${theme.colors.black5}`};
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    max-width: 800px;
    padding: 16px 20px;
  }
`;

export const TeacherNameWrapper = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.black9)};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const CourseNameWrapper = styled.span<Props>`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme, $voted }) => ($voted ? theme.colors.black6 : theme.colors.black9)};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const VoteDoneWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme }) => theme.colors.black6};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    gap: 16px;
  }
`;

export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    align-items: flex-end;
  }
`;

export const CommentsTitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme }) => theme.colors.black9};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const CommentsSubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme }) => theme.colors.black7};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
