import { createQueryString } from '@utils';
import { Dayjs } from 'dayjs';

import { IPaginatedResponse, IPagination, ISorter } from '../common';
import { ICreateMessage } from '../messages';
import { RequestService } from '../request';

import {
  IClosingAverageTicket,
  ITicket,
  ITicketsFilters,
  IOpenTickets,
  ICsatStats,
  ISetTicketReview,
  IUpdateAssignee,
  ISendTicketMessage,
  IDownloadCsatData,
  IDownloadTicketsByTagData,
  IDeleteMessage,
  ICreateTicket,
  IChangeUsefulMessageVote,
  IModeratedTicket
} from './ticket.contract';
import { orderQuery } from './utils';

export class TicketService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTickets(
    search?: string,
    pagination?: IPagination,
    bypass?: boolean,
    filters?: ITicketsFilters
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<ITicket>>(`/tickets?${query}`);
  }

  public getTicket(ticketId: string) {
    return this.fetcher<ITicket>(`/tickets/${ticketId}`);
  }

  public updateTicket(updatedTicket: Partial<ITicket>) {
    return this.putter<ITicket, Partial<ITicket>>(`/tickets/${updatedTicket._id}`, updatedTicket);
  }

  public removeTicket(ticketId: string) {
    return this.deleter(`/tickets/${ticketId}`);
  }

  public sendMessage({ ticketId, createMessage }: ISendTicketMessage) {
    return this.poster<IModeratedTicket, ICreateMessage>(
      `/tickets/${ticketId}/messages`,
      createMessage
    );
  }

  public setTicketAsResolved(ticketId: string) {
    return this.putter<Partial<ITicket>, string>(`/tickets/${ticketId}/resolve`);
  }

  public getCsatStats(dateFilter: [Dayjs, Dayjs], assignee?: string) {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        assignee
      }
    });
    return this.fetcher<ICsatStats>(`/tickets/stats/csat?${query}`);
  }
  public getClosingAverage = async (dateFilter: [Dayjs, Dayjs], teacherId?: string) => {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        teacherId
      }
    });
    return this.fetcher<IClosingAverageTicket>(`/tickets/stats/closing-average?${query}`);
  };

  public getOpenTickets = async (dateFilter: [Dayjs, Dayjs], teacherId?: string) => {
    const [startDate, endDate] = dateFilter;
    const query = createQueryString({
      filters: {
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
        teacherId
      }
    });
    return this.fetcher<IOpenTickets>(`/tickets/stats/open-tickets?${query}`);
  };

  public setTicketReview({ ticketId, review }: ISetTicketReview) {
    return this.poster(`/tickets/${ticketId}/review`, review);
  }

  public updateTicketAssignee({ ticketId, assignee }: IUpdateAssignee) {
    return this.poster(`/tickets/${ticketId}/assignee`, {
      assignee
    });
  }

  public changeUsefulTicketVote(ticketId: string) {
    return this.putter(`/tickets/${ticketId}/vote`);
  }

  public downloadCsatStats() {
    return this.poster<IDownloadCsatData[], undefined>(`/tickets/stats/csat/download`);
  }

  public downloadTicketsByTag() {
    return this.poster<IDownloadTicketsByTagData[], undefined>(`/tickets/stats/by-tag/download`);
  }

  public findAllTicketsByActivityId(activityId?: string, pagination?: IPagination, sort?: ISorter) {
    const query = createQueryString({
      limit: pagination?.limit,
      page: pagination?.page,
      sort:
        sort?.order && sort?.field
          ? orderQuery(sort.order, {
              sort: Array.isArray(sort.field) ? sort.field.join('.') : sort.field.toString()
            })
          : undefined
    });
    return this.fetcher<IPaginatedResponse<ITicket>>(`/tickets/activity/${activityId}?${query}`);
  }

  public deleteMessage({ ticketId, messageId }: IDeleteMessage) {
    return this.deleter(`/tickets/${ticketId}/messages/${messageId}`);
  }

  public createTicket(ticket: ICreateTicket) {
    return this.poster<IModeratedTicket, ICreateTicket>(`/tickets`, ticket);
  }

  public changeUsefulMessageVote({ ticketId, messageId }: IChangeUsefulMessageVote) {
    return this.putter(`/tickets/${ticketId}/messages/${messageId}/vote`);
  }
}
