export enum EGamificationRewardActionType {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT'
}

export enum EGamificationRewardType {
  REFERRAL = 'REFERRAL',
  ACTIVITY_SHARED = 'ACTIVITY_SHARED',
  ATTEND_TO_EVENT = 'ATTEND_TO_EVENT',
  TICKET_RESPONSE_LIKED = 'TICKET_RESPONSE_LIKED',
  HELPFUL_AI_TICKET_RESPONSE = 'HELPFUL_AI_TICKET_RESPONSE',
  ASK_DOUBT_DAILY_ACTIONS = 'ASK_DOUBT_DAILY_ACTIONS',
  COMPLETE_FREE_QUIZ_DAILY_ACTIONS = 'COMPLETE_FREE_QUIZ_DAILY_ACTIONS',
  COMPLETE_TWO_QUIZZES_DAILY_ACTIONS = 'COMPLETE_TWO_QUIZZES_DAILY_ACTIONS',
  COMPLETE_SECTION_DAILY_ACTIONS = 'COMPLETE_SECTION_DAILY_ACTIONS',
  COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS = 'COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS',
  SHARE_ACTIVITY_DAILY_ACTIONS = 'SHARE_ACTIVITY_DAILY_ACTIONS',
  GET_20_POINTS_DAILY_ACTIONS = 'GET_20_POINTS_DAILY_ACTIONS'
}

export const FIRST_DAY_ACTIONS: (
  | EGamificationRewardType.ASK_DOUBT_DAILY_ACTIONS
  | EGamificationRewardType.COMPLETE_FREE_QUIZ_DAILY_ACTIONS
)[] = [
  EGamificationRewardType.ASK_DOUBT_DAILY_ACTIONS,
  EGamificationRewardType.COMPLETE_FREE_QUIZ_DAILY_ACTIONS
];

export const SECOND_DAY_ACTIONS: (
  | EGamificationRewardType.COMPLETE_TWO_QUIZZES_DAILY_ACTIONS
  | EGamificationRewardType.COMPLETE_SECTION_DAILY_ACTIONS
)[] = [
  EGamificationRewardType.COMPLETE_TWO_QUIZZES_DAILY_ACTIONS,
  EGamificationRewardType.COMPLETE_SECTION_DAILY_ACTIONS
];

export const THIRD_DAY_ACTIONS: (
  | EGamificationRewardType.COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS
  | EGamificationRewardType.SHARE_ACTIVITY_DAILY_ACTIONS
)[] = [
  EGamificationRewardType.COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS,
  EGamificationRewardType.SHARE_ACTIVITY_DAILY_ACTIONS
];

export type IDailyActions =
  | typeof FIRST_DAY_ACTIONS
  | typeof SECOND_DAY_ACTIONS
  | typeof THIRD_DAY_ACTIONS;

interface GamificationReward {
  points: number;
}

export const GAMIFICATION_REWARDS: Record<EGamificationRewardType, GamificationReward> = {
  [EGamificationRewardType.REFERRAL]: {
    points: 1500
  },
  [EGamificationRewardType.ACTIVITY_SHARED]: {
    points: 10
  },
  [EGamificationRewardType.TICKET_RESPONSE_LIKED]: {
    points: 1
  },
  [EGamificationRewardType.HELPFUL_AI_TICKET_RESPONSE]: {
    points: 10
  },
  [EGamificationRewardType.ASK_DOUBT_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.COMPLETE_FREE_QUIZ_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.COMPLETE_TWO_QUIZZES_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.COMPLETE_SECTION_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.COMPLETE_REVIEW_QUIZ_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.SHARE_ACTIVITY_DAILY_ACTIONS]: {
    points: 5
  },
  [EGamificationRewardType.GET_20_POINTS_DAILY_ACTIONS]: {
    points: 8
  },
  [EGamificationRewardType.ATTEND_TO_EVENT]: {
    points: 15
  }
};

export enum EBadgeTypes {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  BRONZE = 'BRONZE'
}
