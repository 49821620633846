export const CommonEventTranslations = {
  EVENTS: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldis',
    pt: 'Eventos'
  },
  EVENT_LINK: {
    es: 'Enlace del evento',
    en: 'Event link',
    cat: 'Enllaç de l`esdeveniment',
    eus: 'Gertaeraren esteka',
    pt: 'Link do evento'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  ATTENDEES: {
    es: 'Asistentes',
    en: 'Attendees',
    cat: 'Assistents',
    eus: 'Asistentziak',
    pt: 'Assistentes'
  },
  WHEN: {
    es: '¿Cuándo?',
    en: 'When?',
    cat: 'Quan?',
    eus: 'Noiz?',
    pt: 'Quando?'
  },
  ATTEND: {
    es: 'Asistir',
    en: 'Attend',
    cat: 'Assistir',
    eus: 'Asistitu',
    pt: 'Assistir'
  },
  SPEAKER: {
    es: 'Ponente',
    en: 'Speaker',
    cat: 'Ponent',
    eus: 'Hizlaria',
    pt: 'Palestrante'
  },
  UPLOAD_ATTACHMENT: {
    es: 'Subir archivo',
    en: 'Upload file',
    cat: 'Pujar arxiu',
    eus: 'Igo fitxategia',
    pt: 'Carregar ficheiro'
  },
  DELETE_LIVE_RECORD: {
    es: 'Eliminar grabación',
    en: 'Delete live record',
    cat: 'Eliminar enregistrament',
    eus: 'Ezabatu zuzeneko grabazioa',
    pt: 'Eliminar gravação ao vivo'
  },
  DELETE_LIVE_RECORD_CONFIRMATION: {
    es: '¿Estás seguro de eliminar esta grabación?',
    en: 'Are you sure to delete this live record?',
    cat: 'Estàs segur que vols eliminar aquest enregistrament?',
    eus: 'Ziur zaude zuzeneko grabazio hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta gravação ao vivo?'
  },
  ARE_YOU_SURE_DELETE_THIS_ATTACHMENT: {
    es: '¿Estás seguro de eliminar este archivo?',
    en: 'Are you sure to delete this attachment?',
    cat: 'Estàs segur d`eliminar aquest arxiu?',
    eus: 'Ziur zaude fitxategi hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este ficheiro?'
  },
  ATTACHMENT_REMOVED_CORRECTLY: {
    es: 'EL archivo se ha eliminado correctamente',
    en: 'The file has been removed correctly',
    cat: 'L`arxiu s`ha eliminat correctament',
    eus: 'Fitxategia zuzen ezabatu da',
    pt: 'O ficheiro foi eliminado com sucesso'
  },
  ATTACHMENTS: {
    es: 'Archivos adjuntos',
    en: 'Attachments',
    cat: 'Adjunts',
    eus: 'Eranskinak',
    pt: 'Anexos'
  },
  LIVE_RECORDS: {
    es: 'Grabaciones en directo',
    en: 'Live recordings',
    cat: 'Enregistraments en directe',
    eus: 'Zuzeneko grabazioak',
    pt: 'Gravações ao vivo'
  },
  NO_LIVE_RECORDS_YET: {
    es: 'Aún no hay grabaciones en directo',
    en: 'There are no live recordings yet',
    cat: 'Encara no hi ha enregistraments en directe',
    eus: 'Oraindik ez dago zuzeneko grabazioik',
    pt: 'Ainda não há gravações ao vivo'
  },
  EVENT_DELETE_CONFIRMATION: {
    es: '¿Estás seguro de que quieres eliminar este evento?',
    en: 'Are you sure you want to delete this event?',
    cat: 'Estàs segur que vols eliminar aquest esdeveniment?',
    eus: 'Ziur zaude ekitaldi hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este evento?'
  },
  CONFIRM_MODAL_EVENT_REMOVE_TITLE: {
    es: 'Eliminar evento',
    en: 'Remove event',
    cat: 'Eliminar esdeveniment',
    eus: 'Ekitaldia ezabatu',
    pt: 'Eliminar evento'
  },
  CONFIRM_MODAL_EVENT_REMOVE_CONTENT: {
    es: '¿Deseas eliminar todos los eventos recurrentes o solo este?',
    en: 'Do you want to remove all recurring events or just this one?',
    cat: 'Vols eliminar tots els esdeveniments recurrents o només aquest?',
    eus: 'Ekitaldi errepikatzaile guztiak ezabatu nahi dituzu edo bakarrik hau?',
    pt: 'Quer eliminar todos os eventos recorrentes ou apenas este?'
  },
  CONFIRM_MODAL_EVENT_REMOVE_OK_TEXT: {
    es: 'Todos los eventos',
    en: 'All events',
    cat: 'Tots els esdeveniments',
    eus: 'Ekitaldi guztiak',
    pt: 'Todos os eventos'
  },
  CONFIRM_MODAL_EVENT_REMOVE_CANCEL_TEXT: {
    es: 'Este evento',
    en: 'This event',
    cat: 'Aquest esdeveniment',
    eus: 'Ekitaldi hau',
    pt: 'Este evento'
  },
  EDIT_NAME: {
    es: 'Editar nombre',
    en: 'Edit name',
    cat: 'Editar nom',
    eus: 'Izen aldatu',
    pt: 'Editar nome'
  },
  LIVE_RECORD_NAME_PLACEHOLDER: {
    es: 'Nombre de la grabación',
    en: 'Live recording name',
    cat: 'Nom de l`enregistrament en directe',
    eus: 'Zuzeneko grabazioaren izena',
    pt: 'Nome da gravação ao vivo'
  }
};
