import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 24px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding: 0;
  }
`;
