import styled from 'styled-components';

type Props = {
  isCurrent: boolean;
  isLocked?: boolean;
  isCompleted?: boolean;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.black3};
  color: ${({ theme, isCurrent }) => (isCurrent ? theme.colors.green6 : 'unset')};
  background: ${({ theme, isCurrent }) => (isCurrent ? theme.colors.green1 : 'unset')};

  display: flex;
  gap: 16px;
  align-items: flex-start;
  cursor: ${({ isLocked }) => (isLocked ? 'not-allowed' : 'pointer')};

  :hover {
    background: ${({ theme, isCurrent }) =>
      isCurrent ? theme.colors.green2 : theme.colors.black1};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  color: ${({ theme }) => theme.colors.black9};
`;

export const IconWrapper = styled.div<Props>`
  color: ${({ theme, isLocked, isCompleted }) =>
    isLocked ? theme.colors.black5 : isCompleted ? theme.colors.green6 : 'unset'};
  border-radius: 4px;
  pointer-events: none;
  display: flex;
  flex: none;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isCompleted, isCurrent }) =>
    isCompleted || isCurrent ? theme.colors.green1 : theme.colors.black3};
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const SpanWrapper = styled.span<Props>`
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
`;

export const NameWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > span {
    color: ${({ theme, isLocked }) => isLocked && theme.colors.black5};
  }
`;

export const WatchingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.green6};
  font-size: 12px;
  line-height: 20px;
`;
