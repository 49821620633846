import { create } from 'zustand';

type IStore = {
  isOpen: boolean;
  openForm: () => void;
  closeForm: () => void;
};

export const useReportProblemForm = create<IStore>(set => ({
  isOpen: false,
  openForm: () => set({ isOpen: true }),
  closeForm: () => set({ isOpen: false })
}));
