export const StudentStoreTranslations = {
  STORE: {
    es: 'Tienda',
    en: 'Store',
    cat: 'Botiga',
    eus: 'Denda',
    pt: 'Loja'
  },
  PAGE_SUBTITLE: {
    es: '¡Garantiza tu éxito con los complementos de Ucademy!',
    en: 'Guarantee your success with Ucademy add-ons!',
    cat: 'Garanteix el teu èxit amb els complements de Ucademy!',
    eus: 'Zure arrakasta bermatu Ucademy osagarriekin!',
    pt: 'Garanta o seu sucesso com os complementos da Ucademy!'
  },
  PROMOTION_TITLE: {
    es: '🚀 Promoción de lanzamiento hasta el 1 de marzo 🚀',
    en: '🚀 Launch promotion until March 1st 🚀',
    cat: '🚀 Promoció de llançament fins al 1 de març 🚀',
    eus: '🚀 Abian jartzeko promozioa martxoaren 1era arte 🚀',
    pt: '🚀 Promoção de lançamento até 1 de março 🚀'
  },
  NO_PRICING_TABLE: {
    es: 'Lo sentimos, no hemos podido encontrar productos disponibles para ti.',
    en: 'Sorry, we could not find any available products for you.',
    cat: 'Ho sentim, no hem pogut trobar productes disponibles per a tu.',
    eus: 'Sentitzen dugu, ezin izan dugu produktu eskuragarriak aurkitu zuretzat.',
    pt: 'Sentimos-nos, não conseguimos encontrar produtos disponíveis para você.'
  }
};
