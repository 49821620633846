import { EStudentsTableTabs } from '@constants';

export const UsersTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak',
    pt: 'Usuários'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  LAST_NAME: {
    es: 'Apellido',
    en: 'Last Name',
    cat: 'Cognom',
    eus: 'Abizena',
    pt: 'Sobrenome'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa',
    pt: 'Email'
  },
  USERNAME: {
    es: 'Nombre de usuario',
    en: 'Username',
    cat: 'Nom d`usuari',
    eus: 'Erabiltzaile izena',
    pt: 'Nome de usuário'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  PASSWORD: {
    es: 'Contraseña',
    en: 'Password',
    cat: 'Contrasenya',
    eus: 'Pasahitza',
    pt: 'Senha'
  },
  ROLE: {
    es: 'Rol',
    en: 'Role',
    cat: 'Rol',
    eus: 'Erantzukizuna',
    pt: 'Papel'
  },
  AVATAR: {
    es: 'Avatar',
    en: 'Avatar',
    cat: 'Avatar',
    eus: 'Avatarra',
    pt: 'Avatar'
  },
  NEW_USER: {
    es: 'Nuevo Usuario',
    en: 'New User',
    cat: 'Nou Usuari',
    eus: 'Erabiltzaile Berria',
    pt: 'Usuário Novo'
  },
  EDIT_USER: {
    es: 'Editar Usuario',
    en: 'Edit User',
    cat: 'Editar Usuari',
    eus: 'Erabiltzailea Aldatu',
    pt: 'Usuário Editar'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde',
    pt: 'Salvar Alterações'
  },
  CREATE_USER: {
    es: 'Crear Usuario',
    en: 'Create User',
    cat: 'Crear Usuari',
    eus: 'Erabiltzailea Sortu',
    pt: 'Usuário Criar'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea',
    pt: 'Aluno'
  },
  TEACHER: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  ADMIN: {
    es: 'Administrador',
    en: 'Admin',
    cat: 'Administrador',
    eus: 'Administrazioa',
    pt: 'Administrador'
  },
  MANAGER: {
    es: 'Gerente',
    en: 'Manager',
    cat: 'Gerent',
    eus: 'Kudeatzailea',
    pt: 'Gerente'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia',
    pt: 'Académia'
  },
  USERS_TOTAL_COUNT: {
    es: '{{total}} usuarios',
    en: '{{total}} users',
    cat: '{{total}} usuaris',
    eus: '{{total}} erabiltzaile',
    pt: '{{total}} usuários'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  TAGS: {
    es: 'Formación',
    en: 'Category',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formação'
  },
  DATE_RANGE: {
    es: 'Rango de fechas',
    en: 'Date range',
    cat: 'Rang de dates',
    eus: 'Dataren tartea',
    pt: 'Faixa de datas'
  },
  [EStudentsTableTabs.ALL_STUDENTS]: {
    es: 'Todos',
    en: 'All',
    cat: 'Tots',
    eus: 'Ditugu',
    pt: 'Todos'
  },
  [EStudentsTableTabs.PROMOTIONS]: {
    es: 'Promociones',
    en: 'Promotions',
    cat: 'Promocions',
    eus: 'Promozioak',
    pt: 'Promoções'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  STUDENTS_COUNT: {
    es: 'Nº Alumnos',
    en: 'Students Count',
    cat: 'Nº d`alumnes',
    eus: 'Ikasleen kopurua',
    pt: 'Número de alunos'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  PROMOTIONS_TOTAL_COUNT: {
    es: '{{total}} promociones',
    en: '{{total}} promotions',
    cat: '{{total}} promocions',
    eus: '{{total}} promozioak',
    pt: '{{total}} promoções'
  },
  NEW_PROMOTION: {
    es: 'Nueva promoción',
    en: 'New Promotion',
    cat: 'Nova promoció',
    eus: 'Promozio Berria',
    pt: 'Promoção Nova'
  },
  EDIT_PROMOTION: {
    es: 'Editar promoción',
    en: 'Edit Promotion',
    cat: 'Editar promoció',
    eus: 'Promozioa editatu',
    pt: 'Promoção Editar'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancelar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde',
    pt: 'Salvar'
  },
  DELETE_PROMOTION: {
    es: 'Sacar alumnos y eliminar',
    en: 'Remove students and delete',
    cat: 'Eliminar alumnes i treure',
    eus: 'Ikasleen kentzea eta ezabatzea',
    pt: 'Remover alunos e eliminar'
  },
  DELETE_PROMOTION_CONFIRMATION: {
    es: '¿Estás seguro de que quieres sacar a los alumnos y eliminar la promoción? Esto eliminará todos los exámenes asociados a la promoción.',
    en: 'Are you sure you want to remove the students and delete the promotion? This will delete all exams associated with the promotion.',
    cat: 'Estàs segur que vols treure els alumnes i eliminar la promoció? Aquesta acció eliminarà tots els exàmens associats a la promoció.',
    eus: 'Ziur zaude ikasleen kentzea eta promozioa ezabatzea? Honela egin daiteke ezabatu ikasleak eta ezabatu promozioa.',
    pt: 'Tem certeza que deseja remover os alunos e eliminar a promoção? Esta ação excluirá todos os exames associados à promoção.'
  },
  CANT_UNDO: {
    es: 'Esta acción no se puede deshacer',
    en: 'This action cannot be undone',
    cat: 'Aquesta acció no es pot desfer',
    eus: 'Ez da inoiz egin daiteke',
    pt: 'Esta ação não pode ser desfeita'
  }
};
