import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useMyQuizDetail = (quiz?: string) => {
  const { _id } = useParams<{ _id: string }>();

  const quizId = quiz ?? _id;
  const { isLoading, data, error } = useQuery({
    queryKey: ['quizzes', quizId],
    queryFn: () => QuizService.getQuiz(quizId!),
    enabled: !!quizId
  });

  return {
    quiz: data,
    isLoading,
    error
  };
};
