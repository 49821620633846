import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 16px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.lg}) {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.black7};

  :hover {
    color: ${({ theme }) => theme.colors.green6};
  }
`;
