import { EEventWeekdays } from '@constants';

export const CommonEventsTranslations = {
  EVENTS: {
    es: 'Eventos',
    en: 'Events',
    cat: 'Esdeveniments',
    eus: 'Ekitaldis',
    pt: 'Eventos'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  EVENT_TYPE: {
    es: 'Tipo de evento',
    en: 'Event type',
    cat: "Tipus d'esdeveniment",
    eus: 'Ekitaldi mota',
    pt: 'Tipo de evento'
  },
  EVENT: {
    es: 'Evento',
    en: 'Event',
    cat: 'Esdeveniment',
    eus: 'Ekitaldi',
    pt: 'Evento'
  },
  LIVE_CLASS: {
    es: 'Clase en directo',
    en: 'Live class',
    cat: 'Classe en directe',
    eus: 'Zuzeneko klasea',
    pt: 'Aula ao vivo'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  HOUR: {
    es: 'Hora',
    en: 'Hour',
    cat: 'Hora',
    eus: 'Ordua',
    pt: 'Hora'
  },
  EVENT_LINK: {
    es: 'Enlace al evento',
    en: 'Event link',
    cat: "Enllaç a l'esdeveniment",
    eus: 'Gertaeraren esteka',
    pt: 'Link do evento'
  },
  ATTACH_FILE: {
    es: 'Adjuntar archivo',
    en: 'Attach file',
    cat: 'Adjuntar arxiu',
    eus: 'Fitxategia erantsi',
    pt: 'Anexar ficheiro'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  EVENT_CREATED_SUCCESSFULLY: {
    es: 'Evento creado correctamente',
    en: 'Event successfully created',
    cat: 'Esdeveniment creat amb èxit',
    eus: 'Ekitaldi ongi sortu da',
    pt: 'Evento criado com sucesso'
  },
  EVENT_UPDATED_SUCCESSFULLY: {
    es: 'Evento actualizado correctamente',
    en: 'Event successfully updated',
    cat: 'Esdeveniment actualitzat amb èxit',
    eus: 'Ekitaldi ongi eguneratu da',
    pt: 'Evento atualizado com sucesso'
  },
  CREATE_EVENT: {
    es: 'Nuevo evento',
    en: 'Create event',
    cat: 'Nou esdeveniment',
    eus: 'Ekitaldi berria',
    pt: 'Novo evento'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Course',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  FORMATION: {
    es: 'Formación {{number}}',
    en: 'Formation {{number}}',
    cat: 'Formació {{number}}',
    eus: 'Ikastaroa {{number}}',
    pt: 'Formação {{number}}'
  },
  UPDATE_EVENT: {
    es: 'Actualizar evento',
    en: 'Update event',
    cat: 'Actualitzar esdeveniment',
    eus: 'Ekitaldi eguneratu',
    pt: 'Atualizar evento'
  },
  SPEAKERS: {
    es: 'Ponentes',
    en: 'Speakers',
    cat: 'Ponents',
    eus: 'Hizlariak',
    pt: 'Palestrantes'
  },
  INSCRIBED: {
    es: 'Inscrito',
    en: 'Inscribed',
    cat: 'Inscrit',
    eus: 'Izena eman',
    pt: 'Inscrito'
  },
  ATTEND: {
    es: 'Asistir',
    en: 'Attend',
    cat: 'Assistir',
    eus: 'Joan',
    pt: 'Assistir'
  },
  ATTACHMENT_REMOVED_CORRECTLY: {
    es: 'La grabación se ha eliminado correctamente',
    en: 'Recording successfully removed',
    cat: 'La gravació s`ha eliminat correctament',
    eus: 'Grabazioa ondo ezabatu da',
    pt: 'Gravação removida com sucesso'
  },
  DAY_WEEK_0: {
    es: '💆‍♀️ Domingo',
    en: '💆‍♀️ Sunday',
    cat: '💆‍♀️ Diumenge',
    eus: '💆‍♀️ Igandea',
    pt: '💆‍♀️ Domingo'
  },
  DAY_WEEK_1: {
    es: '🧟 Lunes',
    en: '🧟 Monday',
    cat: '🧟 Dilluns',
    eus: '🧟 Astelehena',
    pt: '🧟 Segunda-feira'
  },
  DAY_WEEK_2: {
    es: '☕️ Martes',
    en: '☕️ Tuesday',
    cat: '☕️ Dimarts',
    eus: '☕️ Asteartea',
    pt: '☕️ Segunda-feira'
  },
  DAY_WEEK_3: {
    es: '📚️ Miércoles',
    en: '📚️ Wednesday',
    cat: '📚️ Dimecres',
    eus: '📚️ Asteazkena',
    pt: '📚️ Terça-feira'
  },
  DAY_WEEK_4: {
    es: '🧠 Jueves',
    en: '🧠 Thursday',
    cat: '🧠 Dijous',
    eus: '🧠 Osteguna',
    pt: '🧠 Quinta-feira'
  },
  DAY_WEEK_5: {
    es: '🍺 Viernes',
    en: '🍺 Friday',
    cat: '🍺 Divendres',
    eus: '🍺 Ostirala',
    pt: '🍺 Sexta-feira'
  },
  DAY_WEEK_6: {
    es: '🎉 Sábado',
    en: '🎉 Saturday',
    cat: '🎉 Dissabte',
    eus: '🎉 Larunbata',
    pt: '🎉 Sábado'
  },
  MONTH: {
    es: 'Mes',
    en: 'Month',
    cat: 'Mes',
    eus: 'Hilabetea',
    pt: 'Mês'
  },
  WEEK: {
    es: 'Semana',
    en: 'Week',
    cat: 'Setmana',
    eus: 'Astea',
    pt: 'Semana'
  },
  LIST: {
    es: 'Lista',
    en: 'List',
    cat: 'Llista',
    eus: 'Zerrenda',
    pt: 'Lista'
  },
  DAY: {
    es: 'Día',
    en: 'Day',
    cat: 'Dia',
    eus: 'Eguna',
    pt: 'Dia'
  },
  TODAY: {
    es: 'Hoy',
    en: 'Today',
    cat: 'Avui',
    eus: 'Gaur',
    pt: 'Hoje'
  },
  MORE_EVENTS_TEXT: {
    es: '+{{num}} Más',
    en: '+{{num}} More',
    cat: '+{{num}} Més',
    eus: '+{{num}} Gehiago',
    pt: '+{{num}} Mais'
  },
  DELETE_EVENT_CONFIRM: {
    es: '¿Estás seguro de eliminar este evento?',
    en: 'Are you sure you want to delete this event?',
    cat: 'Estàs segur que vols eliminar aquest esdeveniment?',
    eus: 'Ziur zaude ekitaldi hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este evento?'
  },
  ATTACHMENT_ADDED_CORRECTLY: {
    es: 'El archivo se ha añadido correctamente',
    en: 'The file has been added correctly',
    cat: 'L`arxiu s`ha afegit correctament',
    eus: 'Fitxategia zuzen gehitu da',
    pt: 'O ficheiro foi adicionado com sucesso'
  },
  EVENT_REMOVED_SUCCESSFULLY: {
    es: 'Evento eliminado correctamente',
    en: 'Event removed successfully',
    cat: 'Esdeveniment eliminat correctament',
    eus: 'Ekitaldi ongi ezabatu da',
    pt: 'Evento eliminado com sucesso'
  },
  TAGS: {
    es: 'Formaciones',
    en: 'Tags',
    cat: 'Etiquetes',
    eus: 'Etiketak',
    pt: 'Etiquetas'
  },
  RECURRING_LABEL: {
    es: '¿Es un evento único o recurrente?',
    en: 'Is it a unique or recurring event?',
    cat: '¿Es un esdeveniment únic o recurrent?',
    eus: 'Eskubide bakarra edo errepikakorra da?',
    pt: 'É um evento único ou recorrente?'
  },
  FREQUENCY: {
    es: 'Frecuencia',
    en: 'Frequency',
    cat: 'Frecuencia',
    eus: 'Errepikapen periodikoa',
    pt: 'Frequência'
  },
  RECURRING_UNIQUE: {
    es: 'Único',
    en: 'Unique',
    cat: 'Únic',
    eus: 'Eskubide bakarra',
    pt: 'Único'
  },
  RECURRING: {
    es: 'Recurrente',
    en: 'Recurring',
    cat: 'Recurrent',
    eus: 'Errepikapen bakarra',
    pt: 'Recorrente'
  },
  DATE_ONLY: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  TIME_ONLY: {
    es: 'Hora',
    en: 'Time',
    cat: 'Hora',
    eus: 'Ordua',
    pt: 'Hora'
  },
  WEEKDAYS: {
    es: 'Días de la semana',
    en: 'Weekdays',
    cat: 'Dies de la setmana',
    eus: 'Astearen egunak',
    pt: 'Dias da semana'
  },
  [EEventWeekdays.MONDAY]: {
    es: 'Lunes',
    en: 'Monday',
    cat: 'Dilluns',
    eus: 'Astelehena',
    pt: 'Segunda-feira'
  },
  [EEventWeekdays.TUESDAY]: {
    es: 'Martes',
    en: 'Tuesday',
    cat: 'Dimarts',
    eus: 'Asteartea',
    pt: 'Terça-feira'
  },
  [EEventWeekdays.WEDNESDAY]: {
    es: 'Miércoles',
    en: 'Wednesday',
    cat: 'Dimecres',
    eus: 'Asteazkena',
    pt: 'Quarta-feira'
  },
  [EEventWeekdays.THURSDAY]: {
    es: 'Jueves',
    en: 'Thursday',
    cat: 'Dijous',
    eus: 'Osteguna',
    pt: 'Quinta-feira'
  },
  [EEventWeekdays.FRIDAY]: {
    es: 'Viernes',
    en: 'Friday',
    cat: 'Divendres',
    eus: 'Ostirala',
    pt: 'Sexta-feira'
  },
  [EEventWeekdays.SATURDAY]: {
    es: 'Sábado',
    en: 'Saturday',
    cat: 'Dissabte',
    eus: 'Larunbata',
    pt: 'Sábado'
  },
  [EEventWeekdays.SUNDAY]: {
    es: 'Domingo',
    en: 'Sunday',
    cat: 'Diumenge',
    eus: 'Igandea',
    pt: 'Domingo'
  },
  CONFIRM_MODAL_TITLE: {
    es: 'Actualizar todos los eventos recurrentes',
    en: 'Update all recurring events',
    cat: 'Actualitzar tots els esdeveniments recurrents',
    eus: 'Eguneratu errepikapen guztiak',
    pt: 'Atualizar todos os eventos recorrentes'
  },
  CONFIRM_MODAL_CONTENT: {
    es: '¿Deseas actualizar todos los eventos recurrentes o solo este?',
    en: 'Do you want to update all recurring events or just this one?',
    cat: 'Vols actualitzar tots els esdeveniments recurrents o només aquest?',
    eus: 'Errepikapen guztiak edo bakarrik hau eguneratu nahi dituzu?',
    pt: 'Quer atualizar todos os eventos recorrentes ou apenas este?'
  },
  CONFIRM_MODAL_OK_TEXT: {
    es: 'Todos los eventos',
    en: 'All events',
    cat: 'Tots els esdeveniments',
    eus: 'Ekitaldi guztiak',
    pt: 'Todos os eventos'
  },
  CONFIRM_MODAL_CANCEL_TEXT: {
    es: 'Este evento',
    en: 'This event',
    cat: 'Aquest esdeveniment',
    eus: 'Ekitaldi hau',
    pt: 'Este evento'
  },
  TIMEZONE: {
    es: 'Zona horaria',
    en: 'Timezone',
    cat: 'Zona horària',
    eus: 'Denbora-zonal',
    pt: 'Fuso horário'
  },
  LIVE_EVENTS: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Zuzeneko klaseak',
    pt: 'Aulas ao vivo'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Fitxategiak',
    pt: 'Filtros'
  },
  VERTICAL: {
    es: 'Vertical',
    en: 'Vertical',
    cat: 'Vertical',
    eus: 'Eskuin',
    pt: 'Vertical'
  },
  TAG: {
    es: 'Formación',
    en: 'Training',
    cat: 'Formació',
    eus: 'Ikasgaia',
    pt: 'Formação'
  },
  TEACHER: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  CLEAR_FILTERS: {
    es: 'Borrar filtros',
    en: 'Clear filters',
    cat: 'Netejar filtres',
    eus: 'Ezabatu filtrotak',
    pt: 'Limpar filtros'
  },
  COURSE_AND_SECTION: {
    es: 'Curso y tema (opcional)',
    en: 'Course and section (optional)',
    cat: 'Curs i tema (opcional)',
    eus: 'Ikastaro eta gaia (aukerakoa)',
    pt: 'Curso e secção (opcional)'
  },
  ADD_FORMATION: {
    es: 'Añadir otra formación',
    en: 'Add another formation',
    cat: 'Afegeix una altra formació',
    eus: 'Beste ikastaroa gehitu',
    pt: 'Adicionar outra formação'
  },
  LIVE_RECORD_NAME_UPDATED_CORRECTLY: {
    es: 'El nombre de la grabación se ha actualizado correctamente',
    en: 'The live recording name has been updated correctly',
    cat: 'L`nom de l`enregistrament en directe s`ha actualitzat correctament',
    eus: 'Zuzeneko grabazioaren izena zuzen aldatu da',
    pt: 'O nome da gravação ao vivo foi atualizado com sucesso'
  }
};
