export const CommonTutoringGroupsTranslations = {
  CREATE_GROUP: {
    es: 'Crear grupo',
    en: 'Create group',
    cat: 'Crear grup',
    eus: 'Taldea sortu',
    pt: 'Criar grupo'
  },
  THERE_ARE_NO_GROUPS_ASSIGNED: {
    es: 'No tienes tutorías asignadas',
    en: 'You have no tutoring groups assigned',
    cat: 'No tens tutories assignades',
    eus: 'Ez duzu tutoretarik asignaturik',
    pt: 'Não tem grupos de tutoria atribuídos'
  },
  THERE_ARE_NO_GROUPS_ASSIGNED_DESCRIPTION: {
    es: 'Si tuvieras alumnos a tu cargo aquí podrías hacerles en general un seguimiento más exhaustivo.',
    en: 'If you had students under your care, you could generally monitor them more closely here.',
    cat: 'Si tinguessis alumnes a càrrec aquí podries fer-los en general un seguiment més exhaustiu.',
    eus: 'Zure zaindutako ikasleak badituzu, hemen jarraitu ziezaiete gehiago.',
    pt: 'Se você tivesse alunos sob sua responsabilidade, poderia monitorá-los com mais rigor aqui.'
  },
  GROUP_NAME: {
    es: 'Nombre del grupo',
    en: 'Group name',
    cat: 'Nom del grup',
    eus: 'Taldearen izena',
    pt: 'Nome do grupo'
  },
  TUTORINGS: {
    es: 'Tutorías',
    en: 'Tutorings',
    cat: 'Tutories',
    eus: 'Tutoretarik',
    pt: 'Aulas'
  },
  GROUPS: {
    es: 'Grupos',
    en: 'Groups',
    cat: 'Grups',
    eus: 'Taldeak',
    pt: 'Grupos'
  },
  GROUP: {
    es: 'Grupo',
    en: 'Group',
    cat: 'Grup',
    eus: 'Taldea',
    pt: 'Grupo'
  },
  GROUP_TAG: {
    es: 'Categoria',
    en: 'Category',
    cat: 'Categoria',
    eus: 'Kategoria',
    pt: 'Categoria'
  },
  MEMBERS: {
    es: 'Miembros',
    en: 'Members',
    cat: 'Membres',
    eus: 'Kideak',
    pt: 'Membros'
  },
  GROUP_TUTOR: {
    es: 'Tutor',
    en: 'Tutor',
    cat: 'Tutor',
    eus: 'Tutorea',
    pt: 'Tutora'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  UPDATE: {
    es: 'Actualizar',
    en: 'Update',
    cat: 'Actualitzar',
    eus: 'Eguneratu',
    pt: 'Atualizar'
  },
  DELETE: {
    es: 'Eliminar',
    en: 'Delete',
    cat: 'Eliminar',
    eus: 'Ezabatu',
    pt: 'Eliminar'
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GROUP: {
    es: '¿Estás seguro de que quieres eliminar este grupo?',
    en: 'Are you sure you want to delete this group?',
    cat: 'Segur que vols eliminar aquest grup?',
    eus: 'Ziur zaude taldea ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir este grupo?'
  },
  GROUP_UPDATED_SUCCESSFULLY: {
    es: 'Grupo actualizado correctamente',
    en: 'Group updated successfully',
    cat: 'Grup actualitzat amb èxit',
    eus: 'Taldea ongi eguneratu da',
    pt: 'Grupo atualizado com sucesso'
  },
  GROUP_CREATED_SUCCESSFULLY: {
    es: 'Grupo creado correctamente',
    en: 'Group created successfully',
    cat: 'Grup creat amb èxit',
    eus: 'Taldea ongi sortu da',
    pt: 'Grupo criado com sucesso'
  },
  NO_MEMBERS_IN_THE_GROUP: {
    es: 'No hay miembros en el grupo',
    en: 'No members in the group',
    cat: 'No hi ha membres en el grup',
    eus: 'Ez dago taldean kide',
    pt: 'Não há membros no grupo'
  },
  DELETE_GROUP: {
    es: 'Eliminar grupo',
    en: 'Delete group',
    cat: 'Eliminar grup',
    eus: 'Taldea ezabatu',
    pt: 'Excluir grupo'
  },
  GROUP_DELETED_SUCCESSFULLY: {
    es: 'Grupo eliminado correctamente',
    en: 'Group deleted successfully',
    cat: 'Grup eliminat amb èxit',
    eus: 'Taldea ongi ezabatu da',
    pt: 'Grupo excluído com sucesso'
  },
  GROUP_DESCRIPTION: {
    es: 'Descripción del grupo',
    en: 'Group description',
    cat: 'Descripció del grup',
    eus: 'Taldearen deskribapena',
    pt: 'Descrição do grupo'
  },
  MEMBER_ADDED_SUCCESSFULLY: {
    es: 'Miembro añadido correctamente',
    en: 'Member added successfully',
    cat: 'Membre afegit amb èxit',
    eus: 'Kidea ongi gehitu da',
    pt: 'Membro adicionado com sucesso'
  },
  SELECT_A_STUDENT_TO_ADD_TO_GROUP: {
    es: 'Selecciona un estudiante para añadir al grupo',
    en: 'Select a student to add to group',
    cat: 'Selecciona un estudiant per afegir al grup',
    eus: 'Hautatu ikasle bat taldeari gehitzeko',
    pt: 'Selecione um aluno para adicionar ao grupo'
  },
  MEMBER_REMOVED_SUCCESSFULLY: {
    es: 'Miembro eliminado correctamente',
    en: 'Member removed successfully',
    cat: 'Membre eliminat amb èxit',
    eus: 'Kidea ongi kendu da',
    pt: 'Membro removido com sucesso'
  },
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_MEMBER: {
    es: '¿Estás seguro de que quieres eliminar este miembro?',
    en: 'Are you sure you want to remove this member?',
    cat: 'Segur que vols eliminar aquest membre?',
    eus: 'Ziur zaude kidea ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir este membro?'
  },
  MEMBERS_TOTAL_COUNT: {
    es: 'Miembros totales: {{count}}',
    en: 'Total members: {{count}}',
    cat: 'Membres totals: {{count}}',
    eus: 'Kide guztira: {{count}}',
    pt: 'Membros totais: {{count}}'
  },
  EDIT_GROUP: {
    es: 'Editar grupo',
    en: 'Edit group',
    cat: 'Edita el grup',
    eus: 'Taldea editatu',
    pt: 'Editar grupo'
  },
  PREVIEW_GROUP: {
    es: 'Vista previa',
    en: 'Preview group',
    cat: 'Vista prèvia del grup',
    eus: 'Taldearen aurrebista',
    pt: 'Pré-visualização do grupo'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Guardar',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  TUTOR: {
    es: 'Tutor:',
    en: 'Tutor:',
    cat: 'Tutor:',
    eus: 'Tutorea:',
    pt: 'Tutora:'
  },
  MEMBERS_COUNT: {
    es: '{{count}} Miembros',
    en: '{{count}} Members',
    cat: '{{count}} Membres',
    eus: '{{count}} Kide',
    pt: '{{count}} Membros'
  },
  ADD_MEMBER: {
    es: 'Añadir miembros',
    en: 'Add members',
    cat: 'Afegir membres',
    eus: 'Gehitu kideak',
    pt: 'Adicionar membros'
  },
  YES: {
    es: 'Si',
    en: 'Yes',
    cat: 'Si',
    eus: 'Bai',
    pt: 'Sim'
  }
};
