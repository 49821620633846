import { EActivityChatMessageRole } from '@constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import { useActivityChatMessages } from './useActivityChatMessages';

import { sdk } from '@/services';

const ActivityChatService = sdk.getService('ActivityChatService');

export const useActivityChatSummary = (activityId?: string) => {
  const { messages, setMessages, setIsInitialLoading } = useActivityChatMessages();

  const queryClient = useQueryClient();
  const queryKey = ['activity-chat'];
  const { mutate: generatePdfActivitySummary, isPending } = useMutation({
    mutationFn: () => {
      setIsInitialLoading(true);
      const userMessageId = uuidv4();
      const aiMessageId = uuidv4();
      const pdfFileId = uuidv4();
      const date = new Date();
      setMessages([
        ...messages,
        {
          _id: userMessageId,
          role: EActivityChatMessageRole.USER,
          content: 'Genera un resumen del contenido del archivo',
          createdAt: date,
          updatedAt: date
        },
        {
          _id: aiMessageId,
          role: EActivityChatMessageRole.ASSISTANT,
          content: '¡Generando tu resumen! Tardaré menos de un minuto.',
          createdAt: date,
          updatedAt: date
        },
        {
          _id: pdfFileId,
          role: EActivityChatMessageRole.ASSISTANT,
          content: '',
          createdAt: date,
          updatedAt: date
        }
      ]);
      return ActivityChatService.generatePdfActivitySummary(activityId!);
    },
    onSuccess: () => {
      setIsInitialLoading(false);
      queryClient.invalidateQueries({ queryKey });
    }
  });

  return {
    generatePdfActivitySummary,
    isPending
  };
};
