import styled from 'styled-components';

export const UserInfoWrapper = styled.div<{ hasmoreanswers: string }>`
  display: flex;
  padding-top: 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  height: 100%;
  padding-bottom: ${({ hasmoreanswers }) => (hasmoreanswers === 'true' ? '0' : '16px')};
`;

export const LikesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const ThreadWrapper = styled.div`
  position: relative;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black3};
  }
`;
