export const CommonTicketsTranslations = {
  YOU_HAVE_NOT_DOUBT_TICKETS: {
    es: 'No tienes dudas',
    en: 'You have not doubt tickets',
    cat: 'No tens dubtes',
    eus: 'Ez duzu zalantzarik',
    pt: 'Não tem dúvidas'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Filtroak',
    pt: 'Filtros'
  },
  OPEN_DOUBTS: {
    es: 'Dudas abiertas',
    en: 'Open doubts',
    cat: 'Dubtes oberts',
    eus: 'Zalantzak irekiak',
    pt: 'Dúvidas abertas'
  },
  RESOLVED_DOUBTS: {
    es: 'Dudas resueltas',
    en: 'Resolved doubts',
    cat: 'Dubtes resolts',
    eus: 'Zalantzak ebaztuta',
    pt: 'Dúvidas resolvidas'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  STUDENT: {
    es: 'Estudiante',
    en: 'Student',
    cat: 'Estudiant',
    eus: 'Ikaslea',
    pt: 'Estudante'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  SELECT_A_COURSE_TO_FILTER: {
    es: 'Selecciona un curso para filtrar',
    en: 'Select a course to filter',
    cat: 'Selecciona un curs per filtrar',
    eus: 'Hautatu ikastaro bat iragazteko',
    pt: 'Selecione um curso para filtrar'
  },
  SELECT_A_STUDENT_TO_FILTER: {
    es: 'Selecciona un estudiante para filtrar',
    en: 'Select a student to filter',
    cat: 'Selecciona un estudiant per filtrar',
    eus: 'Hautatu ikasle bat iragazteko',
    pt: 'Selecione um aluno para filtrar'
  },
  TICKET_STATUS_UPDATED: {
    es: 'Estado del ticket actualizado: {{status}}',
    en: 'Ticket status updated: {{status}}',
    cat: 'Estat del ticket actualitzat: {{status}}',
    eus: 'Ticketaren egoera eguneratua: {{status}}',
    pt: 'Estado do bilhete atualizado: {{status}}'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  CLEAR_SEARCH_FILTER: {
    es: 'Borrar filtro',
    en: 'Clear filter',
    cat: 'Esborrar filtre',
    eus: 'Iragazki garbitu',
    pt: 'Limpar filtro'
  },
  TAG: {
    es: 'Categoría',
    en: 'Category',
    cat: 'Categoria',
    eus: 'Kategoria',
    pt: 'Categoria'
  },
  DELETE_TICKET: {
    es: 'Eliminar duda',
    en: 'Delete doubt',
    cat: 'Eliminar dubte',
    eus: 'Zalantza ezabatu',
    pt: 'Eliminar dúvida'
  },
  TICKET_REMOVED_SUCCESSFULLY: {
    es: 'Duda eliminada con éxito!',
    en: 'Doubt deleted successfully!',
    cat: 'Dubte eliminat correctament!',
    eus: 'Zalantza ongi ezabatua!',
    pt: 'Dúvida eliminada com sucesso!'
  },
  ASSIGN_TEACHER: {
    es: 'Asignar nuevo profesor',
    en: 'Assign a new teacher',
    cat: 'Assignar nou professor',
    eus: 'Irakasle berria asignatu',
    pt: 'Atribuir novo professor'
  },
  ASSIGNEE: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  VISIBILITY: {
    es: 'Visibilidad',
    en: 'Visibility',
    cat: 'Visibilitat',
    eus: 'Ikusgarritasuna',
    pt: 'Visibilidade'
  },
  TICKET_MESSAGE_SENT_SUCCESSFULLY: {
    es: 'Respuesta enviada con éxito!',
    en: 'Answer sent successfully!',
    cat: 'Resposta enviada correctament!',
    eus: 'Erantzun bidali ongi!',
    pt: 'Resposta enviada com sucesso!'
  },
  TICKET_MESSAGE_DELETED_SUCCESSFULLY: {
    es: 'Respuesta eliminada con éxito!',
    en: 'Answer deleted successfully!',
    cat: 'Resposta eliminada correctament!',
    eus: 'Erantzun ongi ezabatua!',
    pt: 'Resposta eliminada com sucesso!'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Pregunta publicada con éxito!',
    en: 'Question published successfully!',
    cat: 'Pregunta publicada correctament!',
    eus: 'Galdera argitaratua ongi!',
    pt: 'Pergunta publicada com sucesso!'
  }
};
