import styled from 'styled-components';

import { Icon } from '@/components/Icon';

export const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 4px;
  background: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      )
      padding-box,
    linear-gradient(
        to left,
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.blue4},
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.purple6}
      )
      border-box;
`;

export const Wrapper = styled.div`
  width: 100%;

  .ant-input-outlined {
    padding: 8px 12px !important;

    border-radius: 4px;
    border: 1px solid transparent;
  }

  .ant-input-outlined:hover,
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within,
  .ant-input-outlined:active {
  }

  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within {
    box-shadow: none;
  }
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
  all: unset;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black3 : theme.colors.green6};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  flex-shrink: 0;
`;

export const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
