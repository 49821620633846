import { create } from 'zustand';

import { ITeacherVoteItem } from '../interfaces';

interface IStore {
  teacherVoteItem?: ITeacherVoteItem;
  setTeacherVoteItem: (teacherVoteItem: ITeacherVoteItem) => void;
  resetVoteItem: () => void;
}

export const useTeacherVoteItem = create<IStore>(set => ({
  setTeacherVoteItem: (teacherVoteItem: ITeacherVoteItem) => set({ teacherVoteItem }),
  resetVoteItem: () => set({ teacherVoteItem: undefined })
}));
