import { Flex, Tag } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 16px;
  flex: 1 0 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black2};
  height: 100%;
  min-height: 610px;
`;

export const TitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const DateWrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  max-width: 70px;
`;

export const AccessCountWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black6};
`;

export const DateTextWrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black6};
  text-transform: capitalize;
`;

export const AccessTag = styled(Tag)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background-color: ${({ theme }) => theme.colors.black3};

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black9};
`;

export const AccessDateWrapper = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
