import { ECourseStatus } from '@constants';

export const CoursesTranslations = {
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  PLEASE_SELECT_AT_LEAST_ONE_ACTIVITY: {
    es: 'Por favor, selecciona al menos una actividad',
    en: 'Please select at least one activity',
    cat: 'Per favor, selecciona almenys una activitat',
    eus: 'Mesedez, hautatu gutxienez ekintza bat',
    pt: 'Por favor, selecione pelo menos uma atividade'
  },
  DUPLICATE_COURSE: {
    es: 'Duplicar Curso',
    en: 'Duplicate Course',
    cat: 'Duplicar Curs',
    eus: 'Ikastaroa Bikoiztu',
    pt: 'Curso duplicado'
  },
  DUPLICATE: {
    es: 'Duplicar',
    en: 'Duplicate',
    cat: 'Duplicar',
    eus: 'Bikoiztu',
    pt: 'Duplicar'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Ezeztatu',
    pt: 'Cancelar'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia',
    pt: 'Academia'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  NEW_COURSE: {
    es: 'Nuevo Curso',
    en: 'New Course',
    cat: 'Nou Curs',
    eus: 'Ikastaro Berria',
    pt: 'Curso novo'
  },
  EDIT_COURSE: {
    es: 'Editar Curso',
    en: 'Edit Course',
    cat: 'Editar Curs',
    eus: 'Ikastaroa Aldatu',
    pt: 'Curso editado'
  },
  SAVE_CHANGES: {
    es: 'Guardar Cambios',
    en: 'Save Changes',
    cat: 'Desa els canvis',
    eus: 'Aldaketak Gorde',
    pt: 'Salvar alterações'
  },
  CREATE_COURSE: {
    es: 'Crear Curso',
    en: 'Create Course',
    cat: 'Crear Curs',
    eus: 'Ikastaroa Sortu',
    pt: 'Curso criado'
  },
  DELETE_COURSE: {
    es: 'Eliminar Curso',
    en: 'Delete Course',
    cat: 'Eliminar Curs',
    eus: 'Ikastaroa Ezabatu',
    pt: 'Curso eliminado'
  },
  DELETE_COURSE_CONFIRMATION: {
    es: '¿Estás seguro de eliminar este curso?',
    en: 'Are you sure you want to delete this course?',
    cat: 'Estàs segur de eliminar aquest curs?',
    eus: 'Zihur zaude ikastaro hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar este curso?'
  },
  DELETE_COURSE_SUCCESS: {
    es: 'Curso eliminado con éxito',
    en: 'Course deleted successfully',
    cat: 'Curs eliminat amb èxit',
    eus: 'Ikastaroa ongi ezabatu da',
    pt: 'Curso eliminado com sucesso'
  },
  RECOVER_COURSE: {
    es: 'Recuperar Curso',
    en: 'Recover Course',
    cat: 'Recuperar Curs',
    eus: 'Ikastaroa Berreskuratu',
    pt: 'Curso recuperado'
  },
  ARCHIVE_COURSE: {
    es: 'Archivar Curso',
    en: 'Archive Course',
    cat: 'Arxivar Curs',
    eus: 'Ikastaroa Artxibatu',
    pt: 'Curso arquivado'
  },
  ARCHIVE_COURSE_CONFIRMATION: {
    es: '¿Estás seguro de archivar este curso?',
    en: 'Are you sure you want to archive this course?',
    cat: "Estàs segur d'arxivar aquest curs?",
    eus: 'Zihur zaude ikastaro hau artxibatu nahi duzula?',
    pt: 'Tem certeza que deseja arquivar este curso?'
  },
  UNARCHIVE_COURSE: {
    es: 'Desarchivar Curso',
    en: 'Unarchive Course',
    cat: 'Desarxivar Curs',
    eus: 'Ikastaroa Desartxibatu',
    pt: 'Curso desarquivado'
  },
  COURSE_PUBLISHED_SUCCESS: {
    es: 'Curso publicado con éxito',
    en: 'Course published successfully',
    cat: 'Curs publicat amb èxit',
    eus: 'Ikastaroa ongi argitaratu da',
    pt: 'Curso publicado com sucesso'
  },
  COURSE_UNPUBLISHED_SUCCESS: {
    es: 'Curso despublicado',
    en: 'Course unpublished',
    cat: 'Curs despublicat',
    eus: 'Ikastaroa ezargitaratua',
    pt: 'Curso despublicado'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  [ECourseStatus.ARCHIVED]: {
    es: 'Archivado',
    en: 'Archived',
    cat: 'Arxivat',
    eus: 'Artxibatua',
    pt: 'Arquivado'
  },
  [ECourseStatus.PUBLISHED]: {
    es: 'Publicado',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratua',
    pt: 'Publicado'
  },
  [ECourseStatus.DRAFT]: {
    es: 'Borrador',
    en: 'Draft',
    cat: 'Esborrany',
    eus: 'Zirriborroa',
    pt: 'Borrador'
  },
  COURSES_TOTAL_COUNT: {
    es: '{{total}} Cursos',
    en: '{{total}} Courses',
    cat: '{{total}} Cursos',
    eus: '{{total}} Ikastaroak',
    pt: '{{total}} Cursos'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar curso...',
    en: 'Search course...',
    cat: 'Cerca curs...',
    eus: 'Bilatu ikastaroa...',
    pt: 'Buscar curso...'
  },
  SELECT_ALL: {
    es: 'Seleccionar todo',
    en: 'Select all',
    cat: 'Selecciona-ho tot',
    eus: 'Hautatu dena',
    pt: 'Selecionar tudo'
  },
  ASSIGNED_TEACHERS: {
    es: 'Profesores asignados',
    en: 'Assigned teachers',
    cat: 'Professors assignats',
    eus: 'Irakasle asignatuak',
    pt: 'Professores atribuídos'
  },
  TASK_PROGRESS: {
    es: 'Progreso',
    en: 'Progress',
    cat: 'Progrés',
    eus: 'Aurrerapena',
    pt: 'Progresso'
  },
  LIST: {
    es: 'Lista',
    en: 'List',
    cat: 'Llista',
    eus: 'Zerrenda',
    pt: 'Lista'
  },
  TASKS: {
    es: 'Tareas',
    en: 'Tasks',
    cat: 'Tasques',
    eus: 'Lanak',
    pt: 'Tarefas'
  },
  PANEL: {
    es: 'Panel',
    en: 'Panel',
    cat: 'Panell',
    eus: 'Panela',
    pt: 'Painel'
  },
  GANTT: {
    es: 'Gantt',
    en: 'Gantt',
    cat: 'Gantt',
    eus: 'Gantt',
    pt: 'Gantt'
  },
  NOTHING_SELECTED: {
    es: 'Selecciona un curso para ver las tareas',
    en: 'Select a course to see the tasks',
    cat: 'Selecciona un curs per veure les tasques',
    eus: 'Kursua hautatu tareas ikusi',
    pt: 'Selecione um curso para ver as tarefas'
  },
  EMPTY_TASKS: {
    es: 'No hay tareas en este curso',
    en: 'No tasks in this course',
    cat: 'No hi ha tasques en aquest curs',
    eus: 'Kursuan ez dago lanik',
    pt: 'Não há tarefas neste curso'
  },
  COURSE_TAB: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  TEACHER_TAB: {
    es: 'Profesor',
    en: 'Teacher',
    cat: 'Professor',
    eus: 'Irakaslea',
    pt: 'Professor'
  },
  NO_TEACHER_SELECTED: {
    es: 'No se ha seleccionado ningún profesor',
    en: 'No teacher selected',
    cat: "No s'ha seleccionat cap professor",
    eus: 'Irakaslea hautatu gabe',
    pt: 'Professor não selecionado'
  },
  EMPTY_TASKS_BY_TEACHER: {
    es: 'No hay tareas para este profesor',
    en: 'No tasks for this teacher',
    cat: 'No hi ha tasques per aquest professor',
    eus: 'Irakasleari esleitu gabe lanik ez dago',
    pt: 'Não há tarefas para este professor'
  },
  DUPLICATE_COMPLEMENTARY_MATERIAL: {
    es: 'Duplicar material complementario',
    en: 'Duplicate complementary material',
    cat: 'Duplicar material complementari',
    eus: 'Material osagarria bikoiztu',
    pt: 'Material complementar duplicado'
  },
  DUPLICATE_FOLDERS_WITH_MATERIAL: {
    es: 'Duplicar carpetas con material',
    en: 'Duplicate folders with material',
    cat: 'Duplicar carpetes amb material',
    eus: 'Materialarekin karpetak bikoiztu',
    pt: 'Pastas com material duplicado'
  },
  DUPLICATE_INTRODUCTION_SECTION: {
    es: 'Duplicar sección de introducción',
    en: 'Duplicate introduction section',
    cat: "Duplicar secció d'introducció",
    eus: 'Sarrera atala bikoiztu',
    pt: 'Seção de introdução duplicada'
  },
  STUDENT_COUNT: {
    es: 'Nº alumnos',
    en: 'Nº students',
    cat: 'Nº alumnes',
    eus: 'Ikasleen zenbakia',
    pt: 'Número de alunos'
  },
  QUIZ_COUNT: {
    es: 'Nº tests',
    en: 'Nº tests',
    cat: 'Nº tests',
    eus: 'Test kopurua',
    pt: 'Número de testes'
  },
  QUIZ_AVERAGE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota',
    pt: 'Média de testes'
  },
  TICKETS_COUNT: {
    es: 'Nº dudas',
    en: 'Nº tickets',
    cat: 'Nº dubtes',
    eus: 'Zalantz kopurua',
    pt: 'Número de dúvidas'
  },
  CSAT: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT',
    pt: 'CSAT'
  },
  ACTIVE_STUDENT_PERCENTAGE: {
    es: '% activos',
    en: '% active',
    cat: '% actius',
    eus: '% aktiboak',
    pt: '% ativos'
  },
  INACTIVE_STUDENT_PERCENTAGE: {
    es: '% inactivos',
    en: '% inactive',
    cat: '% inactius',
    eus: '% ez-aktiboak',
    pt: '% inativos'
  },
  SLEEP_STUDENT_PERCENTAGE: {
    es: '% dormidos',
    en: '% sleep',
    cat: '% adormits',
    eus: '% logeatu gabeak',
    pt: '% adormecidos'
  },
  DOWNTURN_STUDENT_PERCENTAGE: {
    es: '% bajas',
    en: '% downturns',
    cat: '% baixes',
    eus: '% beherapenak',
    pt: '% baixas'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formação'
  },
  AVERAGE_CONNECTED_TIME: {
    es: 'Tiempo medio de conexión',
    en: 'Average connected time',
    cat: 'Temps mitjà de connexió',
    eus: 'Konektatze denbora ertaina',
    pt: 'Tempo médio de conexão'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  REQUEST_ALL_USERS_REPORT: {
    es: 'Informe de alumnos',
    en: 'Students report',
    cat: 'Informe d’alumnes',
    eus: 'Ikasleen txostena',
    pt: 'Relatório de alunos'
  },
  DOWNLOAD_PAGE: {
    es: 'Descargar página',
    en: 'Download page',
    cat: 'Descarrega pàgina',
    eus: 'Orrialdea deskargatu',
    pt: 'Página de download'
  },
  REPORT_REQUESTED_CHECK_EMAIL: {
    es: 'Informe solicitado. Revisa tu correo electrónico en los próximos minutos',
    en: 'Report requested. Check your email in the next minutes',
    cat: 'Informe sol·licitat. Revisa el teu correu electrònic en els propers minuts',
    eus: 'Txostena eskatuta. Begiratu zure posta elektronikoa hurrengo minutuetan',
    pt: 'Relatório solicitado. Verifique o seu email nos próximos minutos'
  }
};
