import { AnalyticsTrackEvents } from '@constants';
import { ITicket } from '@sdk/contracts';
import { Avatar } from 'antd';
import { DefaultTheme, useTheme } from 'styled-components';

import { LikesWrapper, UserInfoWrapper, ThreadWrapper } from './UserInfo.styles';

import { Icon } from '@/components/Icon';
import { useAnalytics } from '@/hooks';
import { useMutateMyTickets, useProfile } from '@/store';

type Props = {
  ticket: ITicket;
};

const AVATAR_SIZE = 32;
const FAV_ICON_SIZE = 24;

export const UserInfo: React.FC<Props> = ({ ticket }) => {
  const { colors } = useTheme();
  const { profile } = useProfile();
  const { changeUsefulTicketVote } = useMutateMyTickets();
  const { fireEvent } = useAnalytics();
  const { issuer, usefulTicket } = ticket;

  const isLiked = usefulTicket.some(_id => _id === profile?._id);

  const handleChangeUsefulTicketVote = () => {
    changeUsefulTicketVote(ticket._id, {
      onSuccess: () => {
        if (!isLiked) {
          fireEvent(AnalyticsTrackEvents.COMMENT_LIKE, {
            ID: issuer._id,
            Name: issuer.name
          });
        }
      }
    });
  };

  return (
    <UserInfoWrapper hasmoreanswers={ticket.messages.length > 0 ? 'true' : 'false'}>
      <Avatar src={issuer.avatar?.url} size={AVATAR_SIZE} style={{ flex: 'none' }}>
        {!issuer.avatar?.url && `${issuer.name[0]}${ticket.issuer.lastName[0]}`}
      </Avatar>
      <LikesWrapper>
        <Icon
          i={isLiked ? 'FavoriteFilled' : 'Favorite'}
          size={FAV_ICON_SIZE}
          onClick={handleChangeUsefulTicketVote}
          color={colors.green6 as keyof DefaultTheme['colors']}
        />
        <div>{usefulTicket.length}</div>
      </LikesWrapper>
      <ThreadWrapper />
    </UserInfoWrapper>
  );
};
