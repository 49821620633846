export const ProductTourTranslations = {
  START_THE_TOUR: {
    es: 'Hacer el tour',
    en: 'Start the tour',
    eus: 'Erabili',
    cat: 'Fes el tour',
    pt: 'Iniciar o tour'
  },
  SKIP_THE_TOUR: {
    es: 'Después lo veo',
    en: 'Skip the tour',
    eus: 'Ez dut ikusi nahi',
    cat: 'Ometrà el tour',
    pt: 'Pular o tour'
  },
  PREVIOUS: {
    es: 'Anterior',
    en: 'Previous',
    eus: 'Aurrekoa',
    cat: 'Anterior',
    pt: 'Anterior'
  },
  NEXT: {
    es: 'Siguiente',
    en: 'Next',
    eus: 'Hurrengoa',
    cat: 'Hurri',
    pt: 'Próximo'
  },
  FINISH: {
    es: 'Finalizar',
    en: 'Finish',
    eus: 'Bukatzea',
    cat: 'Finalitzar',
    pt: 'Finalizar'
  },
  REDO_THE_TOUR_TITLE: {
    es: '¡Esto es todo!',
    en: 'That is all!',
    eus: 'Eskerrik asko!',
    cat: 'Això és tot!',
    pt: 'Isso é tudo!'
  },
  HERE_YOU_CAN_REDO_THE_TOUR_DESCRIPTION: {
    es: 'Si en algún momento te sientes perdido, puedes volver a hacer el tour. Haz clic en este botón y te lo explicaremos de nuevo.',
    en: 'If at any time you feel lost, you can do the tour again. Click this button and we will explain everything to you again.',
    eus: 'Ez datorrenik erabili berriro egin nahi duzun, egin daiteke hemen. Saiatu botoia sakatu eta berriro zurekin zerbait errepikatuko dugu.',
    cat: "Si en qualsevol moment vols fer el tour de nou, pots fer-ho aquí. Només has d'obrir el botó i et explicarem tot de nou.",
    pt: 'Se em qualquer momento você quiser fazer o tour novamente, você pode fazê-lo aqui. Basta abrir o botão e nós explicaremos tudo de novo para você.'
  },
  QUIZ_MODE_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Practica con los tests',
    en: 'Practice with the tests',
    eus: 'Erabili testuak erabiltzeko',
    cat: 'Practica amb els tests',
    pt: 'Pratique com os testes'
  },
  QUIZ_MODE_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Pon a prueba lo que has aprendido haciendo los tests.',
    en: 'Test your knowledge by doing the quizzes.',
    eus: 'Zure ezagutzak frogatu testak eginez.',
    cat: 'Prova el teu coneixement fent els tests.',
    pt: 'Teste seu conhecimento fazendo os testes.'
  },
  LIVE_CLASSES_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Clases en directo',
    en: 'Live classes',
    eus: 'Gaiak',
    cat: 'Esdeveniments',
    pt: 'Aulas ao vivo'
  },
  LIVE_CLASSES_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Reserva tu plaza en las próximas clases en directo con tus profes.',
    en: 'Reserve your place in the next live classes with your teachers.',
    eus: 'Zure profesorekin ezkutatutako gaiak erreserbatu.',
    cat: 'Reserva el teu lloc en les properes classes en directe amb els teus professors.',
    pt: 'Reserve seu lugar nas próximas aulas ao vivo com seus professores.'
  },
  EXAM_GROUPS_WIDGET_PRODUCT_TOUR_TITLE: {
    es: 'Exámenes',
    en: 'Exams',
    eus: 'Eztabaidak',
    cat: 'Exàmens',
    pt: 'Exames'
  },
  EXAM_GROUPS_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Enterate de todos lo relativo a tu convocatoria.',
    en: 'Get all the information about your exam group.',
    eus: 'Eztabaidaren informazioa zure konbokoatik lortu.',
    cat: 'Obten tots els detalls de la teva convocatòria.',
    pt: 'Obtenha todas as informações sobre seu grupo de exame.'
  },
  CONTENT_WIDGET_PRODUCT_TOUR_TITLE: {
    es: '¡Empieza a estudiar!',
    en: 'Start studying!',
    eus: 'Ikasten hasteko!',
    cat: 'Comença a estudiar!',
    pt: 'Comece a estudar!'
  },
  CONTENT_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Accede al contenido y las actividades del curso que estás estudiando.',
    en: 'Access the content and activities of the course you are studying.',
    eus: 'Kursua ikasten duzun kursuaren edukia eta aktibitateak erabili.',
    cat: 'Accedeix al contingut i les activitats del curs que estàs estudiant.',
    pt: 'Acesse o conteúdo e atividades do curso que você está estudando.'
  },
  DO_THE_TOUR: {
    es: 'Hacer el tour',
    en: 'Do the tour',
    eus: 'Erabili',
    cat: 'Fes el tour',
    pt: 'Iniciar o tour'
  },
  THERE_A_TOUR_IN_THIS_PAGE: {
    es: 'Hay un tour en esta página, pulsa en el botón para verlo.',
    en: 'There is a tour in this page, click the button to see it.',
    eus: 'Orrialde honetan tour bat dago, sakatu botoia ikusi nahi duzun.',
    cat: 'Hi ha un tour en aquesta pàgina, fes clic en el botó per veure-ho.',
    pt: 'Há um tour nesta página, clique no botão para ver.'
  },
  DASHBOARD_PRODUCT_TOUR_WELCOME_TITLE: {
    es: '¡Ya estás dentro del campus virtual!',
    en: 'You are already in the virtual campus!',
    eus: 'Zer moduzt duzu campus virtualera!',
    cat: 'Ja estàs dins del campus virtual!',
    pt: 'Você já está no campus virtual!'
  },
  DASHBOARD_PRODUCT_TOUR_WELCOME_DESCRIPTION: {
    es: '¿Damos un tour rápido para que sepas donde está todo?',
    en: 'Do a quick tour so you know where everything is.',
    eus: 'Azkenezko bidea ematen dizkizun, zer moduzt duzun.',
    cat: 'Fes un recorregut ràpid perquè coneguis on està tot.',
    pt: 'Faça um tour rápido para que saiba on está tudo.'
  },
  PLANIFICATION_WIDGET_PRODUCT_TOUR_TITLE: {
    es: '¡Te ayudamos a organizarte!',
    en: 'We help you organize yourself!',
    eus: 'Zure zerbitzua erabiltzeko laguntza',
    cat: 'Et ajudem a organitzar-te!',
    pt: 'Nós ajudamos você a organizar-se!'
  },
  PLANIFICATION_WIDGET_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Sigue tu plan de estudio cada día y avanza con confianza hacia el examen.',
    en: 'Follow your study plan every day and advance with confidence towards the exam.',
    eus: 'Eskatu zure ikastetxeko plan egin den bakoitzean eta ziur tasunarekin aurrera.',
    cat: "Segueix el teu pla d'estudis cada dia i avança amb confiança cap a l'examen.",
    pt: 'Siga seu plano de estudos todos os dias e progrese com confiança para o exame.'
  },
  COURSE_PRODUCT_TOUR_WELCOME_TITLE: {
    es: 'Conoce el nuevo diseño de las lecciones',
    en: 'Discover the new design of the lessons',
    eus: 'Ikasi ikasgaien diseinu berriari buruz',
    cat: 'Coneix el nou disseny de les lliçons',
    pt: 'Descubra o novo design das lições'
  },
  COURSE_PRODUCT_TOUR_WELCOME_DESCRIPTION: {
    es: 'Hemos hecho cambios para que puedas estudiar de forma más fácil y eficiente. ¡Haz el tour y descubre las novedades!',
    en: 'We have made changes to make it easier and more efficient for you to study. Do the tour and discover the new features!',
    eus: 'Aldaketak egin ditugu errazago eta eraginkorrago ikas dezazun. Egin bira eta ezagutu zer berri!',
    cat: 'Hem fet canvis perquè puguis estudiar de manera més fàcil i eficient. Fes el tour i descobreix les novetats!',
    pt: 'Fizemos alterações para tornar mais fácil e eficiente para você estudar. Faça o tour e descubra as novas funcionalidades!'
  },
  LESSONS_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Lecciones del curso',
    en: 'Course lessons',
    eus: 'Kursu ikasgaiak',
    cat: 'Leccions del curs',
    pt: 'Lições do curso'
  },
  LESSONS_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Aquí verás el temario completo de tu curso, organizado y listo para que avances a tu ritmo.',
    en: 'Here you will see the complete syllabus of your course, organized and ready for you to advance at your own pace.',
    eus: 'Kursuaren temarioa ikasi duzun orrialdean, zuzentuta eta zure ikastetxeko plan erabilera egokitzen duena.',
    cat: 'Aquí veureu el temari complet del curs, ordenat i llest per a que progresseu a vostre ritme.',
    pt: 'Aqui verá o tema completo do curso, organizado e pronto para que prossiga a seu ritmo.'
  },
  SECTION_TRACKING_SELECTOR_PRODUCT_TOUR_TITLE: {
    es: 'Organiza tu estudio',
    en: 'Organize your study',
    eus: 'Eskatutako zure ikastetxeko plan',
    cat: 'Organitza el teu estudi',
    pt: 'Organize seu estudo'
  },
  SECTION_TRACKING_SELECTOR_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Indica el estado de cada tema para saber cómo vienes con el estudio.',
    en: 'Indicate the status of each topic to know how you are doing with your study.',
    eus: 'Eztabaidaren informazioa zure konbokoatik lortu.',
    cat: "Indica l'estat de cada tema per saber com vaig amb l'estudi.",
    pt: 'Indique o status de cada tema para saber como está acompanhando o seu estudo.'
  },
  SECTION_TRACKING_FILTER_PRODUCT_TOUR_TITLE: {
    es: 'Sigue tu progreso',
    en: 'Follow your progress',
    eus: 'Eskatutako zure ikastetxeko plan',
    cat: 'Segueix el teu progrés',
    pt: 'Siga seu progresso'
  },
  SECTION_TRACKING_FILTER_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Haz clic en cada estado para filtrar los temas y llevar un control de tu avance.',
    en: 'Click on each status to filter the topics and keep track of your progress.',
    eus: 'Eztabaidaren informazioa zure konbokoatik lortu.',
    cat: 'Filtra per tema per veure el progrés de cada secció.',
    pt: 'Clique em cada status para filtrar os temas e manter o controle do seu progresso.'
  },
  FAVORITE_LESSONS_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Lecciones favoritas',
    en: 'Favorite lessons',
    eus: 'Ikastetxeko ikasgaiak',
    cat: 'Leccions favorites',
    pt: 'Lições favoritas'
  },
  FAVORITE_LESSONS_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Accede rápidamente a las lecciones que has marcado como favoritas.',
    en: 'Access your favorite lessons quickly.',
    eus: 'Ikastetxeko ikasgaiak erabili',
    cat: 'Accede ràpidament a les lliçons favorites.',
    pt: 'Acesse rapidamente as lições favoritas.'
  },
  ACTIVITIES_PRODUCT_TOUR_TITLE: {
    es: 'Te presentamos las nuevas actividades',
    en: 'We present you the new activities',
    eus: 'Eraginkizun berriak eskuzituz',
    cat: 'Presentem-teu les noves activitats',
    pt: 'Apresentamos-te as novas atividades'
  },
  ACTIVITIES_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Te traemos cosas nuevas que van a mejorar tu experiencia. ¿Damos un tour rápido para que sepas donde está todo?',
    en: 'We bring you new things that will improve your experience. Do a quick tour so you know where everything is.',
    eus: 'Zure esperientzia hobetuko duten gauza berriak ekartzen dizkizugu. Bisita azkar bat emango al dugu dena non dagoen jakin dezazun?',
    cat: 'Et portem coses noves que milloraran la teva experiència. Donem un tour ràpid perquè sàpigues on és tot?',
    pt: 'Trazemos coisas novas que vão melhorar a sua experiência. Faça um tour rápido para saber onde está tudo?'
  },
  UMY_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Conversación con Umy',
    en: 'Conversation with Umy',
    eus: 'Umyko konbokoatik',
    cat: 'Conversa amb Umy',
    pt: 'Conversa com o Umy'
  },
  UMY_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Aquí puedes preguntarle a nuestra IA lo que quieras sobre la actividad.',
    en: 'Here you can ask our AI anything about the activity.',
    eus: 'Umyko konbokoatik',
    cat: 'Conversa amb Umy',
    pt: 'Conversa com o Umy'
  },
  QUESTIONS_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Preguntas',
    en: 'Questions',
    eus: 'Galderak',
    cat: 'Preguntes',
    pt: 'Perguntas'
  },
  QUESTIONS_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Te contestará un profesor y podrás consultarlas aquí y en el apartado de “Dudas”.',
    en: 'Your teacher will answer you and you can consult them here and in the “Questions” section.',
    eus: 'Zure ikastaririk egiaztatu egin duzun eta hemen eta “Egiaztapenak” atalean kontsulta dezakezu.',
    cat: 'El teu professor et contestarà i podràs consultar-les aquí i en la secció de “Preguntes”.',
    pt: 'O seu professor responderá e poderá consultar-las aqui e na secção de “Perguntas”.'
  },
  ACTIVITIES_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Actividades',
    en: 'Activities',
    eus: 'Eraginkizunak',
    cat: 'Activitats',
    pt: 'Atividades'
  },
  ACTIVITIES_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'El índice del curso. Completa, guarda y accede a todas las lecciones desde aquí.',
    en: 'The course index. Complete, save and access all the lessons from here.',
    eus: 'Kursua indexa. Egiaztatu, gordetu eta ikastarak hemen erabili.',
    cat: "Índex del curs. Completa, guarda i accede a totes les lliçons des d'aquí.",
    pt: 'Índice do curso. Complete, guarde e acesse todas as lições a partir daqui.'
  },
  NOTES_TAB_PRODUCT_TOUR_TITLE: {
    es: 'Tu cuaderno',
    en: 'Your notebook',
    eus: 'Zure notabokoa',
    cat: 'El teu quadern',
    pt: 'O seu caderno'
  },
  NOTES_TAB_PRODUCT_TOUR_DESCRIPTION: {
    es: 'Toma notas cuando y donde quieras, y edítalas en cualquier momento.',
    en: 'Take notes as and when you want and edit them at any time.',
    eus: 'Zure notabokoa gordetu eta hemen erabili.',
    cat: "Guarda les teves notes i accede-hi des d'aquí.",
    pt: 'Faça anotações quando e onde quiser e edite-as em qualquer momento.'
  }
};
