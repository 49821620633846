import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    gap: 16px;
  }
`;

export const TitleWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;
