import { EQuizMode } from '@constants';

export const QuizResultsTranslations = {
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continua',
    eus: 'Jarraitu',
    pt: 'Continuar'
  },
  ARE_YOU_SURE_TO_REPEAT: {
    es: '¿Seguro que quieres repetir el test?',
    en: 'Are you sure to repeat the test?',
    cat: 'Segur que vols repetir el test?',
    eus: 'Seguru zaude proba berriro egin nahi duzula?',
    pt: 'Tem certeza que deseja repetir o teste?'
  },
  LAST_RESULT_WILL_BE_ERASED: {
    es: 'El último resultado se borrará.',
    en: 'The last result will be erased.',
    cat: 'L`últim resultat s`eliminarà.',
    eus: 'Azken emaitza ezabatuko da.',
    pt: 'O último resultado será apagado.'
  },
  EXIT: {
    es: 'Salir',
    en: 'Exit',
    cat: 'Sortir',
    eus: 'Irten',
    pt: 'Sair'
  },
  REVIEW_RESULTS: {
    es: 'Revisar resultados',
    en: 'Review results',
    cat: 'Revisar resultats',
    eus: 'Aztertu emaitzak',
    pt: 'Revisar resultados'
  },
  CORRECT_RESPONSES: {
    es: 'Aciertos',
    en: 'Correct answers',
    cat: 'Encerts',
    eus: 'Zuzenak',
    pt: 'Respostas corretas'
  },
  WRONG_RESPONSES: {
    es: 'Fallos',
    en: 'Mistakes',
    cat: 'Errors',
    eus: 'Akatsak',
    pt: 'Respostas erradas'
  },
  BLANK_RESPONSES: {
    es: 'Blancos',
    en: 'Blanks',
    cat: 'Blancs',
    eus: 'Hutsak',
    pt: 'Respostas em branco'
  },
  SCORE: {
    es: 'Nota:',
    en: 'Score:',
    cat: 'Nota:',
    eus: 'Puntuazioa:',
    pt: 'Pontuação:'
  },
  PUNTUATION_SYSTEM: {
    es: 'Sistema de puntuación',
    en: 'Punctuation system',
    cat: 'Sistema de puntuació',
    eus: 'Puntuazio sistema',
    pt: 'Sistema de pontuação'
  },
  CORRECT: {
    es: 'Correcto',
    en: 'Correct',
    cat: 'Correcte',
    eus: 'Zuzena',
    pt: 'Correto'
  },
  WRONG: {
    es: 'Incorrecto',
    en: 'Wrong',
    cat: 'Incorrecte',
    eus: 'Okerra',
    pt: 'Errado'
  },
  BLANK: {
    es: 'En blanco',
    en: 'Blank',
    cat: 'En blanc',
    eus: 'Hutsik',
    pt: 'Em branco'
  },
  CONTENT: {
    es: 'Temas',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukia',
    pt: 'Conteúdo'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  [EQuizMode.FREE_MODE]: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Askatasun modua',
    pt: 'Modo livre'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Modo simulacro',
    en: 'Simulator mode',
    cat: 'Mode simulació',
    eus: 'Simulazio modua',
    pt: 'Modo de simulação'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Modo actividad',
    en: 'Activity mode',
    cat: 'Mode activitat',
    eus: 'Jarduera modua',
    pt: 'Modo de atividade'
  },
  REPEAT_TEST: {
    es: 'Repetir test',
    en: 'Repeat test',
    cat: 'Repetir test',
    eus: 'Proba berriro egin',
    pt: 'Repetir teste'
  },
  RETURN_TO_ACTIVITY: {
    es: 'Volver a la actividad',
    en: 'Return to activity',
    cat: 'Tornar a l`activitat',
    eus: 'Itzuli jarduerara',
    pt: 'Voltar para a atividade'
  },
  RESULTS: {
    es: 'Resultados',
    en: 'Results',
    cat: 'Resultats',
    eus: 'Emaitzak',
    pt: 'Resultados'
  },
  TIME: {
    es: '¡Tiempo!',
    en: 'Time!',
    cat: 'Temps!',
    eus: 'Denbora!',
    pt: 'Tempo!'
  }
};
