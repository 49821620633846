import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;

export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.black6};
  width: 100%;

  .ant-progress-text-outer {
    display: none;
  }

  & > :last-child {
    max-width: 100px;
  }
`;
