import { EExamTypes } from '@constants';

export const StudentProfileTranslations = {
  PROFILE: {
    es: 'Mi perfil',
    en: 'My profile',
    cat: 'El meu perfil',
    eus: 'Nire profila',
    pt: 'O meu perfil'
  },
  EDIT_PROFILE: {
    es: 'Editar perfil',
    en: 'Edit profile',
    cat: 'Editar perfil',
    eus: 'Editatu profila',
    pt: 'Editar perfil'
  },
  TRAINING_INFO: {
    es: 'Datos de tu formación',
    en: 'Your training data',
    cat: 'Dades de la teva formació',
    eus: 'Zure prestakuntzaren datuak',
    pt: 'Os seus dados de formação'
  },
  SUMMARY: {
    es: 'Resumen',
    en: 'Summary',
    cat: 'Resum',
    eus: 'Laburpena',
    pt: 'Resumo'
  },
  CERTIFICATES: {
    es: 'Mis certificados',
    en: 'My certificates',
    cat: 'Els meus certificats',
    eus: 'Nire egiaztagiriak',
    pt: 'Os meus certificados'
  },
  PERSONAL_INFO: {
    es: 'Datos personales',
    en: 'Personal info',
    cat: 'Dades personals',
    eus: 'Datu pertsonalak',
    pt: 'Informações pessoais'
  },
  EMAIL: {
    es: 'Correo electrónico',
    en: 'Email',
    cat: 'Correu electrònic',
    eus: 'Posta elektronikoa',
    pt: 'Email'
  },
  CONTRACT: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua',
    pt: 'Contrato'
  },
  CONTRACT_DETAIL: {
    es: 'Accede desde aquí a las condiciones del servicio',
    en: 'Click here to access the terms of service.',
    cat: 'Accedeix des d`aquí a les condicions del servei',
    eus: 'Sartu hemen zerbitzuaren baldintzak',
    pt: 'Clique aqui para acessar os termos de serviço.'
  },
  VIEW_CONTRACT: {
    es: 'Ver contrato',
    en: 'View contract',
    cat: 'Veure contracte',
    eus: 'Ikusi kontratua',
    pt: 'Ver contrato'
  },
  CLOSE: {
    es: 'Cerrar',
    en: 'Close',
    cat: 'Tancar',
    eus: 'Itxi',
    pt: 'Fechar'
  },
  PROFILE_UPDATED_SUCCESSFULLY: {
    es: '¡Perfil actualizado con éxito!',
    en: 'Profile updated successfully!',
    cat: 'Perfil actualitzat amb èxit!',
    eus: 'Profila ongi eguneratua!',
    pt: 'Perfil atualizado com sucesso!'
  },
  TRAINING_INFO_UPDATED_SUCCESSFULLY: {
    es: '¡Datos de tu formación actualizados con éxito!',
    en: 'Your training data has been successfully updated!',
    cat: 'Dades de la teva formació actualitzades amb èxit!',
    eus: 'Zure prestakuntzaren datuak ongi eguneratu dira!',
    pt: 'Os seus dados de formação foram atualizados com sucesso!'
  },
  LANGUAGE: {
    es: 'Idioma',
    en: 'Language',
    cat: 'Idioma',
    eus: 'Hizkuntza',
    pt: 'Idioma'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  COURSES_SELECT: {
    es: 'Elige los cursos que quieras que se vean en la página de inicio',
    en: 'Choose the courses you want to be displayed on the home page',
    cat: 'Tria els cursos que vulguis que es vegin a la pàgina d`inici',
    eus: 'Aukeratu hasiera orrian ikusi nahi dituzun ikastaroak',
    pt: 'Escolha os cursos que deseja que sejam exibidos na página inicial'
  },
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak',
    pt: 'Convocatórias'
  },
  EXAM_GROUPS_FINDER: {
    es: 'Buscador de convocatorias',
    en: 'Exam groups finder',
    cat: 'Cercador de convocatòries',
    eus: 'Deialdien bilatzailea',
    pt: 'Buscador de convocatórias'
  },
  TAG: {
    es: 'Formación',
    en: 'Tag',
    cat: 'Formació',
    eus: 'Formakuntza',
    pt: 'Formação'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad',
    en: 'Autonomous community',
    cat: 'Comunitat',
    eus: 'Autonomia erkidegoa',
    pt: 'Comunidade autónoma'
  },
  PROVINCE: {
    es: 'Provincia',
    en: 'Province',
    cat: 'Província',
    eus: 'Probintzia',
    pt: 'Província'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Titulua',
    pt: 'Título'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  PUBLICATION_DATE: {
    es: 'Fecha de publicación',
    en: 'Publication date',
    cat: 'Data de publicació',
    eus: 'Argitaratze data',
    pt: 'Data de publicação'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  ADD: {
    es: 'Añadir',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu',
    pt: 'Adicionar'
  },
  EXAM_GROUPS_EMPTY: {
    es: 'Aquí aparecerán las convocatorias a las que te apuntes. Todavía no has añadido ninguna convocatoria',
    en: 'Here you will see the exam groups you sign up for. You have not added any exam group yet',
    cat: 'Aquí apareixeran les convocatòries a les que t`apuntis. Encara no has afegit cap convocatòria',
    eus: 'Hemen agertuko dira deialdira apuntatzen zara. Oraindik ez duzu deialdirik gehitu',
    pt: 'Aqui aparecerão as convocatórias que se inscreveu. Ainda não adicionou nenhuma convocatória'
  },
  LEAVE_EXAM_GROUP: {
    es: 'Abandonar convocatoria',
    en: 'Leave exam group',
    cat: 'Abandonar convocatòria',
    eus: 'Utzi deialdia',
    pt: 'Deixar convocatória'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  AT_LEAST_ONE_COURSE_MUST_BE_SELECTED: {
    es: 'Debes seleccionar al menos un curso',
    en: 'You must select at least one course',
    cat: 'Has de seleccionar almenys un curs',
    eus: 'Gutxienez ikastaro bat aukeratu behar duzu',
    pt: 'Você deve selecionar pelo menos um curso'
  },
  EXAM_GROUPS_TOTAL_COUNT: {
    es: '{{total}} convocatorias',
    en: '{{total}} exam groups',
    cat: '{{total}} convocatòries',
    eus: '{{total}} convocatorias',
    pt: '{{total}} convocatórias'
  },
  LEAVE_GROUP_CONFIRMATION: {
    es: '¿Estás seguro de que quieres abandonar la convocatoria?',
    en: 'Are you sure you want to leave the exam group?',
    cat: 'Estàs segur que vols abandonar la convocatòria?',
    eus: 'Ziur zaude deialdia utzi nahi duzula?',
    pt: 'Tem certeza que deseja deixar a convocatória?'
  },
  NOTIFICATION_SETTINGS: {
    es: 'Notificaciones',
    en: 'Notifications',
    cat: 'Notificacions',
    eus: 'Jakinarazpenak',
    pt: 'Notificações'
  },
  LATER: {
    es: 'Más adelante',
    en: 'Later',
    cat: 'Més endavant',
    eus: 'Geroago',
    pt: 'Mais tarde'
  },
  EXAM_GROUPS_NOTIFICATIONS_TOOLTIP: {
    es: 'Configura sobre qué Comunidades Autónomas quieres recibir notificaciones de Convocatorias',
    en: 'Configure which Autonomous Communities you want to receive notifications from',
    cat: 'Configura sobre quines Comunitats Autònomes vols rebre notificacions de Convocatòries',
    eus: 'Konfiguratu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso',
    pt: 'Configure quais Comunidades Autónomas deseja receber notificações de Convocatórias'
  },
  EXAM_GROUPS_NOTIFICATIONS_NAME: {
    es: 'Elige sobre qué Comunidades Autónomas quieres ser notificado',
    en: 'Choose which Autonomous Communities you want to be notified about',
    cat: 'Tria sobre quines Comunitats Autònomes vols ser notificat',
    eus: 'Hautatu zein Autonomia Erkidegotan nahi duzun jakinarazpenak jaso',
    pt: 'Escolha quais Comunidades Autónomas deseja ser notificado'
  },
  TOTAL_PROGRESS: {
    es: 'Avance total',
    en: 'Total progress',
    cat: 'Progrés total',
    eus: 'Aurrerapen guztira',
    pt: 'Progresso total'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batazbesteko nota',
    pt: 'Nota média'
  },
  TOTAL_POINTS: {
    es: 'Puntuación total',
    en: 'Total points',
    cat: 'Puntuació total',
    eus: 'Puntuazio guztira',
    pt: 'Pontuação total'
  },
  BADGES: {
    es: 'Medallas',
    en: 'Badges',
    cat: 'Medalles',
    eus: 'Medalak',
    pt: 'Medalhas'
  },
  DAILY_STREAK: {
    es: 'Racha diaria',
    en: 'Daily streak',
    cat: 'Ratxa diària',
    eus: 'Eguneroko erronka',
    pt: 'Sequência diária'
  },
  STREAK_DAYS: {
    es: '{{days}} días',
    en: '{{days}} days',
    cat: '{{days}} dies',
    eus: '{{days}} egun',
    pt: '{{days}} dias'
  },
  DAILY_COMPLETED_TASKS: {
    es: 'Tareas diarias completadas',
    en: 'Daily completed tasks',
    cat: 'Tasques diàries completades',
    eus: 'Eguneroko zereginak osatuak',
    pt: 'Tarefas diárias completadas'
  },
  GAMIFICATION_INFO: {
    es: 'Consigue puntos de conocimiento para tener un extra en el ranking. Para saber cómo conseguir más, puedes preguntar por el chat.',
    en: 'Get knowledge points to have an extra in the ranking. To know how to get more, you can ask for the chat.',
    cat: 'Aconsegueix punts de coneixement per tenir un extra en el ranking. Per saber com aconseguir-ne més, pots preguntar pel xat.',
    eus: 'Lortu ezagutza puntuak rankingean gehigarri bat izateko. Gehiago lortzeko, txat bidez galdetu dezakezu.',
    pt: 'Obtenha pontos de conhecimento para ter um extra no ranking. Para saber como obter mais, você pode perguntar pel chat.'
  },
  REFERRAL_PROMO_TITLE: {
    es: 'Gana 600€',
    en: 'Win 600€',
    cat: 'Guanya 600€',
    eus: '600€ irabazi',
    pt: 'Ganhe 600€'
  },
  REFERRAL_PROMO_SUBTITLE: {
    es: 'Invita a 4 amigos',
    en: 'Invite 4 friends',
    cat: 'Convida 4 amics',
    eus: 'Gonbidatu 4 lagun',
    pt: 'Convidar 4 amigos'
  },
  REFERRAL_PROMO_DESCRIPTION: {
    es: 'Gana {{amount}}€ por cada amigo que se venga a Ucademy',
    en: 'Win {{amount}}€ for each friend who comes to Ucademy',
    cat: 'Guanya {{amount}}€ per cada amic que vingui a Ucademy',
    eus: '{{amount}}€ irabazi lagun bakoitzeko Ucademyra etortzen denean',
    pt: 'Ganhe {{amount}}€ por cada amigo que vier para o Ucademy'
  },
  REFERRAL_PROMO_BUTTON: {
    es: 'Invitar a un amigo',
    en: 'Invite a friend',
    cat: 'Convida un amic',
    eus: 'Gonbidatu lagun bat',
    pt: 'Convidar um amigo'
  },
  RANKING: {
    es: 'Ranking',
    en: 'Ranking',
    cat: 'Ranking',
    eus: 'Ranking',
    pt: 'Ranking'
  },
  GO_TO_RANKING: {
    es: 'Ir a los rankings',
    en: 'Go to rankings',
    cat: 'Anar als rankings',
    eus: 'Joan ranking-era',
    pt: 'Ir para os rankings'
  },
  NOT_JOINED_ANY_EXAM_GROUP: {
    es: '¡Ups!, todavía no te has unido a ninguna convocatoria',
    en: 'Oops!, you have not joined any exam group yet',
    cat: "Ups!, encara no t'has unit a cap convocatòria",
    eus: 'Ups!, oraindik ez zara deialdirik batera',
    pt: 'Ups!, ainda não está inscrito em nenhuma convocatória'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu',
    pt: 'Estado'
  },
  MY_EXAM_GROUPS: {
    es: 'Mis convocatorias',
    en: 'My exam groups',
    cat: 'Les meves convocatòries',
    eus: 'Nire deialdiak',
    pt: 'Minhas convocatórias'
  },
  HOW_DO_EXAM_GROUPS_WORK: {
    es: '¿Cómo funcionan las convocatorias?',
    en: 'How do exam groups work?',
    cat: 'Com funcionen les convocatòries?',
    eus: 'Nola funtzionatzen dute deialdiak?',
    pt: 'Como funcionam as convocatórias?'
  },
  EXAM_GROUPS_EXPLANATION_1: {
    es: 'En Ucademy publicamos todas las convocatorias oficiales del BOE en la plataforma todos los días. Cuando el gobierno autonómico, provincial o estatal pública una convocatoria, no salen siempre todos los datos al principio. Algunos se actualizan más adelante. Cuando el gobierno correspondiente los actualiza, nosotros también.',
    en: 'At Ucademy we publish all the official BOE calls on the platform every day. When the regional, provincial or state government publishes a call, not all the data comes out at the beginning. Some are updated later. When the corresponding government updates them, we do too.',
    cat: 'A Ucademy publiquem totes les convocatòries oficials del BOE a la plataforma cada dia. Quan el govern autonòmic, provincial o estatal publica una convocatòria, no surten sempre totes les dades al principi. Algunes s`actualitzen més endavant. Quan el govern corresponent les actualitza, nosaltres també.',
    eus: 'Ucademyk egunero BOEko deialdi ofizial guztiak argitaratzen ditu plataforma. Autonomia, probintzia edo estatuko gobernuak deialdia argitaratzen duenean, ez dira beti datu guztiak agertzen hasieran. Batzuk geroago eguneratzen dira. Gobernu horrek eguneratzen dituenean, guk ere eguneratzen dugu.',
    pt: 'A Ucademy publicamos todas as convocatórias oficiais do BOE na plataforma todos os dias. Quando o governo autónomo, provincial ou estadual publica uma convocatória, nem sempre todos os dados saem no início. Alguns são atualizados mais tarde. Quando o governo correspondente atualiza, nós também.'
  },
  EXAM_GROUPS_EXPLANATION_2: {
    es: 'Así que no te preocupes si hay algún dato en el que pone “No disponible”. Lo actualizaremos en cuanto se publique, y si estás apuntado a la convocatoria, te lo notificaremos.',
    en: "So don't worry if there is any data that says 'Not available'. We will update it as soon as it is published, and if you are signed up for the call, we will notify you.",
    cat: "Així que no et preocupis si hi ha alguna dada que posa 'No disponible'. L'actualitzarem en quant es publiqui, i si estàs apuntat a la convocatòria, t'ho notificarem.",
    eus: 'Beraz, ez kezkatu "Ez dagoela" esaten duen edozein datu. Eguneratuko dugu argitaratzen denean, eta deialdian apuntatuta badagozu, jakinaraziko dizugu.',
    pt: 'Bem, não se preocupe se houver algum dado que diz "Não disponível". Atualizaremos assim que for publicado, e se você está inscrito na convocatória, você será notificado.'
  },
  EXAM_GROUPS_EXPLANATION_3: {
    es: 'Lo mismo con las convocatorias disponibles: Si no la encuentras o ves vacío el listado, es porque no ha salido.',
    en: 'The same with the available calls: If you do not find it or see the list empty, it is because it has not come out.',
    cat: 'El mateix amb les convocatòries disponibles: Si no la trobes o veus buit el llistat, és perquè no ha sortit.',
    eus: 'Berdin deialdiak eskuragarriak direnean: Ez baduzu aurkitzen edo zerrenda hutsa ikusten baduzu, ez da agertu.',
    pt: 'O mesmo com as convocatórias disponíveis: Se não a encontrar ou ver a lista vazia, é porque ainda não saiu.'
  },
  JOINED_ON: {
    es: 'Se unió en {{month}} de {{year}}',
    en: 'Joined on {{month}} of {{year}}',
    cat: 'Es va unir en {{month}} de {{year}}',
    eus: '{{year}}ko {{month}}an batu zen',
    pt: 'Uniu-se em {{month}} de {{year}}'
  },
  IN_WHICH_CA_DO_YOU_APPLY: {
    es: '¿En qué Comunidad Autónoma te presentas?',
    en: 'In which Autonomous Community do you apply?',
    cat: 'A quina Comunitat Autònoma et presentes?',
    eus: 'Zein Autonomia Erkidegotan aurkeztuko zara?',
    pt: 'Em qual Comunidade Autónoma se apresenta?'
  },
  WHAT_FIELD_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué rama quieres estudiar?',
    en: 'Which field do you want to study?',
    cat: 'Quina branca vols estudiar?',
    eus: 'Zein arlotan ikasi nahi duzu?',
    pt: 'Em qual área quer estudar?'
  },
  WHAT_CAREER_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué carrera te gustaría estudiar?',
    en: 'Which career would you like to study?',
    cat: 'Quina carrera t`agradaria estudiar?',
    eus: 'Zein karrera ikasi nahi duzu?',
    pt: 'Em qual carreira quer estudar?'
  },
  WHAT_SUBJECTS_DO_YOU_WANT_TO_STUDY: {
    es: '¿Qué asignaturas vas a estudiar?',
    en: 'Which subjects are you going to study?',
    cat: 'Quines assignatures estudiaràs?',
    eus: 'Zein ikasgai ikasiko dituzu?',
    pt: 'Em qual disciplina quer estudar?'
  },
  WHEN_ARE_YOU_GOING_TO_APPLY: {
    es: '¿Cuándo te vas a presentar?',
    en: 'When are you going to apply?',
    cat: 'Quan et presentaràs?',
    eus: 'Noiz aurkeztuko zara?',
    pt: 'Quando vai se apresentar?'
  },
  WHAT_IS_YOUR_BIRTH_COUNTRY: {
    es: '¿Cuál es tu país de nacimiento?',
    en: 'What is your country of birth?',
    cat: 'Quin és el teu país de naixement?',
    eus: 'Zein da zure jaiotze-herria?',
    pt: 'Qual é o seu país de nascimento?'
  },
  WHAT_IS_YOUR_AGE: {
    es: '¿Cuántos años tienes?',
    en: 'How old are you?',
    cat: 'Quants anys tens?',
    eus: 'Zenbat urte dituzu?',
    pt: 'Quantos anos tem?'
  },
  I_DONT_KNOW_YET: {
    es: 'Aún no lo sé',
    en: "I don't know yet",
    cat: 'Encara no ho sé',
    eus: 'Oraindik ez dakit',
    pt: 'Ainda não sei'
  },
  HAVE_YOU_TAKEN_AN_OPPOSITION: {
    es: '¿Te has presentado alguna vez a una oposición?',
    en: 'Have you ever taken an opposition?',
    cat: 'T`has presentat alguna vegada a una oposició?',
    eus: 'Egin duzu inoiz oposizio bat?',
    pt: 'Já participou em uma oposição?'
  },
  WHEN_DID_YOU_TAKE_IT: {
    es: '¿Cuándo te presentaste?',
    en: 'When did you take it?',
    cat: 'Quan et vas presentar?',
    eus: 'Noiz aurkeztu zinen?',
    pt: 'Quando participou?'
  },
  WHAT_OPPOSITION_DID_YOU_TAKE: {
    es: '¿A qué oposición?',
    en: 'What opposition did you take?',
    cat: 'A quina oposició?',
    eus: 'Zein oposizio?',
    pt: 'Qual oposição?'
  },
  WHAT_STUDIES_DO_YOU_HAVE: {
    es: '¿Qué estudios tienes?',
    en: 'What studies do you have?',
    cat: 'Quins estudis tens?',
    eus: 'Zeintzuk dituzu ikasketak?',
    pt: 'Quais estudos tem?'
  },
  WHAT_IS_YOUR_OCCUPATION: {
    es: '¿Cuál es tu profesión?',
    en: 'What is your occupation?',
    cat: 'Quina és la teva professió?',
    eus: 'Zein da zure profesioa?',
    pt: 'Qual é a sua profissão?'
  },
  ARE_YOU_CURRENTLY_WORKING: {
    es: '¿Estás trabajando actualmente?',
    en: 'Are you currently working?',
    cat: 'Estàs treballant actualment?',
    eus: 'Lan egiten ari zara orain?',
    pt: 'Está trabalhando atualmente?'
  },
  WHAT_IS_YOUR_CURRENT_OCCUPATION: {
    es: '¿Cuál es tu profesión actual?',
    en: 'What is your current occupation?',
    cat: 'Quina és la teva professió actual?',
    eus: 'Zein da zure uneko profesioa?',
    pt: 'Qual é a sua profissão atual?'
  },
  IN_WHICH_CA_DO_YOU_LIVE: {
    es: '¿En qué Comunidad Autónoma vives?',
    en: 'In which Autonomous Community do you live?',
    cat: 'A quina Comunitat Autònoma vius?',
    eus: 'Zein Autonomia Erkidegotan bizi zara?',
    pt: 'Em qual Comunidade Autónoma vive?'
  },
  WHEN_DO_YOU_PREFER_LIVE_CLASSES: {
    es: '¿Cuándo prefieres que sean las clases en directo?',
    en: 'When do you prefer live classes?',
    cat: 'Quan prefereixes que siguin les classes en directe?',
    eus: 'Noiz nahi duzu direla klaseak zuzenean?',
    pt: 'Quando prefere as aulas ao vivo?'
  },
  CERTIFIED_GRADES_CERTIFICATE_OR_TITLE: {
    es: 'Certificado de notas o título compulsado',
    en: 'Certified grades certificate or title',
    cat: 'Certificat de notes o títol compulsat',
    eus: 'Nota egiaztatua edo titulua',
    pt: 'Certificado de notas ou título compulsado'
  },
  WE_NEED_IT_FOR_THE_CAM: {
    es: 'Lo necesitamos para la CAM (administración pública)',
    en: 'We need it for the CAM (public administration)',
    cat: 'Ho necessitem per la CAM (administració pública)',
    eus: 'Behar dugu CAM (administrazio publikoa)',
    pt: 'Precisamos disso para a CAM (administração pública)'
  },
  WE_NEED_IT_FOR_THE_CAM_IN_PDF_FORMAT: {
    es: 'Lo necesitamos para la CAM (administración pública) y en formato PDF.',
    en: 'We need it for the CAM (public administration) and in PDF format.',
    cat: 'Ho necessitem per la CAM (administració pública) i en format PDF.',
    eus: 'Behar dugu CAM (administrazio publikoa) eta PDF formatuan.',
    pt: 'Precisamos disso para a CAM (administração pública) e no formato PDF.'
  },
  DO_YOU_NEED_STUDENT_VISA: {
    es: '¿Necesitas visado de estudiante?',
    en: 'Do you need a student visa?',
    cat: 'Necessites visat d`estudiant?',
    eus: 'Behar duzu ikasle biza?',
    pt: 'Precisa de visto de estudante?'
  },
  ATTACH_THE_HOMOLOGATION_REQUEST: {
    es: 'Adjunta la solicitud de homologación',
    en: 'Attach the homologation request',
    cat: 'Adjunta la sol·licitud d`homologació',
    eus: 'Erantsi omologazio eskaera',
    pt: 'Anexar a solicitação de homologação'
  },
  ENROLLMENT: {
    es: 'Matrícula',
    en: 'Enrollment',
    cat: 'Matrícula',
    eus: 'Matrikula',
    pt: 'Matrícula'
  },
  YOUR_SOCIAL_SECURITY_NUMBER: {
    es: 'Tu número de la Seguridad Social',
    en: 'Your Social Security number',
    cat: 'El teu número de la Seguretat Social',
    eus: 'Zure Segurtasun Sozial zenbakia',
    pt: 'O seu número de Segurança Social'
  },
  PHOTOCOPY_OF_YOUR_ID: {
    es: 'Fotocopia de tu DNI/NIE',
    en: 'Photocopy of your ID',
    cat: 'Fotocòpia del teu DNI/NIE',
    eus: 'Zure NIEren argazkia',
    pt: 'Cópia fotocópica do seu DNI/NIE'
  },
  PORTRAIT_PHOTO: {
    es: 'Foto de carnet',
    en: 'Portrait photo',
    cat: 'Foto de carnet',
    eus: 'Argazki erretratu',
    pt: 'Fotografia de porte'
  },
  FRONT_AND_BACK_SIDES: {
    es: 'Parte delantera y trasera',
    en: 'Front and back sides',
    cat: 'Part frontal i posterior',
    eus: 'Aurrealdea eta atzealdea',
    pt: 'Parte frontal e posterior'
  },
  FRONTAL_BUST_PHOTO: {
    es: 'Foto del busto de frente, como la que figura en tu DNI o NIE.',
    en: 'Frontal bust photo, like the one on your ID.',
    cat: 'Foto del bust de front, com la que figura al teu DNI o NIE.',
    eus: 'Aurrealdeko busto argazkia, zure DNI edo NIEan agertzen den bezala.',
    pt: 'Fotografia de busto frontal, como a que está no seu DNI ou NIE.'
  },
  EXAMS_SCORES: {
    es: 'Notas de exámenes',
    en: 'Exam scores',
    cat: "Notes d'exàmens",
    eus: 'Ikasle-notak',
    pt: 'Notas de exames'
  },
  [EExamTypes.ONSITE]: {
    es: 'Exámenes presenciales',
    en: 'Onsite exams',
    cat: 'Exàmens presencials',
    eus: 'Presencialen examenak',
    pt: 'Exames presenciais'
  },
  [EExamTypes.DELIVERABLE]: {
    es: 'Documentos entregables',
    en: 'Deliverable documents',
    cat: 'Documents entregables',
    eus: 'Entregatutako dokumentuak',
    pt: 'Documentos entregáveis'
  },
  [EExamTypes.ONLINE]: {
    es: 'Exámenes online',
    en: 'Online exams',
    cat: 'Exàmens online',
    eus: 'Onlineen examenak',
    pt: 'Exames online'
  },
  MY_EXAMS_SCORES: {
    es: 'Mis notas de exámenes',
    en: 'My exam scores',
    cat: "Les meves notes d'exàmens",
    eus: 'Nire ikasle-notak',
    pt: 'Minhas notas de exames'
  },
  MY_EXAMS_SCORES_DESCRIPTION: {
    es: 'Exámenes y archivos online y presenciales puntuados.',
    en: 'Online and onsite exams and documents scored.',
    cat: 'Exàmens i documents online i presencials qualificats.',
    eus: 'Onlineen eta presencialen examenak eta dokumentuak kalifikatuta.',
    pt: 'Exames online e presenciais e documentos qualificados.'
  },
  PLANIFICATION: {
    es: 'Planificación del estudio',
    en: 'Study plan',
    cat: 'Pla de treball',
    eus: 'Ikasketa plan',
    pt: 'Plano de estudo'
  },
  REPLANIFY: {
    es: 'Replanificar',
    en: 'Replanify',
    cat: 'Replanificar',
    eus: 'Berreplanifikatu',
    pt: 'Replanificar'
  },
  ARE_YOU_SURE_TO_REPLANIFY: {
    es: '¿Estás seguro de querer replanificar?',
    en: 'Are you sure you want to replanify?',
    cat: 'Estàs segur que vols replanificar?',
    eus: 'Ziur zaude replanifikatu nahi duzula?',
    pt: 'Tem certeza que deseja replanificar?'
  },
  PLANIFICATION_RESULT_END_DATE: {
    es: 'Día de finalización del estudio según tu planificación:',
    en: 'Study end date according to your plan:',
    cat: "Data finalització de l'estudi segons el teu pla:",
    eus: 'Ikasketa bukaeraren data zure planifikazioaren arabera:',
    pt: 'Data de finalização do estudo de acordo com o seu plano:'
  },
  PLANIFICATION_RESULT_DAILY_TIME: {
    es: 'Tiempo de estudio mínimo al día con tu planificación:',
    en: 'Minimum study time per day according to your plan:',
    cat: "Temps mínim d'estudi al dia segons el teu pla:",
    eus: 'Ikasketa-denbora minimoa eguneko zure planifikazioaren arabera:',
    pt: 'Tempo mínimo de estudo por dia de acordo com o seu plano:'
  },
  PLANIFICATION_REST_DAYS_TITLE: {
    es: 'Días que no vas a estudiar',
    en: 'Days you will not study',
    cat: 'Günler, en que no et vas a estudiar',
    eus: 'Ikasketa ez egiteko egunak',
    pt: 'Dias que não vai estudar'
  },
  PLANIFICATION_SUBSCRIPTIONS_TITLE: {
    es: 'Contenido de la planificación',
    en: 'Planification content',
    cat: 'Contingut del pla de treball',
    eus: 'Ikasketa planifikazioaren edukia',
    pt: 'Conteúdo do plano de estudo'
  },
  PLANIFICATION_DATES_TITLE: {
    es: 'Planificar según...',
    en: 'Planify according to...',
    cat: 'Planificar segons...',
    eus: 'Planifikatu zehaztutako arabera...',
    pt: 'Planificar de acordo com...'
  },
  PLANIFICATION_DATES_STUDY_HOURS: {
    es: 'Horas de estudio al día',
    en: 'Study hours per day',
    cat: 'Estudi al dia',
    eus: 'Ikasketa-denbora egunekoa',
    pt: 'Tempo de estudo por dia'
  },
  PLANIFICATION_DATES_END_DATE: {
    es: 'Fecha de finalización deseada',
    en: 'Desired study end date',
    cat: "Data final desitjada d'estudi",
    eus: 'Ikasketa bukaeraren data zehaztutakoa',
    pt: 'Data de finalização desejada do estudo'
  },
  PLANIFICATION_DEACTIVATE_TITLE: {
    es: 'Desactivar planificación del estudio',
    en: 'Deactivate study plan',
    cat: 'Desactivar pla de treball',
    eus: 'Ikasketa planifikazioa desaktibatu',
    pt: 'Desativar o plano de estudo'
  },
  PLANIFICATION_DEACTIVATE_SUBTITLE: {
    es: 'Dejarás de ver las tareas diarias recomendadas y tu planificación del contenido en el calendario',
    en: 'You will no longer see the daily recommended tasks and your planification content in the calendar',
    cat: 'No veurem les tasques diàries recomanades i el teu pla de treball en el calendari',
    eus: 'Ez duzu eguneko zerbitzuak aurreikusitakoak eta zure planifikazioaren edukia kalendarioan ikusi',
    pt: 'Não verá as tarefas recomendadas diariamente e o conteúdo do seu plano de estudo no calendário'
  },
  PLANIFICATION_DEACTIVATE_SWITCH_LABEL_ACTIVE: {
    es: 'Activada',
    en: 'Active',
    cat: 'Activada',
    eus: 'Aktibatua',
    pt: 'Ativa'
  },
  PLANIFICATION_DEACTIVATE_SWITCH_LABEL_INACTIVE: {
    es: 'Desactivada',
    en: 'Inactive',
    cat: 'Desactivada',
    eus: 'Desaktibatua',
    pt: 'Inativa'
  },
  PLANIFICATION_HOW_IT_WORKS: {
    es: '¿Cómo funciona la planificación?',
    en: 'How does the plan work?',
    cat: 'Com funciona el pla?',
    eus: 'Nola funtzionatzen du planifikazioa?',
    pt: 'Como funciona o plano?'
  }
};
