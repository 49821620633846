import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;
