import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black2 : theme.colors.white};
  color: ${({ theme }) => theme.colors.black6};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.black2};
  }
`;
