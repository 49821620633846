import { EQuizMode } from '@constants';

export const StudentQuizReviewTranslations = {
  [EQuizMode.FREE_MODE]: {
    es: 'Modo libre',
    en: 'Free mode',
    cat: 'Mode lliure',
    eus: 'Modo libre',
    pt: 'Modo livre'
  },
  [EQuizMode.PRACTICE_MODE]: {
    es: 'Modo simulacro',
    en: 'Simulator mode',
    cat: 'Mode simulació',
    eus: 'Simulazio modua',
    pt: 'Modo de simulação'
  },
  [EQuizMode.ACTIVITY_MODE]: {
    es: 'Modo actividad',
    en: 'Activity mode',
    cat: 'Mode activitat',
    eus: 'Jarduera modua',
    pt: 'Modo de atividade'
  }
};
