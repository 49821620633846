import styled from 'styled-components';

export const CommentsTitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const CommentsSubtitleWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  color: ${({ theme }) => theme.colors.black7};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
