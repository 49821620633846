export const TeacherExamGroupsTranslations = {
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Convocatorias',
    pt: 'Convocatórias'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  TAG: {
    es: 'Categoría',
    en: 'Category',
    cat: 'Categoria',
    eus: 'Kategoria',
    pt: 'Categoria'
  },
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  PUBLICATION_DATE: {
    es: 'Fecha de publicación',
    en: 'Publication date',
    cat: 'Data de publicació',
    eus: 'Argitaratze data',
    pt: 'Data de publicação'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  CCAA: {
    es: 'CCAA',
    en: 'CCAA',
    cat: 'CCAA',
    eus: 'CCAA',
    pt: 'CCAA'
  },
  AUTONOMOUS_COMMUNITY: {
    es: 'Comunidad Autónoma',
    en: 'Autonomous Community',
    cat: 'Comunitat Autònoma',
    eus: 'Autonomia Erkidegoa',
    pt: 'Comunidade Autónoma'
  },
  EXAM_GROUPS_TOTAL_COUNT: {
    es: '{{total}} convocatorias',
    en: '{{total}} exam groups',
    cat: '{{total}} convocatòries',
    eus: '{{total}} convocatorias',
    pt: '{{total}} convocatórias'
  },
  EXAM_GROUPS_EMPTY: {
    es: 'No tienes convocatorias creadas',
    en: 'You have no exam groups created',
    cat: 'No tens convocatòries creades',
    eus: 'Ez duzu convocatoria sorturik',
    pt: 'Não tem convocatórias criadas'
  },
  STATE: {
    es: 'Estatal',
    en: 'State',
    cat: 'Estat',
    eus: 'Estatu',
    pt: 'Estado'
  }
};
