import { Flex } from 'antd';
import styled from 'styled-components';

type Props = {
  $disabled: boolean;
};

export const Wrapper = styled(Flex)<Props>`
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  background: ${({ theme, $disabled }) => ($disabled ? theme.colors.black2 : theme.colors.white)};
  cursor: ${({ $disabled }) => ($disabled ? 'pointer' : 'default')};
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    flex-direction: row;
    align-items: center;
    max-width: 800px;
  }
`;

export const EmojiWrapper = styled(Flex)`
  width: 80px;
  height: 80px;
  padding: 12px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.black2};

  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const DescriptionWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const ExtraWrapper = styled.div<Props>`
  color: ${({ theme, $disabled }) => ($disabled ? theme.colors.black9 : theme.colors.green6)};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
