import { EFeatures } from '@constants';
import { lazy } from 'react';

import { IRoute } from '../../types/routes';

import { LiveEventLottie } from '@/components/LiveEventLottie';
import { PendingChatsBadge } from '@/components/PendingChatsBadge';

const OnboardingPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.OnboardingPage }))
);
const StudentDashboardPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.DashboardPage }))
);
const CoursePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.CoursePage }))
);
const QuizzesPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.QuizzesPage }))
);
const QuizPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.QuizPage }))
);
const QuizReviewPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.QuizReviewPage }))
);
const TicketsPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.TicketsPage }))
);
const EventsPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.EventsPage }))
);
const EventPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.EventPage }))
);
const ChatPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.ChatPage }))
);
const StatsPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.StatsPage }))
);
const ReviewPreview = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.ReviewPreview }))
);
const ReviewMode = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.ReviewMode }))
);
const ProfilePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.ProfilePage }))
);
const ExamGroupDetailPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.ExamGroupDetail }))
);

const RankingPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.RankingPage }))
);
const FreeModePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.FreeModePage }))
);
const StorePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.StorePage }))
);
const PracticeModePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.PracticeModePage }))
);

const CourseSummaryPage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.CourseSummaryPage }))
);
const CommunityPage = lazy(() =>
  import('@/pages/Common').then(module => ({ default: module.CommunityPage }))
);
const RateYourExperiencePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.RateYourExperiencePage }))
);
const TutorVotePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.TutorVotePage }))
);
const TeacherVotePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.TeacherVotePage }))
);
const LiveClassVotePage = lazy(() =>
  import('@/pages/Student').then(module => ({ default: module.LiveClassVotePage }))
);

export const STUDENT: IRoute[] = [
  {
    title: 'ONBOARDING',
    path: '/onboarding',
    icon: 'Undefined',
    Component: OnboardingPage,
    showInMenu: false
  },
  {
    title: 'DASHBOARD',
    path: '/dashboard',
    icon: 'Home',
    Component: StudentDashboardPage,
    showInMenu: true
  },
  {
    title: 'STUDY',
    path: '/subscription/summary',
    icon: 'Book',
    Component: CourseSummaryPage,
    availableForFeatures: [EFeatures.CONTENT],
    showInMenu: true
  },
  {
    title: 'PRACTICE_WITH_TESTS',
    path: '/tests',
    icon: 'TestTool',
    Component: QuizzesPage,
    availableForFeatures: [EFeatures.QUIZZES],
    showInMenu: true,
    subroutes: [
      {
        title: 'ALL_TEST',
        path: '',
        icon: 'Undefined',
        Component: QuizzesPage,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: ':_id',
        icon: 'Undefined',
        Component: QuizPage,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: ':_id/review',
        icon: 'Undefined',
        Component: QuizReviewPage,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: 'review/preview',
        icon: 'Undefined',
        Component: ReviewPreview,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: 'review/mode',
        icon: 'Undefined',
        Component: ReviewMode,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: 'free-mode',
        icon: 'Undefined',
        Component: FreeModePage,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      },
      {
        title: 'ALL_TEST',
        path: 'practice-mode',
        icon: 'Undefined',
        Component: PracticeModePage,
        availableForFeatures: [EFeatures.QUIZZES],
        showInMenu: false
      }
    ]
  },
  {
    title: 'CALENDAR',
    path: '/content/calendar',
    icon: 'EventSchedule',
    Component: EventsPage,
    availableForFeatures: [EFeatures.LIVE_CLASSES, EFeatures.PLANIFICATION],
    showInMenu: true,
    TitleAddon: LiveEventLottie,
    subroutes: [
      {
        title: 'LIVE_EVENTS',
        path: ':_id',
        icon: 'Undefined',
        Component: EventPage,
        availableForFeatures: [EFeatures.LIVE_CLASSES],
        showInMenu: false
      }
    ]
  },
  {
    title: 'DOUBTS',
    path: 'tickets',
    icon: 'Undefined',
    Component: TicketsPage,
    showInMenu: true
  },
  {
    title: 'CHAT_TUTOR',
    path: 'chat',
    icon: 'Undefined',
    Component: ChatPage,
    availableForFeatures: [EFeatures.TUTORING],
    showInMenu: true,
    TitleAddon: PendingChatsBadge
  },
  {
    title: 'CHAT_TUTOR',
    path: 'chat/:tutorId/:studentId',
    icon: 'Undefined',
    Component: ChatPage,
    availableForFeatures: [EFeatures.TUTORING],
    showInMenu: false
  },
  {
    title: 'COMMUNITY',
    path: '/community',
    icon: 'Undefined',
    Component: CommunityPage,
    showInMenu: true,
    subroutes: [
      {
        title: 'RANKING',
        path: 'ranking',
        icon: 'Undefined',
        Component: RankingPage,
        showInMenu: true
      },
      {
        title: 'EXAM_GROUPS',
        path: 'exam-groups',
        icon: 'Undefined',
        Component: ExamGroupDetailPage,
        availableForFeatures: [EFeatures.ANNOUNCEMENTS],
        showInMenu: true
      },
      {
        title: 'STORE',
        path: 'store',
        icon: 'ShoppingBag',
        Component: StorePage,
        showInMenu: true
      },
      {
        title: 'RATE_YOUR_EXPERIENCE',
        path: 'rate-your-experience',
        icon: 'Undefined',
        Component: RateYourExperiencePage,
        showInMenu: true,
        subroutes: [
          {
            title: 'TUTOR_VOTE',
            path: 'tutor-vote',
            icon: 'Undefined',
            Component: TutorVotePage,
            showInMenu: false
          },
          {
            title: 'TEACHER_VOTE',
            path: 'teacher-vote',
            icon: 'Undefined',
            Component: TeacherVotePage,
            showInMenu: false
          },
          {
            title: 'LIVE_CLASS_VOTE',
            path: 'live-class-vote',
            icon: 'Undefined',
            Component: LiveClassVotePage,
            showInMenu: false
          }
        ]
      }
    ]
  },
  {
    title: 'COURSES',
    path: '/subscription',
    icon: 'Undefined',
    Component: CoursePage,
    showInMenu: false,
    availableForFeatures: [EFeatures.CONTENT],
    subroutes: [
      {
        title: 'COURSES',
        path: 'summary',
        icon: 'Undefined',
        Component: CourseSummaryPage,
        availableForFeatures: [EFeatures.CONTENT],
        showInMenu: false
      },
      {
        title: 'COURSES',
        path: ':_id',
        icon: 'Undefined',
        Component: CoursePage,
        availableForFeatures: [EFeatures.CONTENT],
        showInMenu: false
      }
    ]
  },
  {
    title: 'STATS',
    path: '/stats',
    icon: 'ChartClusterBar',
    Component: StatsPage,
    showInMenu: false
  },
  {
    title: 'PROFILE',
    path: '/profile',
    icon: 'Undefined',
    Component: ProfilePage,
    showInMenu: false
  }
];
