import { ERoles, ETicketStatus } from '@constants';
import { AnalyticsTrackEvents } from '@constants';
import {
  IChangeUsefulMessageVote,
  ICreateTicket,
  IDeleteMessage,
  IModeratedTicket,
  ISendTicketMessage,
  ISetTicketReview,
  ITicket,
  IUpdateAssignee
} from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/hooks';
import { sdk } from '@/services';

const TicketService = sdk.getService('TicketService');

export const useMutateMyTickets = () => {
  const { t } = useTranslation(['CommonTicketsTranslations', 'StudentTicketsTranslations']);
  const { fireEvent } = useAnalytics();

  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries({ queryKey: ['tickets'] });

  const { mutate: updateTicket, isPending: isUpdating } = useMutation({
    mutationFn: (ticket: Partial<ITicket>) => TicketService.updateTicket(ticket),
    onSuccess
  });

  const { mutate: setTicketAsResolved, isPending: isResolving } = useMutation({
    mutationFn: (ticketId: string) => TicketService.setTicketAsResolved(ticketId),
    onSuccess: data => {
      onSuccess();
      fireEvent(AnalyticsTrackEvents.DOUBTS_CLOSED, {
        ticketId: data._id,
        courseName: data.ticketRelatedContent?.course.name,
        messagesAmount: data.messages?.length,
        teacherName: `${data.assignee?.name} ${data.assignee?.lastName}`,
        resolved: data.status === ETicketStatus.CLOSED_RESOLVED
      });
    }
  });

  const { mutate: removeTicket, isPending: isRemoving } = useMutation({
    mutationFn: (ticketId: string) => TicketService.removeTicket(ticketId),
    onSuccess: () => {
      onSuccess();
      message.success(t('CommonTicketsTranslations:TICKET_REMOVED_SUCCESSFULLY'));
    }
  });

  const { mutate: sendMessage, isPending: isSending } = useMutation({
    mutationFn: (sendTicketMessage: ISendTicketMessage) =>
      TicketService.sendMessage(sendTicketMessage),
    onSuccess: ({ canCreateTicket, ticket }: IModeratedTicket) => {
      if (!canCreateTicket) return;
      onSuccess();
      message.success(t('CommonTicketsTranslations:TICKET_MESSAGE_SENT_SUCCESSFULLY'));
      if (!ticket) return;
      fireEvent(AnalyticsTrackEvents.THREAD_COMMENT, {
        ticketId: ticket._id,
        courseName: ticket.ticketRelatedContent?.course.name,
        teacher: ticket.messages?.[ticket.messages.length - 1].sender.role === ERoles.TEACHER
      });
    }
  });

  const { mutate: setTicketReview, isPending: isSettingReview } = useMutation({
    mutationFn: (ticketReview: ISetTicketReview) => TicketService.setTicketReview(ticketReview),
    onSuccess
  });

  const { mutate: updateTicketAssignee, isPending: isUpdatingAssignee } = useMutation({
    mutationFn: (updateAssignee: IUpdateAssignee) =>
      TicketService.updateTicketAssignee(updateAssignee),
    onSuccess
  });

  const { mutate: changeUsefulTicketVote, isPending: isChangingUsefulTicketVote } = useMutation({
    mutationFn: (ticketId: string) => TicketService.changeUsefulTicketVote(ticketId),
    onSuccess
  });

  const { mutate: deleteMessage, isPending: isDeletingMessage } = useMutation({
    mutationFn: (m: IDeleteMessage) => TicketService.deleteMessage(m),
    onSuccess: () => {
      onSuccess();
      message.success(t('CommonTicketsTranslations:TICKET_MESSAGE_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: createTicket, isPending: isCreatingTicket } = useMutation({
    mutationFn: (ticket: ICreateTicket) => TicketService.createTicket(ticket),
    onSuccess: ({ canCreateTicket, ticket }: IModeratedTicket) => {
      if (!canCreateTicket) return;
      onSuccess();
      message.success(t('CommonTicketsTranslations:TICKET_CREATED_SUCCESSFULLY'));
      if (!ticket) return;
      fireEvent(AnalyticsTrackEvents.ACTIVITY_NEW_DOUBT, {
        ticketId: ticket._id,
        courseName: ticket.ticketRelatedContent?.course.name
      });
    }
  });

  const { mutate: changeUsefulMessageVote, isPending: isChangingUsefulMessageVote } = useMutation({
    mutationFn: (changeMessageVote: IChangeUsefulMessageVote) =>
      TicketService.changeUsefulMessageVote(changeMessageVote),
    onSuccess
  });

  return {
    updateTicket,
    removeTicket,
    sendMessage,
    setTicketReview,
    updateTicketAssignee,
    changeUsefulTicketVote,
    setTicketAsResolved,
    deleteMessage,
    createTicket,
    changeUsefulMessageVote,
    isWorking:
      isUpdating ||
      isRemoving ||
      isSending ||
      isSettingReview ||
      isUpdatingAssignee ||
      isChangingUsefulTicketVote ||
      isResolving ||
      isDeletingMessage ||
      isCreatingTicket ||
      isChangingUsefulMessageVote
  };
};
