import { useQuery } from '@tanstack/react-query';

import { useMySubscriptions } from './useMySubscriptions';

import { sdk } from '@/services';

const SubscriptionService = sdk.getService('SubscriptionService');

export const useMySubscriptionStudyStatus = () => {
  const { selectedSubscription } = useMySubscriptions();

  const { isLoading, data } = useQuery({
    queryKey: ['study-status', selectedSubscription?._id],
    queryFn: () => SubscriptionService.getSectionsStudyStatus(selectedSubscription?._id),
    enabled: !!selectedSubscription
  });

  return {
    studyStatus: data,
    isLoading
  };
};
