import { IFile } from '../file';
import { RequestService } from '../request';

import { IActivityChat } from './activity-chat.contract';

export class ActivityChatService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }

  public findActivityChat(activityId: string) {
    return this.fetcher<IActivityChat>(`/activity-chats/${activityId}`);
  }

  public async generatePdfActivitySummary(activityId: string) {
    return this.poster<IFile, string>(
      `/activity-chats/activity/${activityId}/generate-pdf-summary`
    );
  }
}
