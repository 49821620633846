import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    padding: 12px 0;

    & > p {
      font-size: 12px;
    }
  }
  .ql-editor.ql-blank::before {
    left: 0;
  }
`;

export const ResourceInfoWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black6};
`;
