import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
`;

export const CountWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black6};
`;
