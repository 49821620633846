import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { create as createStore } from 'zustand';

import { useActivityTrack } from '../subscriptions';

import { sdk } from '@/services';
import { ISorter } from '@/types';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 3;
const INITIAL_SORT: ISorter = { field: 'usefulTicket', order: 'descend' };

const TicketService = sdk.getService('TicketService');

interface IStore {
  page: number;
  limit: number;
  sort: ISorter;
  setLimit: (limit: number) => void;
  setSort: (sort: ISorter) => void;
}

const store = createStore<IStore>(set => ({
  page: INITIAL_PAGE,
  limit: INITIAL_LIMIT,
  sort: INITIAL_SORT,
  setLimit: (limit: number) => set(state => ({ ...state, limit })),
  setSort: (sort: ISorter) => set(state => ({ ...state, sort }))
}));

export const useActivityTicketsList = () => {
  const { page, limit, sort, setLimit, setSort } = store();
  const { getCurrentActivity } = useActivityTrack();
  const { activity } = getCurrentActivity();
  const queryKey = [
    'tickets',
    `activity-${activity?._id}`,
    `page-${page}`,
    `limit-${limit}`,
    `sort-${sort.field}-${sort.order}`
  ];

  const queryFn = () => {
    return TicketService.findAllTicketsByActivityId(activity?._id, { page, limit }, sort);
  };

  const { isPending, data, error } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData
  });

  const loadMoreTickets = () => {
    if (isPending || (data?.docs.length ?? 0) < limit) return;
    setLimit(limit + INITIAL_LIMIT);
  };

  const pagination = data && {
    page: data.page,
    limit: data.limit,
    hasNextPage: data.hasNextPage,
    hasPrevPage: data.hasPrevPage,
    nextPage: data.nextPage,
    pagingCounter: data.pagingCounter,
    prevPage: data.prevPage,
    totalDocs: data.totalDocs,
    totalPages: data.totalPages
  };

  return {
    tickets: data?.docs ?? [],
    isLoading: isPending,
    error,
    pagination,
    loadMoreTickets,
    sort,
    setSort
  };
};
