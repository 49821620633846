import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 128px 24px 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

export const TextWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black7};
`;
