export const StudentLiveClassVoteTranslations = {
  LIVE_CLASS_VOTE_TITLE: {
    es: 'Valora tus clases en directo',
    en: 'Rate your live classes',
    cat: 'Valorar les teves classes en directe',
    eus: 'Baloratu zure zuzeneko klaseak',
    pt: 'Avaliar suas aulas ao vivo'
  },
  LIVE_CLASS_VOTE_SUBTITLE_one: {
    es: 'Hay 1 clase sin valorar',
    en: 'There is 1 class without rating',
    cat: 'Hi ha 1 classe sense valorar',
    eus: 'Baloratu gabeko klase bat dago',
    pt: 'Há 1 aula sem avaliação'
  },
  LIVE_CLASS_VOTE_SUBTITLE_other: {
    es: 'Hay {{count}} clases sin valorar',
    en: 'There are {{count}} classes without rating',
    cat: 'Hi ha {{count}} classes sense valorar',
    eus: 'Kalifikaziorik gabeko {{count}} klase daude',
    pt: 'Há {{count}} aulas sem avaliação'
  },
  LIVE_CLASS_VOTE_COMMENTS_PLACEHOLDER: {
    es: 'Escribe aquí...',
    en: 'Write here...',
    cat: 'Escriu aquí...',
    eus: 'Idatzi hemen...',
    pt: 'Escreva aqui...'
  },
  SEND_VOTE_BUTTON: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Enviar',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  LIVE_CLASS_VOTE_COMMENTS_TITLE: {
    es: '¿Algo que destacar o mejorar de la clase?',
    en: 'Anything to highlight or improve about the class?',
    cat: '¿Alguna cosa que destacar o millorar de la classe?',
    eus: 'Zerbait nabarmendu edo hobetzeko klasean?',
    pt: 'Algo para destacar ou melhorar na aula?'
  },
  LIVE_CLASS_VOTE_TEACHER_RATING: {
    es: 'Valoración del profesor:',
    en: 'Teacher rating:',
    cat: 'Valoració del professor:',
    eus: 'Irakasleen balorazioa:',
    pt: 'Avaliação do professor:'
  },
  LIVE_CLASS_VOTE_CLASS_DIFFICULTY: {
    es: 'Dificultad de la clase:',
    en: 'Class difficulty:',
    cat: 'Dificultat de la classe:',
    eus: 'Klaseko zailtasuna:',
    pt: 'Dificuldade da aula:'
  }
};
